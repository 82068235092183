import React from 'react';
import '../style/components/how-use.scss';

const HowUse = ({ isShow, ImageSrc, toggleOpen }) => {
  return (
    <div className="how-use">
      <i className="fas fa-question-circle" onClick={toggleOpen}></i>
      <div
        className="how-use-img fadeIn"
        style={{ display: isShow ? 'flex' : 'none' }}>
        <div className="img-box bounceIn2">
          <div className="close" onClick={toggleOpen}>
            <i className="fas fa-times"></i>
          </div>
          <img src={ImageSrc} alt="如何使用" />
        </div>
      </div>
    </div>
  );
};

export default HowUse;
