import React from 'react';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const TableLoading = (props) => {
  const { rows } = props;

  return (
    <Table size="medium" aria-label="table loading">
      <TableBody>
        <TableRow>
          <TableCell>
            {Array.apply(0, Array(rows ? rows : 5)).map((row, index) => (
              <Skeleton key={`table_loading_${index}`} animation="wave" />
            ))}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default TableLoading;
