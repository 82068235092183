import React, { useState } from 'react';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import AttachFileRoundedIcon from '@material-ui/icons/AttachFileRounded';

const FileButton = (props) => {
  const {
    fileProps,
    isDisabled,
    uploadFile,
    children,
    buttonProps,
    onChange,
  } = props;
  const [files, setFiles] = useState(undefined);
  const isMultiple = fileProps && fileProps.multiple ? true : false;

  const handleFileUpload = (e) => {
    const files = e.target.files;

    if (uploadFile) {
      uploadFile(isMultiple ? files : files[0]);
    } else {
      setFiles(files);
    }

    if (onChange) {
      onChange(isMultiple ? files : files[0]);
    }
  };

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="stretch"
      spacing={2}>
      <Grid item>
        <Button
          {...buttonProps}
          disabled={isDisabled || false}
          component="label">
          {children}

          <input
            {...fileProps}
            style={{ display: 'none' }}
            type="file"
            onChange={handleFileUpload}
            disabled={isDisabled || false}
          />
        </Button>
      </Grid>
      {files &&
        _.map(files, (file, fileIndex) => {
          return (
            <Grid item xs key={fileIndex}>
              <Alert
                variant="outlined"
                size="small"
                severity="success"
                icon={<AttachFileRoundedIcon />}>
                {file.name}
              </Alert>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default FileButton;
