import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Switch,
  Box,
  FormHelperText,
} from '@material-ui/core';
import _ from 'lodash';
import SettingsBackupRestoreOutlinedIcon from '@material-ui/icons/SettingsBackupRestoreOutlined';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import PropTypes from 'prop-types';

const initSubject = {
  Name: '',
  Id: -1,
  QuestionSubjectId: '',
  Status: 0,
};

const initErrors = {
  name: {
    isError: false,
    helperText: '請輸入科目名稱',
  },
  questionSubjectId: {
    isError: false,
    helperText: '請選擇對應的題目科目',
  },
};

const QuestionBankSubjectFormDialog = (props) => {
  const [subject, setSubject] = useState({ ...initSubject });
  const [errors, setErrors] = useState(_.cloneDeep(initErrors));
  const [isProcessing, setIsProcessing] = useState(false);

  const handleChange = (type, value) => {
    let changeSubject = { ...subject, [type]: value };

    // 如果科目名稱有找到跟題目科目一樣的，自動代入該題目科目
    if (type === 'Name') {
      const sameSubject = _.find(props.questionSubjects, ['Name', value]);

      if (typeof sameSubject !== 'undefined') {
        changeSubject.QuestionSubjectId = sameSubject.Id;
      }
    }

    setSubject(changeSubject);
  };

  const handleAction = () => {
    setIsProcessing(true);

    let checkErrors = { ...errors };

    checkErrors.name.isError = _.trim(subject.Name) === '';
    checkErrors.questionSubjectId.isError =
      _.isInteger(subject.QuestionSubjectId) === false ||
      subject.QuestionSubjectId <= 0;

    setErrors(checkErrors);

    if (typeof _.find(checkErrors, ['isError', true]) === 'undefined') {
      props.action(subject).then((response) => {
        setIsProcessing(false);
      });
    } else {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    if (props.subject.Id === -1) {
      setSubject({ ...initSubject });
    } else {
      setSubject(props.subject);
    }

    return () => {
      setSubject({ ...initSubject });
      setErrors(_.cloneDeep(initErrors));
    };
  }, [props.subject]);

  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      fullWidth
      disableBackdropClick>
      {_.trim(props.label) != '' ? (
        <DialogTitle>{props.label}</DialogTitle>
      ) : null}

      <DialogContent>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="科目名稱"
              autoFocus
              fullWidth
              error={errors.name.isError}
              helperText={errors.name.isError ? errors.name.helperText : ''}
              value={subject.Name}
              onChange={(e) => {
                handleChange('Name', e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth error={errors.questionSubjectId.isError}>
              <InputLabel>對應的題目科目</InputLabel>
              <Select
                value={
                  _.isInteger(subject.QuestionSubjectId)
                    ? subject.QuestionSubjectId
                    : ''
                }
                onChange={(e) => {
                  handleChange('QuestionSubjectId', e.target.value);
                }}>
                {props.questionSubjects.map((questionSubject) => {
                  return (
                    <MenuItem
                      key={questionSubject.Id}
                      value={questionSubject.Id}>
                      {questionSubject.Name}
                    </MenuItem>
                  );
                })}
              </Select>
              {errors.questionSubjectId.isError && (
                <FormHelperText>
                  {errors.questionSubjectId.helperText}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="baseline">
              <Grid item xs={6}>
                狀態
              </Grid>
              <Grid item xs={6}>
                <Box textAlign="right">
                  <FormControlLabel
                    control={<Switch color="primary" />}
                    labelPlacement="start"
                    label={subject.Status === 1 ? '上線' : '下線'}
                    checked={subject.Status === 1}
                    onChange={(e) => {
                      handleChange('Status', e.target.checked ? 1 : 0);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveRoundedIcon />}
          onClick={handleAction}
          disabled={isProcessing}
          autoFocus>
          儲存
        </Button>
        <Button
          onClick={props.close}
          variant="contained"
          disabled={isProcessing}
          startIcon={<SettingsBackupRestoreOutlinedIcon />}>
          取消
        </Button>
      </DialogActions>
    </Dialog>
  );
};

QuestionBankSubjectFormDialog.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  action: PropTypes.func,
  label: PropTypes.string,
  questionSubjects: PropTypes.array,
};

export default QuestionBankSubjectFormDialog;
