import React from 'react';
import { NavLink } from 'react-router-dom';

import backgroundImage from '../images/backgroundImage.png';
import '../style/components/page-404.scss';

const Page404 = () => {
  let title = '您迷路了<br>趕快回家重新出發吧';
  return (
    <div className="page-404">
      <div
        className="image-block"
        style={{ backgroundImage: `url('${backgroundImage}')` }}></div>
      <div className="title" dangerouslySetInnerHTML={{ __html: title }}></div>
      <NavLink className="btn save" to={`/`}>
        回首頁
      </NavLink>
    </div>
  );
};

export default Page404;
