import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { numberToChineseWords } from '../../Util/Util';
import HangoutResources from './HangoutResources';
import ChapterName from './ChapterName';

const HangoutChapter = (props) => {
  const {
    chapterIndex,
    unitIndex,
    chapter,
    parentId,
    addResource,
    deleteResource,
    changeResource,
    changeResourceOrdering,
    deleteChapter,
    addUnit,
    handleInputChapterName,
    hangoutId,
    isUnit,
    refresh,
  } = props;
  const [animation, setAnimation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState(chapter.Name || '');

  useEffect(() => {
    if (chapter.HangoutChapterId === -1) {
      setIsEdit(true);
    }
  }, [chapter]);

  const isShowUnitAddbtn =
    !isUnit &&
    ((chapter.HangoutChapterId !== -1 && chapter.HangoutUnits.length <= 0) ||
      (chapter.HangoutUnits.length > 0 &&
        _.parseInt(
          chapter.HangoutUnits[chapter.HangoutUnits.length - 1]
            .HangoutChapterId,
        ) !== -1))
      ? true
      : false;

  const handleCancelReName = () => {
    if (isLoading) {
      return false;
    }

    setIsEdit(false);
    setName(chapter.Name);
  };

  const handleSaveName = () => {
    if (_.trim(name) === '') {
      Swal.fire({
        title: '請輸入名稱',
        icon: 'error',
      });

      return false;
    }

    let formData = new FormData();

    formData.append('Name', name);
    formData.append('HangoutChapterId', chapter.HangoutChapterId);
    formData.append('HangoutId', hangoutId);

    if (_.parseInt(parentId) > 0) {
      formData.append('ParentId', parentId);
    }

    setIsLoading(true);

    handleInputChapterName(formData, chapterIndex, unitIndex, () => {
      setIsEdit(false);
      setIsLoading(false);
    });
  };

  const handleDeleteChapter = () => {
    Swal.fire({
      icon: 'question',
      title: '確定要刪除嗎？',
      showCancelButton: true,
      confirmButtonText: '確定',
      cancelButtonText: '取消',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteChapter(chapter.HangoutChapterId, chapterIndex, unitIndex, () => {
          setAnimation(true);
        });
      }
    });
  };

  return (
    <>
      {!isUnit && (
        <div
          className={
            animation
              ? 'chapter-section slideInDown'
              : 'chapter-section slideOutUp'
          }>
          <div className="row">
            <div className="col-12" style={{ alignItems: 'baseline' }}>
              <label className="secret--input chapterNum">
                第{numberToChineseWords(chapterIndex + 1)}章
              </label>
              <div className="information-block">
                <ChapterName
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  chapterIndex={chapterIndex}
                  unitIndex={unitIndex}
                  name={name}
                  setName={setName}
                  isLoading={isLoading}
                  chapter={chapter}
                  handleCancelReName={handleCancelReName}
                  handleSaveName={handleSaveName}
                  handleDeleteChapter={handleDeleteChapter}
                />
                {chapter.HangoutChapterId !== -1 && (
                  <HangoutResources
                    addResource={addResource}
                    chapter={chapter}
                    deleteResource={deleteResource}
                    changeResource={changeResource}
                    changeResourceOrdering={changeResourceOrdering}
                    refresh={refresh}
                  />
                )}
              </div>
            </div>
          </div>

          {chapter.HangoutUnits &&
            chapter.HangoutUnits.length > 0 &&
            !isUnit &&
            _.map(chapter.HangoutUnits, (unit, unitIndex) => {
              return (
                <HangoutChapter
                  key={
                    chapterIndex + '_' + unitIndex + '_' + unit.HangoutChapterId
                  }
                  hangoutId={hangoutId}
                  chapter={unit}
                  chapterIndex={chapterIndex}
                  unitIndex={unitIndex}
                  parentId={chapter.HangoutChapterId}
                  deleteChapter={deleteChapter}
                  handleInputChapterName={handleInputChapterName}
                  deleteResource={deleteResource}
                  addResource={addResource}
                  changeResource={changeResource}
                  changeResourceOrdering={changeResourceOrdering}
                  isUnit
                  refresh={refresh}
                />
              );
            })}

          <div className="row">
            <label className="secret--input"></label>
            <button
              className="btn save add-Unit"
              style={{ display: isShowUnitAddbtn ? 'block' : 'none' }}
              onClick={() => {
                addUnit(chapterIndex, chapter.HangoutChapterId, hangoutId);
              }}>
              新增單元
            </button>
          </div>
        </div>
      )}

      {isUnit && (
        <div className="row zoomIn unit-section">
          <div className="col-12" style={{ alignItems: 'flex-start' }}>
            <label className="secret--input"></label>
            <div className="unit-information col-12">
              <label className="secret--input unitNum">
                單元{unitIndex + 1}
              </label>
              <div className="information-block">
                <ChapterName
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  chapterIndex={chapterIndex}
                  unitIndex={unitIndex}
                  name={name}
                  setName={setName}
                  isLoading={isLoading}
                  chapter={chapter}
                  handleCancelReName={handleCancelReName}
                  handleSaveName={handleSaveName}
                  handleDeleteChapter={handleDeleteChapter}
                />
                {chapter.HangoutChapterId !== -1 && (
                  <HangoutResources
                    chapter={chapter}
                    addResource={addResource}
                    deleteResource={deleteResource}
                    changeResource={changeResource}
                    changeResourceOrdering={changeResourceOrdering}
                    refresh={refresh}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HangoutChapter;
