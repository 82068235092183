let serverHost = '';
let frontAPIUrl = 'https://wisdombank.liangshishu.com';
let loginAPIUrl = frontAPIUrl + '/token';
let onlineClassroomAPIUrl =
  'https://onlineclassroomdaily.liangshishu.com/onlineclassroom/api';
let resourceAPIUrl = 'https://www.liangshishu.link:18500';

/**
 * process.env.REACT_APP_WEBSITE_ENV
 * 自設 WEBSITE 環境變數
 * online: 線上正式站
 * onlineTest: 線上測試站
 * local: 本地開發環境
 */
if (process.env.REACT_APP_WEBSITE_ENV !== 'online') {
  serverHost = 'https://managewisdombank-dev.liangshishu.com';
  frontAPIUrl = 'https://wisdombank-dev.liangshishu.com';
  loginAPIUrl = frontAPIUrl + '/token';
  onlineClassroomAPIUrl =
    'https://onlineclassroomdaily-dev.liangshishu.com/onlineclassroom/api';
  resourceAPIUrl = 'https://www.liangshishu.link:18700';
}

export const loginAPI = {
  POST: loginAPIUrl,
};

export const SERVER_HOST = serverHost;

export const FRONT_HOST = frontAPIUrl;

export const SERVER_API_TIMEOUT_MS = 20000;

// 首頁統計數據
export const homePageStatisticAPI = {
  MEMBER: `${serverHost}/api/Member/Statistic`,
  ORDER: `${serverHost}/api/Order/Statistic`,
  FEATURE: `${serverHost}/api/Statistic/Frontpage`,
};

// 首頁輪播
export const homeBannerCarouselAPI = {
  GET: `${serverHost}/api/Banner/2`,
  DELETE: `${serverHost}/api/Banner`,
  ADD: `${serverHost}/api/Banner`,
  IsDisplay: `${serverHost}/api/Banner/IsDisplay`,
  EDIT: `${serverHost}/api/Banner/Edit`,
  DISPALY: `${serverHost}/api/Banner/IsDisplay`,
};

// 會員管理
export const memberManagementAPI = {
  memberList: `${serverHost}/api/Member`,
  memberStatus: `${serverHost}/api/Member/Status`,
  create: `${serverHost}/api/Member/Create`,
  resetPassword: `${serverHost}/api/Member/ResetPassword`,
};

// 會員訂單管理
export const orderAPI = {
  GET_ORDER: `${serverHost}/api/Order`,
};

// 編卷管理
export const questionSourceAPI = {
  GET_SOURCE: `${serverHost}/api/QuestionSource`,
  GET_FILTER_RESULT: `${serverHost}/api/Question`,
  REJECT_QUESTION: `${serverHost}/api/Question/Reject`,
  DETAIL_QUESTION: `${serverHost}/api/Question/Detail`,
  GET_VIDEO_URL: `${frontAPIUrl}/api/Question/GetQuestionVideoUrl`,
  GET_VIDEO_COOKIE_POLICY: `${onlineClassroomAPIUrl}/Video/SignedVideoCookie`,
  GET_AUDIO_URL: `${onlineClassroomAPIUrl}/File/SignedFileUrl`,
  SEND_COMPLETE_EXAM: `${serverHost}/api/QuestionBank`,
  // 重新編卷 API
  RE_EDIT_EXAM: `${serverHost}/api/QuestionBank/Detail`,
  // 依試卷ID抓取試卷題目
  GET_QUESTION_BY_BANKID: `${serverHost}/api/Question/QuestionBank`,
  // 培優補差設定
  SEND_QUESTIONBANK_RELATION: `${serverHost}/api/QuestionBank/Relation`,
  // 取的題目的科目
  GET_QUESTION_SUBJECTS: `${serverHost}/api/QuestionSubject`,
};

// 售卷列表
export const saleExamListAPI = {
  GETLIST: `${serverHost}/api/QuestionBank`,
  COPY: `${serverHost}/api/QuestionBank/Copy`,
  QRCODE: `${serverHost}/api/QuestionBank/QrCode`,
  STATUS_AND_DELETE: `${serverHost}/api/QuestionBank/UpdateStatus`,
  GETLOG: `${serverHost}/api/QuestionBank/Log`,
};

// 課程列表
export const hangoutAPI = {
  GETLIST: `${serverHost}/api/Hangout`,
  SWITCH: `${serverHost}/api/Hangout/EditStatus`,
  SEND_COMPLETE_HANGOUT: `${serverHost}/api/Hangout`,
  GETLOG: `${serverHost}/api/Hangout/Log`,
  EDIT_HANGOUT: `${serverHost}/api/Hangout/Detail`,
  HANGOUT_CHAPTER: `${serverHost}/api/HangoutChapter`,
  HANGOUT_UNIT: `${serverHost}/api/HangoutUnit`,
  HANGOUT_UNIT_VIDEO: `${resourceAPIUrl}/api/HangoutUnit/Video`,
  HANGOUT_UNIT_RESOURCE: `${resourceAPIUrl}/api/hangoutunit/Resource`,
  HANGOUT_UNIT_ISFREE: `${serverHost}/api/HangoutUnit/IsFree`,
};

// 課程類別列表
export const hangoutTypeAPI = {
  GETLIST: `${serverHost}/api/HangoutType`,
  ADD: `${serverHost}/api/HangoutType`,
  DELETE: `${serverHost}/api/HangoutType/Delete`,
  EDIT: `${serverHost}/api/HangoutType/Edit`,
};

// 課程資源
export const hangoutChapterMaterialAPI = {
  ADD: `${serverHost}/api/HangoutChapterMaterial`,
  DELETE: `${serverHost}/api/HangoutChapterMaterial`,
  EDIT: `${serverHost}/api/HangoutChapterMaterial`,
  EDIT_ORDERING: `${serverHost}/api/HangoutChapterMaterial/Order`,
};

// 活動列表
export const promoListAPI = {
  GETLIST: `${serverHost}/api/Promo`,
  ADD: `${serverHost}/api/Promo`,
  DELETE: `${serverHost}/api/Promo`,
};

// 套卷列表
export const suitExamListAPI = {
  GETLIST: `${serverHost}/api/QuestionBankSet`,
  GET: `${serverHost}/api/QuestionBankSet`,
  ADD: `${serverHost}/api/QuestionBankSet`,
  SWITCH: `${serverHost}/api/QuestionBankSet/EditStatus`,
  EDIT: `${serverHost}/api/QuestionBankSet/Edit`,
  EDITPRODUCT: `${serverHost}/api/QuestionBankSet/EditProduct`,
  GET_SALE_AREA: `${serverHost}/api/Area`,
};

// 考卷類別列表
export const examTypeAPI = {
  GETLIST: `${serverHost}/api/QuestionBankType`,
  ADD: `${serverHost}/api/QuestionBankType`,
  EDIT: `${serverHost}/api/QuestionBankType/Edit`,
  DELETE: `${serverHost}/api/QuestionBankType/Delete`,
};

// 考卷科目列表
export const subjectTypeAPI = {
  GETLIST: `${serverHost}/api/QuestionBankSubject`,
  ADD: `${serverHost}/api/QuestionBankSubject`,
  EDIT: `${serverHost}/api/QuestionBankSubject/Edit`,
  DELETE: `${serverHost}/api/QuestionBankSubject/Delete`,
};

// 題庫商店廣告
export const questionStoreAPI = {
  GET: `${serverHost}/api/Banner/3`,
  EDIT: `${serverHost}/api/Banner/Edit`,
};

// 熱門精選
export const popularSelectionAPI = {
  SEARCH: `${serverHost}/api/QuestionBank`,
  SEARCHSUIT: `${serverHost}/api/QuestionBankSet/Search`,
  GET: `${serverHost}/api/Feature`,
  DELETE: `${serverHost}/api/Feature/Delete`,
  SWITCH: `${serverHost}/api/Feature/IsDisplay`,
  EDIT: `${serverHost}/api/Feature/Edit`,
  EDITALL: `${serverHost}/api/Feature/EditProduct`,
  EDITSETALL: `${serverHost}/api/Feature/Set/EditProduct`,
  ADD: `${serverHost}/api/Feature`,
  ADDSUIT: `${serverHost}/api/Feature/Set`,
};

// 影片相關
export const videoAPI = {
  GET: `${serverHost}/api/Handout/Video`,
  ADD: `${serverHost}/api/Handout/Video`,
  EDIT: `${serverHost}/api/Handout/Video`,
  DELETE: `${serverHost}/api/Handout/Video`,
};

// 廣告相關
export const bannerAPI = {
  GET: `${serverHost}/api/Banner/`, // GET 需要再補上 sitecode /api/banner/1
  DELETE: `${serverHost}/api/Banner`,
  ADD: `${serverHost}/api/Banner`,
  EDIT: `${serverHost}/api/Banner/Edit`,
  IsDisplay: `${serverHost}/api/Banner/IsDisplay`,
  DISPALY: `${serverHost}/api/Banner/IsDisplay`,
};

// 打卡相關
export const punchAPI = {
  POST: `https://onlineclassroom.mathking1213.com/tainan/`, // POST 需要再補上 qrCode
};
