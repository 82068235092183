import React, { Component } from 'react';

import { connect } from 'react-redux';

import BreakCrumbs from '../../components/BreakCrumbs';

import IncomeChart from '../../components/Home/IncomeChart';
import LoginMonthChart from '../../components/Home/LoginMonthChart';

import '../../style/components/home/home.scss';

import gear from '../../images/gear.svg';
import members from '../../images/members.svg';
import lover from '../../images/lover.svg';
import sale from '../../images/sale.svg';
import growth from '../../images/growth.svg';
import growthCopy from '../../images/growth_copy.svg';
import movie from '../../images/movie.svg';
import list from '../../images/list.svg';

import {
  initHomeFeatureStatistic,
  initHomeMemberStatistic,
  initHomeOrderStatistic,
} from '../../store/action';

class Home extends Component {
  constructor(props) {
    super(props);
    this.static = {
      featureStaticObj: {},
    };
  }

  componentDidMount() {
    const {
      initHomeFeatureStatistic,
      initHomeMemberStatistic,
      initHomeOrderStatistic,
    } = this.props;
    const asyncRun = async () => {
      await initHomeFeatureStatistic();
      await initHomeMemberStatistic();
      await initHomeOrderStatistic();
    };
    asyncRun();
  }

  render() {
    // 資料後端沒開 API 有些是假的
    const {
      HomePageStatisticState: { order, member },
      HomePageStatisticState: {
        feature: {
          MonthlySale = 0,
          MonthlyHangoutSale = 0,
          MonthlyMembers = 0,
          MonthlyTrial = 0,
          MonthlyBestSale = {
            QuestionBank: { Name: '預設值' },
            Count: 0,
          },
          MonthlyBestTrial = {
            QuestionBank: { Name: '預設值' },
            Count: 0,
          },
          MonthlyBestHangoutSale = {
            Hangout: { Name: '預設值' },
            Count: 0,
          },
        },
      },
      match: { url },
    } = this.props;

    return (
      <div className="main-home pagefadeIn">
        <BreakCrumbs path={url} />
        <div className="block information">
          <div className="information-top row">
            <div className="col-3 info-block">
              <div className="image-box">
                <img src={movie} width="100%" alt="最暢銷影片" />
              </div>
              <div className="content">
                <div className="title">最暢銷課程</div>
                <div className="name">
                  {MonthlyBestHangoutSale.Hangout.Name}
                </div>
                <div className="number">
                  {`${MonthlyBestHangoutSale.Count}份`}
                </div>
              </div>
            </div>
            <div className="col-3 info-block">
              <div className="image-box">
                <img src={growthCopy} width="100%" alt="影片當月銷售額" />
              </div>
              <div className="content">
                <div className="title">課程當月銷售額</div>
                <div className="number">{`${MonthlyHangoutSale}元`}</div>
              </div>
            </div>
            <div className="col-3 info-block">
              <div className="image-box">
                <img src={members} width="100%" alt="新增會員數" />
              </div>
              <div className="content">
                <div className="title">新增會員數</div>
                <div className="number">{`${MonthlyMembers}人`}</div>
              </div>
            </div>
            <div
              className="col-3 info-block"
              onClick={() => {
                this.props.history.push('/OrderList/OrderList');
              }}>
              <div className="image-box">
                <img src={list} width="100%" alt="歷史訂單" />
              </div>
              <div className="content">
                <div className="title">歷史訂單</div>
              </div>
            </div>
          </div>
          <hr />
          <div className="information-bottom row">
            <div className="col-3 info-block">
              <div className="image-box">
                <img src={sale} width="100%" alt="最暢銷卷" />
              </div>
              <div className="content">
                <div className="title">最暢銷卷</div>
                <div className="name">{MonthlyBestSale.QuestionBank.Name}</div>
                <div className="number">{`${MonthlyBestSale.Count}份`}</div>
              </div>
            </div>
            <div className="col-3 info-block">
              <div className="image-box">
                <img src={growth} width="100%" alt="試卷當月銷售額" />
              </div>
              <div className="content">
                <div className="title">試卷當月銷售額</div>
                <div className="number">{`${MonthlySale}元`}</div>
              </div>
            </div>
            <div className="col-3 info-block">
              <div className="image-box">
                <img src={gear} width="100%" alt="當月累計試閱數" />
              </div>
              <div className="content">
                <div className="title">當月累計試閱數</div>
                <div className="number">{`${MonthlyTrial}次`}</div>
              </div>
            </div>
            <div className="col-3 info-block">
              <div className="image-box">
                <img src={lover} width="100%" alt="最愛試用卷" />
              </div>
              <div className="content">
                <div className="title">最愛試用卷</div>
                <div className="name">{MonthlyBestTrial.QuestionBank.Name}</div>
                <div className="number">{`${MonthlyBestTrial.Count}次`}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="block income">
          <IncomeChart reports={order} />
        </div>
        <div className="block login-month">
          <LoginMonthChart reports={member} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  HomePageStatisticState: store.HomePageStatisticState,
  loading: store.loading,
});

export default connect(mapStateToProps, {
  initHomeFeatureStatistic,
  initHomeMemberStatistic,
  initHomeOrderStatistic,
})(Home);
