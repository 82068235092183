import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from '@material-ui/core';

const AuditResultModal = (props) => {
  const { isOpen, close, auditResult } = props;

  const handleClose = () => {
    close();
  };

  return (
    <Dialog onClose={handleClose} open={isOpen} fullWidth={true} maxWidth="sm">
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {auditResult.fileName} 稽核結果
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          direction="row"
          justifycontent="space-around"
          alignItems="stretch"
          spacing={2}>
          <Grid item xs={4}>
            <div className="bonus__audit-count bonus__audit-count--abnormal">
              {auditResult.abnormalCount}
              <div className="bonus__audit-count__text">異常數</div>
            </div>
          </Grid>

          <Grid item xs={4}>
            <div className="bonus__audit-count bonus__audit-count--normal">
              {auditResult.normalCount}
              <div className="bonus__audit-count__text">正確數</div>
            </div>
          </Grid>

          <Grid item xs={4}>
            <div className="bonus__audit-count">
              {auditResult.totalCount}
              <div className="bonus__audit-count__text">總計核對數</div>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuditResultModal;
