import React, { useMemo } from 'react';
import { Box, Checkbox } from '@material-ui/core';
import { TreeView, TreeItem } from '@material-ui/lab';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const GreenCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#0FC29E',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const renderTree = (knowledges, isSelected, handleClickKnowledge) => {
  return _.map(knowledges, (knowledge) => (
    <TreeItem
      key={'knowledge_node_' + knowledge.id}
      nodeId={'knowledge_node_' + knowledge.id}
      label={
        <>
          <GreenCheckbox
            checked={isSelected(knowledge.id)}
            onClick={(event) => {
              event.preventDefault();
              handleClickKnowledge(!isSelected(knowledge.id), knowledge);
            }}
          />
          {knowledge.name}
        </>
      }>
      {Array.isArray(knowledge.knowledgeDtoChildren) &&
      knowledge.knowledgeDtoChildren.length > 0
        ? renderTree(
            knowledge.knowledgeDtoChildren,
            isSelected,
            handleClickKnowledge,
          )
        : null}
    </TreeItem>
  ));
};

const SubjectKnowledgeTabPanel = (props) => {
  const {
    value,
    index,
    subjectKnowledges,
    isSelected,
    changeSelected,
    selected,
  } = props;
  const handleClickKnowledge = (checked, knowledge) => {
    changeSelected(knowledge, checked);
  };
  const defaultExpanded = useMemo(() => {
    let expandeds = [];

    _.each(selected, (selectKnowledge) => {
      if (selectKnowledge.parents.length > 0) {
        _.each(selectKnowledge.parents, (selectKnowledgeParent) => {
          if (
            _.indexOf(
              expandeds,
              'knowledge_node_' + selectKnowledgeParent.id,
            ) === -1
          ) {
            expandeds.push('knowledge_node_' + selectKnowledgeParent.id);
          }
        });
      }
    });

    return expandeds;
  }, []);

  return (
    <Box hidden={value !== index}>
      {value === index && (
        <TreeView
          defaultCollapseIcon={<ExpandMoreRoundedIcon />}
          defaultExpanded={defaultExpanded}
          defaultExpandIcon={<ChevronRightRoundedIcon />}>
          {renderTree(subjectKnowledges, isSelected, handleClickKnowledge)}
        </TreeView>
      )}
    </Box>
  );
};

export default SubjectKnowledgeTabPanel;
