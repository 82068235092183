import React, { useState, useEffect } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
  Drawer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TableLoading from '../../components/TableLoading';
import bonusAPI from '../../middleware/bonusAPI';
import { alertError } from '../../Util/Util';
import TableEmpty from '../../components/TableEmpty';
import BonusesTable from '../../components/Bonus/BonusesTable';
import {
  BonusSaleTypes,
  BonusStatus,
  BonusStatusText,
  LIST_PAGE_COUNT,
} from '../../data/common';
import Swal from 'sweetalert2';
import { trim, find, map } from 'lodash';
import BonusView from '../../components/Bonus/BonusView';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';

const BonusList = (props) => {
  const { match } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [bonuses, setBonuses] = useState();
  const [bonusDetail, setBonusDetail] = useState();
  const [pageNum, setPageNum] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [bonusStatus, setBonusStatus] = useState('');
  const [saleType, setSaleType] = useState(1);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    getBonuses();
  }, [pageNum]);

  const getBonuses = () => {
    setIsLoading(true);

    const startAt =
      trim(startDate) !== '' ? moment(startDate).format('YYYY-MM-DD') : '';

    const endAt =
      trim(endDate) !== '' ? moment(endDate).format('YYYY-MM-DD') : '';

    bonusAPI
      .getBonus(
        keyword,
        pageNum,
        LIST_PAGE_COUNT,
        undefined, // audit stauts
        bonusStatus,
        saleType,
        startAt,
        endAt,
      )
      .then(
        (res) => {
          if (res.totalCount <= 0) {
            setBonuses(undefined);
          } else {
            setBonuses(res);
          }
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const handleKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const handleSearch = () => {
    setPageNum(1);

    if (pageNum === 1) {
      getBonuses();
    }
  };

  const handleCloseDetail = () => {
    setIsOpenDetail(false);
  };

  const handleChangeStatus = (e) => {
    setBonusStatus(e.target.value);
  };

  const handleChangeSaleType = (e) => {
    setSaleType(e.target.value);
  };

  const handleChangeStartDate = (e) => {
    setStartDate(e);
  };

  const handleChangeEndDate = (e) => {
    setEndDate(e);
  };

  const viewBonus = (bonusId) => {
    const findBonus = find(bonuses.historyList, (bonus) => {
      return bonus.id === bonusId;
    });
    setBonusDetail(findBonus);
    setIsOpenDetail(true);
  };

  const deleteBonus = (bonusId) => {
    Swal.fire({
      title: '刪除紅利點數',
      input: 'textarea',
      inputPlaceholder: '輸入刪除原因',
      inputAttributes: {
        'aria-label': '輸入刪除原因',
      },
      inputValidator: (value) => {
        if (trim(value) === '') {
          return '請輸入刪除原因!!';
        }

        if (value.length > 200) {
          return '長度不能超過200個字元';
        }
      },
      showCancelButton: true,
      confirmButtonText: '刪除',
      confirmButtonColor: 'red',
      cancelButtonText: '取消',
    }).then((res) => {
      if (res.isConfirmed) {
        setIsLoading(true);

        bonusAPI
          .deleteBonus(bonusId, res.value)
          .then(
            () => {
              Swal.fire({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                title: '刪除成功',
                icon: 'success',
              });
              getBonuses();
            },
            (error) => {
              alertError(error);
            },
          )
          .then(() => {
            setIsLoading(false);
          });
      }
    });
  };

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={match.url}></BreakCrumbs>

      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center">
          <Grid item xs>
            <Typography variant="h4" color="textPrimary" gutterBottom>
              紅利列表
            </Typography>
          </Grid>
          <Grid item xs style={{ marignLeft: 'auto' }}>
            <div className="bonus__list__totals">
              <div className="bonus__list__total bonus__list__total--reward">
                <div className="bonus__list__total-number">
                  {bonuses ? bonuses.totalReward : 0}
                  <div className="bonus__list__total-title">回饋總計</div>
                </div>
              </div>

              <div className="bonus__list__total bonus__list__total--used">
                <div className="bonus__list__total-number">
                  {bonuses ? bonuses.totalUsed : 0}
                  <div className="bonus__list__total-title">折扣總計</div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <Divider light />

        <Box textAlign="right" mt={2} mb={2}>
          <DatePicker
            autoOk
            label="開始時間"
            clearable
            disableFuture
            inputVariant="outlined"
            value={startDate || null}
            onChange={handleChangeStartDate}
            format="yyyy-MM-dd"
            style={{
              marginRight: '12px',
            }}
          />

          <DatePicker
            autoOk
            label="結束時間"
            clearable
            disableFuture
            inputVariant="outlined"
            value={endDate || null}
            onChange={handleChangeEndDate}
            format="yyyy-MM-dd"
            style={{
              marginRight: '12px',
            }}
          />

          <FormControl
            size="small"
            variant="outlined"
            style={{ marginRight: '12px', minWidth: '150px' }}
            disabled={isLoading}>
            <InputLabel>類型</InputLabel>
            <Select
              value={saleType}
              onChange={handleChangeSaleType}
              label="類型">
              {map(BonusSaleTypes, (bonusSaleTypeText, bonusSaleType) => {
                return (
                  <MenuItem value={bonusSaleType} key={bonusSaleType}>
                    {bonusSaleTypeText}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl
            size="small"
            variant="outlined"
            style={{ marginRight: '12px', minWidth: '150px' }}
            disabled={isLoading}>
            <InputLabel>紅利狀態</InputLabel>
            <Select
              value={bonusStatus}
              onChange={handleChangeStatus}
              label="紅利狀態">
              <MenuItem value="">不設定</MenuItem>

              {map(BonusStatus, (status) => {
                return (
                  <MenuItem value={status} key={status}>
                    {BonusStatusText[status]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            label="手機/姓名 搜尋"
            type="search"
            variant="outlined"
            size="small"
            disabled={isLoading}
            value={keyword}
            onChange={handleKeyword}
            style={{ marginRight: '12px' }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />

          <Button
            variant="contained"
            color="primary"
            startIcon={<SearchIcon />}
            disabled={isLoading}
            onClick={handleSearch}>
            搜尋
          </Button>
        </Box>

        {isLoading && <TableLoading />}

        {!isLoading && !bonuses && <TableEmpty message="查無紅利記錄" />}

        {!isLoading && bonuses && (
          <BonusesTable
            bonuses={bonuses.historyList}
            bonusTotal={bonuses.totalCount}
            viewBonus={viewBonus}
            deleteBonus={deleteBonus}
            isLoading={isLoading}
            pageNum={pageNum}
            setPageNum={setPageNum}
          />
        )}
      </Box>

      <Drawer anchor="right" open={isOpenDetail} onClose={handleCloseDetail}>
        <BonusView bonus={bonusDetail} closeDrawer={handleCloseDetail} />
      </Drawer>
    </div>
  );
};

export default BonusList;
