import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  Box,
  Paper,
  Typography,
  Divider,
  Grid,
  Button,
  IconButton,
  Card,
  CardContent,
  CardActions,
  Chip,
} from '@material-ui/core';
import {
  AddQuestionInExam,
  DeleteQuestionInExam,
  AddMainInExam,
  DeleteMainInExam,
  AddHardInExam,
  DeleteHardInExam,
  AddEasierInExam,
  DeleteEasierInExam,
  AddRelationInExam,
  DeleteRelationInExam,
  DeleteItemInFiltedArray,
  rejectQuestion,
  DeleteDepthQuestionInExam,
  callPageLoading,
  cancelPageLoading,
} from '../../store/action';
import { makeStyles } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Swal from 'sweetalert2';
import ExamQuestionSetting from './ExamQuestionSetting';
import QuestionDetailDialog from '../Dialogs/QuestionDetailDialog';
import ConfirmDialog from '../Dialogs/ConfirmDialog';
import MathJaxAndImgContent from '../Question/MathJaxAndImgContent';
import Pagination from '@material-ui/lab/Pagination';
import Knowledges from '../Question/Knowledges';
import ExamQuestionKnowledgePie from '../SetExam/ExamQuestionKnowledgePie';
import LibraryBooksRoundedIcon from '@material-ui/icons/LibraryBooksRounded';
import BorderColorRoundedIcon from '@material-ui/icons/BorderColorRounded';
import { renderMathJax } from '../../Util/Util';
import AlertRepeatQuestions from './AlertRepeatQuestions';

const useStyles = makeStyles((theme) => ({
  mainColor: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: '#ffe596',
    borderRadius: theme.spacing(1),
    color: theme.palette.common.white,
  },
  mainQuestionList: {
    backgroundColor: '#8ea43d',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    height: '100%',
  },
  mainSetQuestionList: {
    backgroundColor: '#06453f',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    height: '100%',
  },
  questionNumber: {
    textAlign: 'center',
    fontSize: '1.7rem',
    fontWeight: '600',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    margin: `${theme.spacing(2)}px auto`,
    width: '200px',
  },
  questionNumberItem: {
    textAlign: 'center',
    fontSize: '1.3rem',
    fontWeight: '600',
    '& span': {
      cursor: 'pointer',
      borderBottom: `2px solid ${theme.palette.grey[300]}`,
      padding: `0 ${theme.spacing(1)}px`,
      position: 'relative',
      lineHeight: '40px',
      '&:hover': {
        borderBottom: `2px solid ${indigo[200]}`,
      },
      '& .tags': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '20px',
        '& .tag': {
          width: '5px',
          height: '5px',
          marginBottom: '2px',
        },
        '& .tag-main': {
          backgroundColor: red[500],
        },
        '& .tag-like': {
          backgroundColor: red[100],
        },
        '& .tag-better': {
          backgroundColor: red[200],
        },
        '& .tag-improve': {
          backgroundColor: red[300],
        },
      },
    },
    '& span.active': {
      borderBottom: `2px solid ${yellow[300]}`,
      fontSize: '1.5rem',
      transition: theme.transitions.create('font-size', {
        duration: theme.transitions.duration.sta,
      }),
    },
  },
  editExamQuestion: {
    minHeight: '600px',
    overflow: 'hidden',
  },
  questionList: {
    maxHeight: '580px',
    overflow: 'auto',
  },
  questionListTitle: {
    fontWeight: 600,
    fontSize: '2rem',
  },
  questionItem: {
    width: '95%',
    margin: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
  },
  desc: {
    fontSize: '1.2rem',
    fontWeight: 600,
    color: theme.palette.grey[100],
    letterSpacing: '1px',
    textAlign: 'center',
  },
  correctAnswer: {
    color: theme.palette.grey[700],
    marginTop: theme.spacing(1),
  },
  pagination: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& ul': {
      justifyContent: 'center',
      '& li': {
        '& button': {
          backgroundColor: '#fff',
        },
      },
    },
  },
  correctAnswerContent: {
    padding: theme.spacing(0),
    fontSize: '0.9rem',
  },
  questionTypeChip: {
    marginLeft: 'auto !important',
    alignSelf: 'flex-end',
  },
}));

/**
 * START component
 * @param {object} props
 */
const ResultDrag = (props) => {
  /**
   * useState, useEffect
   */
  const [nowExamQuestionNumber, setNowExamQuestionNumber] = useState(0);
  const [isShowQuestion, setIsShowQuestion] = useState(false);
  const [showQuestionId, setShowQuestionId] = useState(null);
  const [isRejectQuestion, setIsRejectQuestion] = useState(false);
  const [rejectQuestionId, setRejectQuestionId] = useState(null);
  const [numberOfPage] = useState(8);
  const editRef = useRef(null);
  let nowMouseDownId = null;

  useEffect(() => {
    renderMathJax(editRef.current);
  });

  /**
   * useSelector useDispatch
   */
  const dispatch = useDispatch();
  const getQuestionResult = useSelector(
    (state) => state.QuestionSorceResultState,
  );
  const examQuestions = useSelector((state) => state.SelectedExamQuestionState);

  /**
   * useStyles
   */
  const classes = useStyles();

  /**
   * other function
   */
  const addExamQuestion = () => {
    let lastExamQuestionNumber = examQuestions.length;

    dispatch(AddQuestionInExam());
    setNowExamQuestionNumber(lastExamQuestionNumber);
  };

  const doRejectQuestion = () => {
    setIsRejectQuestion(false);
    dispatch(callPageLoading({ loadingContent: '退回題目中...' }));

    dispatch(rejectQuestion(rejectQuestionId)).then((response) => {
      if (response.Status === 1) {
        dispatch(DeleteItemInFiltedArray(rejectQuestionId));
        dispatch(DeleteDepthQuestionInExam(rejectQuestionId));
      } else {
        Swal.fire(
          '退回題目失敗',
          '請重新操作退回(原因 ' + _.join(response.Message, '<br>') + ' )',
          'error',
        );
      }

      dispatch(cancelPageLoading());
    });
  };

  const deleteExamQuestion = () => {
    dispatch(
      DeleteQuestionInExam(getExamQuestionsIdByIndex(nowExamQuestionNumber)),
    );
  };

  const makeSureNowExamQuestionNumberIsFine = () => {
    if (
      typeof examQuestions[nowExamQuestionNumber] === 'undefined' &&
      nowExamQuestionNumber > 0
    ) {
      let lastExamQuestionNumber = examQuestions.length - 1;

      lastExamQuestionNumber =
        lastExamQuestionNumber < 0 ? 0 : lastExamQuestionNumber;
      setNowExamQuestionNumber(lastExamQuestionNumber);
    }
  };

  makeSureNowExamQuestionNumberIsFine();

  const getExamQuestionsIdByIndex = useCallback(
    (index) => {
      return examQuestions[index].OnlyId;
    },
    [examQuestions],
  );

  const setExamQuestion = useCallback(
    (result, provided, c) => {
      if (result.destination === null) {
        return;
      }

      let question = getQuestionResult.Questions[result.source.index];

      if (_.isObject(question) === false) {
        Swal.fire('設定錯誤', '找不到設定的題目', 'error');
        return;
      }

      switch (result.destination.droppableId) {
        case 'mainQuestionSetDroppable':
          dispatch(AddMainInExam(question, nowExamQuestionNumber));
          break;

        case 'relationQuestionSetDroppable':
          dispatch(
            AddRelationInExam(
              question,
              getExamQuestionsIdByIndex(nowExamQuestionNumber),
            ),
          );
          break;

        case 'headQuestionSetDroppable':
          dispatch(
            AddHardInExam(
              question,
              getExamQuestionsIdByIndex(nowExamQuestionNumber),
            ),
          );
          break;

        case 'easierQuestionSetDroppable':
          dispatch(
            AddEasierInExam(
              question,
              getExamQuestionsIdByIndex(nowExamQuestionNumber),
            ),
          );
          break;

        default:
          break;
      }
    },
    [
      getQuestionResult,
      dispatch,
      nowExamQuestionNumber,
      getExamQuestionsIdByIndex,
    ],
  );

  const removeExamQuestionSetting = (examQuestionType) => {
    switch (examQuestionType) {
      case 'main':
        dispatch(
          DeleteMainInExam(getExamQuestionsIdByIndex(nowExamQuestionNumber)),
        );
        break;

      case 'relation':
        dispatch(
          DeleteRelationInExam(
            getExamQuestionsIdByIndex(nowExamQuestionNumber),
          ),
        );
        break;

      case 'head':
        dispatch(
          DeleteHardInExam(getExamQuestionsIdByIndex(nowExamQuestionNumber)),
        );
        break;

      case 'easier':
        dispatch(
          DeleteEasierInExam(getExamQuestionsIdByIndex(nowExamQuestionNumber)),
        );
        break;

      default:
        Swal.fire('移除題目類型錯誤', '目前沒有該類型可以移除', 'error');
        break;
    }
  };

  const checkQuestionExistExam = (questionId, excludes) => {
    let checkRs = {
      isQuestionExistExam: false,
      existPositions: [],
    };

    if (examQuestions.length > 0) {
      _.each(examQuestions, (question, index) => {
        if (question.Id === questionId) {
          checkRs.existPositions.push({
            examQuestionIndex: index,
            type: 'main',
          });
        }

        if (
          question.SimilarQuestion &&
          question.SimilarQuestion.Id === questionId
        ) {
          checkRs.existPositions.push({
            examQuestionIndex: index,
            type: 'similar',
          });
        }

        if (
          question.EasierQuestion &&
          question.EasierQuestion.Id === questionId
        ) {
          checkRs.existPositions.push({
            examQuestionIndex: index,
            type: 'easier',
          });
        }

        if (
          question.HarderQuestion &&
          question.HarderQuestion.Id === questionId
        ) {
          checkRs.existPositions.push({
            examQuestionIndex: index,
            type: 'harder',
          });
        }
      });

      if (excludes) {
        _.each(excludes, (exclude) => {
          _.remove(checkRs.existPositions, (existPosition) => {
            return (
              existPosition.examQuestionIndex === exclude.examQuestionIndex &&
              existPosition.type === exclude.type
            );
          });
        });
      }

      checkRs.isQuestionExistExam = checkRs.existPositions.length >= 1;
    }

    return checkRs;
  };

  return (
    <DragDropContext onDragEnd={setExamQuestion}>
      <Box component={Paper} square elevation={1} p={1} ref={editRef}>
        <Typography variant="h5" color="textPrimary" gutterBottom>
          編輯題卷
        </Typography>
        <Divider light />
        <Box mt={2}>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center">
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center">
              {examQuestions.length <= 0 ? (
                <Grid item xs>
                  <Box textAlign="center" className={classes.desc}>
                    目前尚無建立題號，請點擊下方{' '}
                    <PostAddOutlinedIcon color="disabled" /> 新增
                  </Box>
                </Grid>
              ) : (
                <>
                  {examQuestions.map((examQuestion, idx) => {
                    return (
                      <Grid item xs={1} key={'eq_' + examQuestion.OnlyId}>
                        <Box className={classes.questionNumberItem}>
                          <span
                            onClick={() => {
                              if (nowExamQuestionNumber !== idx) {
                                setNowExamQuestionNumber(idx);
                              }
                            }}
                            className={
                              idx === nowExamQuestionNumber ? 'active' : ''
                            }>
                            {idx + 1}
                            <div className="tags">
                              {examQuestion.Substance !== '' && (
                                <Box className="tag tag-main"></Box>
                              )}
                              {_.isObject(examQuestion.SimilarQuestion) &&
                                examQuestion.SimilarQuestion.Substance !==
                                  '' && <Box className="tag tag-like"></Box>}
                              {_.isObject(examQuestion.HarderQuestion) &&
                                examQuestion.HarderQuestion.Substance !==
                                  '' && <Box className="tag tag-better"></Box>}
                              {_.isObject(examQuestion.EasierQuestion) &&
                                examQuestion.EasierQuestion.Substance !==
                                  '' && <Box className="tag tag-improve"></Box>}
                            </div>
                          </span>
                        </Box>
                      </Grid>
                    );
                  })}
                </>
              )}
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center">
              <Grid item xs>
                <Box className={classes.questionNumber}>
                  題卷題號
                  <IconButton
                    aria-label="add"
                    size="small"
                    onClick={addExamQuestion}>
                    <PostAddOutlinedIcon color="secondary" />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box id="editQuestions" className={classes.mainColor}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            wrap="wrap"
            spacing={2}>
            <Grid item xs={12} md={6}>
              <Box className={classes.mainQuestionList}>
                <Typography
                  variant="subtitle1"
                  className={classes.questionListTitle}>
                  題目列表
                  <Box
                    className={classes.questionNumberItem}
                    ml={1}
                    display="inline-block">
                    - 總計{' '}
                    {props.isFetchRandom
                      ? getQuestionResult.Questions.length
                      : getQuestionResult.TotalCount}{' '}
                    題
                  </Box>
                </Typography>
                <Box mt={2}>
                  {getQuestionResult.TotalCount <= 0 ? (
                    <Box
                      className={classes.desc}
                      minHeight="200px"
                      marginTop="205px">
                      目前沒有搜尋到題目，請至上方
                      <SearchOutlinedIcon color="disabled" />
                      取得題目
                    </Box>
                  ) : (
                    <Droppable
                      droppableId="questionGetDroppable"
                      isDropDisabled>
                      {(provided, snapshot) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className={classes.questionList}
                          onMouseUp={() => {
                            if (nowMouseDownId) {
                              document.getElementById(
                                nowMouseDownId,
                              ).style.height = 'auto';
                              nowMouseDownId = null;
                            }
                          }}>
                          {getQuestionResult.Questions.map(
                            (question, index) => {
                              const {
                                isQuestionExistExam,
                                existPositions,
                              } = checkQuestionExistExam(question.Id);

                              return (
                                <Draggable
                                  draggableId={'dr' + question.Id}
                                  index={index}
                                  isDragDisabled={
                                    props.isLoading || isQuestionExistExam
                                  }
                                  key={question.Id}>
                                  {(provided, snapshot) => {
                                    const style = {
                                      ...provided.draggableProps.style,
                                      opacity: snapshot.isDragging
                                        ? '0.7'
                                        : isQuestionExistExam
                                        ? '0.5'
                                        : '1',
                                    };

                                    return (
                                      <Card
                                        id={`dr-card-${question.Id}`}
                                        className={classes.questionItem}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={style}>
                                        <CardContent
                                          onMouseDown={() => {
                                            if (isQuestionExistExam) {
                                              return false;
                                            }

                                            document.getElementById(
                                              `dr-card-${question.Id}`,
                                            ).style.height = '60px';
                                            nowMouseDownId = `dr-card-${question.Id}`;
                                          }}>
                                          {isQuestionExistExam && (
                                            <AlertRepeatQuestions
                                              existPositions={existPositions}
                                            />
                                          )}

                                          <MathJaxAndImgContent
                                            substance={
                                              props.subject === '13' &&
                                              question.Substance > 150
                                                ? question.Substance.substr(
                                                    0,
                                                    150,
                                                  ) + '...'
                                                : question.Substance
                                            }
                                          />

                                          <Grid
                                            container
                                            direction="row"
                                            justify="flex-start"
                                            alignItems="flex-start"
                                            className={classes.correctAnswer}>
                                            <Grid item xs={2}>
                                              簡答
                                            </Grid>
                                            <Grid item xs={10}>
                                              {_.isArray(
                                                question.CorrectAnswers,
                                              ) ? (
                                                _.map(
                                                  question.CorrectAnswers,
                                                  (item, index) => {
                                                    return item.length <= 0 ? (
                                                      <Typography
                                                        variant="subtitle1"
                                                        key={index}>
                                                        答案為圖片內容
                                                        (請看詳解)
                                                      </Typography>
                                                    ) : (
                                                      <MathJaxAndImgContent
                                                        substance={item}
                                                        classes={{
                                                          mathJax:
                                                            classes.correctAnswerContent,
                                                        }}
                                                        key={index}
                                                      />
                                                    );
                                                  },
                                                )
                                              ) : question.CorrectAnswers
                                                  .length <= 0 ? (
                                                <Typography variant="subtitle1">
                                                  答案為圖片內容 (請看詳解)
                                                </Typography>
                                              ) : (
                                                <MathJaxAndImgContent
                                                  substance={
                                                    question.CorrectAnswers
                                                  }
                                                />
                                              )}
                                            </Grid>
                                          </Grid>
                                          {question.Knowledges.length > 0 && (
                                            <Grid
                                              container
                                              direction="row"
                                              justify="flex-start"
                                              alignItems="flex-start"
                                              className={classes.correctAnswer}>
                                              <Grid item xs={2}>
                                                知識點
                                              </Grid>
                                              <Grid item xs={10}>
                                                <Knowledges
                                                  questions={question}
                                                />
                                              </Grid>
                                            </Grid>
                                          )}
                                        </CardContent>
                                        <CardActions>
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                              setShowQuestionId(question.Id);
                                              setIsShowQuestion(true);
                                            }}>
                                            詳解
                                          </Button>
                                          <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => {
                                              setIsRejectQuestion(true);
                                              setRejectQuestionId(question.Id);
                                            }}>
                                            退回
                                          </Button>

                                          {question.QuestionType === 2 && (
                                            <Chip
                                              color="primary"
                                              size="small"
                                              className={
                                                classes.questionTypeChip
                                              }
                                              icon={<BorderColorRoundedIcon />}
                                              label="克漏字"
                                            />
                                          )}

                                          {question.QuestionType === 3 && (
                                            <Chip
                                              color="primary"
                                              size="small"
                                              className={
                                                classes.questionTypeChip
                                              }
                                              icon={<LibraryBooksRoundedIcon />}
                                              label="題組"
                                            />
                                          )}
                                        </CardActions>
                                      </Card>
                                    );
                                  }}
                                </Draggable>
                              );
                            },
                          )}
                          {provided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                  )}
                </Box>
                {props.isFetchRandom === false &&
                  getQuestionResult.TotalCount > numberOfPage && (
                    <Pagination
                      page={props.currPage}
                      count={_.ceil(
                        getQuestionResult.TotalCount / numberOfPage,
                      )}
                      onChange={(event, page) => {
                        if (props.currPage !== page) {
                          props.onChangePage(page);
                        }
                      }}
                      className={classes.pagination}
                      variant="outlined"
                      shape="rounded"
                      disabled={props.isLoading}
                    />
                  )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.mainSetQuestionList}>
                <Typography
                  variant="subtitle1"
                  className={classes.questionListTitle}>
                  設定內容
                  {examQuestions.length > 0 && (
                    <>
                      <Box
                        className={classes.questionNumberItem}
                        ml={1}
                        display="inline-block">
                        - 題目 {nowExamQuestionNumber + 1}
                      </Box>
                      <IconButton
                        aria-label="delExamQuestion"
                        onClick={deleteExamQuestion}>
                        <DeleteForeverOutlinedIcon style={{ color: '#fff' }} />
                      </IconButton>
                    </>
                  )}
                </Typography>

                {examQuestions.length <= 0 ? null : (
                  <Box className={classes.questionList}>
                    <ExamQuestionSetting
                      droppableId="mainQuestionSetDroppable"
                      label="試卷題目"
                      substance={
                        _.isObject(examQuestions[nowExamQuestionNumber])
                          ? examQuestions[nowExamQuestionNumber].Substance
                          : ''
                      }
                      subject={props.subject}
                      removeAction={() => {
                        removeExamQuestionSetting('main');
                      }}
                      question={
                        _.isObject(examQuestions[nowExamQuestionNumber])
                          ? examQuestions[nowExamQuestionNumber]
                          : null
                      }
                      checkRepeatResult={
                        _.isObject(examQuestions[nowExamQuestionNumber])
                          ? checkQuestionExistExam(
                              examQuestions[nowExamQuestionNumber].Id,
                              [
                                {
                                  examQuestionIndex: nowExamQuestionNumber,
                                  type: 'main',
                                },
                              ],
                            )
                          : undefined
                      }
                    />
                    <ExamQuestionSetting
                      droppableId="relationQuestionSetDroppable"
                      label="類似題"
                      substance={
                        _.isObject(examQuestions[nowExamQuestionNumber]) &&
                        _.isObject(
                          examQuestions[nowExamQuestionNumber].SimilarQuestion,
                        )
                          ? examQuestions[nowExamQuestionNumber].SimilarQuestion
                              .Substance
                          : ''
                      }
                      removeAction={() => {
                        removeExamQuestionSetting('relation');
                      }}
                      subject={props.subject}
                      checkRepeatResult={
                        _.isObject(examQuestions[nowExamQuestionNumber]) &&
                        examQuestions[nowExamQuestionNumber].SimilarQuestion
                          ? checkQuestionExistExam(
                              examQuestions[nowExamQuestionNumber]
                                .SimilarQuestion.Id,
                              [
                                {
                                  examQuestionIndex: nowExamQuestionNumber,
                                  type: 'similar',
                                },
                              ],
                            )
                          : undefined
                      }
                    />
                    <ExamQuestionSetting
                      droppableId="headQuestionSetDroppable"
                      label="培優題"
                      substance={
                        _.isObject(examQuestions[nowExamQuestionNumber]) &&
                        _.isObject(
                          examQuestions[nowExamQuestionNumber].HarderQuestion,
                        )
                          ? examQuestions[nowExamQuestionNumber].HarderQuestion
                              .Substance
                          : ''
                      }
                      subject={props.subject}
                      removeAction={() => {
                        removeExamQuestionSetting('head');
                      }}
                      checkRepeatResult={
                        _.isObject(examQuestions[nowExamQuestionNumber]) &&
                        examQuestions[nowExamQuestionNumber].HarderQuestion
                          ? checkQuestionExistExam(
                              examQuestions[nowExamQuestionNumber]
                                .HarderQuestion.Id,
                              [
                                {
                                  examQuestionIndex: nowExamQuestionNumber,
                                  type: 'harder',
                                },
                              ],
                            )
                          : undefined
                      }
                    />
                    <ExamQuestionSetting
                      droppableId="easierQuestionSetDroppable"
                      label="補差題"
                      substance={
                        _.isObject(examQuestions[nowExamQuestionNumber]) &&
                        _.isObject(
                          examQuestions[nowExamQuestionNumber].EasierQuestion,
                        )
                          ? examQuestions[nowExamQuestionNumber].EasierQuestion
                              .Substance
                          : ''
                      }
                      subject={props.subject}
                      removeAction={() => {
                        removeExamQuestionSetting('easier');
                      }}
                      checkRepeatResult={
                        _.isObject(examQuestions[nowExamQuestionNumber]) &&
                        examQuestions[nowExamQuestionNumber].EasierQuestion
                          ? checkQuestionExistExam(
                              examQuestions[nowExamQuestionNumber]
                                .EasierQuestion.Id,
                              [
                                {
                                  examQuestionIndex: nowExamQuestionNumber,
                                  type: 'easier',
                                },
                              ],
                            )
                          : undefined
                      }
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <ExamQuestionKnowledgePie />
      </Box>

      <QuestionDetailDialog
        open={isShowQuestion}
        close={() => {
          setIsShowQuestion(false);
        }}
        questionId={showQuestionId}
      />

      <ConfirmDialog
        open={isRejectQuestion}
        setOpen={setIsRejectQuestion}
        action={doRejectQuestion}
        title="退回題目"
        confirmBtnLable="退回">
        確定退回題目，進行重審嗎？
      </ConfirmDialog>
    </DragDropContext>
  );
};

export default ResultDrag;
