import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import _ from 'lodash';
import VideoList from '../../containers/Video/VideoList';

const VideoNodeDialog = (props) => {
  const { open, setOpen, mode, setSelect, onClose } = props;

  const handleClose = () => {
    setOpen(false);

    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      fullWidth={true}
      maxWidth="lg">
      <DialogTitle>選擇影片節點</DialogTitle>

      <DialogContent>
        <VideoList mode={mode} setSelect={setSelect} />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VideoNodeDialog;
