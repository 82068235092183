import { useState, useEffect, useCallback } from 'react';
import { searchPopularFeature, initHangoutList } from '../Util/fetchMethods';
import _ from 'lodash';
import { transformProductsData } from '../Util/Util';

const initProducts = {
  CurrentPage: 1,
  TotalPages: 1,
  Products: [],
};

const useProducts = (productType, defaultSearchItems) => {
  const [products, setProducts] = useState({ ...initProducts });
  const [searchItems, setSearchItems] = useState({});
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!defaultSearchItems) {
      return;
    }

    searchFetch(defaultSearchItems);

    return () => {
      setProducts({ ...initProducts });
      setIsLoading(false);
      setIsError(false);
      setError('');
    };
  }, [defaultSearchItems]);

  const search = useCallback(
    (inputSearchItems) => {
      return searchFetch(inputSearchItems);
    },
    [searchFetch],
  );

  const page = useCallback(
    (page) => {
      if (page < 0 || page > products.TotalPages) {
        return new Promise((resolve) => {
          setProducts({ ...products, CurrentPage: page, Products: [] });
          resolve();
        });
      } else {
        return searchFetch({ ...searchItems, page: page });
      }
    },
    [products, searchItems, searchFetch],
  );

  const searchFetch = useCallback(
    (fetchSearchItems) => {
      if (_.trim(fetchSearchItems.keyword) === '') {
        throw new Error('需要輸入關鍵字');
      }

      if (_.trim(fetchSearchItems.page) === '') {
        throw new Error('需要頁碼');
      }

      if (_.trim(fetchSearchItems.countOfPage) === '') {
        throw new Error('需要每頁數量');
      }

      setIsError(false);
      setIsLoading(true);
      setSearchItems(fetchSearchItems);

      let getPromise = null;

      switch (productType) {
        case 'hangout':
          getPromise = initHangoutList({
            keyword: fetchSearchItems.keyword,
            status: 1,
            page: fetchSearchItems.page,
            count: fetchSearchItems.countOfPage,
          })
            .then((response) => {
              if (response.Status === 0) {
                throw new Error(_.join(response.Message, '<br>'));
              } else {
                setProducts({
                  Products: transformProductsData(response.Result.HangoutList),
                  CurrentPage: response.Result.CurrentPage,
                  TotalPages: response.Result.TotalPages,
                });
              }
            })
            .catch((err) => {
              setError(err.message);
              setIsError(true);
            })
            .then(() => {
              setIsLoading(false);
            });
          break;

        case 'questionBank':
          getPromise = searchPopularFeature({
            keyword: fetchSearchItems.keyword,
            status: 1,
            page: fetchSearchItems.page,
            count: fetchSearchItems.countOfPage,
          })
            .then((response) => {
              if (response.Status === 0) {
                throw new Error(_.join(response.Message, '<br>'));
              } else {
                setProducts({
                  Products: transformProductsData(
                    response.Result.QuestionBankList,
                  ),
                  CurrentPage: response.Result.CurrentPage,
                  TotalPages: response.Result.TotalPages,
                });
              }
            })
            .catch((err) => {
              setError(err.message);
              setIsError(true);
            })
            .then(() => {
              setIsLoading(false);
            });
          break;

        default:
          getPromise = new Promise((resolve, reject) => {
            reject('尚未設定的 product type');
            setIsLoading(false);
            setError('尚未設定的 product type');
            setIsError(true);
          });
          break;
      }

      return getPromise;
    },
    [setSearchItems, setProducts, setIsLoading, setError, setIsError],
  );

  return {
    products,
    search,
    page,
    isLoading,
    error,
    isError,
  };
};

export default useProducts;
