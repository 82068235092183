import * as types from '../actionType';

let initState = [
  {
    Order: {
      Id: 1,
      CreateAt: '',
      TotalAmount: '',
      Status: 1,
      PaymentId: 1,
      ItemName: '',
    },
    Items: null,
  },
];

function MemberOrderListState(state = initState, action) {
  switch (action.type) {
    case types.MEMBER_ORDER_LIST_SUCCESS: {
      return action.result;
    }
    default:
      return state;
  }
}

export default MemberOrderListState;
