import React, { useEffect } from 'react';
import { trim } from 'lodash';
import authApi from '../middleware/authApi';
import { useDispatch } from 'react-redux';
import { adminLoginSuccess, logoutSuccess } from '../store/action';

const RefreshToken = () => {
  const dispatch = useDispatch();

  // 進頁面時判斷refresh_toek 並重新拿 token 並判斷登出登入
  useEffect(() => {
    const refreshToken = localStorage.getItem('refresh_token');
    const userName = localStorage.getItem('adminName');

    if (trim(refreshToken) !== '') {
      authApi.refreshToken(refreshToken).then(
        (res) => {
          dispatch(
            adminLoginSuccess(
              {
                ...res,
                refresh_token: refreshToken, // 不更新 refresh_token 不然會一直可以使用
              },
              userName,
            ),
          );
        },
        (error) => {
          dispatch(logoutSuccess());
        },
      );
    }
  }, []);

  return null;
};

export default RefreshToken;
