import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Button,
  Box,
  TableCell,
  TableRow,
  Chip,
  TextField,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AvTimerRoundedIcon from '@material-ui/icons/AvTimerRounded';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import DoneOutlineRoundedIcon from '@material-ui/icons/DoneOutlineRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import KnowledgeSelect from '../KnowledgeSelect';
import Swal from 'sweetalert2';
import { seconds2time, time2seconds } from '../../Util/Util';

const useStyles = makeStyles((theme) => ({
  editTr: {
    backgroundColor: theme.palette.grey[50],
  },
  createTr: {
    backgroundColor: theme.palette.background.paper,
  },
  toolsBtn: {
    marginLeft: theme.spacing(1),
  },
  knowledge: {
    padding: theme.spacing(1, 0),
    backgroundColor: theme.palette.grey[100],
    borderRadius: '8px',
    textAlign: 'left',

    '& span': {
      margin: theme.spacing(1),
      padding: theme.spacing(0, 1),
      fontSize: '0.8rem',
      display: 'block',
      wordWrap: 'break-word',
      whiteSpace: 'normal',
      borderLeft: '2px solid #555c78',
    },
  },
}));

const VideoNodeTableRow = (props) => {
  const {
    node,
    saveNode,
    deleteNode,
    handoutVideoId,
    isSelect,
    setSelect,
    videoDuration,
  } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(() => {
    return node ? false : true;
  });
  const [nodeForm, setNodeForm] = useState(() => {
    return {
      name: node ? node.name : '',
      knowledgeList: node
        ? _.map(node.knowledgeList, (knowledge) => knowledge.id)
        : [7032], // defualt 108課綱
      startAt: node ? seconds2time(node.startAt) : 0,
      endAt: node ? seconds2time(node.endAt) : seconds2time(videoDuration),
    };
  });

  useEffect(() => {
    if (isCreate) {
      setIsEdit(true);
    }
  }, [isCreate]);

  const handleSaveNode = () => {
    let startAtSec = time2seconds(nodeForm.startAt);
    let endAtSec = time2seconds(nodeForm.endAt);

    if (nodeForm.name === '') {
      Swal.fire({
        icon: 'error',
        title: '請填寫節點名稱',
      });

      return false;
    }

    if (nodeForm.knowledgeList.length <= 0) {
      Swal.fire({
        icon: 'error',
        title: '請選擇知識點',
      });

      return false;
    }

    if (startAtSec === false) {
      Swal.fire({
        icon: 'error',
        title: '開始秒數格式錯誤(EX: 00:00:00) 分和秒請勿 >= 60',
      });

      return false;
    }

    if (endAtSec === false) {
      Swal.fire({
        icon: 'error',
        title: '結束秒數格式錯誤(EX: 00:00:00) 分和秒請勿 >= 60',
      });

      return false;
    }

    startAtSec = parseInt(startAtSec, 10);
    endAtSec = parseInt(endAtSec, 10);

    if (startAtSec < 0 || startAtSec === '') {
      Swal.fire({
        icon: 'error',
        title: '請設定開始秒數',
      });

      return false;
    }

    if (endAtSec < 0 || endAtSec === '') {
      Swal.fire({
        icon: 'error',
        title: '請設定結束秒數',
      });

      return false;
    }

    if (endAtSec <= startAtSec) {
      Swal.fire({
        icon: 'error',
        title: '結束秒數不能早於開始秒數',
      });

      return false;
    }

    if (endAtSec > _.parseInt(videoDuration, 10)) {
      Swal.fire({
        icon: 'error',
        title: '結束時間大於影片長度',
      });

      return false;
    }

    let editNodeFormData = new URLSearchParams();

    editNodeFormData.append('Name', nodeForm.name);
    editNodeFormData.append(
      'KnowledgeIds',
      nodeForm.knowledgeList ? nodeForm.knowledgeList : '',
    );
    editNodeFormData.append('StartAt', startAtSec);
    editNodeFormData.append('EndAt', endAtSec);

    setIsLoading(true);

    saveNode(editNodeFormData, handoutVideoId, node ? node.nodeId : null).then(
      () => {
        setIsLoading(false);

        if (!isCreate) {
          setIsEdit(false);
        } else {
          setNodeForm({
            name: '',
            knowledgeList: [7032],
            startAt: 0,
            endAt: seconds2time(videoDuration),
          });
        }
      },
    );
  };

  const handleDeleteNode = () => {
    Swal.fire({
      icon: 'question',
      title: '確定要刪除節點嗎？',
      text: '如已有使用節點的講義將會失效!',
      showCancelButton: true,
      confirmButtonText: '刪除',
      cancelButtonText: '取消',
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        deleteNode(handoutVideoId, node.nodeId).then((res) => {
          if (res === false) {
            setIsLoading(false);
          }
        });
      }
    });
  };

  const trClassName = isEdit
    ? isCreate
      ? classes.createTr
      : classes.editTr
    : '';

  return (
    <TableRow className={trClassName}>
      {isEdit && (
        <TableCell align="right" colSpan={4}>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                label="名稱"
                variant="standard"
                value={nodeForm.name}
                fullWidth={true}
                size="small"
                onChange={(e) => {
                  setNodeForm({ ...nodeForm, name: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs>
              <KnowledgeSelect
                initKnowledge={nodeForm.knowledgeList}
                setKnowledge={(knowledge) => {
                  setNodeForm((state) => {
                    return { ...state, knowledgeList: knowledge };
                  });
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                label="開始秒數"
                variant="standard"
                value={nodeForm.startAt}
                fullWidth={true}
                size="small"
                onChange={(e) => {
                  setNodeForm({ ...nodeForm, startAt: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs>
              <TextField
                label="結束秒數"
                variant="standard"
                value={nodeForm.endAt}
                fullWidth={true}
                size="small"
                onChange={(e) => {
                  setNodeForm({ ...nodeForm, endAt: e.target.value });
                }}
              />
            </Grid>
          </Grid>
        </TableCell>
      )}

      {!isEdit && (
        <>
          <TableCell align="right">{node ? node.name : ''}</TableCell>

          <TableCell align="right">
            {node && node.knowledgeList.length > 0 ? (
              <Box className={classes.knowledge}>
                {_.map(node.knowledgeList, (knowledge) => (
                  <span key={knowledge.id}>{knowledge.name}</span>
                ))}
              </Box>
            ) : null}
          </TableCell>

          <TableCell align="right">
            {node ? (
              <Chip
                icon={<AvTimerRoundedIcon />}
                label={seconds2time(node.startAt)}
                color={
                  parseInt(videoDuration, 10) <= parseInt(node.startAt, 10)
                    ? 'secondary'
                    : 'default'
                }
              />
            ) : null}
          </TableCell>

          <TableCell align="right">
            {node ? (
              <Chip
                icon={<AvTimerRoundedIcon />}
                label={seconds2time(node.endAt)}
                color={
                  parseInt(videoDuration, 10) < parseInt(node.startAt, 10)
                    ? 'secondary'
                    : 'default'
                }
              />
            ) : null}
          </TableCell>
        </>
      )}

      <TableCell align="right">
        {!isEdit && !isCreate && (
          <>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<EditIcon />}
              className={classes.toolsBtn}
              onClick={() => {
                setIsEdit(true);
              }}
              disabled={isLoading}>
              編輯
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<DeleteIcon />}
              className={classes.toolsBtn}
              onClick={handleDeleteNode}
              disabled={isLoading}>
              刪除
            </Button>
            {isSelect && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<DoneOutlineRoundedIcon />}
                className={classes.toolsBtn}
                onClick={() => {
                  setSelect(node);
                }}
                disabled={isLoading}>
                加入節點
              </Button>
            )}
          </>
        )}

        {isEdit && !isCreate && (
          <>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DoneRoundedIcon />}
              className={classes.toolsBtn}
              onClick={handleSaveNode}
              disabled={isLoading}>
              儲存
            </Button>
            <Button
              variant="contained"
              color="default"
              size="small"
              startIcon={<ClearRoundedIcon />}
              className={classes.toolsBtn}
              onClick={() => {
                setIsEdit(false);
              }}
              disabled={isLoading}>
              取消
            </Button>
          </>
        )}

        {isEdit && isCreate && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<AddRoundedIcon />}
            className={classes.toolsBtn}
            onClick={handleSaveNode}
            disabled={isLoading}>
            新增
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default VideoNodeTableRow;
