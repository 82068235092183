import instance from './API';

const handoutPath = '/Handout';

const handoutAPI = {
  getList: (params) => {
    return instance.get(handoutPath, {
      params,
    });
  },
  getAllUrl: (handoutId) => {
    return instance.patch(handoutPath + '/' + handoutId);
  },
  get: (handoutId) => {
    return instance.get(handoutPath + '/' + handoutId);
  },
  add: (params) => {
    return instance.post(handoutPath, params);
  },
  edit: (params, handoutId) => {
    return instance.put(handoutPath + '/' + handoutId, params);
  },
  delete: (handoutId) => {
    return instance.delete(handoutPath + '/' + handoutId);
  },
  addNode: (handoutId, nodeId) => {
    return instance.post(handoutPath + '/' + handoutId + '/Node', null, {
      params: {
        nodeId: nodeId,
      },
    });
  },
  editNode: (handoutId, handoutNodeId, nodeId) => {
    return instance.put(
      handoutPath + '/' + handoutId + '/Node/' + handoutNodeId,
      null,
      {
        params: {
          nodeId: nodeId,
        },
      },
    );
  },
  deleteNode: (handoutId, handoutNodeId) => {
    return instance.delete(
      handoutPath + '/' + handoutId + '/Node/' + handoutNodeId,
    );
  },
  sortNode: (handoutId, handoutNodeId, sort) => {
    return instance.put(
      handoutPath +
        '/' +
        handoutId +
        '/Node/' +
        handoutNodeId +
        '/Sort/' +
        sort,
    );
  },
};

export default handoutAPI;
