import * as types from '../actionType';

export default function SendAPIStatus(state = -1, action) {
  switch (action.type) {
    case types.SEND_COMPLETE_EXAM_SUCCESS:
    case types.SEND_QUESTIONBANK_RELATION_SUCCESS:
    case types.EDIT_POPULAR_FEATURE_SUCCESS:
    case types.EDIT_POPULAR_FEATURE_PRODUCT_SUCCESS:
    case types.ADD_POPULAR_FEATURE_PRODUCT_SUCCESS:
    case types.ADD_POPULAR_SUIT_PRODUCT_SUCCESS:
    case types.EDIT_SUIT_FEATURE_PRODUCT_SUCCESS:
    case types.ADD_SUIT_EXAM_SUCCESS:
    case types.EDIT_SUIT_EXAM_SUCCESS:
    case types.EDIT_SUIT_EXAM_PRODUCT_SUCCESS:
    case types.ADD_API_BANNER_SUCCESS:
    case types.EDIT_API_BANNER_SUCCESS:
    case types.EDIT_API_QUESTIONSTORE_SUCCESS:
    case types.SEND_COMPLETE_HANGOUT_SUCCESS:
      return true;
    case types.SEND_COMPLETE_EXAM_FAILURE:
    case types.SEND_QUESTIONBANK_RELATION_FAILURE:
    case types.EDIT_POPULAR_FEATURE_FAILURE:
    case types.EDIT_POPULAR_FEATURE_PRODUCT_FAILURE:
    case types.ADD_POPULAR_FEATURE_PRODUCT_FAILURE:
    case types.ADD_POPULAR_SUIT_PRODUCT_FAILURE:
    case types.EDIT_SUIT_FEATURE_PRODUCT_FAILURE:
    case types.ADD_SUIT_EXAM_FAILURE:
    case types.EDIT_SUIT_EXAM_FAILURE:
    case types.EDIT_SUIT_EXAM_PRODUCT_FAILURE:
    case types.ADMIN_LOGIN_FAILURE:
    case types.ADD_API_BANNER_FAILURE:
    case types.EDIT_API_BANNER_FAILURE:
    case types.EDIT_API_QUESTIONSTORE_FAILURE:
    case types.SEND_COMPLETE_HANGOUT_FAILURE:
      return false;
    case types.INIT_SEND_API_STATUS:
      return -1;
    case types.SERVER_ERROR_500:
      return 500;
    default:
      return state;
  }
}
