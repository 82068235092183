import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import lime from '@material-ui/core/colors/lime';
import deepOrange from '@material-ui/core/colors/deepOrange';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import _ from 'lodash';
import { transformProductData } from '../../Util/Util';

const useStyles = makeStyles((theme) => ({
  avatarQuestionBank: {
    backgroundColor: deepOrange[500],
    color: '#fff',
    fontWeight: theme.typography.fontWeightBold,
  },
  avatarHangout: {
    backgroundColor: lime[900],
    color: '#fff',
    fontWeight: theme.typography.fontWeightBold,
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
  },
  id: {
    color: theme.palette.grey[500],
    paddingTop: theme.spacing(3),
    fontSize: '0.9rem',
  },
  price: {
    fontWeight: theme.typography.fontWeightBold,
  },
  priceDiscount: {
    color: deepOrange[800],
  },
  priceDisabled: {
    color: theme.palette.grey[200],
  },
  priceText: {
    textAlign: 'left',
  },
  priceNumber: {
    fontSize: '1.8rem',
  },
  priceUnit: {
    textAlign: 'right',
  },
  discount: {
    color: deepOrange[800],
    marginTop: theme.spacing(2),
  },
  discountDisabled: {
    color: theme.palette.grey[200],
  },
  discountDate: {
    textAlign: 'center',
    letterSpacing: '1px',
  },
}));

const useTooltipStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: '0.9rem',
    color: theme.palette.common.white,
  },
}));

const ProductCard = (props) => {
  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();

  const product = useMemo(() => {
    return transformProductData(props.product);
  }, [props.product]);

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar
            aria-label="recipe"
            className={
              product.type === 'questionBank'
                ? classes.avatarQuestionBank
                : classes.avatarHangout
            }>
            {product.type === 'questionBank' ? '題庫' : '課程'}
          </Avatar>
        }
        title={product.name}
        subheader={
          product.status === 1
            ? '上架中'
            : product.status === 2
            ? '下架中'
            : '已刪除'
        }
      />
      {product.imgUrl !== '' && (
        <CardMedia
          className={classes.media}
          image={product.imgUrl}
          title="圖片"
        />
      )}
      <CardContent>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="stretch">
          <Grid item xs={5}>
            <Box
              className={clsx(
                classes.price,
                product.isDiscount && classes.priceDisabled,
              )}>
              <Box textAlign="center">
                <div className={classes.priceText}>原價 $</div>
                <div className={classes.priceNumber}>{product.price}</div>
                <div className={classes.priceUnit}>元</div>
              </Box>
            </Box>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={5}>
            <Box
              className={clsx(
                classes.price,
                classes.priceDiscount,
                product.isDiscount === false && classes.priceDisabled,
              )}>
              <Box textAlign="center">
                <div className={classes.priceText}>特價 $</div>
                <div className={classes.priceNumber}>
                  {product.discountPrice !== '' ? product.discountPrice : '---'}
                </div>
                <div className={classes.priceUnit}>元</div>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box
          className={clsx(
            classes.discount,
            product.isDiscount === false && classes.discountDisabled,
          )}>
          <div>特價期間</div>
          <div className={classes.discountDate}>
            {product.discountStartAt !== ''
              ? product.discountStartAt + ' ~ ' + product.discountEndAt
              : '---'}
          </div>
        </Box>
      </CardContent>
      <CardActions disableSpacing>
        <span className={classes.id}>ID {product.id}</span>
        <Tooltip title="移除產品" classes={tooltipClasses}>
          <IconButton
            aria-label="移除產品"
            style={{ marginLeft: 'auto' }}
            onClick={() => {
              if (props.removeProduct && props.disabled !== true) {
                props.removeProduct();
              }
            }}>
            <RemoveCircleOutlineOutlinedIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

export default ProductCard;
