import React, { useEffect, useState } from 'react';
import RestaurantLogo from '../images/restaurant_logo.svg';
import BackBtn from '../components/Bonus/BackBtn';
import '../style/components/confirm-code.scss';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
  Drawer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import bonusAPI from '../middleware/bonusAPI';
import { alertError, seconds2time, time2seconds } from '../Util/Util';
import moment from 'moment';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';

const PhoneConfirmCodeList = (props) => {
  const [touchRefresh, setTouchRefresh] = useState(false);
  const [confirmCodes, setConfirmCodes] = useState(); // undefined
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getConfirmCode();
  }, [touchRefresh]);

  const getConfirmCode = () => {
    setIsLoading(true);

    bonusAPI
      .getPhoneConfirmCode()
      .then(
        (res) => {
          setConfirmCodes(res);
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const getCountDown = (expireDate) => {
    const expire = moment(expireDate);
    const expireDiff = Math.round(expire.diff(moment()) / 1000);

    return seconds2time(expireDiff);
  };

  const handleRefresh = () => {
    setTouchRefresh(!touchRefresh);
  };

  return (
    <div className="bonus">
      <div className="bonus__logo">
        <img src={RestaurantLogo} />
        <div className="bonus__logo-btn">
          <BackBtn />
        </div>
      </div>

      {(!confirmCodes || confirmCodes.length <= 0) && (
        <div className="confirm-code__empty">目前沒有驗證碼</div>
      )}

      {confirmCodes &&
        confirmCodes.length > 0 &&
        confirmCodes.map((confirmCode, index) => (
          <Paper
            className="confirm-code__item"
            key={index + '-' + confirmCode.cellPhone}>
            <Grid
              container
              direction="row"
              justifycontent="space-evenly"
              alignItems="center"
              spacing={2}>
              <Grid item xs className="confirm-code__phone">
                {confirmCode.cellPhone}
              </Grid>
              <Grid item xs className="confirm-code__code">
                <span className="confirm-code__title--small">驗證碼</span>
                {confirmCode.confirmCode}
                <div>
                  <span className="confirm-code__title--small">有效時間</span>
                  {getCountDown(confirmCode.expireAt)}
                </div>
              </Grid>
            </Grid>
          </Paper>
        ))}

      <Button
        variant="contained"
        color="primary"
        fullWidth
        startIcon={<RefreshRoundedIcon />}
        disabled={isLoading}
        onClick={handleRefresh}>
        重新取的驗證碼
      </Button>
    </div>
  );
};

export default PhoneConfirmCodeList;
