import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { parseExamQuestionsToPieData } from '../../Util/Util';
import { ResponsivePie } from '@nivo/pie';
import _ from 'lodash';

const KnowledgePie = (data) => {
  return (
    <ResponsivePie
      data={data}
      innerRadius={0.5}
      padAngle={2}
      cornerRadius={8}
      radialLabelsLinkOffset={3}
      radialLabelsLinkStrokeWidth={2}
      sortByValue={true}
      margin={{ top: 60, right: 40, bottom: 40, left: 40 }}
      animate={true}
      colors={{ scheme: 'nivo' }}
      radialLabel="label"
      enableSlicesLabels={true}
      sliceLabel={function(e) {
        return e.value + ' %';
      }}
      legends={[
        {
          anchor: 'right',
          direction: 'column',
          translateX: -50,
          itemWidth: 150,
          itemHeight: 30,
          itemTextColor: '#999',
          symbolSize: 20,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
              },
            },
          ],
        },
      ]}
      theme={{
        legends: {
          text: {
            fontSize: 13,
            fontWeight: 600,
            color: '#000',
          },
        },
      }}
    />
  );
};

const ExamQuestionKnowledgePie = (props) => {
  const examQuestions = useSelector((state) => state.SelectedExamQuestionState);
  const [pieData, setPieData] = useState([]);

  useEffect(() => {
    setPieData(parseExamQuestionsToPieData(examQuestions));
  }, [examQuestions]);

  return (
    <Box>
      {pieData.length > 0 && <Box height="300px">{KnowledgePie(pieData)}</Box>}
    </Box>
  );
};

export default ExamQuestionKnowledgePie;
