import { FormControlLabel, Checkbox } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { isArray, map, includes, filter } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { seconds2time } from '../../Util/Util';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 'auto'),
  },
  videoTitle: {
    fontSize: '1rem',
    fontWeight: '500',
    borderBottom: '1px solid #eee',
    padding: theme.spacing(1),
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  contentNodes: {
    flex: 1,
  },
  nodeContent: {
    display: 'flex',
    color: '#9b9595',
    fontSize: '0.9rem',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  nodeName: {
    fontSize: '1.6rem',
    fontWeight: '600',
  },
  node: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    transition: 'background 0.5s',
    '&:hover': {
      backgroundColor: '#fff3f3',
      borderRadius: theme.spacing(1),
    },
  },
  knowledge: {
    marginRight: theme.spacing(0.5),
    borderBottom: '1px solid #97a788',
  },
}));

const VideoNodeCard = (props) => {
  const { video, selectNode, selectedNodes, selectNodeAction } = props;
  const [isAllCheck, setIsAllCheck] = useState(false);
  const [nodeState, setNodeState] = useState([]);
  const classes = useStyles();

  const handleAllCheckClick = (e) => {
    const allCheckStatus = e.target.checked;

    if (allCheckStatus === true) {
      const addNodes = filter(nodeState, (node) => {
        return node.isSelected === false;
      }).map((node) => node.node);

      selectNodeAction('add', addNodes);
    } else {
      const removeNodes = filter(nodeState, (node) => {
        return node.isSelected === true;
      }).map((node) => node.node);

      selectNodeAction('remove', removeNodes);
    }
  };

  const handleNodeClick = (checked, node) => {
    if (!selectNode) {
      return false;
    }

    const nodeClickStatus = checked;

    if (nodeClickStatus === true) {
      selectNodeAction('add', node);
    } else {
      selectNodeAction('remove', node);
    }
  };

  useEffect(() => {
    const nodes = isArray(video.nodeList)
      ? map(video.nodeList, (node, index) => {
          return {
            id: node.nodeId,
            isSelected:
              filter(
                selectedNodes,
                (selectedNode) => selectedNode.nodeId === node.nodeId,
              ).length > 0,
            node: node,
          };
        })
      : [];

    setNodeState(nodes);

    return () => {
      setNodeState([]);
    };
  }, [video, selectedNodes]);

  useEffect(() => {
    let allCheckStatus = false;

    if (nodeState.length > 0) {
      allCheckStatus =
        filter(nodeState, (node) => !node.isSelected).length <= 0;
    }

    setIsAllCheck(allCheckStatus);
  }, [nodeState]);

  return (
    <div className={classes.root}>
      <div className={classes.videoTitle}>
        <span>{video.handoutVideoId}</span>
        <span>{video.name}</span>
      </div>

      <div className={classes.content}>
        <div>
          {selectNode && (
            <FormControlLabel
              labelPlacement="bottom"
              label="全選"
              control={<Checkbox color="primary" />}
              checked={isAllCheck}
              onChange={handleAllCheckClick}
            />
          )}
        </div>
        <div className={classes.contentNodes}>
          {map(video.nodeList, (node) => {
            const nodeIsSelect =
              filter(
                selectedNodes,
                (selectedNode) => selectedNode.nodeId === node.nodeId,
              ).length > 0;

            return (
              <div
                key={node.nodeId}
                className={classes.node}
                style={{ cursor: selectNode ? 'pointer' : 'auto' }}
                onClick={() => {
                  handleNodeClick(!nodeIsSelect, node);
                }}>
                <div className={classes.nodeName}>
                  {selectNode && (
                    <FormControlLabel
                      label={node.name}
                      labelPlacement="end"
                      control={<Checkbox color="primary" size="small" />}
                      checked={nodeIsSelect}
                    />
                  )}
                  {!selectNode && <span>{node.name}</span>}
                </div>

                <div className={classes.nodeContent}>
                  <div>{node.nodeId}</div>
                  <div>
                    {seconds2time(node.startAt)} : {seconds2time(node.endAt)}
                  </div>
                  <div>
                    {node.knowledgeList &&
                      node.knowledgeList.length > 0 &&
                      map(node.knowledgeList, (knowledge) => (
                        <span key={knowledge.id} className={classes.knowledge}>
                          {knowledge.name}
                        </span>
                      ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default VideoNodeCard;
