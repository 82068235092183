import React from 'react';
import PropTypes from 'prop-types';
import '../style/components/alert-box.scss';

const AlertBox = ({ isVisible, content, closebox }) => {
  return (
    <div
      className="alert-box animation bounceIn"
      style={{ display: isVisible ? 'block' : 'none' }}>
      <div className="top">
        <div className="cross" onClick={closebox}></div>
      </div>
      <div
        className="contnet"
        dangerouslySetInnerHTML={{ __html: content }}></div>
      <div className="combtns">
        <div className="ok" onClick={closebox}>
          確認
        </div>
      </div>
    </div>
  );
};

AlertBox.propTypes = {
  isVisible: PropTypes.bool,
  content: PropTypes.string,
  closebox: PropTypes.func,
};

export default AlertBox;
