import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BougthRecord from './BougthRecord';
import MemberList from './MemberList';
import Page404 from '../../components/Page404';
import '../../style/components/MemberManagememt/member-management.scss';

const MemberManagement = (props) => {
  return (
    <Switch>
      <Route exact path={`${props.match.url}`} component={MemberList} />
      <Route
        path={`${props.match.url}/BougthRecord/:id`}
        component={BougthRecord}
      />
      <Route component={Page404} />
    </Switch>
  );
};

export default MemberManagement;
