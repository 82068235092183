import * as types from '../actionType';

let initState = [];

function popularFeature(state = initState, action) {
  switch (action.type) {
    case types.POPULAR_FEATURE_STATISTIC_SUCCESS: {
      return action.result;
    }
    default:
      return state;
  }
}

export default popularFeature;
