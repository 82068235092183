import React, { Component } from 'react';

import BreakCrumbs from '../../components/BreakCrumbs';
import '../../style/components/SaleExam/peiyou-setting.scss';
import barImage from '../../images/barMachine.png';
import sourceImage from '../../images/source-exam.png';
import targeImage from '../../images/target-exam.png';
import payYouDesImage from '../../images/payyoudes.png';
import searchImage from '../../images/Search.png';

import AlertBox from '../../components/AlertBox';
import HowUse from '../../components/HowUse';
import { popularSelectionAPI } from '../../store/apiconfig';

import { connect } from 'react-redux';
import {
  reEditExamStateAPI,
  initReEditExamState,
  initExamTypeList,
  addNewHardExam,
  DeleteHardExamById,
  addNewEasierExam,
  DeleteEasierExamById,
  InitAPIStatus,
  sendQuestionBankRelation,
} from '../../store/action';

class PeiyouSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allExams: {
        QuestionBankList: [],
      },
      ExamTypeFilter: '-1',
      YearFilter: '',
      IsNew2OldFilter: false,

      IsHowUseOpen: false,

      IsAlertVisible: false,
      alertString: '',
    };
  }

  componentDidMount() {
    this.props.reEditExamStateAPI(
      this.props.match.params.QuestionBankID,
      false,
    );
    this.props.initExamTypeList();
    this.SearchExams();
  }

  componentWillUnmount() {
    this.props.initReEditExamState();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.SendAPIState !== -1) {
      if (nextProps.SendAPIState === true) {
        this.setState({
          IsAlertVisible: true,
          alertString: '培優補差設定成功',
        });
      } else {
        this.setState({
          IsAlertVisible: true,
          alertString: '培優補差設定失敗',
        });
      }
    }
  }

  SearchExams(keyword) {
    let query = popularSelectionAPI.SEARCH;
    if (keyword) {
      query = query + '?keyword=' + keyword + '&page=1&count=1000';
    }
    fetch(query + '?page=1&count=1000', {
      method: 'get',
      headers: {
        Authorization: 'bearer ' + localStorage.getItem('access_token'),
      },
    })
      .then((response) => {
        if (response.status === 401) {
          // token過期 導回登入頁面
          this.props.logoutSuccess();
        } else {
          return response.json();
        }
      })
      .then((data) => {
        this.setState({
          allExams: data.Result,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  handleOnChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  handleTypeSelectChange = (e) => {
    this.setState({
      ExamTypeFilter: e.target.value,
    });
  };

  handleYearChange = (e) => {
    this.setState({
      YearFilter: e.target.value,
    });
  };

  handleNew2OldFilter = (e) => {
    this.setState({
      IsNew2OldFilter: true,
    });
  };

  handleToggleHowUseOpen = (e) => {
    this.setState({
      IsHowUseOpen: !this.state.IsHowUseOpen,
    });
  };

  handleDragstart = (e) => {
    e.dataTransfer.setData('text/plain', e.target.id);
  };

  handleDropEasier = (e) => {
    this.cancelDefault(e);
    let id = Number(e.dataTransfer.getData('text/plain'));
    let targetObj = {};
    this.state.allExams.forEach((topic) => {
      if (topic.QuestionBankId === id) {
        Object.assign(targetObj, topic);
      }
    });
    this.props.addNewEasierExam(targetObj);
  };

  handleDropHard = (e) => {
    this.cancelDefault(e);
    let id = Number(e.dataTransfer.getData('text/plain'));
    let targetObj = {};
    this.state.allExams.forEach((topic) => {
      if (topic.QuestionBankId === id) {
        Object.assign(targetObj, topic);
      }
    });
    this.props.addNewHardExam(targetObj);
  };

  handleDragenter = (e) => {
    this.cancelDefault(e);
  };

  cancelDefault = (e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  handleDragover = (e) => {
    this.cancelDefault(e);
  };

  handleAlertClose = () => {
    if (this.props.SendAPIState === true) {
      window.location = '/SaleExam/ExamList';
    }
    if (this.props.SendAPIState !== -1) {
      this.props.InitAPIStatus();
    }
    this.setState({
      IsAlertVisible: false,
    });
  };

  completeQuestionBankRelation = () => {
    const {
      QuestionBankId,
      HarderQuestionBankList,
      EasierQuestionBankList,
    } = this.props.reEditExamState;
    let sendObj = {
      SourceQuestionBankId: QuestionBankId,
      HarderQuestionBankId: HarderQuestionBankList.map(
        (item) => item.QuestionBankId,
      ),
      EasierQuestionBankId: EasierQuestionBankList.map(
        (item) => item.QuestionBankId,
      ),
    };
    this.props.sendQuestionBankRelation(sendObj);
  };

  get FilterArray() {
    let { ExamTypeFilter, YearFilter, IsNew2OldFilter, allExams } = this.state;
    console.log(JSON.parse(JSON.stringify(allExams)).QuestionBankList);
    let FilterArray = JSON.parse(JSON.stringify(allExams)).QuestionBankList;
    // 自己不會是自己的選項
    FilterArray = FilterArray.filter(
      (item) =>
        item.QuestionBankId !== this.props.reEditExamState.QuestionBankId,
    );

    if (IsNew2OldFilter) {
      FilterArray = FilterArray.sort((a, b) => a.CreateAt - b.CreateAt);
    }
    if (ExamTypeFilter !== '-1') {
      FilterArray = FilterArray.filter(
        (item) => item.TypeId === Number(ExamTypeFilter),
      );
    }
    if (YearFilter !== '') {
      FilterArray = FilterArray.filter(
        (item) => item.Year === Number(YearFilter),
      );
    }
    return FilterArray;
  }

  render() {
    let { IsNew2OldFilter, IsHowUseOpen } = this.state;
    let {
      Name,
      TypeId,
      HarderQuestionBankList = [],
      EasierQuestionBankList = [],
    } = this.props.reEditExamState;
    const { examTypeState } = this.props;

    let TypeIdName = '';
    examTypeState.forEach((type) => {
      if (type.Id === TypeId) TypeIdName = type.Name;
    });

    //培優列表
    let drawHardQuestionList =
      HarderQuestionBankList.length > 0 ? (
        HarderQuestionBankList.map((item) => {
          let typeName = '';
          examTypeState.forEach((type) => {
            if (type.Id === item.TypeId) typeName = type.Name;
          });
          return (
            <div
              key={item.QuestionBankId}
              id={item.QuestionBankID}
              className="background"
              style={{ backgroundImage: `url(${sourceImage})` }}>
              <div
                className="cross"
                onClick={() => {
                  this.props.DeleteHardExamById(item.QuestionBankId);
                }}>
                <i className="far fa-times-circle"></i>
              </div>
              <div className="title">{item.Name}</div>
              <div className="type">{typeName}</div>
            </div>
          );
        })
      ) : (
        <div
          className="background"
          style={{ backgroundImage: `url(${sourceImage})` }}>
          <div className="title">拖曳新增培優卷</div>
          <div className="type"></div>
        </div>
      );

    //補差列表
    let drawEasierQuestionList =
      EasierQuestionBankList.length > 0 ? (
        EasierQuestionBankList.map((item) => {
          let typeName = '';
          examTypeState.forEach((type) => {
            if (type.Id === item.TypeId) typeName = type.Name;
          });
          return (
            <div
              key={item.QuestionBankId}
              className="background"
              style={{ backgroundImage: `url(${sourceImage})` }}>
              <div
                className="cross"
                onClick={() => {
                  this.props.DeleteEasierExamById(item.QuestionBankId);
                }}>
                <i className="far fa-times-circle"></i>
              </div>
              <div className="title">{item.Name}</div>
              <div className="type">{typeName}</div>
            </div>
          );
        })
      ) : (
        <div
          className="background"
          style={{ backgroundImage: `url(${sourceImage})` }}>
          <div className="title">拖曳新增補差卷</div>
          <div className="type"></div>
        </div>
      );

    // 搜尋列表
    let drawAllExamsQuestionList =
      this.FilterArray.length > 0
        ? this.FilterArray.map((item) => {
            let typeName = '';
            examTypeState.forEach((type) => {
              if (type.Id === item.TypeId) typeName = type.Name;
            });
            return (
              <div
                className="background"
                style={{ backgroundImage: `url(${sourceImage})` }}
                key={item.QuestionBankId}
                id={item.QuestionBankId}
                draggable="true"
                onDragStart={this.handleDragstart}>
                <div className="title">{item.Name}</div>
                <div className="type">{typeName}</div>
              </div>
            );
          })
        : '';

    // 中文搜尋解決辦法
    let isOnComposition = false;
    const isChrome = !!window.chrome && !!window.chrome.webstore;
    const handleComposition = (e) => {
      if (e.type === 'compositionend') {
        //composition結束 代表中文輸入完成
        isOnComposition = false;
        if (!isOnComposition && isChrome) {
          //進行搜尋
          this.SearchExams(e.target.value);
        }
      } else {
        isOnComposition = true;
      }
    };

    return (
      <div className="peiyou-setting pagefadeIn">
        <BreakCrumbs path={this.props.match.url} />
        <div className="block">
          <HowUse
            isShow={IsHowUseOpen}
            ImageSrc={payYouDesImage}
            toggleOpen={this.handleToggleHowUseOpen}
          />
          <div className="bar-image-box">
            <div
              className="bar-image"
              style={{ backgroundImage: `url(${barImage})` }}>
              <div className="exam-block makeup">
                <h4>補差卷</h4>
                <div
                  className="content"
                  onDrop={this.handleDropEasier}
                  onDragEnter={this.handleDragenter}
                  onDragOver={this.handleDragover}>
                  {drawEasierQuestionList}
                </div>
              </div>
              <div className="exam-block target">
                <div className="content">
                  <div
                    className="background"
                    style={{
                      backgroundImage: `url(${targeImage})`,
                    }}>
                    <div className="title">{Name}</div>
                    <div className="type">{TypeIdName}</div>
                  </div>
                </div>
              </div>
              <div className="exam-block paiyou">
                <h4>培優卷</h4>
                <div
                  className="content"
                  onDrop={this.handleDropHard}
                  onDragEnter={this.handleDragenter}
                  onDragOver={this.handleDragover}>
                  {drawHardQuestionList}
                </div>
              </div>
            </div>
            <div className="btn-group">
              <button
                className="btn save"
                onClick={this.completeQuestionBankRelation}>
                完成
              </button>
            </div>
          </div>
        </div>
        <div className="search-block">
          <div className="search-top">
            <div className="filter-tags">
              <div
                className={IsNew2OldFilter ? 'filter-tag active' : 'filter-tag'}
                onClick={this.handleNew2OldFilter}>
                最新題目
              </div>
              {/* <div className="filter-tag">排序A to Z</div> */}
              <select
                className="filter-tag"
                value={this.state.ExamTypeFilter}
                onChange={this.handleTypeSelectChange}>
                <option value="-1">試卷類別</option>
                {examTypeState.map((item) => (
                  <option key={item.Id} value={item.Id}>
                    {item.Name}
                  </option>
                ))}
              </select>
              <div className="filter-tag input-box">
                <input
                  type="text"
                  placeholder="學年度"
                  value={this.YearFilter}
                  onChange={this.handleYearChange}
                />
              </div>
            </div>
            <div className="input-box">
              <input
                type="text"
                placeholder="搜尋考卷"
                onCompositionStart={handleComposition}
                onCompositionUpdate={handleComposition}
                onCompositionEnd={handleComposition}
                onChange={(e) => {
                  if (!isOnComposition) {
                    this.SearchExams(e.target.value);
                  }
                }}
              />
              <div className="search-icon">
                <img src={searchImage} alt="" />
              </div>
            </div>
          </div>
          <div className="search-result">{drawAllExamsQuestionList}</div>
        </div>
        <AlertBox
          isVisible={this.state.IsAlertVisible}
          content={this.state.alertString}
          closebox={this.handleAlertClose}
        />
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  questionSource: store.QuestionSourceState,
  reEditExamState: store.ReEditExamState,
  examTypeState: store.ExamTypeState,
  SendAPIState: store.SendAPIStatusState,
});

export default connect(mapStateToProps, {
  reEditExamStateAPI,
  initReEditExamState,
  initExamTypeList,
  addNewHardExam,
  DeleteHardExamById,
  addNewEasierExam,
  DeleteEasierExamById,
  InitAPIStatus,
  sendQuestionBankRelation,
})(PeiyouSetting);
