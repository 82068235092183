import { useState, useEffect } from 'react';
import { getSaleArea } from '../Util/fetchMethods';
import _ from 'lodash';

const useSaleArea = () => {
  const [saleArea, setSaleArea] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    getSaleArea()
      .then((response) => {
        if (response.Status === 0) {
          throw new Error(_.join(response.Message, '<br>'));
        } else {
          setSaleArea(response.Result);
        }
      })
      .catch((err) => {
        setError(err.message);
      })
      .then(() => {
        setLoading(false);
      });

    return () => {
      setSaleArea([]);
      setLoading(false);
      setError('');
    };
  }, []);

  return {
    saleArea,
    loading,
    error,
  };
};

export default useSaleArea;
