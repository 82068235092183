import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import _ from 'lodash';
import HandoutNodeItem from './HandoutNodeItem';
import SourceUrlDialog from '../SourceUrlDialog';
import VideoNodePlayerDialog from '../Video/VideoNodePlayerDialog';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

const HandoutNodes = (props) => {
  const {
    isEdit,
    nodes,
    sortNodes,
    deleteHandoutNode,
    handoutId,
    changeVideoNode,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [urlDialogBag, setUrlDialogBag] = useState({
    open: false,
    sourceId: '',
    type: '',
  });
  const [videoDialogBag, setVideoDialogBag] = useState({
    open: false,
    videoPath: '',
    videoName: '',
    startAt: '',
    endAt: '',
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.source.index === result.destination.index) {
      return;
    }

    const handoutNodeId = nodes[result.source.index].handoutNodeId;
    const newSort = _.parseInt(result.destination.index) + 1;
    const newNodes = reorder(
      nodes,
      result.source.index,
      result.destination.index,
    );

    setIsLoading(true);

    sortNodes(handoutNodeId, newSort, newNodes).then(() => {
      setIsLoading(false);
    });
  };

  const showSourceUrlDialog = (sourceId, type) => {
    setUrlDialogBag({
      ...urlDialogBag,
      open: true,
      sourceId: sourceId,
      type: type,
    });
  };

  const showVideoNodeDialog = (videoPath, videoName, startAt, endAt) => {
    setVideoDialogBag({
      ...videoDialogBag,
      open: true,
      videoPath: videoPath,
      videoName: videoName,
      startAt: startAt,
      endAt: endAt,
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {_.map(nodes, (node, index) => (
              <Draggable
                key={node.nodeId + '_' + node.sort}
                draggableId={String(node.nodeId + '_' + node.sort)}
                index={index}
                isDragDisabled={!isEdit}>
                {(provided, snapshot) => (
                  <div>
                    <div
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}>
                      <HandoutNodeItem
                        node={node}
                        handoutId={handoutId}
                        deleteHandoutNode={deleteHandoutNode}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        isDragging={snapshot.isDragging}
                        showSourceUrlDialog={showSourceUrlDialog}
                        showVideoNodeDialog={showVideoNodeDialog}
                        changeVideoNode={changeVideoNode}
                        isEdit={isEdit}
                      />
                    </div>
                    {provided.placeholder}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <VideoNodePlayerDialog
        open={videoDialogBag.open}
        setOpen={(open) => {
          setVideoDialogBag({ ...videoDialogBag, open: open });
        }}
        videoPath={videoDialogBag.videoPath}
        videoName={videoDialogBag.videoName}
        startAt={videoDialogBag.startAt}
        endAt={videoDialogBag.endAt}
      />
      <SourceUrlDialog
        open={urlDialogBag.open}
        setOpen={(open) => {
          setUrlDialogBag({ ...urlDialogBag, open: open });
        }}
        sourceId={urlDialogBag.sourceId}
        type={urlDialogBag.type}
        title="講義節點連結顯示"
      />
    </DragDropContext>
  );
};

export default HandoutNodes;
