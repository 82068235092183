import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Button,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import bonusProductAPI from '../../middleware/bonusProductAPI';
import ReferralCodeList from '../../components/BonusProduct/ReferralCodeList';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import EditReferralCode from '../../components/BonusProduct/EditReferralCode';
import { trim } from 'lodash';

const BonusProductReferralCodeModal = (props) => {
  const { close, isOpen, bonusProductId } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [referralCodes, setReferralCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageState, setPageState] = useState({
    status: 'list',
    referralCode: undefined,
  });

  const getReferralCodes = () => {
    setIsLoading(true);

    bonusProductAPI
      .getReferralCode(bonusProductId)
      .then((res) => {
        setReferralCodes(res);
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const deleteReferralCode = (codeId) => {
    setIsLoading(true);

    bonusProductAPI
      .deleteReferralCode(bonusProductId, codeId)
      .then(() => {
        getReferralCodes();
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const editReferralCode = (referralCodeData) => {
    if (trim(referralCodeData.id) !== '') {
      setIsLoading(true);

      bonusProductAPI
        .updateReferralCode(
          bonusProductId,
          referralCodeData.id,
          referralCodeData,
        )
        .then((rs) => {
          setPageState({
            status: 'list',
            referralCode: undefined,
          });
          getReferralCodes();
        })
        .then(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);

      bonusProductAPI
        .createReferralCode(bonusProductId, referralCodeData)
        .then((rs) => {
          setPageState({
            status: 'list',
            referralCode: undefined,
          });
          getReferralCodes();
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  };

  const setEditReferralCode = (referralCode) => {
    setPageState({
      status: 'edit',
      referralCode: referralCode,
    });
  };

  const setAddReferralCode = () => {
    setPageState({
      status: 'add',
      referralCode: undefined,
    });
  };

  const goList = () => {
    setPageState({
      status: 'list',
      referralCode: undefined,
    });
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      close();
    }
  };

  useEffect(() => {
    if (bonusProductId) {
      getReferralCodes();
    }
  }, [bonusProductId]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      fullWidth={true}>
      <DialogTitle>推薦碼設定</DialogTitle>
      <DialogContent>
        {pageState.status === 'list' && (
          <ReferralCodeList
            referralCodes={referralCodes}
            isLoading={isLoading}
            setEditReferralCode={setEditReferralCode}
            deleteReferralCode={deleteReferralCode}
          />
        )}

        {pageState.status === 'add' && (
          <EditReferralCode
            editAction={editReferralCode}
            isLoading={isLoading}
          />
        )}

        {pageState.status === 'edit' && (
          <EditReferralCode
            editAction={editReferralCode}
            isLoading={isLoading}
            referralCode={pageState.referralCode}
          />
        )}
      </DialogContent>
      <DialogActions>
        {pageState.status === 'list' && !isLoading && (
          <Button
            color="primary"
            startIcon={<AddCircleOutlinedIcon />}
            variant="contained"
            onClick={setAddReferralCode}
            fullWidth>
            新增推薦碼
          </Button>
        )}

        {(pageState.status === 'edit' || pageState.status === 'add') &&
          !isLoading && (
            <Button color="default" variant="contained" onClick={goList}>
              取消
            </Button>
          )}

        <Button color="primary" onClick={close} disabled={isLoading}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BonusProductReferralCodeModal;
