// 編卷管理 拉取題目後的試卷

import * as types from '../actionType';
import _ from 'lodash';

let initState = [
  {
    OnlyId: new Date().getTime(),
    CorrectAnswers: [],
    HasVideo: false,
    QuestionType: 1,
    SubQuestions: [],
    Knowledges: [],
    Id: -1,
    SimilarQuestion: { Id: -1, Substance: '' },
    EasierQuestion: { Id: -1, Substance: '' },
    HarderQuestion: { Id: -1, Substance: '' },
    Substance: '',
    score: 0,
  },
];

const newExamQuestion = () => {
  return {
    OnlyId: new Date().getTime(),
    CorrectAnswers: [],
    HasVideo: false,
    QuestionType: 1,
    SubQuestions: [],
    Knowledges: [],
    Id: -1,
    SimilarQuestion: { Id: -1, Substance: '' },
    EasierQuestion: { Id: -1, Substance: '' },
    HarderQuestion: { Id: -1, Substance: '' },
    Substance: '',
    score: 0,
  };
};

function SelectedExamQuestionState(state = initState, action) {
  switch (action.type) {
    case types.ADD_QUESTION_IN_EXAM: {
      let newArray = [...state];
      newArray.push(Object.assign({}, newExamQuestion()));

      return newArray;
    }

    case types.ADD_MAIN_QUESTION_EXAM: {
      let newArray = [...state];
      let onlyId = newArray[action.nowQuestion].OnlyId;

      newArray[action.nowQuestion] = Object.assign({}, action.item, {
        OnlyId: onlyId,
      });

      return newArray;
    }

    case types.ADD_EASIER_QUESTION_EXAM: {
      let newArray = [...state];

      newArray.forEach((item, index) => {
        if (item.OnlyId === action.OnlyId) {
          newArray[index].EasierQuestion = Object.assign({}, action.item);
        }
      });

      return newArray;
    }

    case types.ADD_HARD_QUESTION_EXAM: {
      let newArray = [...state];

      newArray.forEach((item, index) => {
        if (item.OnlyId === action.OnlyId) {
          newArray[index].HarderQuestion = Object.assign({}, action.item);
        }
      });

      return newArray;
    }

    case types.ADD_RELATION_IN_EXAM: {
      let newArray = [...state];

      newArray.forEach((item, index) => {
        if (item.OnlyId === action.OnlyId) {
          newArray[index].SimilarQuestion = Object.assign({}, action.item);
        }
      });

      return newArray;
    }

    case types.DELETE_QUESTION_IN_EXAM: {
      let newArray = [...state];

      return newArray.filter((item) => item.OnlyId !== action.OnlyId);
    }

    case types.DELETE_DEPTH_QUESTION_IN_EXAM: {
      let newArray = [...state];

      _.remove(newArray, (item) => {
        return item.Id === action.questionId;
      });

      _.forEach(newArray, (item, index) => {
        if (
          _.isObject(item.HarderQuestion) &&
          item.HarderQuestion.Id === action.questionId
        ) {
          newArray[index].HarderQuestion = {
            id: -1,
            Substance: '',
          };
        }

        if (
          _.isObject(item.EasierQuestion) &&
          item.EasierQuestion.Id === action.questionId
        ) {
          newArray[index].EasierQuestion = {
            id: -1,
            Substance: '',
          };
        }

        if (
          _.isObject(item.SimilarQuestion) &&
          item.SimilarQuestion.Id === action.questionId
        ) {
          newArray[index].SimilarQuestion = {
            id: -1,
            Substance: '',
          };
        }
      });

      return newArray;
    }

    case types.DLELTE_MAIN_QUESTION_EXAM: {
      let newArray = [...state];

      newArray.forEach((item, idx) => {
        if (item.OnlyId === action.OnlyId) {
          newArray[idx] = Object.assign({}, newExamQuestion());
        }
      });

      return newArray;
    }

    case types.DELETE_RELATION_IN_EXAM: {
      let newArray = [...state];

      newArray.forEach((item, index) => {
        if (item.OnlyId === action.OnlyId) {
          newArray[index].SimilarQuestion = {
            Id: -1,
            Substance: '',
          };
        }
      });

      return newArray;
    }

    case types.DELETE_EASIER_QUESTION_EXAM: {
      let newArray = [...state];

      newArray.forEach((item, index) => {
        if (item.OnlyId === action.OnlyId) {
          newArray[index].EasierQuestion = {
            Id: -1,
            Substance: '',
          };
        }
      });

      return newArray;
    }

    case types.DELETE_HARD_QUESTION_EXAM: {
      let newArray = [...state];

      newArray.forEach((item, index) => {
        if (item.OnlyId === action.OnlyId) {
          newArray[index].HarderQuestion = {
            Id: -1,
            Substance: '',
          };
        }
      });

      return newArray;
    }

    case types.REMOVEALL_QUESTION_IN_EXAM: {
      let newArray = [];

      return newArray;
    }

    case types.GET_QUESTION_BY_BANKID_SUCCESS: {
      let newArray = [];

      _.forOwn(action.result, (item) => {
        newArray.push({ ...item, OnlyId: _.uniqueId('exam-question') });
      });

      return newArray;
    }

    case types.CHANGE_QUESTION_SCORE: {
      let newArray = [...state];

      newArray[action.id].score = action.score;

      return newArray;
    }

    default:
      return state;
  }
}

export default SelectedExamQuestionState;
