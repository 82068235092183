import React, { useEffect, useState } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import {
  Box,
  Grid,
  Button,
  Paper,
  Typography,
  Divider,
  TextField,
} from '@material-ui/core';
import videoAPI from '../../middleware/videoAPI';
import videoNodeAPI from '../../middleware/videoNodeAPI';
import { LIST_PAGE_COUNT } from '../../data/common';
import Swal from 'sweetalert2';
import useVideoQueue from '../../hooks/useVideoQueue';
import VideoTable from '../../components/Video/VideoTable';
import TableLoading from '../../components/TableLoading';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import _ from 'lodash';
import VideoSourceDialog from '../../components/Video/VideoSourceDialog';
import questionAPI from '../../middleware/questionAPI';
import SearchIcon from '@material-ui/icons/Search';
import { alertError } from '../../Util/Util';

const VideoList = (props) => {
  const { mode, setSelect } = props;
  const {
    addVideoQueue,
    createVideoBagByFile,
    clearDoneVideoQueue,
    queueUploadStatus,
    uploadDoneNotify,
  } = useVideoQueue();
  const [pageParams, setPageParams] = useState({
    currentPage: 0,
    count: LIST_PAGE_COUNT,
    keyword: '',
    videoId: '',
  });
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [videoSourceDialogOpen, setVideoSourceDialogOpen] = useState(false);
  const [videoSourceDialogMode, setVideoSourceDialogMode] = useState('create');
  const [
    videoSourceDialogHandoutVideo,
    setVideoSourceDialogHandoutVideo,
  ] = useState(null);

  useEffect(() => {
    if (uploadDoneNotify) {
      refreshVideo(uploadDoneNotify);
    }
  }, [uploadDoneNotify]);

  useEffect(() => {
    const alertExitFn = (e) => {
      e.preventDefault();
      return (e.returnValue = '影片檔案還在上傳中，請勿關閉頁面');
    };
    let alertExitEvent = false;

    if (queueUploadStatus === 'working' && !alertExitEvent) {
      window.addEventListener('beforeunload', alertExitFn);
      alertExitEvent = true;
    } else if (queueUploadStatus === 'idle' && alertExitEvent) {
      alertExitEvent = window.removeEventListener('beforeunload', alertExitFn);
      alertExitEvent = false;
    }

    return () => {
      if (alertExitEvent) {
        window.removeEventListener('beforeunload', alertExitFn);
        alertExitEvent = false;
      }
    };
  }, [queueUploadStatus]);

  useEffect(() => {
    setIsLoading(true);

    videoAPI
      .getList({
        page: pageParams.currentPage + 1,
        count: pageParams.count,
        keyword:
          _.trim(pageParams.keyword) !== ''
            ? _.trim(pageParams.keyword)
            : undefined,
        videoId:
          _.parseInt(pageParams.videoId, 10) > 0
            ? _.parseInt(pageParams.videoId, 10)
            : undefined,
      })
      .then(
        (response) => {
          if (
            response.totalPages < pageParams.currentPage + 1 &&
            pageParams.currentPage > 0
          ) {
            setPageParams({
              ...pageParams,
              currentPage: response.totalPages - 1,
            });
          } else {
            clearDoneVideoQueue(
              _.map(response.handoutVideoList, (video) => video.handoutVideoId),
            );
            setVideos(response.handoutVideoList);
            setTotalCount(response.totalCount);
            setIsLoading(false);
          }
        },
        (error) => {
          Swal.fire({
            title: '無法取得影片列表',
            html:
              error.messages && _.isArray(error.messages)
                ? _.join(error.messages, '<br />')
                : error,
            icon: 'error',
          });
          setIsLoading(false);
        },
      );

    return () => {
      setVideos([]);
    };
  }, [pageParams, setVideos]);

  const handleSearch = () => {
    setPageParams({
      ...pageParams,
      currentPage: 0,
      keyword: document.getElementById('video-keyword-textfield').value,
      videoId: '',
    });
  };

  const handleSearchVideoId = () => {
    setPageParams({
      ...pageParams,
      currentPage: 0,
      keyword: '',
      videoId: document.getElementById('video-id-textfield').value,
    });
  };

  const handleChangePage = (event, page) => {
    setPageParams({ ...pageParams, currentPage: page });
  };

  const handleChangeRowsPerPage = (event) => {
    setPageParams({ ...pageParams, currentPage: 0, count: event.target.value });
  };

  const fileUpload = (e, handoutVideo, callback) => {
    let fileBags = [];

    if (e.target.files && e.target.files.length > 0) {
      _.forEach(e.target.files, (file) => {
        fileBags.push(createVideoBagByFile(file, handoutVideo));
      });

      setIsLoading(true);

      addVideoQueue(fileBags, (addResults) => {
        let resultHtml = '';

        _.each(addResults, (result) => {
          const styleColor = result.status === 'error' ? 'red' : 'green';

          resultHtml +=
            '<div style="font-size: 14px;color:' +
            styleColor +
            ';"><b>' +
            result.name +
            '</b>  ' +
            (result.status === 'error'
              ? '建立失敗，' + result.errorMessage
              : '建立成功，準備上傳檔案中') +
            '</div>';
        });

        Swal.fire({
          title: '建立影片結果',
          html: resultHtml,
          icon: 'info',
          showConfirmButton: false,
          timer: 1500,
        });

        setIsLoading(false);

        if (handoutVideo) {
          setPageParams({ ...pageParams });
        } else {
          setPageParams({ ...pageParams, currentPage: 0 });
        }

        if (callback) {
          callback();
        }
      });
    }
  };

  const saveVideo = (formData) => {
    videoAPI.edit(formData).then(
      (response) => {
        setPageParams({ ...pageParams });
      },
      (error) => {
        Swal.fire({
          title: '發生錯誤',
          icon: 'error',
          html:
            error && _.isArray(error.messages)
              ? _.join(error.messages, '<br />')
              : error,
        });
      },
    );
  };

  const deleteVideo = (handoutVideoId) => {
    videoAPI.delete(handoutVideoId).then(
      (response) => {
        setPageParams({ ...pageParams });
      },
      (error) => {
        alertError(error);
      },
    );
  };

  const saveNode = (formData, handoutVideoId, nodeId) => {
    const api = nodeId ? videoNodeAPI.edit : videoNodeAPI.add;

    return api(formData, handoutVideoId, nodeId).then(
      (response) => {
        return refreshNode(handoutVideoId);
      },
      (error) => {
        Swal.fire({
          title: '發生錯誤',
          icon: 'error',
          html:
            error && _.isArray(error.messages)
              ? _.join(error.messages, '<br />')
              : error,
        });
      },
    );
  };

  const deleteNode = (handoutVideoId, nodeId) => {
    return videoNodeAPI.delete(handoutVideoId, nodeId).then(
      (response) => {
        return refreshNode(handoutVideoId);
      },
      (error) => {
        alertError(error);
        return false;
      },
    );
  };

  const refreshNode = (handoutVideoId) => {
    return videoNodeAPI.getList(handoutVideoId).then((response) => {
      let newVideos = [...videos];

      _.forEach(newVideos, (video, index) => {
        if (video.handoutVideoId === handoutVideoId) {
          newVideos[index].nodeList = [...response.nodeList];
        }
      });

      setVideos(newVideos);
    });
  };

  const refreshVideo = (handoutVideoId) => {
    videoAPI.get(handoutVideoId).then((response) => {
      let newVideos = [...videos];

      _.forEach(newVideos, (newVideo, newVideoIndex) => {
        if (newVideo.handoutVideoId === handoutVideoId) {
          newVideos[newVideoIndex] = response;
        }
      });

      setVideos(newVideos);
      clearDoneVideoQueue([handoutVideoId]);
    });
  };

  const refreshList = () => {
    setPageParams({ ...pageParams });
  };

  const changeVideoFile = (handoutVideo) => {
    setVideoSourceDialogMode('update');
    setVideoSourceDialogHandoutVideo(handoutVideo);
    setVideoSourceDialogOpen(true);
  };

  const searchQuestionById = (questionId) => {
    return questionAPI.getOneById(questionId);
  };

  const createVideoByQuestion = (question, duration) => {
    const name = '題目 ' + question.id + ' 解題影片';

    return videoAPI.add(name).then(
      (response) => {
        if (response.handoutVideoId) {
          let editVideoFormData = new URLSearchParams();

          editVideoFormData.append('HandoutVideoId', response.handoutVideoId);
          editVideoFormData.append('Name', name);
          editVideoFormData.append('Duration', duration);
          editVideoFormData.append('VideoName', question.videoName);
          editVideoFormData.append(
            'KnowledgeIds',
            question.knowledges.length > 0
              ? _.map(question.knowledges, (knowledge) => knowledge.id)
              : [],
          );

          return videoAPI.edit(editVideoFormData).then(
            (editResponse) => {
              return {
                status: true,
              };
            },
            (editError) => {
              return {
                status: false,
                message:
                  editError.messages && _.isArray(editError.messages)
                    ? _.join(editError.messages, '<br />')
                    : editError,
              };
            },
          );
        }

        return {
          status: false,
          message: '無法取得影片ID',
        };
      },
      (error) => {
        return {
          status: false,
          message:
            error.messages && _.isArray(error.messages)
              ? _.join(error.messages, '<br />')
              : error,
        };
      },
    );
  };

  const updateVideoByQuestion = (handoutVideo, videoName, duration) => {
    let editVideoFormData = new URLSearchParams();

    editVideoFormData.append('HandoutVideoId', handoutVideo.handoutVideoId);
    editVideoFormData.append('Name', handoutVideo.name);
    editVideoFormData.append('VideoName', videoName);
    editVideoFormData.append('Duration', duration);

    return videoAPI.edit(editVideoFormData).then(
      (response) => {
        return {
          status: true,
        };
      },
      (error) => {
        return {
          status: false,
          message:
            error.messages && _.isArray(error.messages)
              ? _.join(error.messages, '<br />')
              : error,
        };
      },
    );
  };

  return (
    <div className="popular pagefadeIn">
      {mode !== 'selector' && (
        <BreakCrumbs path={props.match.url}></BreakCrumbs>
      )}

      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center">
          <Grid item xs={8}>
            <Typography variant="h4" color="textPrimary" gutterBottom>
              影片列表
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Box textAlign="right">
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddRoundedIcon />}
                disabled={isLoading}
                onClick={() => {
                  setVideoSourceDialogMode('create');
                  setVideoSourceDialogHandoutVideo(null);
                  setVideoSourceDialogOpen(true);
                }}>
                建立影片
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Divider light />
        {isLoading ? (
          <TableLoading rows={pageParams.count} />
        ) : (
          <>
            <Box pt={2}>
              <form noValidate autoComplete="off">
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center">
                  <Grid item xs={6}>
                    <TextField
                      label="關鍵字"
                      variant="outlined"
                      fullWidth={true}
                      id="video-keyword-textfield"
                      defaultValue={pageParams.keyword}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleSearch();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'center' }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<SearchIcon />}
                      onClick={handleSearch}>
                      搜尋影片
                    </Button>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      label="影片ID"
                      variant="outlined"
                      fullWidth={true}
                      id="video-id-textfield"
                      defaultValue={pageParams.videoId}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleSearchVideoId();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'right' }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<SearchIcon />}
                      onClick={handleSearchVideoId}>
                      搜尋影片ID
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
            <VideoTable
              videos={videos}
              defaultPrePageRows={LIST_PAGE_COUNT}
              perPageRows={pageParams.count}
              page={pageParams.currentPage}
              totalCount={totalCount}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              saveVideo={saveVideo}
              deleteVideo={deleteVideo}
              changeVideoFile={changeVideoFile}
              saveNode={saveNode}
              deleteNode={deleteNode}
              isSelect={mode === 'selector'}
              setSelect={setSelect}
            />
          </>
        )}
      </Box>
      <VideoSourceDialog
        open={videoSourceDialogOpen}
        setOpen={setVideoSourceDialogOpen}
        mode={videoSourceDialogMode}
        handoutVideo={videoSourceDialogHandoutVideo}
        fileUpload={fileUpload}
        searchQuestionById={searchQuestionById}
        createVideoByQuestion={createVideoByQuestion}
        updateVideoByQuestion={updateVideoByQuestion}
        refreshList={refreshList}
      />
    </div>
  );
};

export default VideoList;
