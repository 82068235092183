import instance from './API';

const sourceUrlPath = '/Source';

const sourceUrlAPI = {
  get: (sourceId, type) => {
    return instance.get(sourceUrlPath + '/' + sourceId, {
      params: { type: type },
    });
  },
};

export default sourceUrlAPI;
