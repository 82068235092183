import { BonusAuditStatus } from '../data/common';
import instance from './API';
import cafeShiftInstance from './APICafeShift';
import { trim } from 'lodash';

const bonusAPI = {
  verifyQrcode: (qrcodeData) => {
    return instance.post('/VerifyQRCode', undefined, {
      params: {
        qrcode: qrcodeData,
      },
    });
  },

  accumulatedPoints: (contact, amount) => {
    return instance.post('/AccumulatedPoints', undefined, {
      params: {
        contact: contact,
        amount: amount,
      },
    });
  },

  makeupPoints: (contact, amount, cafeShiftPhone, makeupDate) => {
    return instance.post('/AccumulatedPoints', undefined, {
      params: {
        contact: contact,
        amount: amount,
        cellphone: cafeShiftPhone,
        makeupdate: makeupDate,
      },
    });
  },

  cafeShiftLogin: (username, password) => {
    const params = new URLSearchParams();

    params.append('grant_type', 'password');
    params.append('sys', 'CDWB');
    params.append('username', username);
    params.append('password', password);

    return cafeShiftInstance.post('/cafeshift/auth', params);
  },

  calculateBonus: (contact, invoice) => {
    return instance.get('/CalculateBonus', {
      params: {
        contact: contact,
        invoice: invoice,
      },
    });
  },

  redeemBonus: (contact, invoice, bonus) => {
    return instance.post('/RedeemBonus', undefined, {
      params: {
        contact: contact,
        invoice: invoice,
        bonus: bonus,
      },
    });
  },

  getBonus: (
    keyword,
    pageNum,
    perPageCount,
    bonusAudit,
    bonusStatus,
    saleType,
    startDate,
    endDate,
  ) => {
    return instance.get('/BonusHistory', {
      params: {
        keyword: keyword !== '' ? keyword : undefined,
        page: pageNum,
        count: perPageCount,
        audit: bonusAudit !== '' ? bonusAudit : undefined,
        status: bonusStatus !== '' ? bonusStatus : undefined,
        saleType: saleType !== '' ? saleType : undefined,
        startAt: trim(startDate) !== '' ? startDate : undefined,
        endAt: trim(endDate) !== '' ? endDate : undefined,
      },
    });
  },

  deleteBonus: (bonusId, comment) => {
    return instance.delete(`/Bonus/${bonusId}`, {
      data: {
        comment: comment,
      },
    });
  },

  auditBonus: (bonusId, comment) => {
    const params = new URLSearchParams();

    params.append('Comment', comment);
    params.append('Audit', BonusAuditStatus.SOLVED);

    return instance.post(`/Bonus/Comment/${bonusId}`, params);
  },

  uploadAuditRewardCsv: (csvFile) => {
    const formData = new FormData();

    formData.append('File', csvFile);

    return instance.post('/Bonus/Audit', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  uploadAuditRedeemCsv: (csvFile) => {
    const formData = new FormData();

    formData.append('File', csvFile);

    return instance.post('/Bonus/Audit/Redeem', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  getPhoneConfirmCode: () => {
    return instance.get('/ConfirmCode');
  },
};

export default bonusAPI;
