import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../style/components/SaleExam/add-new-type.scss';

const AddNewExamType = ({
  title,
  isVisible,
  addNewExamTypeFunc,
  closeAddBlock,
  setRenderStatus,
}) => {
  const [inputValue, setInputValue] = useState('');

  const handleAddNewExamType = () => {
    addNewExamTypeFunc({ Name: inputValue });
    setRenderStatus(true);
    closeAddBlock();
    setInputValue('');
  };

  const handleInputValue = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <div
      className="add-new-exam-type fadeIn"
      style={{ display: isVisible ? 'flex' : 'none' }}>
      <div className="add-block">
        <div
          className="cross"
          onClick={() => {
            closeAddBlock();
            setInputValue('');
          }}>
          X
        </div>
        <div className="title">{title}</div>
        <div className="body">
          <input type="text" value={inputValue} onChange={handleInputValue} />
          <button className="btn add-save" onClick={handleAddNewExamType}>
            儲存
          </button>
          <button
            className="btn add-cancel"
            onClick={() => {
              closeAddBlock();
              setInputValue('');
            }}>
            取消
          </button>
        </div>
      </div>
    </div>
  );
};

AddNewExamType.propTypes = {
  title: PropTypes.string,
  isVisible: PropTypes.bool,
  addNewExamTypeFunc: PropTypes.func,
  closeAddBlock: PropTypes.func,
};

export default AddNewExamType;
