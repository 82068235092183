import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import SimpleVideoList from '../../containers/Video/SimpleVideoList';

const SimpleVideoNodeDialog = (props) => {
  const {
    open,
    close,
    selectedNodes,
    setSelectedNodes,
    selectNode,
    addSelectedNodes,
  } = props;

  const handleClose = () => {
    if (close) {
      close();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      fullWidth={true}
      maxWidth="lg">
      <DialogTitle>選擇影片節點</DialogTitle>

      <DialogContent>
        <SimpleVideoList
          selectNode={selectNode}
          selectedNodes={selectedNodes}
          setSelectedNodes={setSelectedNodes}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="primary" onClick={addSelectedNodes}>
          加入 {selectedNodes.length <= 0 ? '' : `(${selectedNodes.length})`}
        </Button>
        <Button onClick={handleClose}>關閉</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SimpleVideoNodeDialog;
