import React, { useState } from 'react';
import { Box, Grid, Chip, Button, Paper } from '@material-ui/core';
import PlayCircleOutlineRoundedIcon from '@material-ui/icons/PlayCircleOutlineRounded';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import Swal from 'sweetalert2';
import map from '../../data/Map';
import { seconds2time } from '../../Util/Util';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 'auto'),
    padding: theme.spacing(1),
  },
  isDragging: {
    backgroundColor: theme.palette.warning.main,
  },
  videoNode: {
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(1),
    color: theme.palette.error.light,
  },
  nodeTitle: {
    marginBottom: theme.spacing(1),
    fontSize: '0.7rem',
    fontWeight: '600',
    borderBottom: '1px solid #fff',
    color: '#9fa0a7',
  },
  nodeTimes: {
    marginLeft: theme.spacing(1),
    letterSpacing: '1px',
    fontSize: '0.8rem',
    display: 'inline-block',

    '& > span': {
      fontWeight: '600',
      padding: theme.spacing(0, 1),
    },
  },
  nodeTimeArrow: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  buttons: {
    textAlign: 'right',

    '& > button': {
      marginLeft: theme.spacing(2),
    },
  },
  nodeVideoId: {
    margin: theme.spacing(0, 1),
  },
}));

const HandoutNodeItem = (props) => {
  const {
    isLoading,
    setIsLoading,
    node,
    deleteHandoutNode,
    handoutId,
    isDragging,
    showSourceUrlDialog,
    showVideoNodeDialog,
    changeVideoNode,
    isEdit,
  } = props;
  const classes = useStyles();

  const handleDeleteHandoutNode = () => {
    Swal.fire({
      icon: 'question',
      title: '確定要刪除嗎？',
      text: '請注意如果已使用此連結，刪除後將無法再恢復!',
      showCancelButton: true,
      confirmButtonText: '刪除',
      cancelButtonText: '取消',
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);

        deleteHandoutNode(handoutId, node.handoutNodeId).then(() => {
          setIsLoading(false);
        });
      }
    });
  };

  const handleOpenSourceUrl = () => {
    showSourceUrlDialog(node.handoutNodeId, map.SourceUrlType.handoutNode);
  };

  const handleOpenVideoNode = () => {
    showVideoNodeDialog(
      node.videoPath,
      node.videoName,
      node.startAt,
      node.endAt,
    );
  };

  const handleChangeVideoNode = () => {
    changeVideoNode(node.handoutNodeId);
  };

  const rootClassName =
    classes.root + (isDragging ? ' ' + classes.isDragging : '');

  return (
    <Box component={Paper} className={rootClassName}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={2}>
        <Grid xs={1} item>
          ID {node.handoutNodeId}
        </Grid>

        <Grid xs={8} item>
          <Box className={classes.videoNode}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              spacing={1}>
              <Grid item xs={12}>
                <Box className={classes.nodeTitle}>
                  影片節點 | 影片ID{' '}
                  <span className={classes.nodeVideoId}>{node.videoId}</span>
                </Box>
              </Grid>
              <Grid item xs={7}>
                <Chip label={node.name} />
                <div className={classes.nodeTimes}>
                  片長
                  <span>
                    {seconds2time(
                      _.parseInt(node.endAt) - _.parseInt(node.startAt),
                    )}
                  </span>
                  秒
                </div>
                <div className={classes.nodeTimes}>
                  片段時間<span>{seconds2time(node.startAt)}</span>
                  <div className={classes.nodeTimeArrow}>
                    <ArrowRightAltRoundedIcon />
                  </div>
                  <span>{seconds2time(node.endAt)}</span>秒
                </div>
              </Grid>
              <Grid item xs={5} className={classes.buttons}>
                <Button
                  variant="contained"
                  color="default"
                  size="small"
                  startIcon={<PlayCircleOutlineRoundedIcon />}
                  disabled={isLoading}
                  onClick={handleOpenVideoNode}>
                  觀看影片
                </Button>

                {isEdit && (
                  <Button
                    variant="contained"
                    color="default"
                    size="small"
                    startIcon={<EditIcon />}
                    disabled={isLoading}
                    onClick={handleChangeVideoNode}>
                    更換影片節點
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid xs={3} item className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<LinkRoundedIcon />}
            disabled={isLoading}
            onClick={handleOpenSourceUrl}>
            顯示連結
            <br />
            QR CODE
          </Button>

          {isEdit && (
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<DeleteIcon />}
              disabled={isLoading}
              onClick={handleDeleteHandoutNode}>
              刪除
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default HandoutNodeItem;
