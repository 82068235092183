import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { format, isValid } from 'date-fns';

export default function DateInput(props) {
  const handleDateChange = (date) => {
    const setDate = isValid(date) ? format(date, 'yyyy/MM/dd') : null;

    props.setDate(setDate);
  };

  const defaultInputAttr = {
    fullWidth: true,
    autoOk: true,
    variant: 'inline',
  };

  return (
    <KeyboardDatePicker
      disableToolbar
      format="yyyy/MM/dd"
      label={props.label}
      value={props.date}
      onChange={handleDateChange}
      {...Object.assign(defaultInputAttr, props.inputAttr)}
    />
  );
}
