import axios from 'axios';
import qs from 'qs';
import { camelizeKeys } from 'humps';
import { SERVER_HOST, SERVER_API_TIMEOUT_MS } from '../store/apiconfig';

function parseBody(response) {
  if (response.status === 200 && response.data.error === '1') {
    return response.data;
  }

  return parseError(response.data.errorDescription);
}

/**
 * parse error response
 */
function parseError(errorDescription) {
  if (errorDescription instanceof Error) {
    return Promise.reject({ messages: [errorDescription.message] });
  } else {
    return Promise.reject({ messages: ['登入失敗'] });
  }
}

/**
 * axios instance
 */
const instance = axios.create({
  baseURL: `${SERVER_HOST}`,
  timeout: SERVER_API_TIMEOUT_MS,
  responseType: 'json',
  paramsSerializer(params) {
    return qs.stringify(params, { indices: false });
  },
});

instance.interceptors.request.use(
  (config) => {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    return config;
  },
  (error) => {
    if (error.response) {
      return parseError(error.response.data);
    }

    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => parseBody(camelizeKeys(response)),
  (error) => {
    if (error.response) {
      return parseError(error.response.data);
    }

    return Promise.reject(error);
  },
);

export default instance;
