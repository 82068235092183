import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

function IncomeChart({ reports }) {
  const options = {
    plugins: {
      // Change options for ALL labels of THIS CHART
      datalabels: {
        align: 'top',
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback(label) {
              let finalLabel = label;
              if (label >= 1000000) finalLabel = `${label / 1000000}M`;
              if (label >= 1000 && label < 1000000)
                finalLabel = `${label / 1000}K`;
              return finalLabel;
            },
            min: 0,
          },
          scaleLabel: {
            display: true,
            labelString: '1K = 1,000, 1M = 1000,000',
          },
        },
      ],
    },
  };
  const data = {
    labels: reports.length ? reports.map((yearReport) => yearReport.label) : [],
    datasets: [
      {
        label: '每月營收',
        fill: false,
        lineTension: 0,
        backgroundColor: 'rgba(250, 0, 0, 0.4)',
        borderColor: 'rgba(250, 0, 0, 1)',
        pointBackgroundColor: '#fff',
        data: reports.length
          ? reports.map((yearReport) => yearReport.value)
          : [],
      },
    ],
  };

  if (reports && reports.length && reports[0] === 0) {
    return (
      <div className="loading__overlay">
        <div className="loading__content">
          <div className="spinner" />
        </div>
      </div>
    );
  }

  return (
    <div style={{ overflowX: 'auto', marginBottom: '20px', padding: '2px' }}>
      <div
        style={{
          position: 'relative',
          minWidth: '800px',
          width: '100%',
          border: '3px solid #dfdfdf',
          borderRadius: '5px',
          textAlign: 'center',
        }}>
        <h2>每月收益圖</h2>
        <Line data={data} options={options} />
      </div>
    </div>
  );
}

export default IncomeChart;
