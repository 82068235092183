import { createStore, applyMiddleware } from 'redux';
import itemApp from './reducers';
import thunk from 'redux-thunk';

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require(`redux-logger`);
  const logger = createLogger({
    collapsed: true,
  });

  middlewares.push(logger);
}

const store = createStore(itemApp, applyMiddleware(...middlewares));

export default store;
