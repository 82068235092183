import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import BreakCrumbs from '../../components/BreakCrumbs';
import AddPopular from '../../components/Advertising/AddPopular';
import AlertBox from '../../components/AlertBox';

import '../../style/components/Advertisting/popular.scss';

import { connect } from 'react-redux';
import { popularFeatureAddProduct, InitAPIStatus } from '../../store/action';

class AddPopularFeature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popularFeature: {
        Title: '',
        Description: '',
      },
      popularItems: [],
      AddPopularOn: false,

      IsAlertVisible: false,
      alertString: '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.SendAPIState !== -1) {
      if (nextProps.SendAPIState === true) {
        this.setState({
          IsAlertVisible: true,
          alertString: '儲存成功',
        });
      } else {
        this.setState({
          IsAlertVisible: true,
          alertString: '儲存失敗',
        });
      }
    }
  }

  handleOnSwitch = () => {
    this.setState({
      AddPopularOn: !this.state.AddPopularOn,
    });
  };

  handleAddProducts = (newProducts) => {
    let newArr = [...this.state.popularItems];
    newProducts.forEach((newPro) => {
      if (!newArr.some((Pro) => Pro.QuestionBankId === newPro.QuestionBankId)) {
        newArr.push(newPro);
      }
    });
    this.setState({
      popularItems: newArr,
    });
  };

  AddAndSavePopularFeatureProduct = () => {
    let { Title, Description } = this.state.popularFeature;
    if (Title.trim() === '' || Description === '') {
      this.setState({
        IsAlertVisible: true,
        alertString: '請填寫完整的精選資訊',
      });
      return;
    }

    if (this.state.popularItems.length === 0) {
      this.setState({
        IsAlertVisible: true,
        alertString: '至少新增一個商品於列表中',
      });
      return;
    }

    let questionBankIdSting = [];
    this.state.popularItems.forEach((item) => {
      questionBankIdSting.push(item.QuestionBankId);
    });

    this.props.popularFeatureAddProduct({
      Title: Title,
      Description: Description,
      QuestionBankIdList: questionBankIdSting.join(','),
    });
  };

  removeQuestionBankFromFeature = (id) => {
    let newArray = [...this.state.popularItems];
    newArray = newArray.filter((item) => item.QuestionBankId !== id);
    this.setState({
      popularItems: newArray,
    });
  };

  handleAlertClose = () => {
    if (this.props.SendAPIState === true) {
      window.location = '/Advertising/PopularList';
    }

    if (this.props.SendAPIState !== -1) {
      this.props.InitAPIStatus();
    }
    this.setState({
      IsAlertVisible: false,
    });
  };

  handTitleChange = (e) => {
    let newObj = Object.assign({}, this.state.popularFeature);
    newObj.Title = e.target.value;
    this.setState({
      popularFeature: newObj,
    });
  };

  handDescriptionChange = (e) => {
    let newObj = Object.assign({}, this.state.popularFeature);
    newObj.Description = e.target.value;
    this.setState({
      popularFeature: newObj,
    });
  };

  render() {
    const { popularFeature, popularItems } = this.state;
    return (
      <div className="popular pagefadeIn">
        <BreakCrumbs path={this.props.match.url} />
        <div className="block">
          <div className="top-block">
            <div className="text">挑選熱門商品</div>
            <button onClick={this.handleOnSwitch}>+ 新增題庫商品</button>
          </div>
          <div className="feature-information row">
            <div className="feature-title col-12">
              <label>精選名稱</label>
              <input
                type="text"
                value={popularFeature.Title}
                onChange={this.handTitleChange}
              />
            </div>
            <div className="feature-description col-12">
              <label>簡介</label>
              <textarea
                rows="3"
                cols="50"
                value={popularFeature.Description}
                onChange={this.handDescriptionChange}></textarea>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th className="text-left">商品名稱</th>
                  <th>商品狀態</th>
                  <th>原價</th>
                  <th>售價</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                {popularItems.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.QuestionBankId}</td>
                      <td>
                        <img
                          src={item.ImageUrl}
                          width="50px"
                          height="50px"
                          alt={item.Name}
                        />
                      </td>
                      <td className="text-left">{item.Name}</td>
                      <td>
                        {item.Status === 1
                          ? '上架中'
                          : item.Status === 2
                          ? '已下架'
                          : '已刪除'}
                      </td>
                      <td
                        className={
                          item.Price >
                          (item.DiscountPrice ? item.DiscountPrice : item.Price)
                            ? 'line'
                            : ''
                        }>
                        {'NT$' + item.Price}
                      </td>
                      <td>
                        {'NT$' +
                          (item.DiscountPrice
                            ? item.DiscountPrice
                            : item.Price)}
                      </td>
                      <td>
                        <button
                          className="btn-trash"
                          onClick={() => {
                            this.removeQuestionBankFromFeature(
                              item.QuestionBankId,
                            );
                          }}>
                          <i className="fa fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="btn-group">
            <button
              className="btn save"
              onClick={this.AddAndSavePopularFeatureProduct}>
              新增精選
            </button>
            <NavLink className="btn cancel" to={`/Advertising/PopularList`}>
              回列表
            </NavLink>
          </div>
        </div>
        <AddPopular
          isVisiable={this.state.AddPopularOn}
          onSwitch={this.handleOnSwitch}
          onAddProducts={this.handleAddProducts}
        />
        <AlertBox
          isVisible={this.state.IsAlertVisible}
          content={this.state.alertString}
          closebox={this.handleAlertClose}
        />
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  SendAPIState: store.SendAPIStatusState,
});

export default connect(mapStateToProps, {
  popularFeatureAddProduct,
  InitAPIStatus,
})(AddPopularFeature);
