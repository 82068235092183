import React, { useEffect, useState } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  Grid,
  Button,
  Paper,
  Typography,
  Divider,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Fab,
  Slide,
} from '@material-ui/core';
import _ from 'lodash';
import Swal from 'sweetalert2';
import DateInput from '../../components/Forms/DateInput';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import KeyboardReturnOutlinedIcon from '@material-ui/icons/KeyboardReturnOutlined';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import handoutAPI from '../../middleware/handoutAPI';
import { alertError } from '../../Util/Util';
import HandoutNodes from '../../components/Handout/HandoutNodes';
import VideoNodeDialog from '../../components/Video/VideoNodeDialog';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  node: {
    backgroundColor: theme.palette.grey[100],
    '& > button': {
      marginTop: theme.spacing(2),
    },
  },
  fab: {
    position: 'fixed',
    right: theme.spacing(3),
    bottom: theme.spacing(2),
    zIndex: 200,
    '& span': {
      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
  },
}));

const Handout = (props) => {
  const { handoutId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [smartNotes, setSmartNotes] = useState([]);
  const [openVideoNodes, setOpenVideoNodes] = useState(false);
  const [nowSelectVideoNode, setNowSelectVideoNode] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    smartNote: '',
    startAt: null,
    expireAt: null,
    description: '',
    nodeList: [],
  });

  useEffect(() => {
    setIsLoading(true);

    let load = [];

    const smartNoteLoad = axios.get(
      'https://smartnote.liangshishu.com/api.php/1_3/bookService/getBookListLSS?pageSize=99&pageNO=1&channelId=71',
    );

    load.push(smartNoteLoad);

    if (handoutId && handoutId > 0) {
      const handoutLoad = handoutAPI.get(handoutId);

      load.push(handoutLoad);
    }

    axios
      .all(load)
      .then(
        axios.spread((smartNoteResult, handoutResult) => {
          if (smartNoteResult.status === 200) {
            setSmartNotes(smartNoteResult.data.data.Booklist);
          } else {
            Swal.fire({
              title: '無法取的金考拉講義資訊',
              icon: 'error',
            });
          }

          if (handoutId && handoutResult) {
            setFormData({
              name: handoutResult.name,
              smartNote: handoutResult.code ? handoutResult.code : '',
              startAt: handoutResult.startAt,
              expireAt: handoutResult.expireAt,
              description: handoutResult.description,
              nodeList: handoutResult.nodeList,
            });
          }
        }),
      )
      .catch((error) => {
        alertError(error);
      })
      .then(() => {
        setIsLoading(false);
      });
  }, []);

  const handleFormChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = () => {
    if (_.trim(formData.name) === '') {
      Swal.fire({
        title: '無法儲存講義',
        text: '請填寫講義名稱',
        icon: 'error',
      });

      return false;
    }

    let handoutParams = new URLSearchParams();
    handoutParams.append('Name', _.trim(formData.name));

    if (_.trim(formData.smartNote) !== '') {
      handoutParams.append('Code', formData.smartNote);
    }

    if (_.trim(formData.startAt) !== '') {
      handoutParams.append(
        'StartAt',
        _.trim(formData.startAt).substr(0, 10) + ' 00:00:00',
      );
    }

    if (_.trim(formData.expireAt) !== '') {
      handoutParams.append(
        'ExpireAt',
        _.trim(formData.expireAt).substr(0, 10) + ' 23:59:59',
      );
    }

    if (_.trim(formData.description) !== '') {
      handoutParams.append('Description', _.trim(formData.description));
    }

    setIsLoading(true);

    const api = handoutId ? handoutAPI.edit : handoutAPI.add;

    api(handoutParams, handoutId).then(
      (response) => {
        Swal.fire({
          title: '儲存講義成功',
          icon: 'success',
          didClose: () => {
            setIsLoading(false);
            history.push('/Handout/' + response.handoutId);
          },
        });
      },
      (error) => {
        alertError(error, '儲存講義失敗');
        setIsLoading(false);
      },
    );
  };

  const refreshHandout = () => {
    Swal.fire({
      title: '重新整理講義內容',
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    return handoutAPI.get(handoutId).then(
      (response) => {
        setFormData({
          name: response.name,
          smartNote: response.code ? response.code : '',
          startAt: response.startAt,
          expireAt: response.expireAt,
          description: response.description,
          nodeList: response.nodeList,
        });
        Swal.close();
      },
      (error) => {
        Swal.close();
        alertError(error);
      },
    );
  };

  const setNode = (node) => {
    const apiPromise = nowSelectVideoNode
      ? handoutAPI.editNode(handoutId, nowSelectVideoNode, node.nodeId)
      : handoutAPI.addNode(handoutId, node.nodeId);

    apiPromise.then(
      (response) => {
        refreshHandout();
      },
      (error) => {
        alertError(error);
      },
    );
  };

  const sortNodes = (handoutNodeId, newSort, newNodes) => {
    return handoutAPI.sortNode(handoutId, handoutNodeId, newSort).then(
      (response) => {
        setFormData({ ...formData, nodeList: newNodes });
      },
      (error) => {
        alertError(error);
      },
    );
  };

  const deleteHandoutNode = (handoutId, handoutNodeId) => {
    return handoutAPI.deleteNode(handoutId, handoutNodeId).then(
      (response) => {
        if (response) {
          return refreshHandout();
        }
      },
      (error) => {
        alertError(error);
      },
    );
  };

  const changeVideoNode = (handoutNodeId) => {
    setNowSelectVideoNode(handoutNodeId);
    setOpenVideoNodes(true);
  };

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={props.match.url}></BreakCrumbs>

      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Typography variant="h5" color="textPrimary" gutterBottom>
          {handoutId ? '編輯講義' : '建立講義'}
        </Typography>
        <Divider light />
        <Box p={3}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            spacing={2}>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth={true}
                label="講義名稱"
                value={formData.name}
                onChange={(e) => {
                  handleFormChange('name', e.target.value);
                }}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl style={{ width: '100%' }}>
                <InputLabel id="smartNoteLabel">金考拉講義對應</InputLabel>
                <Select
                  labelId="smartNoteLabel"
                  id="smartNoteSelect"
                  fullWidth={true}
                  value={formData.smartNote}
                  onChange={(e) => {
                    handleFormChange('smartNote', e.target.value);
                  }}
                  disabled={isLoading || smartNotes.length <= 0}>
                  <MenuItem value="">不對應金考拉講義</MenuItem>
                  {_.map(smartNotes, (smartNote) => (
                    <MenuItem value={smartNote.id} key={smartNote.id}>
                      {smartNote.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <DateInput
                label="開放時間"
                date={formData.startAt}
                setDate={(date) => {
                  handleFormChange('startAt', date);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <DateInput
                label="結束時間"
                date={formData.expireAt}
                setDate={(date) => {
                  handleFormChange('expireAt', date);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth={true}
                multiline
                variant="outlined"
                rows={4}
                label="講義描述"
                value={formData.description || ''}
                onChange={(e) => {
                  handleFormChange('description', e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Divider light />

        <Box p={1} mt={2} mb={2} textAlign="right">
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ marginRight: '24px' }}
            startIcon={<SaveOutlinedIcon />}
            disabled={isLoading}
            onClick={handleSave}>
            儲存
          </Button>
          <Button
            variant="contained"
            color="default"
            size="large"
            startIcon={<KeyboardReturnOutlinedIcon />}
            disabled={isLoading}
            onClick={() => {
              history.push('/Handout/HandoutList');
            }}>
            回列表
          </Button>
        </Box>
      </Box>

      {handoutId && (
        <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
          <Typography variant="h5" color="textPrimary" gutterBottom>
            挑選影片
          </Typography>
          <Divider light />
          <Box p={1} className={classes.node}>
            {formData.nodeList && (
              <HandoutNodes
                nodes={formData.nodeList}
                sortNodes={sortNodes}
                isEdit
                deleteHandoutNode={deleteHandoutNode}
                handoutId={handoutId}
                changeVideoNode={changeVideoNode}
              />
            )}
          </Box>
          <VideoNodeDialog
            mode="selector"
            setSelect={setNode}
            open={openVideoNodes}
            setOpen={setOpenVideoNodes}
            onClose={() => {
              refreshHandout();
            }}
          />

          <Slide
            in={true}
            direction="down"
            mountOnEnter
            unmountOnExit
            timeout={{
              enter: 1000,
            }}>
            <Fab
              variant="extended"
              color="secondary"
              size="small"
              className={classes.fab}
              onClick={() => {
                setNowSelectVideoNode(null);
                setOpenVideoNodes(true);
              }}>
              <AddRoundedIcon />
              加入影片節點
            </Fab>
          </Slide>
        </Box>
      )}
    </div>
  );
};

export default Handout;
