import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import homeCarousel from './HomeCarousel';
import questionBank from './QuestionBank';
import EditPopularFeature from './EditPopularFeature';
import EditSuitFeature from './EditSuitFeature';
import AddPopularFeature from './AddPopularFeature';
import AddSuitFeature from './AddSuitFeature';
import popularList from './PopularList';
import BonusProductBanners from './BonusProductBanners';

import Page404 from '../../components/Page404';

import '../../style/components/Advertisting/adventisting.scss';

class Advertisting extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.url}`}
          component={homeCarousel}
        />
        <Route
          path={`${this.props.match.url}/HomeCarousel`}
          component={homeCarousel}
        />
        <Route
          path={`${this.props.match.url}/BonusProducts`}
          component={BonusProductBanners}
        />
        <Route
          path={`${this.props.match.url}/QuestionBank`}
          component={questionBank}
        />
        <Route
          path={`${this.props.match.url}/PopularList`}
          component={popularList}
        />
        <Route
          path={`${this.props.match.url}/EditPopularFeature/:id`}
          component={EditPopularFeature}
        />
        <Route
          path={`${this.props.match.url}/EditSuitFeature/:id`}
          component={EditSuitFeature}
        />
        <Route
          path={`${this.props.match.url}/AddPopularFeature`}
          component={AddPopularFeature}
        />
        <Route
          path={`${this.props.match.url}/AddSuitFeature`}
          component={AddSuitFeature}
        />
        <Route component={Page404} />
      </Switch>
    );
  }
}

export default Advertisting;
