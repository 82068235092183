import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Paper,
  Typography,
  Divider,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Tabs,
  Tab,
  Switch,
} from '@material-ui/core';
import { sendCompleteExam, addFlashMessage } from '../../store/action/index';
import { initExamTypeList } from '../../Util/fetchMethods';
import ImageFileUpload from '../../components/Forms/ImageFileUpload';
import { makeStyles } from '@material-ui/core/styles';
import { ExamSaleType } from '../../data/common';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import ExamQuestionScoreSetting from './ExamQuestionScoreSetting';
import LoyaltyOutlinedIcon from '@material-ui/icons/LoyaltyOutlined';
import TimerOffOutlinedIcon from '@material-ui/icons/TimerOffOutlined';
import DateInput from '../Forms/DateInput';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  forms: {
    '& > div': {
      marginBottom: theme.spacing(4),
    },
  },
  examStatusSwitch: {
    textAlign: 'right',
  },
  emptySaleEvent: {
    textAlign: 'center',
    fontSize: '2.5rem',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[300],
  },
}));

/**
 * 背景設定的初始內容
 */
const initExam = {
  SubjectId: 1,
  SaleType: 1,
  AreaId: 1,
  Price: '',
  Year: '',
  TypeId: 1,
  Name: '',
  Introduction: '',
  Status: 2,
  ImageFile: '',
  ImageUrl: '',
  QuestionIds: [], // array id
  SimilarQuestionIds: [],
  EasierQuestionIds: [],
  HarderQuestionIds: [],

  QuestionBankId: -1, //新增題庫QuestionBankId要設為-1，編輯題庫則設為要編輯的題庫Id

  // 該題
  FormalSingleChoiceScore: 0,
  FormalMultipleChoiceScore: 0,
  FormalMultiSelectionModeId: 1,
  FormalSetChoiceScore: 0,
  FormalClozeChoiceScore: 0,

  // 類似題
  SimilarSingleChoiceScore: 0,
  SimilarMultipleChoiceScore: 0,
  SimilarMultiSelectionModeId: 1,
  SimilarSetChoiceScore: 0,
  SimilarClozeChoiceScore: 0,

  // 補差題
  EasierSingleChoiceScore: 0,
  EasierMultipleChoiceScore: 0,
  EasierMultiSelectionModeId: 1,
  EasierSetChoiceScore: 0,
  EasierClozeChoiceScore: 0,

  // 培優題
  HarderSingleChoiceScore: 0,
  HarderMultipleChoiceScore: 0,
  HarderMultiSelectionModeId: 1,
  HarderSetChoiceScore: 0,
  HarderClozeChoiceScore: 0,

  // 促銷活動
  DiscountStartAt: new Date().toLocaleDateString(), //沒給就空著
  DiscountEndAt: new Date().toLocaleDateString(),
  DiscountPrice: -1, //沒有給-1

  // 歷史五標
  TopScore: 0,
  FrontScore: 0,
  AverageScore: 0,
  BackScore: 0,
  BottomScore: 0,
};

/**
 * 提供給 題目數量 一個共用的格式使用
 */
const initQuestionTypeCounts = {
  count: 0,
  videoCount: 0,
  singleChoiceCount: 0,
  multipleChoiceCount: 0,
  setChoiceCount: 0,
  setChoiceSubCount: 0,
  clozeChoiceCount: 0,
  clozeChoiceSubCount: 0,
};

const getInitExamQuestionTypeCounts = () => {
  return {
    formal: { ...initQuestionTypeCounts },
    similar: { ...initQuestionTypeCounts },
    easier: { ...initQuestionTypeCounts },
    harder: { ...initQuestionTypeCounts },
  };
};

/**
 * ExamSetting
 * component Start
 */
const ExamSetting = (props) => {
  const [exam, setExam] = useState({ ...initExam });
  const [examTypes, setExamTypes] = useState([]);
  const [nowQuestionScoreIndex, setNowQuestionScoreIndex] = useState(0);
  const [examQuestionTypeCounts, setExamQuestionTypeCounts] = useState(() => {
    return getInitExamQuestionTypeCounts();
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const questionSource = useSelector((state) => state.QuestionSourceState);
  const examQuestions = useSelector((state) => state.SelectedExamQuestionState);
  const imageFileRef = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  /**
   * ajax 取的試卷類別
   */
  useEffect(() => {
    initExamTypeList().then((response) => {
      if (response.Status !== 1) {
        Swal.fire(
          '取的試卷類別失敗',
          _.join(response.Message, '<br>'),
          'error',
        );
      } else {
        setExamTypes(response.Result);
      }
    });
  }, []);

  /**
   * 處理編輯題庫時，從API丟回來的資料
   */
  useEffect(() => {
    if (_.isEmpty(props.exam) === false) {
      let newExam = _.merge({ ...exam }, props.exam);

      _.forOwn(newExam, (value, key) => {
        if (value === null) {
          let defaultValue = '';

          switch (key) {
            case 'DiscountPrice':
              defaultValue = -1;
              break;

            case 'DiscountStartAt':
            case 'DiscountEndAt':
              defaultValue = new Date().toLocaleDateString();
              break;
          }

          newExam[key] = defaultValue;
        }
      });

      setExam(newExam);
    }

    return () => {
      setExam({ ...initExam });
      setNowQuestionScoreIndex(0);
    };
  }, [props.exam, initExam, setExam]);

  /**
   * 取出設定題目的題數和題型等
   * 設定各小題的題目ID，如沒有設定完成視為未設定
   */
  useEffect(() => {
    let examQuestionType = getInitExamQuestionTypeCounts();
    let questionIncompleteSetting = false;
    let questionIds = [];
    let similarQuestionIds = [];
    let easierQuestionIds = [];
    let harderQuestionIds = [];

    if (_.isEmpty(examQuestions) === false) {
      const countContentByType = (type, content) => {
        if (_.isEmpty(content) || content.Id === -1) {
          return;
        }

        if (content.Id > 0) {
          examQuestionType[type].count++;
        }

        if (content.HasVideo === true) {
          examQuestionType[type].videoCount++;
        }

        switch (_.parseInt(content.QuestionType)) {
          case 2:
            examQuestionType[type].clozeChoiceCount++;

            if (content.SubQuestions.length > 0) {
              examQuestionType[type].clozeChoiceSubCount =
                _.parseInt(content.SubQuestions.length) +
                examQuestionType[type].clozeChoiceSubCount;
            }
            break;

          case 3:
            examQuestionType[type].setChoiceCount++;

            if (content.SubQuestions.length > 0) {
              examQuestionType[type].setChoiceSubCount =
                _.parseInt(content.SubQuestions.length) +
                examQuestionType[type].setChoiceSubCount;
            }
            break;

          default:
            if (content.CorrectAnswers.length > 1) {
              examQuestionType[type].multipleChoiceCount++;
            } else {
              examQuestionType[type].singleChoiceCount++;
            }
            break;
        }
      };

      const checkQuestionId = (id) => {
        id = _.parseInt(id);

        return _.isInteger(id) && id > 0;
      };

      _.forEach(examQuestions, (examQuestion) => {
        /**
         * 計算各題目類別的數量
         */
        countContentByType('formal', examQuestion);
        countContentByType('similar', examQuestion.SimilarQuestion);
        countContentByType('easier', examQuestion.EasierQuestion);
        countContentByType('harder', examQuestion.HarderQuestion);

        /**
         * 將設定完成的小題，格式化成後端的樣子
         */
        if (
          checkQuestionId(examQuestion.Id) &&
          checkQuestionId(
            _.isEmpty(examQuestion.SimilarQuestion)
              ? -1
              : examQuestion.SimilarQuestion.Id,
          ) &&
          checkQuestionId(
            _.isEmpty(examQuestion.EasierQuestion)
              ? -1
              : examQuestion.EasierQuestion.Id,
          ) &&
          checkQuestionId(
            _.isEmpty(examQuestion.HarderQuestion)
              ? -1
              : examQuestion.HarderQuestion.Id,
          )
        ) {
          questionIds.push(examQuestion.Id);
          similarQuestionIds.push(examQuestion.SimilarQuestion.Id);
          easierQuestionIds.push(examQuestion.EasierQuestion.Id);
          harderQuestionIds.push(examQuestion.HarderQuestion.Id);
        } else {
          questionIncompleteSetting = true;
        }
      });
    }

    setExamQuestionTypeCounts(examQuestionType);
    setExam((prevState) => ({
      ...prevState,
      QuestionIds: questionIds,
      SimilarQuestionIds: similarQuestionIds,
      EasierQuestionIds: easierQuestionIds,
      HarderQuestionIds: harderQuestionIds,
    }));
    props.setQuestionIncompleteSetting(questionIncompleteSetting);
  }, [
    examQuestions,
    props.setQuestionIncompleteSetting,
    setExamQuestionTypeCounts,
  ]);

  const handleExamChange = (examType, changeValue) => {
    if (examType === 'DiscountStartAt' || examType === 'DiscountEndAt') {
      let discountStart =
        examType === 'DiscountStartAt'
          ? new Date(changeValue)
          : new Date(exam.DiscountStartAt);
      let discountEnd =
        examType === 'DiscountEndAt'
          ? new Date(changeValue)
          : new Date(exam.DiscountEndAt);

      if (discountStart.getTime() > discountEnd.getTime()) {
        Swal.fire('促銷活動時間錯誤', '促銷開始時間不能低於結束時間', 'error');
        return;
      }
    }

    setExam({ ...exam, [examType]: changeValue });
  };

  /**
   * questionSet 是指 正式題 類似題...
   * questionType 是指 單選、複選...
   */
  const setQuestionScore = (questionSet, questionType, score) => {
    score = score === '' ? 0 : score;
    score = _.parseInt(score);

    if (!_.isInteger(score) || score < 0) {
      return;
    }

    setExam({ ...exam, [questionSet + questionType]: score });
  };

  const saveExam = () => {
    try {
      if (_.isEmpty(exam.QuestionIds)) {
        throw new Error('請設定題目');
      }

      if (_.trim(exam.Price) === '') {
        throw new Error('請設定價格');
      }

      if (_.trim(exam.Year) === '') {
        throw new Error('請設定學年度');
      }

      if (_.trim(exam.Name) === '') {
        throw new Error('請設定名稱');
      }

      if (_.trim(exam.Introduction) === '') {
        throw new Error('請填寫試卷描述');
      }

      if (_.isEmpty(imageFileRef.current.files) && _.isEmpty(exam.ImageUrl)) {
        throw new Error('請設定試卷圖片');
      }

      if (
        exam.DiscountPrice !== -1 &&
        _.parseInt(exam.DiscountPrice) >= _.parseInt(exam.Price)
      ) {
        throw new Error('促銷價格大於原本售價');
      }

      if (
        exam.DiscountPrice !== -1 &&
        (exam.DiscountPrice < 0 ||
          _.trim(exam.DiscountPrice) === '' ||
          _.trim(exam.DiscountStartAt) === '' ||
          _.trim(exam.DiscountEndAt) === '')
      ) {
        throw new Error('促銷內容請設定完整');
      }

      const allQuestionIds = _.concat(
        exam.QuestionIds,
        exam.SimilarQuestionIds,
        exam.EasierQuestionIds,
        exam.HarderQuestionIds,
      );

      if (
        _.filter(_.countBy(allQuestionIds), (countById) => countById > 1)
          .length >= 1
      ) {
        throw new Error('試卷題目設定含有重複題目');
      }
    } catch (error) {
      Swal.fire('儲存編卷失敗', error.message, 'error');

      return;
    }

    setIsProcessing(true);

    let formData = new FormData();

    _.forOwn(exam, (post, name) => {
      if (_.isArray(post)) {
        post = JSON.stringify(post);
      }

      // 後端API如果填空會錯誤
      if (
        (name === 'DiscountStartAt' || name === 'DiscountEndAt') &&
        post === ''
      ) {
        post = new Date().toLocaleDateString();
      }

      if (name === 'ImageFile') {
        post = imageFileRef.current.files[0];
      }

      formData.append(name, post);
    });

    formData.append('KnowledgeDistribution', '');

    dispatch(sendCompleteExam(formData)).then((response) => {
      if (response.Status === 1) {
        dispatch(addFlashMessage('編卷儲存完成!'));
        history.push('/SaleExam/ExamList');
        return;
      } else {
        Swal.fire('編卷儲存失敗', _.join(response.Message, '<br>'), 'error');
      }

      setIsProcessing(false);
    });
  };

  return (
    <>
      <Box component={Paper} square elevation={1} p={1}>
        <Typography variant="h5" color="textPrimary" gutterBottom>
          試卷資料
        </Typography>
        <Divider light />
        <Box mt={2}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            spacing={2}>
            <Grid item sm={12} md={7} className={classes.forms}>
              <TextField
                fullWidth
                label="試卷名稱"
                value={exam.Name}
                onChange={(e) => {
                  handleExamChange('Name', e.target.value);
                }}
              />

              <FormControl fullWidth>
                <InputLabel id="exam-saletype-label">試卷型態</InputLabel>
                <Select
                  labelId="exam-saletype-label"
                  id="exam-saletype-select"
                  value={exam.SaleType}
                  onChange={(e) => {
                    handleExamChange('SaleType', e.target.value);
                  }}>
                  {_.map(ExamSaleType, (saleType, id) => {
                    return (
                      <MenuItem value={id} key={id}>
                        {saleType}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>
                  設定試卷型態，請注意此設定不會影響分類顯示
                </FormHelperText>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="exam-type-label">試卷類別</InputLabel>
                <Select
                  labelId="exam-type-label"
                  id="exam-type-select"
                  value={exam.TypeId}
                  onChange={(e) => {
                    handleExamChange('TypeId', e.target.value);
                  }}>
                  {_.map(examTypes, (examType) => {
                    if (examType.Status === 1) {
                      return (
                        <MenuItem value={examType.Id} key={examType.Id}>
                          {examType.Name}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
                <FormHelperText>影響前台的顯示分類</FormHelperText>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="exam-area-label">銷售地區</InputLabel>
                <Select
                  labelId="exam-area-label"
                  id="exam-area-select"
                  value={exam.AreaId}
                  onChange={(e) => {
                    handleExamChange('AreaId', e.target.value);
                  }}>
                  {_.map(questionSource.questionBankCurrency, (currency) => {
                    return (
                      <MenuItem value={currency.Id} key={currency.Id}>
                        {currency.Name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <TextField
                fullWidth
                label="價格"
                value={exam.Price}
                onChange={(e) => {
                  handleExamChange('Price', e.target.value);
                }}
              />

              <TextField
                fullWidth
                label="學年度"
                value={exam.Year}
                type="number"
                onChange={(e) => {
                  if (e.target.value.length > 3) {
                    return;
                  }

                  handleExamChange('Year', e.target.value);
                }}
              />

              <FormControl fullWidth>
                <InputLabel id="exam-subject-label">科目</InputLabel>
                <Select
                  labelId="exam-subject-label"
                  id="exam-subject-select"
                  value={exam.SubjectId}
                  onChange={(e) => {
                    handleExamChange('SubjectId', e.target.value);
                  }}>
                  {_.map(questionSource.questionBankSubject, (subject) => {
                    return (
                      <MenuItem value={subject.Id} key={subject.Id}>
                        {subject.Name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <TextField
                fullWidth
                label="試卷描述"
                variant="outlined"
                multiline
                rows={3}
                value={exam.Introduction}
                onChange={(e) => {
                  handleExamChange('Introduction', e.target.value);
                }}
                helperText="中文建議 60 個字以內需加標點符號總長不要超過70，英文則160字內需加標點符號。"
              />

              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="baseline">
                <Grid item xs={3}>
                  <Typography variant="subtitle1">上架狀態</Typography>
                </Grid>
                <Grid item xs={3} className={classes.examStatusSwitch}>
                  <Switch
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    checked={exam.Status === 1}
                    onChange={(e) => {
                      handleExamChange('Status', e.target.checked ? 1 : 2);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={12} md={5}>
              <ImageFileUpload
                label="設定試卷圖片"
                imageUrl={exam.ImageUrl}
                imageFileInputRef={imageFileRef}
                helper="檔案格式為png, jpeg, jpg, 770×437px"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box component={Paper} square elevation={1} p={1} mt={2}>
        <Typography variant="h5" color="textPrimary" gutterBottom>
          分數設定
        </Typography>
        <Divider light />
        <Box mt={2}>
          <Tabs
            value={nowQuestionScoreIndex}
            onChange={(event, value) => {
              setNowQuestionScoreIndex(value);
            }}
            indicatorColor="primary"
            textColor="primary">
            <Tab label="正式題設定" value={0} />
            <Tab label="類似題設定" value={1} />
            <Tab label="補差題設定" value={2} />
            <Tab label="培優題設定" value={3} />
          </Tabs>
          <ExamQuestionScoreSetting
            id="question-score-type-formal"
            nowQuestionScoreIndex={nowQuestionScoreIndex}
            index={0}
            questionScore="Formal"
            questionCounts={examQuestionTypeCounts.formal}
            multiSelectionMode={exam.FormalMultiSelectionModeId}
            setMultiSelectionMode={(mode) => {
              handleExamChange('FormalMultiSelectionModeId', mode);
            }}
            singleChoiceScore={exam.FormalSingleChoiceScore}
            multipleChoiceScore={exam.FormalMultipleChoiceScore}
            setChoiceScore={exam.FormalSetChoiceScore}
            clozeChoiceScore={exam.FormalClozeChoiceScore}
            setQuestionScore={setQuestionScore}
          />
          <ExamQuestionScoreSetting
            id="question-score-type-similar"
            nowQuestionScoreIndex={nowQuestionScoreIndex}
            index={1}
            questionScore="Similar"
            questionCounts={examQuestionTypeCounts.similar}
            multiSelectionMode={exam.SimilarMultiSelectionModeId}
            setMultiSelectionMode={(mode) => {
              handleExamChange('SimilarMultiSelectionModeId', mode);
            }}
            singleChoiceScore={exam.SimilarSingleChoiceScore}
            multipleChoiceScore={exam.SimilarMultipleChoiceScore}
            setChoiceScore={exam.SimilarSetChoiceScore}
            clozeChoiceScore={exam.SimilarClozeChoiceScore}
            setQuestionScore={setQuestionScore}
          />
          <ExamQuestionScoreSetting
            id="question-score-type-easier"
            nowQuestionScoreIndex={nowQuestionScoreIndex}
            index={2}
            questionScore="Easier"
            questionCounts={examQuestionTypeCounts.easier}
            multiSelectionMode={exam.EasierMultiSelectionModeId}
            setMultiSelectionMode={(mode) => {
              handleExamChange('EasierMultiSelectionModeId', mode);
            }}
            singleChoiceScore={exam.EasierSingleChoiceScore}
            multipleChoiceScore={exam.EasierMultipleChoiceScore}
            setChoiceScore={exam.EasierSetChoiceScore}
            clozeChoiceScore={exam.EasierClozeChoiceScore}
            setQuestionScore={setQuestionScore}
          />
          <ExamQuestionScoreSetting
            id="question-score-type-harder"
            nowQuestionScoreIndex={nowQuestionScoreIndex}
            index={3}
            questionScore="Harder"
            questionCounts={examQuestionTypeCounts.harder}
            multiSelectionMode={exam.HarderMultiSelectionModeId}
            setMultiSelectionMode={(mode) => {
              handleExamChange('HarderMultiSelectionModeId', mode);
            }}
            singleChoiceScore={exam.HarderSingleChoiceScore}
            multipleChoiceScore={exam.HarderMultipleChoiceScore}
            setChoiceScore={exam.HarderSetChoiceScore}
            clozeChoiceScore={exam.HarderClozeChoiceScore}
            setQuestionScore={setQuestionScore}
          />
        </Box>
      </Box>
      <Box component={Paper} square elevation={1} p={1} mt={2}>
        <Typography variant="h5" color="textPrimary" gutterBottom>
          歷史五標
        </Typography>
        <Divider light />
        <Box mt={2} mb={2}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={3}>
            <Grid item xs>
              <TextField
                label="底標"
                fullWidth
                value={exam.BottomScore}
                onChange={(e) => {
                  handleExamChange('BottomScore', e.target.value);
                }}
              />
            </Grid>
            <Grid item xs>
              <TextField
                label="後標"
                fullWidth
                value={exam.BackScore}
                onChange={(e) => {
                  handleExamChange('BackScore', e.target.value);
                }}
              />
            </Grid>
            <Grid item xs>
              <TextField
                label="均標"
                fullWidth
                value={exam.AverageScore}
                onChange={(e) => {
                  handleExamChange('AverageScore', e.target.value);
                }}
              />
            </Grid>
            <Grid item xs>
              <TextField
                label="前標"
                fullWidth
                value={exam.FrontScore}
                onChange={(e) => {
                  handleExamChange('FrontScore', e.target.value);
                }}
              />
            </Grid>
            <Grid item xs>
              <TextField
                label="頂標"
                fullWidth
                value={exam.TopScore}
                onChange={(e) => {
                  handleExamChange('TopScore', e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box component={Paper} square elevation={1} p={1} mt={2}>
        <Typography variant="h5" color="textPrimary" gutterBottom>
          促銷活動
        </Typography>
        <Divider light />
        <Box mt={2}>
          {exam.DiscountPrice === -1 ? (
            <Box mt={2} className={classes.emptySaleEvent}>
              <div>目前尚無促銷活動</div>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<LoyaltyOutlinedIcon />}
                onClick={() => {
                  setExam({ ...exam, DiscountPrice: 0 });
                }}>
                開啟促銷活動
              </Button>
            </Box>
          ) : (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={3}>
              <Grid item xs>
                <DateInput
                  label="促銷開始時間"
                  date={exam.DiscountStartAt}
                  setDate={(date) => {
                    handleExamChange('DiscountStartAt', date);
                  }}
                />
              </Grid>
              <Grid item xs>
                <DateInput
                  label="促銷結束時間"
                  date={exam.DiscountEndAt}
                  setDate={(date) => {
                    handleExamChange('DiscountEndAt', date);
                  }}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  label={`促銷價格 [原始售價 ${exam.Price}]`}
                  fullWidth
                  value={exam.DiscountPrice}
                  onChange={(e) => {
                    handleExamChange('DiscountPrice', e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs>
                <Box textAlign="center">
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<TimerOffOutlinedIcon />}
                    onClick={() => {
                      handleExamChange('DiscountPrice', -1);
                    }}>
                    關閉促銷
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
      <Box mt={7} mb={3} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          size="large"
          disableElevation
          disabled={isProcessing}
          startIcon={<AssignmentTurnedInOutlinedIcon />}
          onClick={saveExam}>
          儲存編卷
        </Button>
      </Box>
    </>
  );
};

export default ExamSetting;
