// Qrcord 顯示
import React, { Component } from 'react';
import { QRCode } from 'react-qrcode-logo';
import qrcodeLogo from '../images/qrcode_logo.png';
import '../style/components/qrcord_card.scss';

class QrcordBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { isVisible, toggleOpen, QrcordText } = this.props;
    if (!isVisible) {
      return null;
    }
    return (
      <div className="u-editblock">
        <div
          className="qrcord-card bounceIn"
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <div className="card-inner" id="qrcord-card">
            <h4>活動QR code(右鍵另存圖片)</h4>
            <div className="u-qrcode">
              <QRCode
                value={QrcordText}
                size={300}
                padding={0}
                logoImage={qrcodeLogo}
              />
            </div>
            <div className="u-btn-group right">
              <button
                className="btn btn-sm cancel m-l-16"
                onClick={(e) => {
                  e.preventDefault();
                  toggleOpen();
                }}>
                取消
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QrcordBlock;
