import React, { useState, useEffect } from 'react';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import moment from 'moment';
import 'moment/locale/zh-tw';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { DateTimePicker } from '@material-ui/pickers';
import { trim } from 'lodash';
import Swal from 'sweetalert2';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { formatNumberComma } from '../../Util/Util';

const lastMonth = moment().subtract(1, 'month');

const MakeupBonus = (props) => {
  const { isLoading, cafeShiftAuth, callMakeupBonus } = props;
  const [makeupDate, setMakeupDate] = useState(() => {
    return new Date();
  });
  const [username, setUsername] = useState('');
  const [amount, setAmount] = useState('');
  const [isUseServiceCharge, setIsUseServiceCharge] = useState(false);
  const [serviceCharge, setServiceCharge] = useState(0);

  useEffect(() => {
    if (amount) {
      const serviceCharge = Math.round(amount * 0.1);

      setServiceCharge(serviceCharge);
    } else {
      setServiceCharge(0);
    }
  }, [amount]);

  const handleMakeupDate = (date) => {
    setMakeupDate(date);
  };

  const handleMakup = () => {
    if (trim(username) === '') {
      Swal.fire({
        icon: 'error',
        title: '請輸入會員',
      });

      return false;
    }

    if (trim(amount) === '') {
      Swal.fire({
        icon: 'error',
        title: '請輸入消費金額',
      });

      return false;
    }

    if (trim(makeupDate) === '') {
      Swal.fire({
        icon: 'error',
        title: '請選擇補登日期',
      });

      return false;
    }

    if (!cafeShiftAuth || trim(cafeShiftAuth.cellPhone) === '') {
      Swal.fire({
        icon: 'error',
        title: '遺失餐廳登入帳號資訊',
        text: '請回首頁重新操作',
      });

      return false;
    }

    const bonusDate = moment(makeupDate).format('YYYY-MM-DD HH:mm:00');

    callMakeupBonus(username, totalAmount, cafeShiftAuth.cellPhone, bonusDate);
  };

  const switchServiceCharge = () => {
    setIsUseServiceCharge(!isUseServiceCharge);
  };

  const totalAmount = isUseServiceCharge
    ? parseInt(amount, 10) + parseInt(serviceCharge, 10)
    : parseInt(amount, 10);

  return (
    <div>
      <div className="bonus__title">
        <NoteAddOutlinedIcon />
        補登紅利
      </div>

      <Paper style={{ padding: '12px' }}>
        <Grid
          container
          direction="column"
          justifycontent="center"
          alignItems="stretch"
          spacing={3}>
          <Grid item xs={12}>
            <DateTimePicker
              label="補登日期"
              inputVariant="outlined"
              ampm={false}
              animateYearScrolling={true}
              autoOk
              disableFuture
              fullWidth
              minDate={lastMonth}
              value={makeupDate}
              onChange={handleMakeupDate}
              disabled={isLoading}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="會員帳號"
              variant="outlined"
              value={username}
              fullWidth
              disabled={isLoading}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="outlined" size="medium" fullWidth>
              <InputLabel>消費金額</InputLabel>
              <OutlinedInput
                id="guestAmount"
                autoFocus
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                labelWidth={60}
                type="number"
                disabled={isLoading}
              />
            </FormControl>

            <div className="bonus__service-charge">
              <FormControlLabel
                control={
                  <Switch
                    checked={isUseServiceCharge}
                    size="medium"
                    onChange={() => {
                      switchServiceCharge();
                    }}
                    color="primary"
                  />
                }
                label={
                  <span
                    className={`bonus__service-charge__title
                      ${
                        isUseServiceCharge
                          ? ''
                          : 'bonus__service-charge--delete '
                      }
                    `}>
                    服務費 $ {formatNumberComma(serviceCharge)}
                  </span>
                }
              />
            </div>
            <Divider />
            <div className="bonus__total-amount">
              發票金額 $ {formatNumberComma(totalAmount)}
            </div>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startIcon={<AddIcon />}
              onClick={handleMakup}
              disabled={isLoading}
              fullWidth>
              確定補登紅利
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default MakeupBonus;
