import React from 'react';
import { Line } from 'react-chartjs-2';

function LoginMonthChart({ reports }) {
  const loginAtHour = reports.labels
    ? reports.labels.sort((a, b) => a.label - b.label)
    : [];
  const workdaysCount = reports.labels
    ? reports.workdays.sort((a, b) => a.Hour - b.Hour)
    : [];
  const weekendsCount = reports.labels
    ? reports.weekends.sort((a, b) => a.Hour - b.Hour)
    : [];
  const options = {
    plugins: {
      // Change options for ALL labels of THIS CHART
      datalabels: {
        align: 'top',
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback(label) {
              let finalLabel = label;
              if (label >= 1000000) finalLabel = `${label / 1000000}M`;
              if (label >= 1000 && label < 1000000)
                finalLabel = `${label / 1000}K`;
              return finalLabel;
            },
            min: 0,
          },
          scaleLabel: {
            display: true,
            labelString: '登入人次',
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: '時間點',
          },
        },
      ],
    },
  };
  const data = {
    labels: reports.labels
      ? loginAtHour.map((monthReport) => monthReport.label)
      : [],
    datasets: [
      {
        label: '工作日登入人數',
        fill: false,
        lineTension: 0,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        data: reports.weekends
          ? workdaysCount.map((monthReport) => monthReport.value)
          : [],
      },
      {
        label: '假日登入人數',
        fill: false,
        lineTension: 0,
        backgroundColor: 'rgba(250, 0, 0, 0.4)',
        borderColor: 'rgba(250, 0, 0, 1)',
        pointBackgroundColor: '#fff',
        data: reports.workdays
          ? weekendsCount.map((monthReport) => monthReport.value)
          : [],
      },
    ],
  };

  if (reports && reports.length && reports[0] === 0) {
    return (
      <div className="loading__overlay">
        <div className="loading__content">
          <div className="spinner" />
        </div>
      </div>
    );
  }

  return (
    <div style={{ overflowX: 'auto', marginBottom: '20px', padding: '2px' }}>
      <div
        style={{
          position: 'relative',
          minWidth: '800px',
          width: '100%',
          border: '3px solid #dfdfdf',
          borderRadius: '5px',
          textAlign: 'center',
        }}>
        <h2>當月累計登入人數變化</h2>
        <Line data={data} options={options} />
      </div>
    </div>
  );
}

export default LoginMonthChart;
