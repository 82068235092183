import * as types from '../actionType';

let initState = [];

function HangoutListState(state = initState, action) {
  switch (action.type) {
    case types.HANGOUT_LIST_SUCCESS: {
      return action.result;
    }
    default:
      return state;
  }
}

export default HangoutListState;
