import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/zh-TW';
import Swal from 'sweetalert2';
import { TextField } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { logoutSuccess } from '../../store/action';
import httpErrorHandle from '../../Util/httpErrorHandle';
import Button from '../../components/Button';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import BreakCrumbs from '../../components/BreakCrumbs';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import dateFormat from '../../Util/dateFmt';
import { fetchOrders } from '../../Util/fetchMethods.js';
import '../../style/components/MemberManagememt/member-management.scss';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';

function OrderList() {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const dpLogoutSuccess = useMemo(() => {
    return bindActionCreators(logoutSuccess, dispatch);
  }, [dispatch, logoutSuccess]);

  const columns = [
    { title: '購買日期', field: 'date', property: true },
    { title: '訂單編號', field: 'id', property: true },
    {
      title: '狀態',
      field: 'state',
      render: ({ state }) => {
        if (state === 0) {
          return '未完成';
        } else if (state === 1) {
          return '完成';
        } else {
          return '錯誤';
        }
      },
    },
    { title: '品項', field: 'item' },
    { title: '金額', field: 'amount' },
    {
      title: '付款方式',
      field: 'payment',
      render: ({ payment }) => {
        switch (payment) {
          case 1:
            return '信用卡';
          case 2:
            return 'ATM';
          case 3:
            return '超商繳費';
          case 4:
            return '兌換卷';
          default:
            return 'error payment';
        }
      },
    },

    {
      title: '會員',
      field: 'member',
      render: ({ member, userID }) => {
        return (
          <button
            style={{ width: '130px' }}
            onClick={() => {
              history.push(`/MemberManagement/BougthRecord/${userID}`);
            }}>
            {member}
          </button>
        );
      },
    },
    { title: '聯絡方式', field: 'contact' },
  ];

  const [page, setPage] = useState({
    rowsPrePage: 10,
    currPage: 1,
    totalPages: 0,
  });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [searchText, setSearchText] = useState('');

  const updateOrders = useCallback(
    (p, rowsPrePage, searchText, start, end) => {
      if (start.length !== 0 && end.length !== 0) {
        start = dateFormat(start);
        end = dateFormat(end);
      }

      fetchOrders(p, rowsPrePage, searchText, start, end)
        .then((json) => {
          const orderList = json.Result.OrderList;
          const newOrders = orderList.map((item) => {
            const order = item.Order;
            const newOrder = {
              date: order.CreateAt.split('T')[0],
              id: order.Id,
              state: order.Status,
              item: order.ItemNameList,
              amount: order.TotalAmount,
              payment: order.PaymentId,
              member: order.RealName,
              contact: order.Contact,
              userID: order.UserId,
            };
            return newOrder;
          });
          setOrders(newOrders);
          const { CurrentPage, TotalPages, StartAt, EndAt } = json.Result;
          // 設定搜尋時間範圍，移除時區，只使用日期。
          setStartDate(new Date(StartAt.split('T')[0]));
          setEndDate(new Date(EndAt.split('T')[0]));
          // 設定頁面
          setPage({
            currPage: CurrentPage,
            totalPages: TotalPages,
            rowsPrePage: rowsPrePage,
          });
        })
        .catch((error) => {
          httpErrorHandle(
            error.httpCode,
            `fetchOrders Error: ${error.msg} httpErrorCode: ${error.httpCode}`,
            dpLogoutSuccess,
          );
        });
    },
    [dpLogoutSuccess],
  );

  const [orders, setOrders] = useState([]);

  useEffect(() => {
    // 載入第一頁，每頁十列
    updateOrders(1, 10, '', '', '');
  }, [updateOrders]);

  /* Pagination */
  const gotoPage = (p) => {
    updateOrders(p, page.rowsPrePage, searchText, startDate, endDate);
  };
  const pageSize = (e) => {
    updateOrders(page.currPage, e.target.value, searchText, startDate, endDate);
  };

  /* Search */
  const changeStartDate = (date) => {
    setStartDate(date);
  };
  const changeEndDate = (date) => {
    setEndDate(date);
  };
  const selectDate = [
    {
      id: 'start',
      label: '開始日期',
      value: startDate,
      onChange: changeStartDate,
    },
    {
      id: 'end',
      label: '結束日期',
      value: endDate,
      onChange: changeEndDate,
    },
  ];
  const inputText = (e) => {
    setSearchText(e.target.value);
  };
  const searching = () => {
    if (startDate > endDate) {
      Swal.fire('搜尋時間錯誤', '結束日期不能小於開始日期！', 'error');
    } else {
      updateOrders(1, page.rowsPrePage, searchText, startDate, endDate);
    }
  };

  return (
    <div className="member-managemenet member-list pagefadeIn">
      <BreakCrumbs path={match.url} />
      <div className="block">
        <form autoComplete="off">
          {selectDate.map((item, index) => {
            return (
              <MuiPickersUtilsProvider
                key={index}
                locale={locale}
                utils={DateFnsUtils}>
                <KeyboardDatePicker
                  invalidDateMessage="無效的日期格式"
                  style={{ margin: '5px', width: '200px' }}
                  okLabel="確認"
                  cancelLabel="取消"
                  margin="dense"
                  inputVariant="outlined"
                  id={item.id}
                  label={item.label}
                  format="yyyy/MM/dd"
                  value={item.value}
                  onChange={item.onChange}
                />
              </MuiPickersUtilsProvider>
            );
          })}
          <TextField
            style={{ margin: '5px', width: '200px' }}
            margin="dense"
            id="search-input"
            label="搜尋 姓名或手機關鍵字..."
            variant="outlined"
            onChange={inputText}
          />
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            size="medium"
            onClick={searching}>
            搜尋
          </Button>
        </form>
        <Table columns={columns} datas={orders} />
        <div className="pagination-box">
          <Pagination
            currPage={page.currPage}
            totalPage={page.totalPages}
            onChangPage={gotoPage}
          />
          <div className="view-single-page">
            每頁顯示
            <select value={page.rowsPrePage} onChange={pageSize}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderList;
