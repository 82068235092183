import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Slide,
  Box,
  Grid,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import bonusProductAPI from '../../middleware/bonusProductAPI';
import AddRecommendProductCard from './AddRecommendProductCard';
import { LIST_PAGE_COUNT } from '../../data/common';
import { alertError } from '../../Util/Util';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  ul: {
    justifyContent: 'center',
  },
});

const AddRecommendProductModal = (props) => {
  const { isOpen, close, addRecommendProduct } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [bonusProducts, setBonusProducts] = useState([]);
  const [bonusProductTotal, setBonusProductTotal] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    if (isOpen) {
      searchProduct();
    }
  }, [isOpen]);

  useEffect(() => {
    searchProduct();
  }, [page]);

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      close();
    }
  };

  const handleChangeKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const searchProduct = () => {
    setIsLoading(true);

    bonusProductAPI
      .getList(undefined, keyword, LIST_PAGE_COUNT, page)
      .then(
        (res) => {
          setBonusProducts(res.productList);
          setBonusProductTotal(res.totalCount);
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const handleAddRecommendProduct = (product) => {
    setIsLoading(true);

    const addResponse = addRecommendProduct(product);

    if (addResponse !== false) {
      addResponse.then(() => {
        setIsLoading(false);
        searchProduct();
      });
    } else {
      setIsLoading(false);
    }
  };

  const handleSearchClick = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      searchProduct();
    }
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="md"
      onClose={handleClose}>
      <DialogTitle>新增推薦商品</DialogTitle>
      <DialogContent>
        <Box textAlign="right" mb={2}>
          <TextField
            label="商品名稱"
            type="search"
            name="keyword"
            variant="outlined"
            size="small"
            disabled={isLoading}
            value={keyword}
            onChange={handleChangeKeyword}
            style={{ marginRight: '12px' }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                handleSearchClick();
              }
            }}
          />

          <Button
            variant="contained"
            color="primary"
            startIcon={<SearchIcon />}
            disabled={isLoading}
            onClick={handleSearchClick}>
            搜尋
          </Button>
        </Box>

        <Grid
          container
          direction="row"
          justifycontent="flex-start"
          alignItems="flex-start"
          spacing={2}>
          {bonusProducts.map((product) => {
            return (
              <Grid item xs={3} key={product.id}>
                <AddRecommendProductCard
                  product={product}
                  addRecommendProduct={handleAddRecommendProduct}
                  isLoading={isLoading}
                />
              </Grid>
            );
          })}

          {bonusProducts.length > 0 && (
            <Grid item xs={12}>
              <Pagination
                classes={{
                  ul: classes.ul,
                }}
                disabled={isLoading}
                count={Math.ceil(bonusProductTotal / LIST_PAGE_COUNT)}
                page={page}
                color="primary"
                onChange={(e, value) => {
                  setPage(value);
                }}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddRecommendProductModal;
