import React from 'react';
import {
  Card,
  CardHeader,
  CardActions,
  CardMedia,
  Button,
  Avatar,
} from '@material-ui/core';
import RecommendProductSubHeader from './RecommendProductSubHeader';

const RecommendProduct = (props) => {
  const { product, removeRecommendProduct, openChangeSort } = props;

  const handleRemoveRecommend = () => {
    removeRecommendProduct(product);
  };

  const handleChangeSort = () => {
    openChangeSort(product);
  };

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar style={{ backgroundColor: 'green' }}>{product.sort}</Avatar>
        }
        title={product.name}
        subheader={<RecommendProductSubHeader product={product} multiple />}
      />

      <CardMedia
        style={{ height: '120px' }}
        image={product.mainImage.imageName}
        title="封面"
      />

      <CardActions>
        <Button size="small" color="primary" onClick={handleChangeSort}>
          設定順序
        </Button>
        <Button size="small" color="secondary" onClick={handleRemoveRecommend}>
          移除推薦
        </Button>
      </CardActions>
    </Card>
  );
};

export default RecommendProduct;
