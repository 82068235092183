import instance from './API';

const videoPath = '/Handout/Video';

const videoAPI = {
  getList: (params) => {
    return instance.get(videoPath, {
      params,
    });
  },
  get: (handoutVideoId) => {
    return instance.get(videoPath + `/${handoutVideoId}`);
  },
  add: (videoName) => {
    return instance.post(videoPath, { name: videoName });
  },
  edit: (formData) => {
    return instance.put(videoPath, formData);
  },
  delete: (handoutVideoId) => {
    return instance.delete(videoPath + '/' + handoutVideoId);
  },
};

export default videoAPI;
