import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ChangeRecommendProductSortModal = (props) => {
  const { isOpen, close, changeSort, product, maxSort, isLoading } = props;
  const sorts = useMemo(() => {
    let newSorts = [];

    for (let index = 1; index <= maxSort; index++) {
      newSorts.push(index);
    }

    return newSorts;
  }, [maxSort]);
  const [sort, setSort] = useState();

  useEffect(() => {
    if (isOpen && product) {
      setSort(product.sort);
    }
  }, [isOpen, product]);

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      close();
    }
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleChangeSort = () => {
    changeSort(product, sort);
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      onClose={handleClose}
      fullWidth>
      {product && (
        <>
          <DialogTitle>設定 {product.name} 順序</DialogTitle>
          <DialogContent>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>推薦順序</InputLabel>
              <Select value={sort} onChange={handleSortChange} label="推薦順序">
                {sorts.map((sort) => {
                  return (
                    <MenuItem value={sort} key={sort}>
                      {sort}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </DialogContent>
        </>
      )}
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isLoading}>
          關閉
        </Button>
        <Button
          onClick={handleChangeSort}
          color="secondary"
          disabled={isLoading}>
          設定
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeRecommendProductSortModal;
