import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { emphasize, withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import HomeIcon from '@material-ui/icons/Home';
import deepOrange from '@material-ui/core/colors/deepOrange';
import pathMap from '../data/RouteMap';
import _ from 'lodash';

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: deepOrange[50],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: deepOrange[100],
      cursor: 'pointer',
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(deepOrange[100], 0.12),
    },
  },
}))(Chip);

const StyledBreadcrumbs = withStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
  },
}))(Breadcrumbs);

function BreakCrumbs({ path }) {
  const parsePath = (nowPath) => {
    let pathArray = nowPath.split('/').filter((item) => item.length > 0);

    pathArray = pathArray.map((item) => {
      return {
        name: pathMap[item],
        path: item,
      };
    });

    pathArray = pathArray.filter((item) => item.name !== undefined);

    return pathArray;
  };

  const paths = parsePath(path);

  return (
    <StyledBreadcrumbs aria-label="breadcrumb">
      {paths.length > 0 && (
        <StyledBreadcrumb
          component={Link}
          to="/"
          label="首頁"
          icon={<HomeIcon fontSize="small" />}
          clickable={true}
        />
      )}
      {paths.map((item, idx) => {
        return parseInt(idx, 10) + 1 !== paths.length ? (
          <StyledBreadcrumb
            key={idx}
            component={Link}
            to={
              '/' +
              _.join(
                _.slice(paths, 0, parseInt(idx, 10) + 1).map(
                  (item) => item.path,
                ),
                '/',
              )
            }
            label={item.name}
            clickable={true}
          />
        ) : (
          <StyledBreadcrumb key={idx} label={item.name} disabled={true} />
        );
      })}
    </StyledBreadcrumbs>
  );
}

BreakCrumbs.propTypes = {
  path: PropTypes.string,
};

BreakCrumbs.defaultProps = {
  path: '',
};

export default BreakCrumbs;
