import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import BreakCrumbs from '../../components/BreakCrumbs';
import Pagination from '../../components/Pagination';
import ConfirmBox from '../../components/ConfirmBox';
import CheckSecretHistory from '../../components/Secret/CheckSecretHistory';
import HowUse from '../../components/HowUse';
import Table from '../../components/Table';
import '../../style/components/Advertisting/popular.scss';
import { Checkbox } from '@material-ui/core';
import { initHangoutList, switchHangoutDisplay } from '../../Util/fetchMethods';
import Search from '../../components/Search';

const SecretList = ({ match }) => {
  const [hangoutList, setHangoutList] = useState([]);
  const [renderStatus, setRenderStatus] = useState();
  const [currPage, setCurrPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [countOfPage, setCountOfPage] = useState(10);
  const [historyBox, setHistoryBox] = useState({
    checkId: 0,
    checkName: '',
    isCheckHistoryVisible: false,
  });
  const [deleteBox, setDeleteBox] = useState({
    deletingID: '',
    isDeleteVisible: false,
  });

  const [isTrash, setIsTrash] = useState(() => {
    const { path } = match;

    return path.includes('Trash');
  });

  useEffect(() => {
    const { path } = match;

    setIsTrash(path.includes('Trash'));
    setCurrPage(1);
  }, [match]);

  const updateHangoutList = () => {
    initHangoutList({
      keyword: keyword || '',
      page: currPage,
      count: countOfPage,
      status: isTrash ? 3 : undefined,
    })
      .then((res) => {
        setHangoutList(res.Result.HangoutList);
        setTotalPage(res.Result.TotalPages);

        if (res.Result.CurrentPage !== currPage) {
          setCurrPage(res.Result.CurrentPage);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // 預設 undefined , 當呼叫 doRender 時才塞進 true false 用來防止初始就 render
    if (typeof renderStatus !== 'undefined') {
      updateHangoutList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderStatus]);

  useEffect(() => {
    updateHangoutList();
  }, [currPage, countOfPage, isTrash]);

  const doRender = () => {
    const newStatus =
      typeof renderStatus !== 'undefined' ? !renderStatus : false;
    setRenderStatus(newStatus);
  };

  const handleChangePage = (page) => {
    setCurrPage(page);
  };

  const handleCheckboxOnChange = (id, status) => {
    //更改後端資料
    switchHangoutDisplay({
      HangoutId: id,
      Status: status === 2 ? 1 : 2,
    }).then(() => {
      //畫面更新, 好處是可以確認在上面的 API 執行完成後再更新畫面
      doRender();
    });
  };

  const handleOpenDeleteBox = (e, id) => {
    e.preventDefault();
    setDeleteBox({
      deletingID: id,
      isDeleteVisible: true,
    });
  };

  const handleRecover = (id) => {
    handleCheckboxOnChange(id, 1);
  };

  const handleDeleteBoxConfirm = (id) => {
    let newArray = [...hangoutList].filter((exam) => exam.HangoutId !== id);
    setHangoutList(newArray);
    switchHangoutDisplay({ HangoutId: id, Status: 3 });
  };

  const handleCloseDeleteBox = () => {
    setDeleteBox({ ...deleteBox, isDeleteVisible: false });
  };

  const handleOpenCheckHistoryBox = (id, name) => {
    setHistoryBox({
      checkId: id,
      checkName: name,
      isCheckHistoryVisible: true,
    });
  };

  const handleCloseCheckHistoryBox = () => {
    setHistoryBox({ ...historyBox, isCheckHistoryVisible: false });
  };

  const handleToggleHowUseOpen = () => {
    window.open(
      'https://docs.google.com/presentation/d/1Se8z0ONJ7CB3KyIq9rxEZKR_yaKFl7GlaRb2omSoSVo/edit?usp=sharing',
    );
  };

  const search = () => {
    if (currPage === 1) {
      updateHangoutList();
    } else {
      setCurrPage(1);
    }
  };

  //Table.js 已有sort 功能
  // const handleSortItems = (type) => {
  //   if (hangoutList.length === 0) return;
  //   let newArray = [...hangoutList];

  //   if (newArray[0][type] < newArray[newArray.length - 1][type]) {
  //     newArray.sort((a, b) => {
  //       return a[type] < b[type] ? 1 : -1;
  //     });
  //   } else {
  //     newArray.sort((a, b) => {
  //       return a[type] > b[type] ? 1 : -1;
  //     });
  //   }

  //   setHangoutList(newArray);
  // };

  // 計算 '每頁顯示' 改變時 currPage 的頁數
  const handleCountOfpageShow = (e) => {
    const changeCountOfPageValue = parseInt(e.target.value, 10);

    if (countOfPage < changeCountOfPageValue) {
      setCurrPage(Math.ceil((countOfPage * currPage) / changeCountOfPageValue));
    }

    setCountOfPage(changeCountOfPageValue);
  };

  //table data setting
  const columns = [
    {
      title: 'ID',
      filed: 'HangoutId',
      property: false,
      render: (props) => {
        return <>{props.HangoutId}</>;
      },
    },
    {
      title: '建立日期',
      filed: 'CreateAt',
      property: true,
      render: (props) => {
        return <>{props.CreateAt.slice(0, 10)}</>;
      },
    },
    { title: '課程名稱', field: 'Name', property: true },
    {
      title: '授課老師',
      field: 'Teacher',
      property: true,
      render: (props) => {
        return <>{props.Teacher ? props.Teacher.Name : ''}</>;
      },
    },
    { title: '定價', field: 'Price', property: true },
    { title: '特價', field: 'DiscountPrice', property: true },
    { title: '單元數', field: 'TotalChapterCount', property: false },
    {
      title: '上架',
      field: 'Status',
      property: false,
      render: (props) => {
        return (
          <Checkbox
            color="primary"
            checked={props.Status === 1 ? true : false}
            disabled={isTrash}
            onChange={() => {
              handleCheckboxOnChange(props.HangoutId, props.Status);
            }}
          />
        );
      },
    },
    {
      title: '操作',
      field: 'operation',
      property: false,
      render: (props) => {
        return (
          <>
            {!isTrash && (
              <>
                <NavLink to={`/Secret/EditSecret/${props.HangoutId}`}>
                  編輯
                </NavLink>
                <a
                  className="primary"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    handleOpenCheckHistoryBox(props.HangoutId, props.Name);
                  }}>
                  查看
                </a>
                <a
                  style={{ cursor: 'pointer' }}
                  className="danger"
                  onClick={(e) => {
                    handleOpenDeleteBox(e, props.HangoutId);
                  }}>
                  刪除
                </a>
              </>
            )}

            {isTrash && (
              <a
                style={{ cursor: 'pointer' }}
                className="danger"
                onClick={(e) => {
                  handleRecover(props.HangoutId);
                }}>
                恢復
              </a>
            )}
          </>
        );
      },
    },
  ];
  // render jsx
  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={match.url} />

      <div className="block">
        <div className="top-block">
          <div className="text" style={{ fontSize: '24px' }}>
            管理課程列表
          </div>
          <NavLink to={`/Secret/AddSecret`}>+ 新增課程</NavLink>

          {isTrash && <NavLink to={`/Secret/SecretList`}>離開垃圾桶</NavLink>}

          {!isTrash && (
            <NavLink to={`/Secret/SecretList/Trash`}>垃圾桶</NavLink>
          )}

          <Search
            prompt="搜尋課程名稱"
            value={keyword}
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            searching={search}
          />

          <HowUse
            isShow={false}
            ImageSrc={''}
            toggleOpen={handleToggleHowUseOpen}
          />
        </div>

        <Table
          columns={columns}
          // 取出每頁要顯示的資料
          datas={hangoutList}
        />

        <div className="pagination-box">
          <Pagination
            currPage={currPage}
            totalPage={totalPage}
            onChangPage={handleChangePage}
          />
          <div className="view-single-page">
            每頁顯示
            <select value={countOfPage} onChange={handleCountOfpageShow}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
          </div>
        </div>
      </div>
      <ConfirmBox
        isVisible={deleteBox.isDeleteVisible}
        title={'刪除'}
        content={`刪除後無法復原<br>請確認是否繼續`}
        confirmMethod={() => {
          handleDeleteBoxConfirm(deleteBox.deletingID);
        }}
        closebox={handleCloseDeleteBox}
      />
      <CheckSecretHistory
        isVisible={historyBox.isCheckHistoryVisible}
        Name={historyBox.checkName}
        Id={historyBox.checkId}
        closeFunc={handleCloseCheckHistoryBox}
      />
    </div>
  );
};

export default SecretList;
