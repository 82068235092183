import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SaleExamList from './SaleExamList';
import EaxmType from './EaxmType';
import PeiyouSetting from './PeiyouSetting';
import SuitExamList from './SuitExamList';
import SuitExam from './SuitExam';
import ProductList from '../ProductList/SubjectType';
import Page404 from '../../components/Page404';

const SaleExam = (props) => {
  return (
    <Switch>
      <Route exact path={`${props.match.url}`} component={SaleExamList} />
      <Route
        exact
        path={`${props.match.url}/ExamList`}
        component={SaleExamList}
      />
      <Route
        exact
        path={`${props.match.url}/SuitExamList`}
        component={SuitExamList}
      />
      <Route path={`${props.match.url}/SuitExam/:id`} component={SuitExam} />
      <Route path={`${props.match.url}/SuitExam`} component={SuitExam} />
      <Route exact path={`${props.match.url}/ExamType`} component={EaxmType} />
      <Route
        exact
        path={`${props.match.url}/ProductList`}
        component={ProductList}
      />
      <Route
        exact
        path={`${props.match.url}/PeiyouSetting/:QuestionBankID`}
        component={PeiyouSetting}
      />
      <Route component={Page404} />
    </Switch>
  );
};

export default SaleExam;
