import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { parseExamQuestionsToPieData } from '../../Util/Util';
import { makeStyles } from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange';

const useStyles = makeStyles((theme) => ({
  ul: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    '& li': {
      float: 'left',
      padding: theme.spacing(0, 1, 0, 0),
      fontSize: '0.9rem',
      color: orange[700],
      fontWeight: '600',
      '& .dot': {
        display: 'inline-block',
        marginRight: '4px',
        width: '8px',
        height: '8px',
        backgroundColor: orange[300],
      },
    },
  },
  clearfix: {
    '& ::after': {
      display: 'block',
      content: '',
      clear: 'both',
    },
  },
}));

const Knowledges = (props) => {
  const [knowledges, setKnowledges] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    let knowledgesData = [];

    if (!_.isArray(props.questions)) {
      knowledgesData = parseExamQuestionsToPieData([props.questions]);
    } else {
      knowledgesData = parseExamQuestionsToPieData(props.questions);
    }

    setKnowledges(knowledgesData);
  }, [props.questions]);

  return (
    <>
      <ul className={classes.ul}>
        {_.map(knowledges, (knowledge, index) => {
          return (
            <li key={knowledge.id}>
              <div className="dot"></div>
              {knowledge.label}
            </li>
          );
        })}
      </ul>
      <div className={classes.clearfix}></div>
    </>
  );
};

export default Knowledges;
