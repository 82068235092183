import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { logoutSuccess } from '../store/action';

const LogoutPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    Swal.fire({
      icon: 'error',
      title: '帳號已登出，請重新登入',
      showCancelButton: false,
      timer: 2000,
    }).then(() => {
      window.location.href = '/';
    });

    dispatch(logoutSuccess());
  }, []);

  return <div></div>;
};

export default LogoutPage;
