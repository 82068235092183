import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import bugFixingSvg from '../images/bug_fixing.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    backgroundColor: theme.palette.grey[50],
    color: 'red',

    '& img': {
      margin: theme.spacing(5, 'auto'),
      width: '60vh',
      maxWidth: '98%',
    },
  },
  message: {
    fontSize: '1.5rem',
    fontWeight: '700',
    padding: theme.spacing(0, 0, 3),
  },
}));

const TableError = (props) => {
  const classes = useStyles();
  const { message } = props;

  return (
    <Box textAlign="center" className={classes.root}>
      <img src={bugFixingSvg} />
      <Box className={classes.message}>
        {message || '頁面發生錯誤，請重新操作'}
      </Box>
    </Box>
  );
};

export default TableError;
