import React from 'react';
import { Editor as TinymceEditor } from '@tinymce/tinymce-react';
import { uploadS3File } from '../Util/awsS3';
import { v4 as uuidv4 } from 'uuid';

const Editor = (props) => {
  const { content, setContent, imageS3Position, editorProps, disabled } = props;

  const handleEditChange = (newContent) => {
    setContent(newContent);
  };

  const uploadImageToS3 = (blobInfo, success, failure) => {
    uploadS3File(blobInfo.blob(), {
      ...imageS3Position,
      fileName: uuidv4(),
    }).then(
      (response) => {
        success(response);
      },
      (error) => {
        failure(error);
      },
    );
  };

  return (
    <>
      <TinymceEditor
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        value={content}
        init={{
          ...editorProps,
          language: 'zh_TW',
          menubar: false,
          width: '100%',
          link_default_protocol: 'https',
          relative_urls: true,
          document_base_url: '//',
          plugins: [
            'advlist autolink lists link image charmap',
            'paste wordcount emoticons',
          ],
          toolbar:
            'formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | link image charmap emoticons | bullist numlist outdent indent table | removeformat undo redo ',
          images_upload_handler: imageS3Position ? uploadImageToS3 : undefined,
        }}
        onEditorChange={handleEditChange}
        disabled={disabled || false}
      />

      <style
        dangerouslySetInnerHTML={{
          __html: '.tox .tox-editor-header { z-index: 0; }',
        }}
      />
    </>
  );
};

export default Editor;
