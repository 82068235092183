import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Collapse } from '@material-ui/core';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import { DatePicker } from '@material-ui/pickers';
import { trim } from 'lodash';
import moment from 'moment';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #eee',
    display: 'flex',
    lexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    padding: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  codeInput: {
    flexBasis: '100%',
  },
  textIcon: {
    marginRight: theme.spacing(1),
    color: '#ff8181',
  },
  dateInput: {
    flexBasis: '47%',
    flex: 'auto',
  },
  messageAlert: {
    flexBasis: '100%',
  },
}));

const initReferralCode = {
  id: undefined,
  code: '',
  startDate: new Date(),
  endDate: new Date(),
};

const EditReferralCode = (props) => {
  const { referralCode, editAction, isLoading } = props;
  const classes = useStyles();
  const [editReferralCodeData, setEditReferralCodeData] = useState(() => {
    return referralCode ? referralCode : initReferralCode;
  });
  const [canSubmit, setCanSubmit] = useState(false);
  const [message, setMessage] = useState('');

  const handleChangeCode = (e) => {
    setEditReferralCodeData({
      ...editReferralCodeData,
      code: trim(e.target.value),
    });
  };

  const handleChangeStartDate = (e) => {
    setEditReferralCodeData({
      ...editReferralCodeData,
      startDate: e,
    });
  };

  const handleChangeEndDate = (e) => {
    setEditReferralCodeData({
      ...editReferralCodeData,
      endDate: e,
    });
  };

  const handleEdit = () => {
    editAction({
      ...editReferralCodeData,
      startDate: moment(editReferralCodeData.startDate).format('YYYY-MM-DD'),
      endDate: moment(editReferralCodeData.endDate).format('YYYY-MM-DD'),
    });
  };

  useEffect(() => {
    const { code, startDate, endDate } = editReferralCodeData;
    const checkCodePattern = /^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/;

    if (trim(code) === '') {
      setMessage('');
      setCanSubmit(false);
    } else if (!checkCodePattern.test(code)) {
      setMessage('請檢查 推薦碼 是否符合英文數字並包含一個英文字母');
      setCanSubmit(false);
    } else if (trim(startDate) === '') {
      setMessage('請設定開始日期');
      setCanSubmit(false);
    } else if (trim(endDate) === '') {
      setMessage('請設定結束日期');
      setCanSubmit(false);
    } else {
      const momentStartDate = moment(startDate);
      const momentEndDate = moment(endDate);

      if (momentStartDate.isBefore(momentEndDate) === false) {
        setMessage('結束日期不能比開始日期早');
        setCanSubmit(false);
      } else {
        setMessage('');
        setCanSubmit(true);
      }
    }
  }, [editReferralCodeData]);

  return (
    <div className={classes.root}>
      <TextField
        className={classes.codeInput}
        label="推薦碼"
        helperText="可使用英文和數字組成，但至少要有一個英文字母，並注意大小寫"
        InputProps={{
          startAdornment: (
            <ConfirmationNumberOutlinedIcon className={classes.textIcon} />
          ),
        }}
        value={editReferralCodeData.code}
        onChange={handleChangeCode}
      />

      <DatePicker
        className={classes.dateInput}
        label="開始日期"
        animateYearScrolling={true}
        autoOk
        disabled={isLoading}
        onChange={handleChangeStartDate}
        value={editReferralCodeData.startDate}
      />

      <DatePicker
        className={classes.dateInput}
        label="結束日期"
        animateYearScrolling={true}
        autoOk
        disabled={isLoading}
        onChange={handleChangeEndDate}
        value={editReferralCodeData.endDate}
      />

      <Collapse in={!!message} className={classes.messageAlert}>
        <Alert severity="warning" variant="filled">
          {message}
        </Alert>
      </Collapse>

      <Button
        variant="contained"
        color="primary"
        startIcon={<SaveRoundedIcon />}
        disabled={!canSubmit || isLoading}
        fullWidth
        onClick={handleEdit}>
        {referralCode ? '修改' : '新增'}
      </Button>
    </div>
  );
};

export default EditReferralCode;
