import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import _ from 'lodash';
import SettingsBackupRestoreOutlinedIcon from '@material-ui/icons/SettingsBackupRestoreOutlined';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import PropTypes from 'prop-types';

/**
 * 刪除使用的 dialog
 *
 * @param {*} props
 * {
 *   open [bool] 控制顯示
 *   setOpen [func] 設定 open 的函式
 *   action [func] 執行刪除的函式
 *   title [string] dialog 的標題
 *   children [jsx|string] dialog 的內容
 * }
 */
const ConfirmDialog = (props) => {
  const handleCloseDeleteDialog = () => {
    props.setOpen(false);
  };

  const handleAction = () => {
    props.action();
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleCloseDeleteDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      {_.trim(props.title) != '' ? (
        <DialogTitle>{props.title}</DialogTitle>
      ) : null}
      <DialogContent>
        <DialogContentText>{props.children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          startIcon={<ThumbUpOutlinedIcon />}
          onClick={handleAction}
          autoFocus>
          {_.isString(props.confirmBtnLable) ? props.confirmBtnLable : '確認'}
        </Button>
        <Button
          onClick={handleCloseDeleteDialog}
          variant="contained"
          startIcon={<SettingsBackupRestoreOutlinedIcon />}>
          取消
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  action: PropTypes.func,
  title: PropTypes.string,
  confirmBtnLable: PropTypes.string,
};

export default ConfirmDialog;
