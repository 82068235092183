import { combineReducers } from 'redux';
import SendAPIStatusState from './sendAPIStatus';
import loginState from './loginState';
import HomePageStatisticState from './homePageStatistic';
import QuestionSourceState from './questionSource';
import QuestionSorceResultState from './questionSorceResult';
import SelectedExamQuestionState from './selectedExamQuestion';
import BannerCarouselState from './bannerCarousel';
import QuestionStoreState from './questionStore';
import MemberOrderListState from './memberOrderList';
import SaleExamListState from './saleExamList';
import ExamTypeState from './examType';
import SubjectTypeState from './subjectType';
import ReEditExamState from './ReEditExam';
import PopularFeatureState from './popularFeature';
import SuitExamState from './suitExam';
import HangoutListState from './hangoutList';
import HangoutTypeState from './hangoutType';
import PromoListState from './promoList';
import loading from './loading';
import pageLoadingState from './pageLoadingState';
import flashMessageState from './flashMessageState';
import videoQueueState from './videoQueueState';
import KnowledgeState from './KnowledgeState';

const itemApp = combineReducers({
  loginState,
  SendAPIStatusState,
  HomePageStatisticState,
  BannerCarouselState,
  QuestionSourceState,
  QuestionSorceResultState,
  SelectedExamQuestionState,
  QuestionStoreState,
  MemberOrderListState,
  SaleExamListState,
  ExamTypeState,
  SubjectTypeState,
  ReEditExamState,
  PopularFeatureState,
  SuitExamState,
  HangoutListState,
  HangoutTypeState,
  PromoListState,
  loading,
  pageLoadingState,
  flashMessageState,
  videoQueueState,
  KnowledgeState,
});

export default itemApp;
