import * as types from '../actionType';
import _ from 'lodash';

let initState = {
  messages: [],
  deleteIds: [],
};

function flashMessageState(state = initState, action) {
  switch (action.type) {
    case types.ADD_FLASH_MESSAGE: {
      let newState = Object.assign({}, state);
      const messageId = _.uniqueId('flash-message-');

      newState.messages.push({
        message: action.message,
        id: messageId,
      });

      newState.deleteIds.push(messageId);

      return newState;
    }

    case types.DELETE_FLASH_MESSAGE: {
      let newState = Object.assign({}, state);

      newState.messages = _.filter(newState.messages, (message) => {
        return _.findIndex(newState.deleteIds, message.id) !== -1;
      });

      newState.deleteIds = [];

      return newState;
    }

    default:
      return state;
  }
}

export default flashMessageState;
