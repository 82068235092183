import React from 'react';
import { Route, Switch } from 'react-router-dom';
import TeacherList from './TeacherList';
import Teacher from './Teacher';
import Page404 from '../../components/Page404';

const TeacherRoute = (props) => {
  return (
    <Switch>
      <Route exact path={`${props.match.url}`} component={TeacherList} />
      <Route exact path={`${props.match.url}/Create`} component={Teacher} />
      <Route
        exact
        path={`${props.match.url}/Edit/:teacherId`}
        component={Teacher}
      />
      <Route component={Page404} />
    </Switch>
  );
};

export default TeacherRoute;
