import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router';

const BackBtn = (props) => {
  const { backAction } = props;
  const history = useHistory();

  const handleBack = () => {
    history.push('/Bonus');

    if (backAction) {
      backAction();
    }
  };

  return (
    <Button
      variant="contained"
      size="large"
      startIcon={<ArrowBackIcon />}
      onClick={handleBack}>
      回首頁
    </Button>
  );
};

export default BackBtn;
