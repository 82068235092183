import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Grid,
  CardHeader,
  Avatar,
  Divider,
  IconButton,
} from '@material-ui/core';
import {
  BonusAuditStatus,
  BonusAuditStatusText,
  BonusSaleTypes,
  BonusStatus,
  BonusStatusText,
} from '../../data/common';
import { formatNumberComma } from '../../Util/Util';
import moment from 'moment';
import CancelIcon from '@material-ui/icons/Cancel';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import AssignmentTurnedInRoundedIcon from '@material-ui/icons/AssignmentTurnedInRounded';
import AssignmentLateRoundedIcon from '@material-ui/icons/AssignmentLateRounded';
import BeenhereRoundedIcon from '@material-ui/icons/BeenhereRounded';

const BonusView = (props) => {
  const { bonus, closeDrawer } = props;

  const isUseBonus = bonus.bonus < 0;

  const isAudit = bonus.audit > BonusAuditStatus.PENDING;

  const bonusDate = moment(bonus.bonusDate);

  const handleClose = () => {
    closeDrawer();
  };

  return (
    <div className="bonus__view">
      <div className="bonus__close-view">
        關閉
        <IconButton onClick={handleClose}>
          <CancelIcon />
        </IconButton>
      </div>

      {bonus && (
        <Grid
          container
          direction="column"
          justifycontent="flex-start"
          alignItems="stretch"
          spacing={3}>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardHeader
                avatar={
                  <Avatar
                    className={
                      isUseBonus
                        ? 'bonus__avatar--use'
                        : 'bonus__avatar--reward'
                    }>
                    P
                  </Avatar>
                }
                title={<Typography variant="h3">{bonus.bonus}</Typography>}
                subheader="紅利點數"
              />

              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifycontent="space-around"
                  alignItems="stretch"
                  spacing={2}>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">類型</Typography>
                    <Typography variant="h6">
                      {BonusSaleTypes[bonus.saleType]}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography color="textSecondary">發票金額</Typography>
                    <Typography variant="h6">
                      $ {formatNumberComma(bonus.invoiceAmount)}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography color="textSecondary">狀態</Typography>
                    <Typography variant="h6">
                      {BonusStatusText[bonus.status]}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography color="textSecondary">建立者</Typography>
                    <Typography variant="h6">{bonus.createUser}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography color="textSecondary">紅利日期</Typography>
                    <Typography variant="h6">
                      {bonusDate.format('YYYY-MM-DD HH:mm:ss')}
                    </Typography>
                  </Grid>
                </Grid>

                <Typography
                  variant="body2"
                  component="p"
                  className="bonus__comment">
                  {bonus.comment}
                </Typography>

                {bonus.status === BonusStatus.DELETE && (
                  <>
                    <Typography color="error">刪除原因</Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      className="bonus__comment bonus__comment--error">
                      {bonus.auditComment}
                    </Typography>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>

          {isAudit && (
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardHeader
                  avatar={
                    <Avatar
                      className={
                        bonus.audit === BonusAuditStatus.ERROR
                          ? 'bonus__avatar--error'
                          : bonus.audit === BonusAuditStatus.NORMAL
                          ? 'bonus__avatar--normal'
                          : 'bonus__avatar--solved'
                      }>
                      {bonus.audit === BonusAuditStatus.ERROR && (
                        <AssignmentLateRoundedIcon />
                      )}

                      {bonus.audit === BonusAuditStatus.NORMAL && (
                        <AssignmentTurnedInRoundedIcon />
                      )}

                      {bonus.audit === BonusAuditStatus.SOLVED && (
                        <BeenhereRoundedIcon />
                      )}
                    </Avatar>
                  }
                  title={
                    <Typography variant="h3">
                      {BonusAuditStatusText[bonus.audit]}
                    </Typography>
                  }
                  subheader={`稽核狀態`}
                />

                {bonus.auditComment && bonus.auditComment !== '' && (
                  <CardContent>
                    <Typography
                      variant="body2"
                      component="p"
                      className="bonus__comment bonus__comment--audit">
                      {bonus.auditComment}
                    </Typography>
                  </CardContent>
                )}
              </Card>
            </Grid>
          )}

          <Grid item xs={12}>
            <Card variant="outlined">
              <CardHeader
                avatar={
                  <Avatar>
                    <AccountCircleOutlinedIcon />
                  </Avatar>
                }
                title={
                  <Typography variant="h3">
                    {bonus.customer.realName}
                  </Typography>
                }
                subheader={`ID ${bonus.customer.id}`}
              />

              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifycontent="space-around"
                  alignItems="stretch"
                  spacing={2}>
                  <Grid item xs={12}>
                    <Typography color="textSecondary">手機</Typography>
                    <Typography variant="h6">
                      {bonus.customer.cellPhone}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography color="textSecondary">email</Typography>
                    <Typography variant="h6">{bonus.customer.email}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default BonusView;
