import React, { useEffect, useState, useMemo } from 'react';

const BannerTags = (props) => {
  const { banners, selectIndex, setSelectIndex } = props;
  const [isShowCreateTag, setIsShowCreateTag] = useState(false);
  const isSelectCreateTag = useMemo(() => {
    return (
      (banners && banners.length === selectIndex) ||
      (banners && banners.length === 0)
    );
  }, [banners, selectIndex]);

  const createTagIndex = useMemo(() => {
    return banners && banners.length === 0 ? 0 : !banners ? 0 : banners.length;
  }, [banners]);

  const handleClickIndex = (index) => {
    setSelectIndex(index);
  };

  const handleClickAddBanner = () => {
    setSelectIndex(banners.length);
    setIsShowCreateTag(true);
  };

  useEffect(() => {
    if (!isSelectCreateTag && banners && banners.length > 0) {
      setIsShowCreateTag(false);
      if (selectIndex) {
        setSelectIndex(selectIndex);
      }
    }

    if (!banners || banners.length <= 0) {
      setIsShowCreateTag(true);
    }
  }, [banners]);

  return (
    <div className="tag-group">
      {banners &&
        banners.map((banner, bannerIndex) => (
          <div
            key={banner.Id}
            className={selectIndex === bannerIndex ? 'tag active' : 'tag'}
            onClick={() => {
              handleClickIndex(bannerIndex);
            }}>
            {`廣告 ${bannerIndex + 1}`}
          </div>
        ))}

      {isShowCreateTag && (
        <div
          className={createTagIndex === selectIndex ? 'tag active' : 'tag'}
          onClick={() => {
            handleClickIndex(createTagIndex);
          }}>
          {`建立新廣告`}
        </div>
      )}

      {!isShowCreateTag && (
        <div className="tag add-ad-btn" onClick={handleClickAddBanner}>
          <div className="add-circle">+</div>
        </div>
      )}
    </div>
  );
};

export default BannerTags;
