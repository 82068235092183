import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { clearShownMessages } from '../store/action/index';

let showMessagesIds = [];

const FlashMessage = (props) => {
  const flashMessageState = useSelector((state) => state.flashMessageState);
  const location = useLocation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const shownMessage = (shownMessageId) => {
    showMessagesIds = [
      ...showMessagesIds.filter((messageId) => messageId !== shownMessageId),
    ];
  };

  useEffect(() => {
    if (flashMessageState.messages.length > 0) {
      flashMessageState.messages.forEach((message) => {
        if (showMessagesIds.includes(message.id)) {
          return;
        }

        enqueueSnackbar(message.message, {
          key: message.id,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          variant: 'success',
          onExited: (event, key) => {
            shownMessage(key);
          },
        });
      });

      dispatch(clearShownMessages());
    }
  }, [location]);

  return null;
};

export default FlashMessage;
