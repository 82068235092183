import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HandoutList from './HandoutList';
import Handout from './Handout';
import Page404 from '../../components/Page404';

const HandoutRoute = (props) => {
  return (
    <Switch>
      <Route
        exact
        path={`${props.match.url}/HandoutList`}
        component={HandoutList}
      />
      <Route exact path={`${props.match.url}/Create`} component={Handout} />
      <Route
        exact
        path={`${props.match.url}/Edit/:handoutId`}
        component={Handout}
      />
      <Route
        path={props.match.url}
        render={() => {
          // 不使用 react-route 轉換，是為了不讓該網址進 history ，才不會上一頁時一直無限導回
          window.location.replace(props.match.url + '/HandoutList');
          return false;
        }}
      />
      <Route component={Page404} />
    </Switch>
  );
};

export default HandoutRoute;
