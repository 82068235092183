import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Tabs,
  Tab,
  Box,
  AppBar,
  TextField,
  LinearProgress,
  Paper,
  Grid,
  Typography,
} from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import MiniProductCard from '../../components/SaleExam/MiniProductCard';

const useStyles = makeStyles((theme) => ({
  selectedProducts: {
    backgroundColor: green[500],
    borderRadius: theme.spacing(1),
  },
}));

const SelectedProducts = (props) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h6" component="div">
        預計加入的商品
      </Typography>
      <Box className={classes.selectedProducts} mt={2} p={1}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          spacing={3}>
          {props.selectedProducts.map((product) => {
            return (
              <Grid item xs={12} sm={3} key={product.id}>
                <MiniProductCard
                  product={product}
                  onClick={() => {
                    props.onClick && props.onClick(product);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
};

export default SelectedProducts;
