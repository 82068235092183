import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import _ from 'lodash';
import TablePaginationActions from '../TablePaginationActions';
import { LIST_PAGE_COUNT } from '../../data/common';
import BonusesTableRow from './BonusesTableRow';

const BonusesTable = (props) => {
  const {
    bonuses,
    bonusTotal,
    viewBonus,
    deleteBonus,
    isLoading,
    pageNum,
    setPageNum,
    auditBonus,
  } = props;

  return (
    <TableContainer component={Box}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>顧客</TableCell>
            <TableCell>類型</TableCell>
            <TableCell>紅利點數</TableCell>
            <TableCell>發票金額</TableCell>
            <TableCell>狀態</TableCell>
            <TableCell>稽核</TableCell>
            <TableCell>日期</TableCell>
            <TableCell>建立者</TableCell>
            <TableCell align="right">功能</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(bonuses, (bonus) => (
            <BonusesTableRow
              key={bonus.id}
              bonus={bonus}
              viewBonus={viewBonus}
              deleteBonus={deleteBonus}
              isLoading={isLoading}
              auditBonus={auditBonus}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[LIST_PAGE_COUNT]}
              colSpan={10}
              count={bonusTotal}
              rowsPerPage={LIST_PAGE_COUNT}
              page={pageNum - 1}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={(event, currentPage) => {
                setPageNum(currentPage + 1);
              }}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default BonusesTable;
