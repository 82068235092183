import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Add } from '@material-ui/icons';
import httpErrorHandle from '../../Util/httpErrorHandle';
import Table from '../../components/Table';
import BreakCrumbs from '../../components/BreakCrumbs';
import CreateMODMember from '../../components/MemberManagement/CreateMODMember';
import Pagination from '../../components/Pagination';
import Search from '../../components/Search';
import '../../style/components/MemberManagememt/member-management.scss';
import Button from '../../components/Button';
import { RadioSelector as MemberFilter } from '../../components/RadioSelector';
import { fetchMembers, createMember } from '../../Util/fetchMethods';
import { logoutSuccess, memberStatusSwitch } from '../../store/action';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import Checkbox from '@material-ui/core/Checkbox';
import { MemberLevelText, SignUpFromText } from '../../data/common';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';

function MemberList(props) {
  const [memberTypeList] = useState({
    all: '1',
    carpDragon: '2',
    mod: '3',
  });
  const [members, setMembers] = useState([]);
  const [memberTotalCount, setMemberTotalCount] = useState(0);
  const [page, setPage] = useState({
    rowsPrePage: 10,
    currPage: 1,
    totalPages: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateMember, setIsCreateMember] = useState(false);
  const [memberType, setMemberType] = useState(memberTypeList.all);
  const [searchText, setSearchText] = useState('');
  const [searchSignAtDate, setSearchSignAtDate] = useState(null);
  const dispatch = useDispatch();

  const dispatchLogoutSuccess = useMemo(() => {
    return bindActionCreators(logoutSuccess, dispatch);
  }, [dispatch, logoutSuccess]);

  const updateMembers = useCallback(
    (p, rowsPrePage, type, searchText, signUpAtDate) => {
      setIsLoading(true);

      fetchMembers(p, rowsPrePage, type, searchText, signUpAtDate)
        .then((json) => {
          const {
            MemberList,
            CurrentPage,
            TotalPages,
            TotalCount,
          } = json.Result;
          setMembers(MemberList);
          setPage({
            currPage: CurrentPage,
            totalPages: TotalPages,
            rowsPrePage: rowsPrePage,
          });
          setMemberTotalCount(TotalCount);
        })
        .catch((error) => {
          httpErrorHandle(
            error.httpCode,
            `fetchMembers Error: ${error.msg} httpErrorCode: ${error.httpCode}`,
            dispatchLogoutSuccess,
          );
        })
        .then(() => {
          setIsLoading(false);
        });
    },
    [dispatchLogoutSuccess],
  );

  const inputText = (e) => {
    setSearchText(e.target.value);
  };
  const searchingText = () => {
    updateMembers(
      1,
      page.rowsPrePage,
      memberType,
      searchText,
      searchSignAtDate,
    );
  };

  /* create MOD member */
  const createModMember = () => {
    setIsCreateMember(true);
  };
  const cancelCreateMember = () => {
    setIsCreateMember(false);
  };
  const createMemberAction = (newMember) => {
    createMember(newMember).then((data) => {
      if (data.Result) {
        Swal.fire({
          icon: 'success',
          title: '新增成功',
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonText: '確定',
          heightAuto: false,
        }).then((result) => {
          if (result.value) {
            // 取得所有會員的第一頁
            setMemberType(memberTypeList.all);
            setSearchSignAtDate(null);
            setSearchText('');
            updateMembers(1, 10, memberTypeList.all);
          }
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: '輸入的帳號已存在，請重新輸入。',
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonText: '確定',
          heightAuto: false,
        });
      }
    });
  };

  const switchMemberState = (id, status) => {
    const newArray = members.map((member) => {
      if (member.Id === id) {
        member.Status = member.Status === 1 ? 2 : 1;
      }
      return member;
    });
    setMembers([...newArray]);
    dispatch(memberStatusSwitch({ UserId: id, Status: status === 1 ? 2 : 1 }));
  };

  /* filter Member*/
  const filterMemberType = (e) => {
    setMemberType(e.target.value);
    updateMembers(
      1,
      page.rowsPrePage,
      e.target.value,
      searchText,
      searchSignAtDate,
    );
  };
  const filterList = [
    {
      value: memberTypeList.all,
      type: memberType,
      text: '所有會員',
    },
    {
      value: memberTypeList.carpDragon,
      type: memberType,
      text: '鯉躍龍門會員',
    },
    {
      value: memberTypeList.mod,
      type: memberType,
      text: 'MOD 會員',
    },
  ];

  const getLevelText = (level) => {
    if (MemberLevelText[level]) {
      return MemberLevelText[level];
    } else {
      return level;
    }
  };

  const getSignUpFromText = (signUpFrom) => {
    return SignUpFromText[signUpFrom]
      ? SignUpFromText[signUpFrom]
      : signUpFrom === null
      ? '一般註冊'
      : signUpFrom;
  };

  useEffect(() => {
    // 載入所有成員，第一頁，每頁十列
    updateMembers(1, 10, memberTypeList.all);
  }, [updateMembers, memberTypeList.all]);

  const columns = [
    { title: 'No.', field: 'Id', property: true },
    { title: '姓名', field: 'RealName', property: true },
    {
      title: '性別',
      field: 'Gender',
      property: true,
      render: (props) => {
        if (props.Gender === 1) {
          return '男';
        }
        return '女';
      },
    },
    { title: '生日', field: 'BirthDate', property: true },
    { title: '手機/MOD帳號', field: 'CellPhone', property: true },
    { title: 'E-mail', field: 'Email', property: false },
    {
      title: '紅利等級',
      field: 'Level',
      property: false,
      render: (props) => <div>{getLevelText(props.Level)}</div>,
    },
    {
      title: '特約公司',
      field: 'CompanyList',
      property: false,
      render: (props) => {
        if (Array.isArray(props.CompanyList)) {
          return props.CompanyList.map((company) => (
            <div>
              {company.Name} - {company.Dept}
            </div>
          ));
        } else {
          return null;
        }
      },
    },
    { title: '推薦人', field: 'RecommendUserId', property: false },
    {
      title: 'Line綁定',
      field: 'IsBindingLine',
      property: false,
      render: (props) => (
        <Checkbox
          checked={props.IsBindingLine === true}
          color="primary"
          disabled={true}
        />
      ),
    },
    {
      title: '註冊來源',
      field: 'SignUpFrom',
      property: false,
      render: (props) => <div>{getSignUpFromText(props.SignUpFrom)}</div>,
    },
    {
      title: '註冊日',
      field: 'SignUpAt',
      property: false,
      render: (props) => (
        <div>{moment(props.SignUpAt).format('YYYY-MM-DD')}</div>
      ),
    },
    {
      title: '購買紀錄',
      field: 'Authority',
      property: false,
      render: (props) => (
        <NavLink
          to={`/MemberManagement/BougthRecord/${props.Id}?name=${props.RealName}`}>
          查詢
        </NavLink>
      ),
    },
    {
      title: '狀態',
      field: 'Status',
      property: false,
      render: (props) => (
        <Checkbox
          checked={props.Status === 2}
          color="primary"
          onChange={() => {
            switchMemberState(props.Id, props.Status);
          }}
        />
      ),
    },
  ];

  const gotoPage = (p) => {
    updateMembers(
      p,
      page.rowsPrePage,
      memberType,
      searchText,
      searchSignAtDate,
    );
  };

  const pageSize = (e) => {
    updateMembers(
      page.currPage,
      e.target.value,
      memberType,
      searchText,
      searchSignAtDate,
    );
  };

  const handleChangeSignAtDate = (date) => {
    setSearchSignAtDate(
      date !== null ? moment(date).format('YYYY-MM-DD') : null,
    );
  };

  useEffect(() => {
    updateMembers(
      1,
      page.rowsPrePage,
      memberType,
      searchText,
      searchSignAtDate,
    );
  }, [searchSignAtDate]);

  useEffect(() => {
    if (isLoading) {
      Swal.showLoading();
    } else {
      Swal.close();
      Swal.hideLoading();
    }
  }, [isLoading]);

  return (
    <div className="member-managemenet member-list pagefadeIn">
      <BreakCrumbs path={props.match.url} />
      <div className="block">
        <div
          className="form-group"
          style={{ display: 'inline-block', width: '100%' }}>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={createModMember}>
            新增 MOD 會員
          </Button>
          {filterList.map((item) => {
            return (
              <MemberFilter
                key={item.value}
                textColor={
                  item.value === memberTypeList.mod ? 'secondary' : 'initial'
                }
                color={
                  item.value === memberTypeList.mod ? 'secondary' : 'primary'
                }
                value={Number(item.value)}
                type={Number(item.type)}
                onChange={filterMemberType}
                text={item.text}
              />
            );
          })}
          <Search
            prompt="搜尋 姓名或手機關鍵字..."
            value={searchText}
            onChange={inputText}
            searching={searchingText}
          />
          <DatePicker
            autoOk
            label="註冊日期"
            clearable
            disableFuture
            value={searchSignAtDate}
            onChange={handleChangeSignAtDate}
            format="yyyy/MM/dd"
            style={{ float: 'right', marginLeft: '12px' }}
          />
          <div
            style={{
              margin: '6px auto',
              fontSize: '1rem',
              color: '#4E546C',
              float: 'right',
              lineHeight: '42px',
            }}>
            總計 {memberTotalCount}
          </div>
          <Table columns={columns} datas={members} />
          <div className="pagination-box">
            <Pagination
              currPage={page.currPage}
              totalPage={page.totalPages}
              onChangPage={gotoPage}
            />
            <div className="view-single-page">
              每頁顯示
              <select value={page.rowsPrePage} onChange={pageSize}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
            </div>
          </div>
          <CreateMODMember
            isOpen={isCreateMember}
            close={cancelCreateMember}
            create={createMemberAction}
          />
        </div>
      </div>
    </div>
  );
}

export default MemberList;
