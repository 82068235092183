export const zhTW = {
  props: {
    MuiTablePagination: {
      backIconButtonText: '上一頁',
      labelRowsPerPage: '每頁筆數:',
      labelDisplayedRows: ({ from, to, count }) =>
        `目前筆數 ${from}-${to} 總筆數 ${count !== -1 ? count : `超過 ${to}`}`,
      nextIconButtonText: '下一頁',
    },
    MuiRating: {
      getLabelText: (value) => `${value} 星${value !== 1 ? '星' : ''}`,
    },
    MuiAutocomplete: {
      clearText: '清除',
      closeText: '關閉',
      loadingText: '讀取中...',
      noOptionsText: '沒有選擇',
      openText: '打開',
    },
    MuiAlert: {
      closeText: '關閉',
    },
    // MuiPagination: {
    //   'aria-label': 'Pagination navigation',
    //   getItemAriaLabel: (type, page, selected) => {
    //     if (type === 'page') {
    //       return `${selected ? '' : 'Go to '}page ${page}`;
    //     }
    //     if (type === 'first') {
    //       return 'Go to first page';
    //     }
    //     if (type === 'last') {
    //       return 'Go to last page';
    //     }
    //     if (type === 'next') {
    //       return 'Go to next page';
    //     }
    //     if (type === 'previous') {
    //       return 'Go to previous page';
    //     }
    //     return undefined;
    //   },
    // },
  },
};
