import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import Table from '../../components/Table';
import BreakCrumbs from '../../components/BreakCrumbs';
import Pagination from '../../components/Pagination';
import ConfirmBox from '../../components/ConfirmBox';
import { Checkbox } from '@material-ui/core';
import '../../style/components/Advertisting/popular.scss';
import {
  initPopularFeatureList,
  popularFeatureDelete,
  switchPopularFeatureDisplay,
} from '../../Util/fetchMethods';

const PopularList = ({ match }) => {
  const [propularProducts, setPropularProducts] = useState([]);
  const [renderStatus, setRenderStatus] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [countOfPage, setCountOfPage] = useState(10);
  const [startPage, setStartPage] = useState(1);
  const [confirmBox, setConfirmBox] = useState({
    isConfirmVisible: false,
    deletingID: '',
  });

  const updateInitPopularFeatureList = () => {
    initPopularFeatureList()
      .then((res) => {
        setPropularProducts(res.Result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const doRender = () => {
    setRenderStatus(!renderStatus);
  };

  useEffect(() => {
    updateInitPopularFeatureList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderStatus]);

  // 上一次每頁顯示
  const preCountOfPage = useRef(10);

  useEffect(() => {
    preCountOfPage.current = countOfPage;
    handlePageStart(currPage, countOfPage);
    handleTotalPage(propularProducts, countOfPage);
  }, [countOfPage, currPage, propularProducts]);

  const handleTotalPage = (propularProducts, countOfPage) => {
    setTotalPage(Math.ceil(propularProducts.length / countOfPage));
  };

  const handlePageStart = (currPage, countOfPage) => {
    setStartPage((currPage - 1) * countOfPage);
  };

  const handleChangePage = (page) => {
    setCurrPage(page);
  };

  // 計算 '每頁顯示' 改變時 currPage 的頁數
  const handleCountOfpageShow = (e) => {
    setCountOfPage(e.target.value);
    preCountOfPage.current < e.target.value
      ? setCurrPage(Math.ceil((countOfPage * currPage) / e.target.value))
      : setCurrPage(currPage);
  };

  const handleSelectChange = (e) => {
    setCountOfPage(e.target.value);
  };

  const handleCheckboxOnChange = (id, status) => {
    //更改本地端資料
    // let newArray = [...propularProducts].map((product) => {
    //   if (product.Feature.Id === id) {
    //     product.Feature.IsDisplay = !product.Feature.IsDisplay;
    //   }
    //   return product;
    // });
    // setPropularProducts(newArray);

    //更改後端資料
    switchPopularFeatureDisplay({
      FeatureId: id,
      IsDisplay: !status,
    }).then(() => {
      //畫面更新, 好處是可以確認在上面的 API 執行完成後再更新畫面
      doRender();
    });
  };

  const handleOpenConfrimBox = (id) => {
    setConfirmBox({
      deletingID: id,
      isConfirmVisible: true,
    });
  };

  const handleCloseConfrimBox = () => {
    setConfirmBox({ ...confirmBox, isConfirmVisible: false });
  };

  //table data setting
  const columns = [
    {
      title: 'No',
      filed: 'FeatureId',
      property: false,
      render: (props) => {
        return <>{props.Feature.Id}</>;
      },
    },
    {
      title: '精選名稱',
      filed: 'FeatureTitle',
      property: false,
      render: (props) => {
        return <>{props.Feature.Title}</>;
      },
    },
    {
      title: '精選類型',
      filed: 'FeatureType',
      property: false,
      render: (props) => {
        return <>{props.Feature.FeatureType === 1 ? '題庫' : '套卷'}</>;
      },
    },
    {
      title: '建立日期',
      filed: 'CreateAt',
      property: false,
      render: (props) => {
        return <>{props.Feature.CreateAt.slice(0, 10)}</>;
      },
    },
    {
      title: '題庫數量',
      filed: 'Count',
      property: false,
      render: (props) => {
        return <>{props.Feature.Count}</>;
      },
    },
    {
      title: '編輯',
      filed: 'Edit',
      property: false,
      render: (props) => {
        return (
          <>
            <NavLink
              to={
                props.Feature.FeatureType === 1
                  ? `/Advertising/EditPopularFeature/${props.Feature.Id}`
                  : `/Advertising/EditSuitFeature/${props.Feature.Id}`
              }>
              編輯
            </NavLink>
          </>
        );
      },
    },
    {
      title: '操作',
      filed: 'Operation',
      property: false,
      render: (props) => {
        return (
          <>
            <i
              style={{ cursor: 'pointer' }}
              className="fa fa-trash-alt"
              onClick={() => {
                handleOpenConfrimBox(props.Feature.Id);
              }}></i>
          </>
        );
      },
    },
    {
      title: '狀態',
      filed: 'Status',
      property: false,
      render: (props) => {
        return (
          <Checkbox
            color="primary"
            checked={props.Feature.IsDisplay}
            onChange={() => {
              handleCheckboxOnChange(props.Feature.Id, props.Feature.IsDisplay);
            }}
          />
        );
      },
    },
  ];

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={match.url} />
      <div className="block">
        <div className="top-block">
          <div className="text">挑選熱門精選輪播</div>
          <NavLink to={`/Advertising/AddPopularFeature`}>
            + 新增題庫精選
          </NavLink>
          <NavLink to={`/Advertising/AddSuitFeature`}>+ 新增套卷精選</NavLink>
        </div>

        <Table
          columns={columns}
          // 取出每頁要顯示的資料
          datas={propularProducts.slice(
            startPage,
            startPage + countOfPage,
          )}></Table>

        <div className="pagination-box">
          <Pagination
            currPage={currPage}
            totalPage={totalPage}
            onChangPage={handleChangePage}
          />
          <div className="view-single-page">
            每頁顯示
            <select
              value={countOfPage}
              onChange={(handleSelectChange, handleCountOfpageShow)}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
          </div>
        </div>
      </div>
      <ConfirmBox
        isVisible={confirmBox.isConfirmVisible}
        title={'刪除'}
        content={'刪除後無法復原<br>請確認是否繼續'}
        confirmMethod={() => {
          popularFeatureDelete({
            FeatureId: confirmBox.deletingID,
          }).then(() => {
            doRender();
          });
        }}
        closebox={handleCloseConfrimBox}
      />
    </div>
  );
};

export default PopularList;
