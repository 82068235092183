import React, { useState, useEffect } from 'react';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import BurstModeOutlinedIcon from '@material-ui/icons/BurstModeOutlined';
import RefreshIcon from '@material-ui/icons/Refresh';

const BonusProductImage = (props) => {
  const {
    index,
    bonusProductImage,
    isCover,
    setCover,
    deleteImage,
    revertDelete,
  } = props;
  const [fileImageUrl, setFileImageUrl] = useState();

  useEffect(() => {
    if (bonusProductImage.isUpload === true) {
      const reader = new FileReader();
      reader.readAsDataURL(bonusProductImage.file);
      reader.addEventListener('load', imageLoaded);
    } else {
      setFileImageUrl(bonusProductImage.imageName);
    }
  }, [bonusProductImage]);

  const imageLoaded = (e) => {
    setFileImageUrl(e.target.result);
  };

  let className = 'bonus-product__image__item bonus-product__image';

  if (bonusProductImage.isDelete) {
    className = className + ' bonus-product__image--delete';
  }

  return (
    <div className={className}>
      {fileImageUrl && (
        <img style={{ backgroundImage: `url("${fileImageUrl}")` }} />
      )}

      {!isCover && (
        <div className="bonus-product__image__tools">
          {setCover && !bonusProductImage.isDelete && (
            <BurstModeOutlinedIcon
              onClick={() => {
                setCover(index);
              }}
            />
          )}

          {deleteImage && !bonusProductImage.isDelete && (
            <DeleteForeverIcon
              onClick={() => {
                deleteImage(index);
              }}
            />
          )}

          {revertDelete && bonusProductImage.isDelete && (
            <RefreshIcon
              onClick={() => {
                revertDelete(index);
              }}
            />
          )}
        </div>
      )}

      {isCover && <div className="bonus-product__cover__tag">封面</div>}
    </div>
  );
};

export default BonusProductImage;
