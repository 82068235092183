import React from 'react';
import BurstModeOutlinedIcon from '@material-ui/icons/BurstModeOutlined';
import BonusProductImage from './BonusProductImage';

const BonusProductCover = (props) => {
  const { bonusProductImage } = props;

  return (
    <div className="bonus-product__cover">
      {bonusProductImage && (
        <BonusProductImage bonusProductImage={bonusProductImage} isCover />
      )}

      {!bonusProductImage && (
        <div className="bonus-product__cover__title">
          <BurstModeOutlinedIcon />
          <br />
          封面
        </div>
      )}
    </div>
  );
};

export default BonusProductCover;
