import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reduxSetKnowledge } from '../store/action/index';
import knowledgeAPI from '../middleware/knowledgeAPI';
import _ from 'lodash';

const flat = (knowledge) => {
  let flatKnowledge = [];

  _.forEach(knowledge, (know) => {
    flatDeep(know, flatKnowledge, 0, [], {
      name: know.name,
      id: know.id,
    });
  });

  return flatKnowledge;
};

const flatDeep = (knowledge, flatKnowledge, deepNumber, parents, subject) => {
  let newParents = [...parents];

  flatKnowledge.push({
    id: knowledge.id,
    parentId: knowledge.parentId,
    name: knowledge.name,
    deepNumber: deepNumber,
    subject: subject,
    parents: newParents,
  });

  if (
    knowledge.knowledgeDtoChildren &&
    knowledge.knowledgeDtoChildren.length > 0
  ) {
    newParents.push({
      id: knowledge.id,
      name: knowledge.name,
    });

    _.forEach(knowledge.knowledgeDtoChildren, (childKnowledge) => {
      flatDeep(
        childKnowledge,
        flatKnowledge,
        deepNumber + 1,
        newParents,
        subject,
      );
    });
  }
};

const useKnowledge = () => {
  const dispatch = useDispatch();
  const knowledges = useSelector((state) => state.KnowledgeState);
  const [flatKnowledge, setFlatKnowledge] = useState(() => {
    if (knowledges.length > 0) {
      return flat(knowledges);
    } else {
      return [];
    }
  });

  const loadKnowledge = () => {
    return knowledgeAPI.get().then((response) => {
      setFlatKnowledge(flat(response));
      dispatch(reduxSetKnowledge(response));
    });
  };

  return {
    knowledges,
    flatKnowledge,
    loadKnowledge,
  };
};

export default useKnowledge;
