export const QuestionStatus = {
  EDIT: 1,
  SUBMIT_1: 2,
  DECLINE_1: 3,
  ACCEPT: 4,
  DELETE: 5,
  SUBMIT_2: 6,
  DECLINE_2: 7,
};

export const ExamSaleType = {
  4: '無詳解',
  3: '無影片詳解',
  2: '無文字詳解',
  1: '完整詳解',
};

export const ProductType = {
  questionBank: '題庫',
  hangout: '課程',
};

export const ProductStatus = {
  optional: '可選擇',
  selected: '已選擇',
  alreadyInPack: '已在產品包',
};

// 共用的每頁筆數
export const LIST_PAGE_COUNT = 8;

export const BonusSaleTypes = {
  1: '餐廳',
  2: '網路訂單',
  3: '生日禮',
  4: '入會禮',
  5: '推薦禮',
  6: 'Email綁定',
  7: '兌換紅利商品',
  8: '去年度紅利點數歸零',
  9: '推薦碼兌換',
};

export const BonusStatus = {
  NORMAL: 0,
  MAKEUP: 1,
  DELETE: 99,
};

export const BonusStatusText = {
  [BonusStatus.NORMAL]: '正常',
  [BonusStatus.MAKEUP]: '補登',
  [BonusStatus.DELETE]: '刪除',
};

export const BonusAuditStatus = {
  NULL: 0,
  PENDING: 1,
  NORMAL: 2,
  ERROR: 3,
  SOLVED: 4,
};

export const BonusAuditStatusText = {
  [BonusAuditStatus.NULL]: '尚未稽核',
  [BonusAuditStatus.PENDING]: '尚未稽核',
  [BonusAuditStatus.NORMAL]: '稽核正確',
  [BonusAuditStatus.ERROR]: '稽核異常',
  [BonusAuditStatus.SOLVED]: '已處理',
};

export const MemberLevelText = {
  1: '同窗',
  2: '解惑',
  3: '授業',
  4: '傳道',
  5: '良師',
};

export const SignUpFromText = {
  CP: '竹北',
  line: 'Line',
  onlineclassroomdaily: '線上教室',
  GDKoala: '金考拉',
};

export const BonusProductStatus = {
  OFF_SALE: 0,
  ON_SALE: 1,
  DELETE: 2,
};

export const BonusProductStatusText = {
  [BonusProductStatus.OFF_SALE]: '下線',
  [BonusProductStatus.ON_SALE]: '上線',
  [BonusProductStatus.DELETE]: '刪除',
};

export const BonusProductType = {
  EXCHANGE: 1,
  DISCOUNT: 2,
};

export const BonusProductTypeText = {
  [BonusProductType.EXCHANGE]: '兌換券',
  [BonusProductType.DISCOUNT]: '優惠券',
};

export const BonusProductExpriyDay = {
  SEVEN_DAYS: 7,
  THIRTY_DAYS: 30,
  SIXTY_DAYS: 60,
  NINETY_DAYS: 90,
};

export const BonusProductExpriyDayText = {
  [BonusProductExpriyDay.SEVEN_DAYS]: '7 天',
  [BonusProductExpriyDay.THIRTY_DAYS]: '30 天',
  [BonusProductExpriyDay.SIXTY_DAYS]: '60 天',
  [BonusProductExpriyDay.NINETY_DAYS]: '90 天',
};

export const UserBonusProductStatus = {
  UN_USE: 0,
  USED: 1,
  GIFTED: 2,
  EXPIRED: 99, // 非實際資料庫使用, 與後端協議 99 = 過期
};

export const UserBonusProductStatusText = {
  [UserBonusProductStatus.UN_USE]: '未使用',
  [UserBonusProductStatus.USED]: '已使用',
  [UserBonusProductStatus.GIFTED]: '已贈送',
  [UserBonusProductStatus.EXPIRED]: '過期',
};

export const UserRoleIds = {
  MEMBER: '1',
  WISDOMBANK_ADMIN: '3',
  SUPER_ADMIN: '8',
  RESTAURANT_STAFF: '19',
  TEACHER_EDUCATION: '22',
};
