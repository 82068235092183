import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';
import '../style/components/login-page.scss';
import AlertBox from '../components/AlertBox';
import Loading from '../components/Loading';
import backgroundImage from '../images/loginback.svg';
import logo from '../images/fish-logo.svg';
import logoGreen from '../images/logo＿green.svg';
import {
  adminLoginRequestFunc,
  InitAPIStatus,
  switchRememberMe,
} from '../store/action';
import {
  getEnvName,
  isLocalEnv,
  isOnlineEnv,
  isOnlineTestEnv,
} from '../Util/Util';

const INIT_SEND_API_STATUS = -1;

function Logo({ logo, alt }) {
  return (
    <div className="right-container">
      <img src={logo} alt={alt} />
    </div>
  );
}

function TopHeader({ logo, text }) {
  const envName = getEnvName();

  return (
    <div className="top-header">
      <div className="logo">
        <img src={logo} alt="ManagerSystem" />
      </div>
      <div className="text">
        {text}
        {isOnlineTestEnv() || isLocalEnv() ? (
          <div className="sub__text--highlight">{envName}</div>
        ) : null}
      </div>
    </div>
  );
}

function InputTextField({ id, label, type, value, action, icon }) {
  return (
    <div className="input-box">
      <i className={icon} />
      <input
        type={type}
        name={id}
        placeholder={label}
        value={value}
        onChange={action}
      />
    </div>
  );
}

function Remember({ checked, action, label }) {
  return (
    <>
      <div className="tool-box">
        <div className="remember-me">
          <input
            id="rember-me"
            type="checkbox"
            checked={checked}
            onChange={action}
          />
          <label htmlFor="rember-me" className="check-box"></label>
          <label htmlFor="rember-me" className="rember-me">
            {label}
          </label>
        </div>
      </div>
    </>
  );
}

/**
 * user init
 */
function userInit() {
  if (process.env.NODE_ENV === 'development') {
    return { userName: 'banqiao@', password: '0229688101' }; // 餐廳收銀使用
    //return { userName: `sa`, password: `liangshishu` }; // 管理帳號
  } else if (localStorage && localStorage.getItem('rememberAdminName')) {
    return {
      userName: localStorage.getItem('rememberAdminName'),
      password: ``,
    };
  } else {
    return {
      userName: ``,
      password: ``,
    };
  }
}

/**
 * 登入頁面
 * @param {object} loginState
 * @param {bool} loading
 * @param {bool|number} SendAPIState
 * @param {function} adminLoginRequestFunc
 * @param {function} InitAPIStatus
 * @param {function} switchRememberMe
 */
function LoginPage({
  loginState,
  SendAPIState,
  loading,
  adminLoginRequestFunc,
  InitAPIStatus,
  switchRememberMe,
}) {
  const [user, setUser] = useState(userInit);
  const [alert, setAlert] = useState({ isAlert: false, msg: 'No Alert' });

  useEffect(() => {
    const checkLogin = () => {
      if (!alert.isAlert && !SendAPIState) {
        setAlert({
          ...alert,
          isAlert: true,
          msg: '登入失敗 請重新登入',
        });
      }
    };

    checkLogin();
  });

  const handleLogin = (e) => {
    e.preventDefault();
    if (_.isEmpty(user.userName) || _.isEmpty(user.password)) {
      setAlert({ ...alert, isAlert: true, msg: '帳號密碼都要填寫唷' });
    } else {
      adminLoginRequestFunc(user);
    }
  };

  const handleChangeUserName = (e) => {
    setUser({ ...user, userName: e.target.value });
  };

  const handleChangePassword = (e) => {
    setUser({ ...user, password: e.target.value });
  };

  const handleAlertClose = () => {
    setAlert({ ...alert, isAlert: false });
    if (SendAPIState !== INIT_SEND_API_STATUS) {
      InitAPIStatus();
    }
  };

  const handleRememberCheckBox = () => {
    switchRememberMe(user);
  };

  const textField = [
    {
      id: 'account',
      label: '請輸入帳號',
      type: 'text',
      value: user.userName,
      action: handleChangeUserName,
      icon: 'fas fa-envelope',
    },
    {
      id: 'password',
      label: '請輸入密碼',
      type: 'password',
      value: user.password,
      action: handleChangePassword,
      icon: 'fas fa-key',
    },
  ];

  if (loginState.isLogin) {
    return <Redirect to="/" />;
  }

  return (
    <div className="login-page">
      <div
        className="background-image"
        style={{ backgroundImage: `url(${backgroundImage})` }}></div>
      {loading ? (
        <Loading />
      ) : (
        <form
          className="outer-container"
          style={{
            borderColor: isOnlineEnv() ? '' : '#5f70ff',
          }}>
          <div className="left-container">
            <TopHeader logo={logo} text={'管理系統'} />
            {textField.map((item) => {
              return (
                <InputTextField
                  key={item.id}
                  id={item.id}
                  type={item.type}
                  label={item.label}
                  value={item.value}
                  action={item.action}
                  icon={item.icon}
                />
              );
            })}
            <Remember
              checked={loginState.isRemberMe}
              action={handleRememberCheckBox}
              label={'記住我'}
            />
            <button className="login-btn" onClick={handleLogin}>
              登入
            </button>
          </div>
          <div className="sepetate"></div>
          <Logo logo={logoGreen} alt={'LiangShiShuin'} />
        </form>
      )}
      <AlertBox
        isVisible={alert.isAlert}
        content={alert.msg}
        closebox={handleAlertClose}
      />
    </div>
  );
}

const mapStateToProps = (store) => ({
  loginState: store.loginState,
  SendAPIState: store.SendAPIStatusState,
  loading: store.loading,
});

export default connect(mapStateToProps, {
  adminLoginRequestFunc,
  InitAPIStatus,
  switchRememberMe,
})(LoginPage);
