import * as types from '../actionType';

let initState = [];

function KnowledgeState(state = initState, action) {
  switch (action.type) {
    case types.SET_KNOWLEDGE: {
      return [...action.knowledge];
    }

    default:
      return state;
  }
}

export default KnowledgeState;
