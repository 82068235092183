import React from 'react';
import { Route, Switch } from 'react-router-dom';
import VideoList from './VideoList';
import Page404 from '../../components/Page404';

const VideoRoute = (props) => {
  return (
    <Switch>
      <Route
        exact
        path={`${props.match.url}/VideoList`}
        component={VideoList}
      />
      <Route
        path={props.match.url}
        render={() => {
          // 不使用 react-route 轉換，是為了不讓該網址進 history ，才不會上一頁時一直無限導回
          window.location.replace(props.match.url + '/VideoList');
          return false;
        }}
      />
      <Route component={Page404} />
    </Switch>
  );
};

export default VideoRoute;
