import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import BreakCrumbs from '../../components/BreakCrumbs';
import useSuitExam from '../../hooks/useSuitExm';
import useSaleArea from '../../hooks/useSaleArea';
import SuitExamSetting from '../../components/SaleExam/SuitExamSetting';
import SuitExamProducts from '../../components/SaleExam/SuitExamProducts';
import { Box, Paper, Button } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import KeyboardReturnOutlinedIcon from '@material-ui/icons/KeyboardReturnOutlined';
import Swal from 'sweetalert2';
import _ from 'lodash';
import {
  addSuitExam,
  saveSuitExam,
  saveSuitExamProducts,
} from '../../Util/fetchMethods';
import { addFlashMessage } from '../../store/action/index';

const SuitExam = (props) => {
  const { id } = useParams();
  const { ...suitExamHook } = useSuitExam(id);
  const { ...saleAreaHook } = useSaleArea();
  const [suitExam, setSuitExam] = useState({ ...suitExamHook.initSuitExam });
  const [isReady, setIsReady] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  /**
   * 處理 hooks 的錯誤
   */
  useEffect(() => {
    /**
     * 有 id 才處理
     */
    if (id) {
      if (suitExamHook.loading === false && suitExamHook.error !== '') {
        Swal.fire('無法取的套卷內容', suitExamHook.error, 'error');
      } else {
        setSuitExam({ ...suitExamHook.suitExam });
      }
    }

    if (saleAreaHook.loading === false && saleAreaHook.error !== '') {
      Swal.fire('無法取的銷售區域', saleAreaHook.error, 'error');
    }

    setIsReady(true);

    return () => {
      setIsReady(false);
      setSuitExam({ ...suitExamHook.initSuitExam });
    };
  }, [
    saleAreaHook.loading,
    suitExamHook.loading,
    suitExamHook.initSuitExam,
    setIsReady,
    setSuitExam,
    id,
  ]);

  /**
   * 對應套卷設定變更
   * @param {string} name 套卷設定的欄位名稱
   * @param {*} e 變更的 dom 物件
   */
  const handleSuitExamSettingChange = (name, e) => {
    let value = '';

    if (name !== 'Status') {
      value = e.target.value;
    } else {
      value = e.target.checked ? 1 : 2;
    }

    let newQuestionBankSet = { ...suitExam.QuestionBankSet, [name]: value };

    setSuitExam({ ...suitExam, QuestionBankSet: newQuestionBankSet });
  };

  /**
   * 編輯套卷商品內容，需注意在此的 product 內容結構，應該要用原本的資料結構(如 questionBank, hangout)而非 product 的結構
   * @param {array} products 編輯產包內容，陣列組成，內容為 object {productType, action, product} 組成，可參考下列
   * @param {string} productType 產品類型, 題庫|課程
   * @param {strint} action 動作 add|del
   * @param {obj} product 產品內容
   */
  const editProducts = (products) => {
    let newSuitExam = { ...suitExam };

    _.each(products, (productBag) => {
      const { productType, action, product } = productBag;

      switch (productType) {
        case 'questionBank':
          if (action === 'add') {
            if (
              _.findIndex(newSuitExam.QuestionBankList, [
                'QuestionBankId',
                product.QuestionBankId,
              ]) === -1
            ) {
              newSuitExam.QuestionBankList.push(product);
            }
          } else if (action === 'del') {
            _.remove(newSuitExam.QuestionBankList, (questionBankInPack) => {
              return (
                questionBankInPack.QuestionBankId === product.QuestionBankId
              );
            });
          }
          break;

        case 'hangout':
          if (action === 'add') {
            if (
              _.findIndex(newSuitExam.HangoutList, [
                'HangoutId',
                product.HangoutId,
              ]) === -1
            ) {
              newSuitExam.HangoutList.push(product);
            }
          } else if (action === 'del') {
            _.remove(newSuitExam.HangoutList, (hangoutInPack) => {
              return hangoutInPack.HangoutId === product.HangoutId;
            });
          }
          break;
      }
    });

    setSuitExam(newSuitExam);
  };

  /**
   * 儲存內容
   * 先儲存套卷內容
   * 再儲存套卷產品
   */
  const submit = () => {
    const {
      Title,
      Description,
      Price,
      AreaId,
      Status,
    } = suitExam.QuestionBankSet;
    const NumberRegx = /^[0-9]+$/;

    if (_.trim(Title) === '') {
      Swal.fire('無法儲存', '請填寫套裝名稱', 'error');
      return;
    }

    if (_.trim(Description) === '') {
      Swal.fire('無法儲存', '請填寫簡介', 'error');
      return;
    }

    if (!NumberRegx.test(Price) || Price === 0) {
      Swal.fire('無法儲存', '請輸入價格，並大於0', 'error');
      return;
    }

    if (
      suitExam.HangoutList.length <= 0 &&
      suitExam.QuestionBankList.length <= 0
    ) {
      Swal.fire('無法儲存', '請設定至少一項的商品內容', 'error');
      return;
    }

    const questionBankIds = _.map(suitExam.QuestionBankList, 'QuestionBankId');
    const hangoutIds = _.map(suitExam.HangoutList, 'HangoutId');

    setIsReady(false);

    if (id) {
      /**
       * 修改
       */
      saveSuitExam({
        QuestionBankSetId: id,
        Title: Title,
        Description: Description,
        AreaId: AreaId,
        Price: Price,
        Status: Status,
      })
        .then((response) => {
          if (response.Status === 1) {
            saveSuitExamProducts({
              QuestionBankSetId: id,
              QuestionBankIdList: questionBankIds.join(','),
              HangoutIdList: hangoutIds.join(','),
            })
              .then((productResponse) => {
                if (productResponse.Status === 1) {
                  dispatch(addFlashMessage('修改套卷 ' + Title + ' 完成'));
                  history.push('/SaleExam/SuitExamList');
                } else {
                  Swal.fire(
                    '修改失敗',
                    _.join(productResponse.Message, '<br>'),
                    'error',
                  );
                  setIsReady(true);
                }
              })
              .catch((err) => {
                Swal.fire('修改失敗', err.msg, 'error');
                setIsReady(true);
              });
          } else {
            Swal.fire('修改失敗', _.join(response.Message, '<br>'), 'error');
            setIsReady(true);
          }
        })
        .catch((err) => {
          Swal.fire('修改失敗', err.msg, 'error');
          setIsReady(true);
        });
    } else {
      /**
       * 新增
       */
      addSuitExam({
        Title: Title,
        Description: Description,
        QuestionBankIdList: questionBankIds.join(','),
        HangoutIdList: hangoutIds.join(','),
        AreaId: AreaId,
        Price: Price,
        Status: Status,
      })
        .then((response) => {
          if (response.Status === 1) {
            dispatch(addFlashMessage('新增套卷 ' + Title + ' 完成'));
            history.push('/SaleExam/SuitExamList');
          } else {
            Swal.fire('儲存失敗', _.join(response.Message, '<br>'), 'error');
            setIsReady(true);
          }
        })
        .catch((err) => {
          Swal.fire('儲存失敗', err.msg, 'error');
          setIsReady(true);
        });
    }
  };

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={props.match.url}></BreakCrumbs>

      <SuitExamSetting
        suitExamSetting={suitExam.QuestionBankSet}
        handleSuitExamSettingChange={handleSuitExamSettingChange}
        saleArea={saleAreaHook.saleArea}
        isReady={isReady}
      />

      <SuitExamProducts
        questionBankList={suitExam.QuestionBankList}
        hangoutList={suitExam.HangoutList}
        editProducts={editProducts}
      />

      <Box
        component={Paper}
        square
        elevation={2}
        p={1}
        mt={3}
        mb={3}
        textAlign="right">
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{ marginRight: '24px' }}
          startIcon={<SaveOutlinedIcon />}
          disabled={!isReady}
          onClick={submit}>
          儲存
        </Button>
        <Button
          variant="contained"
          color="default"
          size="large"
          startIcon={<KeyboardReturnOutlinedIcon />}
          disabled={!isReady}
          onClick={() => {
            history.goBack();
          }}>
          回列表
        </Button>
      </Box>
    </div>
  );
};

export default SuitExam;
