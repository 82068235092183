import * as types from '../actionType';

let initState = {
  isLoading: false,
  cancelable: false,
  loadingContent: '',
};

function pageLoadingState(state = initState, action) {
  switch (action.type) {
    case types.CANCEL_PAGE_LOADING: {
      return { ...state, isLoading: false };
    }
    case types.CALL_PAGE_LOADING: {
      return { ...state, ...action.setting };
    }
    default:
      return state;
  }
}

export default pageLoadingState;
