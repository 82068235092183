import React, { useState } from 'react';
import _ from 'lodash';
import CloudUploadRoundedIcon from '@material-ui/icons/CloudUploadRounded';
import {
  Badge,
  Collapse,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Box,
} from '@material-ui/core';
import useVideoQueue from '../hooks/useVideoQueue';
import { makeStyles } from '@material-ui/core/styles';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import ExposureZeroRoundedIcon from '@material-ui/icons/ExposureZeroRounded';
import CloudDoneRoundedIcon from '@material-ui/icons/CloudDoneRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import PauseCircleOutlineRoundedIcon from '@material-ui/icons/PauseCircleOutlineRounded';
import FindInPageRoundedIcon from '@material-ui/icons/FindInPageRounded';
import VideoFileStatus from './Video/VideoFileStatus';

const useStyles = makeStyles((theme) => ({
  list: {
    width: '300px',
    marginLeft: '-135px',
    backgroundColor: theme.palette.grey[300],
    cursor: 'auto',
    borderRadius: theme.spacing(0.5),
  },
  queue: {
    width: '100%',
  },
  listItemAvatar: {
    color: '#fff',
  },
}));

const UploadQueueStatus = () => {
  const { videoQueueState } = useVideoQueue();
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  return (
    <>
      <Badge badgeContent={videoQueueState.queue.length} color="primary">
        <CloudUploadRoundedIcon
          onClick={() => {
            setExpanded(!expanded);
          }}
        />
      </Badge>
      <Collapse in={expanded} timeout={{ appear: 0, enter: 0, exit: 0 }}>
        <List className={classes.list}>
          {videoQueueState.queue.length <= 0 && (
            <ListItem>
              <ExposureZeroRoundedIcon />
              <ListItemText primary="目前無檔案上傳" />
            </ListItem>
          )}

          {_.map(videoQueueState.queue, (queue, index) => (
            <ListItem key={queue.handoutVideoId + '+' + index}>
              <ListItemAvatar className={classes.listItemAvatar}>
                <Avatar>
                  {queue.status === 'upload' && <PublishRoundedIcon />}
                  {queue.status === 'done' && <CloudDoneRoundedIcon />}
                  {queue.status === 'getVideoInfo' && <FindInPageRoundedIcon />}
                  {queue.status === 'standbyUpload' && (
                    <PauseCircleOutlineRoundedIcon />
                  )}
                  {queue.status === 'error' && <ErrorRoundedIcon />}
                </Avatar>
              </ListItemAvatar>
              <Box className={classes.queue}>
                <Box>{queue.name}</Box>
                <VideoFileStatus videoQueue={queue} />
              </Box>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default UploadQueueStatus;
