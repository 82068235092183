import React from 'react';
import Barcode from 'react-barcode';
import { trim } from 'lodash';

const BonusProductBarcode = (props) => {
  const { bonusProduct } = props;

  return (
    <div className="bonus__product__barcode">
      {trim(bonusProduct.barcode) !== '' && (
        <Barcode
          height={30}
          fontSize={16}
          value={bonusProduct.barcode}
          width={1}
        />
      )}

      {trim(bonusProduct.barcode) === '' && <div>無設定 barcode</div>}

      <div>[ {bonusProduct.name} ]</div>
    </div>
  );
};

export default BonusProductBarcode;
