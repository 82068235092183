import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import { transformProductData } from '../../Util/Util';
import { ExamSaleType, ProductType, ProductStatus } from '../../data/common';
import green from '@material-ui/core/colors/green';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  disabledCard: {
    backgroundColor: theme.palette.grey[200],
    boxShadow: 'none',
  },
  selectedCard: {
    backgroundColor: green[300],
    transition: 'background-color 0.4s, color 0.4s',
  },
  productTypeTag: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    padding: '3px 6px',
    backgroundColor: '#000',
    borderRadius: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '1px',
    color: theme.palette.common.white,
  },
  productStatusTag: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    borderRadius: theme.spacing(1),
    borderRadius: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    padding: '3px 6px',
  },
  productStatusSelected: {
    backgroundColor: green[800],
  },
  productStatusAlready: {
    backgroundColor: theme.palette.common.white,
    color: '#000',
  },
}));

const MiniProductCard = (props) => {
  const classes = useStyles();
  const product = useMemo(() => {
    return transformProductData(props.product);
  }, [props.product]);

  return (
    <Card
      className={clsx(
        props.disabled && classes.disabledCard,
        props.status === 'selected' && classes.selectedCard,
      )}
      onClick={() => {
        if (props.disabled !== true && props.onClick) {
          props.onClick();
        }
      }}>
      <CardActionArea disabled={props.disabled} disableRipple={props.disabled}>
        <CardMedia
          image={product.imgUrl}
          title="產品圖片"
          component="img"
          height="90"
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="subtitle1"
            style={{ height: '60px' }}>
            {product.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {ExamSaleType[product.saleType]}
          </Typography>
        </CardContent>
        {props.disabledProductTypeTag !== true && (
          <div className={classes.productTypeTag}>
            {ProductType[product.type]}
          </div>
        )}
        <div
          className={clsx(
            classes.productStatusTag,
            props.status === 'alreadyInPack' && classes.productStatusAlready,
            props.status === 'optional' && classes.productStatusOptional,
            props.status === 'selected' && classes.productStatusSelected,
          )}>
          {ProductStatus[props.status]}
        </div>
      </CardActionArea>
    </Card>
  );
};

export default MiniProductCard;
