import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import TablePaginationActions from '../TablePaginationActions';
import UserBonusProductTableRow from './UserBonusProductTableRow';

const UserBonusProductTable = (props) => {
  const {
    userBonusProducts,
    userBonusProductTotal,
    page,
    setPage,
    perPageCount,
  } = props;

  return (
    <TableContainer component={Box}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell>會員</TableCell>
            <TableCell>商品</TableCell>
            <TableCell>類型</TableCell>
            <TableCell>使用期限</TableCell>
            <TableCell>使用日期</TableCell>
            <TableCell>兌換員工</TableCell>
            <TableCell>狀態</TableCell>
            <TableCell>贈送人</TableCell>
            <TableCell>推薦碼</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userBonusProducts.map((userBonusProduct) => (
            <UserBonusProductTableRow
              key={userBonusProduct.id}
              userBonusProduct={userBonusProduct}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[perPageCount]}
              colSpan={9}
              count={userBonusProductTotal}
              rowsPerPage={perPageCount}
              page={page - 1}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={(event, currentPage) => {
                setPage(currentPage + 1);
              }}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default UserBonusProductTable;
