import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import axios from 'axios';
import ConfirmBox from '../../components/ConfirmBox';
import Swal from 'sweetalert2';
import '../../style/components/SaleExam/sale-exam.scss';
import QrcordBlock from '../../components/QrcordBlock';
import AddNewPromo from '../../components/PromoList/AddNewPromo';
import AddPopular from '../../components/Advertising/AddPopular';

import {
  fetchPromos,
  deletePromoQuestionBank,
  createPromoQuestionBankAPI,
  deletePromoAPI,
  createPromoAPI,
} from '../../Util/fetchMethods.js';
import { logoutSuccess } from '../../store/action';

import httpErrorHandle from '../../Util/httpErrorHandle';
import BreakCrumbs from '../../components/BreakCrumbs';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';

import { connect } from 'react-redux';
import { FRONT_HOST } from '../../store/apiconfig';

function OrderList({ logoutSuccess }) {
  const history = useHistory();
  const match = useRouteMatch();

  const [promos, setPromos] = useState([]);
  const [allPromos, setAllPromos] = useState([]);
  const [promoId, setTargetPromoId] = useState(0);
  const [addSuitExamOn, setAddSuitExamOn] = useState(false);
  const [isOpenQrcodeBlock, setOpenQrcodeBlock] = useState(false);
  const [qrcordText, setQrcordText] = useState('');
  const [deletingID, setDeletingID] = useState(0);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [examination, setExamination] = useState([]);
  const [isAddVisible, setIsAddVisible] = useState(false);
  const [countOfPage, setCountOfPage] = useState(10);
  const [currPage, setCurrPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [pageStart, setPageStart] = useState(0);

  const updatePromos = useCallback(() => {
    fetchPromos()
      .then((json) => {
        const promoList = json.Result;
        const newPromos = promoList.map((promo) => {
          const newPromo = {
            usage: promo.Usage,
            id: promo.PromoId,
            startAt: promo.StartAt,
            endAt: promo.EndAt,
            questionBankList: promo.QuestionBankList,
          };
          return newPromo;
        });
        setTotalPage(Math.ceil(newPromos.length / countOfPage));
        setPageStart((currPage - 1) * countOfPage);

        let pagePromo = newPromos.slice(pageStart, pageStart + countOfPage);
        setPromos(pagePromo);
        setAllPromos(newPromos);
      })
      .catch((error) => {
        httpErrorHandle(
          error.httpCode,
          `fetchOrders Error: ${error.msg} httpErrorCode: ${error.httpCode}`,
          logoutSuccess,
        );
      });
  }, [logoutSuccess]);

  const callDeletePromoQuestionBankAPI = (id, questionBankId) => {
    deletePromoQuestionBank(id, questionBankId).then((data) => {
      Swal.fire({
        icon: 'success',
        title: '修改成功',
        showConfirmButton: false,
        showCloseButton: false,
        timer: 1500,
      });
      updatePromos();
    });
  };

  const callCreatePromoQuestionBankAPI = (id, questionBankId) => {
    createPromoQuestionBankAPI(id, questionBankId).then((data) => {
      Swal.fire({
        icon: 'success',
        title: '修改成功',
        showConfirmButton: false,
        showCloseButton: false,
        timer: 1500,
      });
      updatePromos();
    });
  };

  const callCreatePromoAPI = (newPromo) => {
    Swal.fire({
      icon: 'warning',
      title: '新增活動中',
      showConfirmButton: false,
      showCloseButton: true,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    createPromoAPI(newPromo).then((data) => {
      if (data.Code === 22) {
        Swal.fire({
          icon: 'warning',
          title: '活動名稱已重複',
          showConfirmButton: false,
          showCloseButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: '新增成功',
          showConfirmButton: false,
          showCloseButton: false,
          timer: 1500,
        });
        updatePromos();
      }
    });
  };

  const callDeletePromoAPI = (id) => {
    deletePromoAPI(id).then((data) => {
      Swal.fire({
        icon: 'success',
        title: '刪除成功',
        showConfirmButton: false,
        showCloseButton: false,
        timer: 1500,
      });
      updatePromos();
    });
  };

  const handleDeleteQBbox = (id, questionBankId) => {
    Swal.fire({
      icon: 'info',
      title: '是否確定要將題庫從活動中移除？',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: '確定',
      heightAuto: false,
    }).then((result) => {
      if (result.value) {
        callDeletePromoQuestionBankAPI(id, questionBankId);
      }
    });
    return;
  };
  const handleAddProducts = (newProducts) => {
    Swal.fire({
      icon: 'warning',
      title: '修改內容中',
      showConfirmButton: false,
      showCloseButton: true,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    var qbIdList = '';
    newProducts.forEach(function(element) {
      qbIdList = qbIdList + element.QuestionBankId + ',';
    });
    callCreatePromoQuestionBankAPI(promoId, qbIdList.slice(0, -1));
  };
  const handleOnSwitch = () => {
    setAddSuitExamOn(!addSuitExamOn);
  };

  const handleToggleOpenQrcordBlcok = () => {
    setOpenQrcodeBlock(!isOpenQrcodeBlock);
  };

  const handleCloseDeleteBox = () => {
    setIsDeleteVisible(!isDeleteVisible);
  };

  const handleCloseAddBox = () => {
    setIsAddVisible(!isAddVisible);
  };
  /* create MOD member */
  const openAddSuitExamOn = (promoId) => {
    setAddSuitExamOn(true);
    setTargetPromoId(promoId);
  };

  const handleOpenQrcordBlcok = (text) => {
    var url = '/url/api/tinyurl?url=' + text;

    if (process.env.NODE_ENV === 'development') {
      url = 'https://managewisdombank-dev.liangshishu.com' + url;
    }

    var self = this;
    axios
      .get(url)
      .then(function(response) {
        // handle success
        const shortUrl = response.data.result;

        setQrcordText(shortUrl);
        setOpenQrcodeBlock(true);
      })
      .catch(function(error) {
        // handle error
        console.log(error);
      });
  };

  const handleOpenDeleteBox = (e, id) => {
    e.preventDefault();
    setDeletingID(id);
    setIsDeleteVisible(true);
  };

  const handleDeleteBoxComfrim = (id) => {
    Swal.fire({
      icon: 'warning',
      title: '刪除中',
      showConfirmButton: false,
      showCloseButton: true,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    let newArray = examination.filter((exam) => exam.PromoId !== id);
    setExamination(newArray);
    callDeletePromoAPI(id);
  };

  const handleSelectChange = (e) => {
    setCountOfPage(e.target.value);
    setCurrPage(1);
    setTotalPage(Math.ceil(allPromos.length / e.target.value));
    setPageStart(0);
    let pagePromo = allPromos.slice(0, e.target.value);
    setPromos(pagePromo);
  };

  const handleChangePage = (page) => {
    setCurrPage(page);
    var newPageStart = (page - 1) * countOfPage;
    setPageStart(newPageStart);
    let pagePromo = allPromos.slice(newPageStart, newPageStart + countOfPage);
    setPromos(pagePromo);
  };

  useEffect(() => {
    updatePromos();
  }, [updatePromos]);

  const columns = [
    { title: '活動名稱', field: 'usage', property: true },
    {
      title: '活動時間',
      field: 'time',
      render: ({ id, startAt, endAt }) => {
        return (
          <span key={id}>
            {startAt.replace('T', ' ')}
            <br />
            <span style={{ writingMode: 'vertical-lr' }}>~</span>
            <br />
            {endAt.replace('T', ' ')}
          </span>
        );
      },
    },
    {
      title: '活動內容',
      field: 'questionBankList',
      render: ({ id, questionBankList }) => {
        return questionBankList.map((questionBank, i) => {
          return (
            <span key={i}>
              ({questionBank.SaleType}){questionBank.Name}
              <span
                onClick={(e) => {
                  e.preventDefault();
                  handleDeleteQBbox(id, questionBank.QuestionBankId);
                }}>
                <i className="fa fa-trash-alt"></i>
              </span>
              <br />
            </span>
          );
        });
      },
    },
    {
      title: '操作',
      field: 'operation',
      render: ({ id, usage }) => {
        return (
          <>
            <button
              className="success"
              style={{ width: '130px' }}
              onClick={() => {
                openAddSuitExamOn(id);
              }}>
              加入題庫
            </button>
            <br />
            <button
              className="primary"
              style={{ width: '130px' }}
              onClick={(e) => {
                e.preventDefault();
                handleOpenQrcordBlcok(
                  `${FRONT_HOST}/promotion?promocode=` + encodeURI(usage),
                );
              }}>
              QR code
            </button>
            <br />
            <button
              className="danger"
              style={{ width: '130px' }}
              onClick={(e) => {
                handleOpenDeleteBox(e, id);
              }}>
              刪除
            </button>
          </>
        );
      },
    },
  ];

  return (
    <div className="member-managemenet promo-list pagefadeIn">
      <QrcordBlock
        isVisible={isOpenQrcodeBlock}
        QrcordText={qrcordText}
        toggleOpen={handleToggleOpenQrcordBlcok}
      />
      <BreakCrumbs path={match.url} />
      <div className="block">
        <div className="top-block">
          <div className="text">活動管理</div>
          <p>※活動內容欄位以(銷售類別)題庫名稱</p>
          <p>銷售類別：1.完整詳解, 2.無文字詳解, 3.無影片詳解, 4.無詳解</p>
          <button
            className="btn save"
            onClick={() => {
              handleCloseAddBox();
            }}>
            + 新增
          </button>
        </div>
        <Table columns={columns} datas={promos} />
        <div className="pagination-box">
          <Pagination
            currPage={currPage}
            totalPage={totalPage}
            onChangPage={handleChangePage}
          />
          <div className="view-single-page">
            每頁顯示
            <select value={countOfPage} onChange={handleSelectChange}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
          </div>
        </div>
        <AddPopular
          isVisiable={addSuitExamOn}
          onSwitch={handleOnSwitch}
          onAddProducts={handleAddProducts}
        />
        <AddNewPromo
          title="新增活動"
          isVisible={isAddVisible}
          AddNewPromoFunc={callCreatePromoAPI}
          closeAddBlock={handleCloseAddBox}
        />
        <ConfirmBox
          isVisible={isDeleteVisible}
          title={'刪除'}
          content={`刪除後無法復原<br>請確認是否繼續`}
          confirmMethod={() => {
            handleDeleteBoxComfrim(deletingID);
          }}
          closebox={handleCloseDeleteBox}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (store) => ({});

export default connect(mapStateToProps, {
  logoutSuccess,
})(OrderList);
