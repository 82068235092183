import React, { useState, useEffect } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import TableLoading from '../../components/TableLoading';
import { alertError, dateFormat } from '../../Util/Util';
import TableEmpty from '../../components/TableEmpty';
import {
  BonusProductTypeText,
  LIST_PAGE_COUNT,
  UserBonusProductStatusText,
} from '../../data/common';
import bonusProductAPI from '../../middleware/bonusProductAPI';
import UserBonusProductTable from '../../components/BonusProduct/UserBonusProductTable';
import { DatePicker } from '@material-ui/pickers';
import { map } from 'lodash';
import SearchIcon from '@material-ui/icons/Search';

const UserBonusProductList = (props) => {
  const { match } = props;
  const [userBonusProducts, setUserBonusProducts] = useState([]);
  const [userBonusProductTotal, setUserBonusProductTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [startUseDate, setStartUseDate] = useState(null);
  const [endUseDate, setEndUseDate] = useState(null);
  const [bonusProductType, setBonusProductType] = useState('');
  const [userBonusProductStatus, setUserBonusProductStatus] = useState('');
  const [keyword, setKeyword] = useState('');
  const [isPromo, setIsPromo] = useState(false);
  const [isReferralCode, setIsReferralCode] = useState(false);

  useEffect(() => {
    getUserBonusProducts();
  }, [page]);

  const getUserBonusProducts = () => {
    setIsLoading(true);

    bonusProductAPI
      .getUserBonusProductList(page, LIST_PAGE_COUNT, {
        startUseDate: dateFormat(startUseDate),
        endUseDate: dateFormat(endUseDate),
        bonusProductType,
        userBonusProductStatus,
        keyword,
        isPromo,
        isReferralCode,
      })
      .then(
        (res) => {
          setUserBonusProducts(res.userProductList);
          setUserBonusProductTotal(res.totalCount);
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const handleSetPage = (newPage) => {
    setPage(newPage);
  };

  const handleSearch = () => {
    setPage(1);
    getUserBonusProducts();
  };

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={match.url}></BreakCrumbs>

      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center">
          <Grid item xs>
            <Typography variant="h4" color="textPrimary" gutterBottom>
              會員兌換商品列表
            </Typography>
          </Grid>
        </Grid>

        <Divider light />

        <Box mt={2} mb={2}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="flex-end"
            spacing={2}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isReferralCode}
                    onChange={(e) => {
                      setIsReferralCode(e.target.checked);
                    }}
                  />
                }
                label="有推薦碼"
                labelPlacement="end"
              />
            </Grid>

            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isPromo}
                    onChange={(e) => {
                      setIsPromo(e.target.checked);
                    }}
                  />
                }
                label="會員註冊禮"
                labelPlacement="end"
              />
            </Grid>

            <Grid item>
              <DatePicker
                autoOk
                label="使用時間起始"
                clearable
                disableFuture
                size="small"
                inputVariant="outlined"
                value={startUseDate}
                onChange={(e) => {
                  setStartUseDate(e);
                }}
                format="yyyy-MM-dd"
              />
            </Grid>

            <Grid item>
              <DatePicker
                autoOk
                label="使用時間結束"
                clearable
                size="small"
                disableFuture
                inputVariant="outlined"
                value={endUseDate}
                onChange={(e) => {
                  setEndUseDate(e);
                }}
                format="yyyy-MM-dd"
              />
            </Grid>

            <Grid item>
              <FormControl
                size="small"
                variant="outlined"
                disabled={isLoading}
                style={{ minWidth: '120px' }}>
                <InputLabel>類型</InputLabel>

                <Select
                  value={bonusProductType}
                  onChange={(e) => {
                    setBonusProductType(e.target.value);
                  }}
                  label="類型">
                  <MenuItem value={''}>不限制</MenuItem>

                  {map(BonusProductTypeText, (text, id) => {
                    return (
                      <MenuItem value={id} key={id}>
                        {text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl
                size="small"
                variant="outlined"
                disabled={isLoading}
                style={{ minWidth: '120px' }}>
                <InputLabel>狀態</InputLabel>

                <Select
                  value={userBonusProductStatus}
                  onChange={(e) => {
                    setUserBonusProductStatus(e.target.value);
                  }}
                  label="狀態">
                  <MenuItem value={''}>不限制</MenuItem>

                  {map(UserBonusProductStatusText, (text, id) => {
                    return (
                      <MenuItem value={id} key={id}>
                        {text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <TextField
                label="姓名/券名 搜尋"
                type="search"
                variant="outlined"
                size="small"
                disabled={isLoading}
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SearchIcon />}
                disabled={isLoading}
                onClick={handleSearch}>
                搜尋
              </Button>
            </Grid>
          </Grid>
        </Box>

        {isLoading && <TableLoading />}

        {!isLoading && userBonusProductTotal <= 0 && (
          <TableEmpty message="無會員兌換商品" />
        )}

        {!isLoading && userBonusProductTotal > 0 && (
          <UserBonusProductTable
            userBonusProducts={userBonusProducts}
            userBonusProductTotal={userBonusProductTotal}
            page={page}
            perPageCount={LIST_PAGE_COUNT}
            setPage={handleSetPage}
          />
        )}
      </Box>
    </div>
  );
};

export default UserBonusProductList;
