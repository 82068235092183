import * as types from '../actionType';

const initState = [
  {
    Id: 24,
    ImageUrl:
      'https://s3-ap-northeast-1.amazonaws.com/questionbank-resource/BannerPic/c5f60131-f636-42d7-8bb8-cc5a3c96ada0',
    Title: null,
    SubTitle: null,
    Hyperlink: 'www.google.com',
    StartAt: '2018-05-01T00:00:00',
    EndAt: '2018-05-30T00:00:00',
    IsDisplay: false,
    FileUpload: null,
  },
];

function QuestionStoreState(state = initState, action) {
  switch (action.type) {
    case types.QUESTION_STORE_SUCCESS: {
      return action.result;
    }
    default:
      return state;
  }
}

export default QuestionStoreState;
