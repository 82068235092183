import React from 'react';
import { Button, TableCell, TableRow, Chip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {
  BonusAuditStatus,
  BonusAuditStatusText,
  BonusSaleTypes,
  BonusStatus,
  BonusStatusText,
} from '../../data/common';
import { formatNumberComma } from '../../Util/Util';
import moment from 'moment';
import VerifiedUserRoundedIcon from '@material-ui/icons/VerifiedUserRounded';

const BonusesTableRow = (props) => {
  const { bonus, viewBonus, deleteBonus, auditBonus, isLoading } = props;

  const bonusDate = moment(bonus.bonusDate);

  const isDelete = bonus.status === BonusStatus.DELETE;

  const handleDelete = () => {
    deleteBonus(bonus.id);
  };

  const handleViewBonus = () => {
    viewBonus(bonus.id);
  };

  const handleAudit = () => {
    auditBonus(bonus.id);
  };

  return (
    <TableRow>
      <TableCell component="th">{bonus.id}</TableCell>
      <TableCell component="th">{bonus.customer.realName}</TableCell>
      <TableCell>{BonusSaleTypes[bonus.saleType]}</TableCell>
      <TableCell>
        <div
          className={`bonus__table-point ${
            bonus.bonus < 0 ? 'bonus__table-point--use' : ''
          }`}>
          {bonus.bonus}
        </div>
      </TableCell>
      <TableCell>$ {formatNumberComma(bonus.invoiceAmount)}</TableCell>
      <TableCell>
        <Chip
          size="small"
          color={
            bonus.status === BonusStatus.NORMAL
              ? 'default'
              : bonus.status === BonusStatus.DELETE
              ? 'secondary'
              : 'primary'
          }
          label={BonusStatusText[bonus.status]}
        />
      </TableCell>
      <TableCell>
        <Chip
          size="small"
          color={
            bonus.audit === BonusAuditStatus.ERROR
              ? 'secondary'
              : bonus.audit === BonusAuditStatus.SOLVED
              ? 'primary'
              : 'default'
          }
          label={BonusAuditStatusText[bonus.audit]}
        />
      </TableCell>
      <TableCell>{bonusDate.format('YYYY-MM-DD HH:mm:ss')}</TableCell>
      <TableCell>{bonus.createUser}</TableCell>
      <TableCell align="right">
        <Button
          variant="contained"
          color="default"
          size="small"
          startIcon={<AssignmentIcon />}
          disabled={isLoading}
          style={{ marginRight: '12px' }}
          onClick={handleViewBonus}>
          檢視
        </Button>

        {auditBonus && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<VerifiedUserRoundedIcon />}
            disabled={isLoading}
            style={{ marginRight: '12px' }}
            onClick={handleAudit}>
            稽核
          </Button>
        )}

        {!isDelete && (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<DeleteIcon />}
            disabled={isLoading}
            onClick={handleDelete}>
            刪除
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default BonusesTableRow;
