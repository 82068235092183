// 編卷管理 題目來源搜尋 結果

import * as types from '../actionType';

let initState = {
  Questions: [],
  TotalCount: 0,
};

function QuestionSorceResultState(state = initState, action) {
  switch (action.type) {
    case types.QUESTION_FILTER_RESULT_SUCCESS: {
      return { ...action.result };
    }

    case types.DELETE_ITEM_IN_FILTED_ARRAY: {
      let filterQuestions = state.Questions.filter(
        (item) => item.Id !== action.id,
      );

      return {
        Questions: filterQuestions,
        TotalCount: filterQuestions.length,
      };
    }

    case types.REMOVEALL_ITEM_FILTED_ARRAY: {
      let newData = { ...initState };

      return newData;
    }

    default:
      return state;
  }
}

export default QuestionSorceResultState;
