import React from 'react';
import { Box, Grid } from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import MathJaxAndImgContent from './MathJaxAndImgContent';
import AudioPlayer from './AudioPlayer';
import QuestionVideo from './QuestionVideo';
import teal from '@material-ui/core/colors/teal';
import clsx from 'clsx';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import QuestionVideoButton from './QuestionVideoButton';

const useStyles = makeStyles((theme) => ({
  option: {
    margin: theme.spacing(2, 'auto'),
    padding: theme.spacing(1),
    borderRadius: '6px',
    position: 'relative',
  },
  correct: {
    backgroundColor: 'lightgreen',
  },
  number: {
    fontSize: '0.9rem',
    letterSpacing: '1px',
    fontWeight: '600',
    color: theme.palette.grey[600],
  },
  icon: {
    position: 'absolute',
    top: '-10px',
    left: '-10px',
    color: teal[500],
  },
}));

const AnswerOption = (props) => {
  const classes = useStyles();
  const { answer, number } = props;

  return (
    <Box
      className={
        answer.Correct ? clsx(classes.option, classes.correct) : classes.option
      }>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start">
        <Grid item xs={1} className={classes.number}>
          ({number})
        </Grid>
        <Grid item xs={11}>
          <MathJaxAndImgContent
            substance={answer.Substance}
            imageUrl={answer.ImageUrl}
          />
          <AudioPlayer
            audioName={answer.AudioName}
            audioPath={answer.AudioPath}
          />
          <QuestionVideoButton
            videoName={answer.VideoName}
            videoPath={answer.VideoPath}
          />
        </Grid>
      </Grid>
      {answer.Correct && (
        <Box className={classes.icon}>
          <DoneAllIcon />
        </Box>
      )}
    </Box>
  );
};

export default AnswerOption;
