import React from 'react';
import PropTypes from 'prop-types';
import '../style/components/pagination.scss';

const Pagination = ({ currPage, totalPage, onChangPage }) => {
  const maxPage = 6;

  // 點選換頁
  const handleChangePage = (page, e) => {
    e.preventDefault();
    if (page === -1) return;
    onChangPage(Number(page));
  };
  // 前一頁
  const handleRrePage = (e) => {
    e.preventDefault();
    if (currPage === 1) return;
    onChangPage(currPage - 1);
  };
  // 下一頁
  const handleNextPage = (e) => {
    e.preventDefault();
    if (currPage === totalPage) return;
    onChangPage(currPage + 1);
  };
  // 頁數
  let totalPageArray = [];

  // 第一頁
  totalPageArray.push(
    <li key={'first-page'} className={1 === currPage ? 'active' : ''}>
      <a
        href="/"
        onClick={(e) => {
          handleChangePage(1, e);
        }}>
        1
      </a>
    </li>,
  );

  if (currPage >= 5 && totalPage >= maxPage) {
    totalPageArray.push(
      <li key={'first-elip'}>
        <a
          href="/"
          onClick={(e) => {
            handleChangePage(-1, e);
          }}>
          ...
        </a>
      </li>,
    );
  }

  for (let pageNumber = 0; pageNumber < totalPage; pageNumber++) {
    if (pageNumber === 0 || pageNumber === totalPage - 1) continue;
    if (totalPage > maxPage) {
      if (Math.abs(currPage - (pageNumber + 1)) > 2) continue;
    }
    totalPageArray.push(
      <li
        className={pageNumber + 1 === currPage ? 'active' : ''}
        key={pageNumber}>
        <a
          href="/"
          onClick={(e) => {
            handleChangePage(pageNumber + 1, e);
          }}>
          {pageNumber + 1}
        </a>
      </li>,
    );
  }

  if (totalPage - currPage > 3 && totalPage >= maxPage) {
    totalPageArray.push(
      <li key={'last-elip'}>
        <a
          href="/"
          onClick={(e) => {
            handleChangePage(-1, e);
          }}>
          ...
        </a>
      </li>,
    );
  }

  // 最後一頁
  if (totalPage !== 1) {
    totalPageArray.push(
      <li key={'last-page'} className={totalPage === currPage ? 'active' : ''}>
        <a
          href="/"
          onClick={(e) => {
            handleChangePage(totalPage, e);
          }}>
          {totalPage}
        </a>
      </li>,
    );
  }

  let seletedPageArray = [];
  for (let pageNumber = 0; pageNumber < totalPage; pageNumber++) {
    seletedPageArray.push(
      <option key={pageNumber} value={pageNumber + 1}>
        {pageNumber + 1}
      </option>,
    );
  }

  return (
    <nav
      aria-label="Page navigation"
      style={{ display: totalPage === 0 ? 'none' : '' }}>
      <ul className="pagination">
        <li className={currPage === 1 ? 'disabled' : ''}>
          <a href="/" aria-label="Previous" onClick={handleRrePage}>
            <span aria-hidden="true">上一頁</span>
          </a>
        </li>
        {totalPageArray}
        <li className={currPage === totalPage ? 'disabled' : ''}>
          <a href="/" aria-label="Next" onClick={handleNextPage}>
            <span aria-hidden="true">下一頁</span>
          </a>
        </li>
      </ul>
      <div className="pagination selected">
        <li className={currPage === 1 ? 'disabled' : ''}>
          <a href="/" aria-label="Previous" onClick={handleRrePage}>
            <span aria-hidden="true">上一頁</span>
          </a>
        </li>
        <select
          value={currPage}
          onChange={(e) => {
            handleChangePage(e.target.value, e);
          }}>
          {seletedPageArray}
        </select>
        <li className={currPage === totalPage ? 'disabled' : ''}>
          <a href="/" aria-label="Next" onClick={handleNextPage}>
            <span aria-hidden="true">下一頁</span>
          </a>
        </li>
      </div>
    </nav>
  );
};

Pagination.propTypes = {
  currPage: PropTypes.number,
  totalPage: PropTypes.number,
  onChangPage: PropTypes.func,
};

export default Pagination;
