import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import {
  escape,
  trim,
  unescape,
  isEmpty,
  cloneDeep,
  parseInt,
  join,
} from 'lodash';
import moment from 'moment';
import BreakCrumbs from '../../components/BreakCrumbs';
import AlertBox from '../../components/AlertBox';
import '../../style/components/secret/add-secret.scss';
import { connect } from 'react-redux';
import {
  initQuestionSource,
  initHangoutTypeList,
  sendCompleteHangout,
  editHangoutStateAPI,
  InitAPIStatus,
  hangoutChapterEditName,
  DeleteHangoutChapter,
  hangoutUnitEditName,
  DeleteHangoutUnit,
  logoutSuccess,
  serverError,
  deleteHangoutUnitVideo,
  uploadUnitResource,
  deleteHangoutUnitResource,
  switchHangoutUnitFree,
  initSaleExamList,
} from '../../store/action';
import {
  hangoutChapterMaterialAddAPI,
  hangoutChapterMaterialEditAPI,
  hangoutChapterMaterialDeleteAPI,
  hangoutChapterMaterialEditOrderingAPI,
} from '../../Util/fetchMethods';
import Editor from '../../components/Editor';
import { multipartUploadS3File } from '../../Util/awsS3';
import { v4 as uuidv4 } from 'uuid';
import Map from '../../data/Map';
import { AWS_HANGOUT_POSITION } from '../../data/aws';
import Swal from 'sweetalert2';
import HangoutChapter from '../../components/Secret/HangoutChapter';
import teacherAPI from '../../middleware/teacherAPI';

const initChapter = {
  Name: '',
  HangoutId: -1,
  HangoutChapterId: -1,
  HangoutUnits: [],
  ParentId: undefined,
  ResourceList: [],
};

class EditSecret extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hangout: {
        Name: '',
        Introduction: '',
        TypeId: 1,
        Status: 2,
        Price: '',
        AreaId: '1',

        DiscountStartAt: new Date().toLocaleDateString(), //沒給就空著
        DiscountEndAt: new Date().toLocaleDateString(),
        DiscountPrice: -1, //沒有給-1

        ImageFile: '',
        ImageUrl: '',
        SubjectId: 1,
        HangoutId: -1, // 新增 -1
        TeacherId: undefined,

        HangoutChapters: [],
        renderStatus: false,
      },
      step: 1,
      IsAlertVisible: false,
      alertString: '',
      teachers: [],
    };

    this.addResource = this.addResource.bind(this);
  }

  componentDidMount() {
    const m = this;

    this.props.initQuestionSource();
    this.props.initHangoutTypeList();
    teacherAPI.getList().then((response) => {
      m.setState({
        teachers: response,
      });
    });
    this.getHangout();

    if (this.props.saleExamListState) {
      this.props.initSaleExamList('', 1, 1000000000);
    }
  }

  componentDidUpdate() {
    if (this.state.renderStatus == true) {
      this.handleSendComplete();
      this.setState({ renderStatus: false });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.SendAPIState !== -1) {
      if (nextProps.SendAPIState === true) {
        this.setState({
          IsAlertVisible: true,
          alertString:
            this.state.hangout.HangoutId === -1
              ? '已成功新增課程'
              : '已修改課程',
        });
      } else {
        this.setState({
          IsAlertVisible: true,
          alertString:
            this.state.hangout.HangoutId === -1
              ? '課程新增失敗'
              : '課程修改失敗',
        });
      }
    }
  }

  getHangout = () => {
    if (this.props.match.params.HangoutId) {
      this.props.editHangoutStateAPI(
        this.props.match.params.HangoutId,
        (result) => {
          this.setState(
            {
              hangout: {
                ...result,
                Introduction: unescape(result.Introduction),
              },
            },
            function() {
              if (!isEmpty(this.props.location.state)) {
                this.onChangeStep(this.props.location.state.step);
              }
            },
          );
        },
      );
    }
  };

  handleAlertOpen = (string) => {
    this.setState({
      IsAlertVisible: true,
      alertString: string,
    });
  };

  handleAlertClose = () => {
    if (this.props.SendAPIState !== -1) {
      this.props.InitAPIStatus();
    }

    if (this.state.hangout.HangoutId !== -1) {
      this.setState({
        IsAlertVisible: false,
      });
    } else {
      this.setState({
        IsAlertVisible: false,
      });
    }
  };

  onChangeStep = (step) => {
    if (this.state.step === step) return;
    this.setState({
      step: step,
    });
  };

  onClickUploadFile = () => {
    document.querySelector(`#uploadInput-setbg`).click();
  };

  handleSelectedImg = (evt) => {
    const file = evt.target.files.item(0);
    if (!file) return;
    let imageType = /^image\//;
    if (!imageType.test(file.type)) {
      alert('請選擇圖片！');
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', this.imageLoaded);
  };

  imageLoaded = (evt) => {
    let newObj = Object.assign({}, this.state.hangout);
    newObj.ImageUrl = evt.target.result;
    this.setState({
      hangout: newObj,
    });
  };

  handleDataChange = (type, e) => {
    let newObj = Object.assign({}, this.state.hangout);

    newObj[type] = type !== 'Introduction' ? e.target.value : e;
    this.setState({
      hangout: newObj,
    });
  };

  addChapter = () => {
    let newObj = Object.assign({}, this.state.hangout);
    let newChapter = cloneDeep(initChapter);

    newObj.HangoutChapters.push({
      ...newChapter,
      HangoutId: this.state.hangout.HangoutId,
    });

    this.setState({
      hangout: newObj,
    });
  };

  deleteChapter = (hangoutChapterId, chapterIndex, unitIndex, callback) => {
    if (hangoutChapterId !== -1) {
      this.props.DeleteHangoutChapter(hangoutChapterId, () => {
        if (callback) {
          callback();
        }

        this.removeChapter(chapterIndex, unitIndex);
      });
    } else {
      if (callback) {
        callback();
      }

      this.removeChapter(chapterIndex, unitIndex);
    }
  };

  removeChapter = (chapterIndex, unitIndex) => {
    let newHangout = cloneDeep(this.state.hangout);

    if (!unitIndex && unitIndex !== 0) {
      newHangout.HangoutChapters.splice(chapterIndex, 1);
    } else {
      newHangout.HangoutChapters[chapterIndex].HangoutUnits.splice(
        unitIndex,
        1,
      );
    }

    this.setState({
      hangout: newHangout,
    });
  };

  addUnit = (chapterIndex, chapterId, hangoutId) => {
    let newObj = Object.assign({}, this.state.hangout);
    let newChapter = cloneDeep(initChapter);

    newObj.HangoutChapters[chapterIndex].HangoutUnits.push({
      ...newChapter,
      ParentId: chapterId,
      HangoutId: hangoutId,
    });

    this.setState({
      hangout: newObj,
    });
  };

  handleInputChapterName = (formData, chapterIndex, unitIndex, callback) => {
    this.props.hangoutChapterEditName(formData, (hangoutChapterId) => {
      if (callback) {
        callback();
      }

      let newHangout = cloneDeep(this.state.hangout);

      if (!unitIndex && unitIndex !== 0) {
        newHangout.HangoutChapters[chapterIndex].Name = formData.get('Name');
      } else {
        newHangout.HangoutChapters[chapterIndex].HangoutUnits[
          unitIndex
        ].Name = formData.get('Name');
      }

      if (parseInt(formData.get('HangoutChapterId')) === -1) {
        if (!unitIndex && unitIndex !== 0) {
          newHangout.HangoutChapters[
            chapterIndex
          ].HangoutChapterId = hangoutChapterId;
        } else {
          newHangout.HangoutChapters[chapterIndex].HangoutUnits[
            unitIndex
          ].HangoutChapterId = hangoutChapterId;
        }
      }

      this.setState({
        hangout: newHangout,
      });
    });
  };

  handleCheckboxOnChange = () => {
    let newObj = Object.assign({}, this.state.hangout);
    newObj.Status = newObj.Status === 1 ? 2 : 1;
    this.setState({
      hangout: newObj,
    });
  };

  //日期管理
  handleDateChange = (type, date) => {
    let newObj = Object.assign({}, this.state.hangout);
    if (date !== null) {
      newObj[type] = date.format('YYYY/MM/DD');
      if (type === 'DiscountEndAt') {
        let start_time = newObj.DiscountStartAt;
        let end_time = newObj.DiscountEndAt;
        if (Date.parse(start_time).valueOf() > Date.parse(end_time).valueOf()) {
          alert('結束時間不能小於開始時間！');
          return;
        }
      }
      if (type === 'DiscountStartAt') {
        let start_time = newObj.DiscountStartAt;
        let end_time = newObj.DiscountEndAt;
        if (Date.parse(start_time).valueOf() > Date.parse(end_time).valueOf()) {
          alert('開始時間不能大於結束時間！');
          return;
        }
      }
      this.setState({ hangout: newObj });
    }
  };

  // 新增促銷活動
  handleAddActive = () => {
    let newObj = Object.assign({}, this.state.hangout);
    newObj.DiscountStartAt = new Date().toLocaleDateString();
    newObj.DiscountEndAt = new Date().toLocaleDateString();
    newObj.DiscountPrice = '';
    this.setState({
      hangout: newObj,
    });
  };

  // 刪除促銷活動
  handleDeleteActive = () => {
    let newObj = Object.assign({}, this.state.hangout);
    newObj.DiscountPrice = -1;
    this.setState({
      hangout: newObj,
    });
  };

  uploadVideo = (videoObj) => {
    let newObj = Object.assign({}, this.state.hangout);
    newObj.HangoutChapters.forEach((chapter) => {
      chapter.HangoutUnits.forEach((Unit) => {
        if (Unit.HangoutUnitId === videoObj.HangoutUnitId) {
          Unit.Video = videoObj.Video;
        }
      });
    });
    this.setState({
      hangout: newObj,
    });
  };

  deleteVideo = (HangoutUnitId) => {
    let newObj = Object.assign({}, this.state.hangout);
    newObj.HangoutChapters.forEach((chapter) => {
      chapter.HangoutUnits.forEach((Unit) => {
        if (Unit.HangoutUnitId === HangoutUnitId) {
          Unit.Video = null;
        }
      });
    });
    this.setState(
      {
        hangout: newObj,
      },
      () => {
        this.props.deleteHangoutUnitVideo(HangoutUnitId);
      },
    );
  };

  changeVideoOpen = (HangoutUnitId) => {
    let IsFree;
    let newObj = Object.assign({}, this.state.hangout);
    newObj.HangoutChapters.forEach((chapter) => {
      chapter.HangoutUnits.forEach((Unit) => {
        if (Unit.HangoutUnitId === HangoutUnitId) {
          Unit.IsFree = !Unit.IsFree;
          IsFree = Unit.IsFree;
        }
      });
    });
    this.setState(
      {
        hangout: newObj,
      },
      () => {
        this.props.switchHangoutUnitFree(HangoutUnitId, IsFree);
      },
    );
  };

  uploadPDF = (file, HangoutUnitId) => {
    const form = new FormData();
    form.append('HangoutUnitId', HangoutUnitId);
    form.append('ResourceFile', file);

    this.props.uploadUnitResource(form, this.setResourse);
  };

  setResourse = (ResourceObj) => {
    let newObj = Object.assign({}, this.state.hangout);
    newObj.HangoutChapters.forEach((chapter) => {
      chapter.HangoutUnits.forEach((Unit) => {
        if (Unit.HangoutUnitId === ResourceObj.HangoutUnitId) {
          Unit.Resource = ResourceObj;
        }
      });
    });
    this.setState({
      hangout: newObj,
    });
  };

  deletePDF = (HangoutUnitId) => {
    let newObj = Object.assign({}, this.state.hangout);
    newObj.HangoutChapters.forEach((chapter) => {
      chapter.HangoutUnits.forEach((Unit) => {
        if (Unit.HangoutUnitId === HangoutUnitId) {
          Unit.Resource = null;
        }
      });
    });
    this.setState(
      {
        hangout: newObj,
      },
      () => {
        this.props.deleteHangoutUnitResource(HangoutUnitId);
      },
    );
  };

  handleSendComplete = () => {
    //處理圖片
    const imgFile = document.querySelector(`#uploadInput-setbg`).files[0];
    let {
      Name,
      Introduction,
      TypeId,
      Status,
      Price,
      AreaId,
      DiscountStartAt,
      DiscountEndAt,
      DiscountPrice,
      ImageUrl,
      SubjectId,
      HangoutId,
      TeacherId,
      CourseType,
    } = this.state.hangout;

    if (trim(TeacherId) === '') {
      this.handleAlertOpen('請設定授課老師');
      return;
    }

    if (
      Price.toString().trim() === '' ||
      Name.trim() === '' ||
      Introduction.trim() === ''
    ) {
      this.handleAlertOpen('資料填寫不完全<br>請重新確認');
      return;
    }

    if (imgFile === undefined && ImageUrl === '') {
      this.handleAlertOpen('請上傳圖片!');
      return;
    }

    if (DiscountPrice !== -1 && Number(DiscountPrice) > Number(Price)) {
      this.handleAlertOpen('特價價格有誤!');
      return;
    }

    if (DiscountPrice !== -1 && DiscountPrice === '') {
      this.handleAlertOpen('請輸入促銷活動相關欄位');
      return;
    }

    // 因後端無法接受空字串 故轉成當日日期
    if (DiscountStartAt === '' || DiscountStartAt === null) {
      DiscountStartAt = new Date().toLocaleDateString();
    }

    if (DiscountEndAt === '' || DiscountEndAt === null) {
      DiscountEndAt = new Date().toLocaleDateString();
    }

    if (DiscountPrice === '' || DiscountPrice === null) {
      DiscountPrice = -1;
    }

    let formData = new FormData();
    formData.append('HangoutId', HangoutId);
    formData.append('TeacherId', TeacherId);
    formData.append('AreaId', AreaId);
    formData.append('Price', Price);
    formData.append('SubjectId', SubjectId);
    formData.append('TypeId', TypeId);
    formData.append('Name', Name);
    formData.append('Introduction', escape(Introduction));
    formData.append('Status', Status);
    formData.append('DiscountStartAt', DiscountStartAt);
    formData.append('DiscountEndAt', DiscountEndAt);
    formData.append('DiscountPrice', DiscountPrice);
    formData.append('ImageFile', imgFile);
    formData.append('CourseType', CourseType);

    this.props.sendCompleteHangout(formData, (result) => {
      this.setState({ ...this.state.hangout, result });
    });
  };

  resetChapterID = (chapterIndex, chapterId) => {
    let newObj = Object.assign({}, this.state.hangout);
    newObj.HangoutChapters[chapterIndex].HangoutChapterId = chapterId;
    this.setState({
      hangout: newObj,
    });
  };

  resetUnitID = (chapterIndex, unitIndex, UnitId) => {
    let newObj = Object.assign({}, this.state.hangout);
    newObj.HangoutChapters[chapterIndex].HangoutUnits[
      unitIndex
    ].HangoutChapterId = UnitId;
    this.setState({
      hangout: newObj,
    });
  };

  deleteResource = (hangoutChapterMaterialId) => {
    return hangoutChapterMaterialDeleteAPI(hangoutChapterMaterialId).then(
      (response) => {
        if (response.Status === 0) {
          Swal.fire({
            title: '刪除失敗',
            html: join(response.Message, '<br />'),
            icon: 'error',
          });
        } else {
          Swal.fire({
            title: '刪除成功',
            icon: 'success',
          });

          this.getHangout();
        }

        return response;
      },
    );
  };

  async addResource(resource, type, chapter, handleUploadProgress) {
    const s3FileName = uuidv4();
    const refresh = this.getHangout;

    let materialFormData = new FormData();
    materialFormData.append('HangoutChapterId', chapter.HangoutChapterId);
    materialFormData.append('Name', resource.name);
    materialFormData.append('Content', '');
    materialFormData.append('QuestionBankId', resource.questionBankId);
    materialFormData.append('MaterialType', Map.MaterialTypes[type]);
    materialFormData.append(
      'Duration',
      type === 'video' ? resource.videoNode.duration : 0,
    );

    if (type === 'pdf') {
      materialFormData.append('FileName', resource.file.name);
      materialFormData.append('FolderName', AWS_HANGOUT_POSITION[type].dir);
      materialFormData.append('BucketName', AWS_HANGOUT_POSITION[type].bucket);
      materialFormData.append('ResourceName', s3FileName);

      const s3UploadResponse = await multipartUploadS3File(resource.file, {
        bucket: AWS_HANGOUT_POSITION[type].bucket,
        dir: AWS_HANGOUT_POSITION[type].dir,
        fileName: s3FileName,
        acl: AWS_HANGOUT_POSITION[type].acl || undefined,
        uploadStart: (totalPart) => {
          if (handleUploadProgress) {
            handleUploadProgress('start', totalPart);
          }
        },
        uploadProgress: (nowPart) => {
          if (handleUploadProgress) {
            handleUploadProgress('progress', nowPart);
          }
        },
      }).then(
        (response) => {
          const result = response !== false;

          return {
            result: result,
            response: response,
            error: new Error('s3上傳失敗'),
          };
        },
        (error) => {
          return {
            result: false,
            error: error,
          };
        },
      );

      if (s3UploadResponse.result === false) {
        throw s3UploadResponse.error;
      }
    }

    if (type === 'video') {
      materialFormData.append('NodeId', resource.videoNode.nodeId);
    }

    if (type === 'assignment') {
      materialFormData.append('Description', escape(resource.description));
      materialFormData.append(
        'SubmissionCount',
        trim(resource.submissionCount) === '' ? null : resource.submissionCount,
      );
      materialFormData.append('SubmissionType', resource.submissionType);
    }

    const addResponse = await hangoutChapterMaterialAddAPI(
      materialFormData,
    ).then(
      (response) => {
        if (response.Status === 1) {
          return {
            result: true,
            data: response.Result,
          };
        }

        return {
          result: false,
          error: new Error(join(response.Message, '<br />')),
        };
      },
      (error) => {
        return {
          result: false,
          error: error,
        };
      },
    );

    if (addResponse.result === false) {
      throw addResponse.error;
    }

    return addResponse.data;
  }

  changeResource = (params) => {
    const refresh = this.getHangout;

    return hangoutChapterMaterialEditAPI(params)
      .then(async (response) => {
        if (response.Status === 1) {
          await refresh();
        } else {
          throw new Error(join(response.Message, '<br />'));
        }
      })
      .catch((error) => {
        Swal.fire({
          title: '修改失敗',
          text: error.message,
          icon: 'error',
        });
      });
  };

  changeResourceOrdering = (params) => {
    const refresh = this.getHangout;

    return hangoutChapterMaterialEditOrderingAPI(params)
      .then(() => {
        refresh();
      })
      .catch((error) => {
        Swal.fire({
          title: '修改失敗',
          text: error.msg ? error.msg : error.message,
          icon: 'error',
        });
      });
  };

  get chapterNum() {
    if (this.state.TotalChapterCount) {
      return this.state.TotalChapterCount;
    }
    return this.state.hangout.HangoutChapters.length;
  }

  render() {
    const { hangout, teachers } = this.state;
    const { HangoutChapters = [] } = hangout;
    const {
      questionSourceState,
      hangoutTypeState,
      hangoutChapterEditName,
      hangoutUnitEditName,
    } = this.props;
    const isShowChapter =
      HangoutChapters.length === 0
        ? true
        : HangoutChapters[HangoutChapters.length - 1].HangoutChapterId === -1
        ? false
        : true;

    const chapterLayout =
      HangoutChapters.length > 0
        ? HangoutChapters.map((item, index) => (
            <HangoutChapter
              key={item.HangoutChapterId}
              hangoutId={hangout.HangoutId}
              chapter={item}
              chapterIndex={index}
              handleInputChapterName={this.handleInputChapterName}
              deleteChapter={this.deleteChapter}
              addUnit={this.addUnit}
              logoutSuccess={this.props.logoutSuccess}
              serverError={this.props.serverError}
              addResource={this.addResource}
              deleteResource={this.deleteResource}
              changeResource={this.changeResource}
              changeResourceOrdering={this.changeResourceOrdering}
              refresh={this.getHangout}
            />
          ))
        : null;

    return (
      <div className="add-secret fadeIn">
        <BreakCrumbs path={this.props.match.url} />
        <div className="tag-group">
          <div
            className={this.state.step === 1 ? 'tag active' : 'tag'}
            onClick={() => {
              this.onChangeStep(1);
            }}>
            基本資料
          </div>
          <div
            className={this.state.step === 2 ? 'tag active' : 'tag'}
            style={{
              display: this.state.hangout.HangoutId === -1 ? 'none' : '',
            }}
            onClick={() => {
              this.onChangeStep(2);
            }}>
            課程內容
          </div>
        </div>
        <div
          className="block fadeIn"
          style={{
            display: this.state.step === 1 ? 'block' : 'none',
          }}>
          <div className="title">基本資料</div>
          <div className="form-groups row">
            <div className="col-9 col-pad-8 col-phone-12 form-left">
              <div className="row">
                <div className="col-6 col-pad-12 col-phone-12">
                  <label className="secret--input">銷售地區</label>
                  <select
                    name="AreaId"
                    className="secret--input"
                    value={hangout['AreaId']}
                    onChange={(e) => {
                      this.handleDataChange('AreaId', e);
                    }}>
                    {questionSourceState.questionBankCurrency.map((item) => (
                      <option key={item.Id} value={item.Id}>
                        {item.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-6 col-pad-12 col-phone-12">
                  <label className="secret--input">價格</label>
                  <input
                    name="Price"
                    className="secret--input"
                    type="text"
                    value={hangout['Price']}
                    onChange={(e) => {
                      this.handleDataChange('Price', e);
                    }}
                  />
                </div>
                <div className="col-6 col-pad-12 col-phone-12">
                  <label className="secret--input">影片類別</label>
                  <select
                    name="TypeId"
                    className="secret--input"
                    value={hangout['TypeId']}
                    onChange={(e) => {
                      this.handleDataChange('TypeId', e);
                    }}>
                    {hangoutTypeState.map((item) => (
                      <option key={item.Id} value={item.Id}>
                        {item.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-6 col-pad-12 col-phone-12">
                  <label className="secret--input">科目</label>
                  <select
                    name="SubjectId"
                    className="secret--input"
                    value={hangout['SubjectId']}
                    onChange={(e) => {
                      this.handleDataChange('SubjectId', e);
                    }}>
                    {questionSourceState.questionBankSubject.map((item) => (
                      <option key={item.Id} value={item.Id}>
                        {item.Name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-pad-12">
                  <label className="secret--input">授課老師</label>
                  <select
                    name="TeacherId"
                    className="secret--input"
                    placeholder="請選擇授課老師"
                    value={hangout['TeacherId'] || 0}
                    onChange={(e) => {
                      this.handleDataChange('TeacherId', e);
                    }}>
                    <option label=" "></option>
                    {teachers.map((item) => (
                      <option key={item.teacherId} value={item.teacherId}>
                        [{item.status === 0 ? '下線' : '上線'}] - {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-6 col-pad-12">
                  <label className="secret--input">課程主題數</label>
                  <input
                    name="Name"
                    className="secret--input"
                    type="text"
                    value={this.chapterNum}
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label className="secret--input">課程類型</label>
                  <select
                    name="CourseType"
                    className="secret--input"
                    value={hangout['CourseType']}
                    disabled>
                    <option value={1}>課程</option>
                    <option value={2}>師培課程</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label className="secret--input">課程名稱</label>
                  <input
                    name="Name"
                    className="secret--input"
                    type="text"
                    value={hangout['Name']}
                    onChange={(e) => {
                      this.handleDataChange('Name', e);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12" style={{ margin: 0 }}>
                  <label className="secret--input">課程介紹</label>
                  <Editor
                    content={hangout['Introduction']}
                    setContent={(newContent) => {
                      this.handleDataChange('Introduction', newContent);
                    }}
                    imageS3Position={{
                      bucket: 'questionbank-resource',
                      dir: 'HangoutPic/Introduction',
                      acl: 'public-read',
                    }}
                    editorProps={{
                      height: 400,
                      image_dimensions: false,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-3 col-pad-4 col-phone-12 form-right">
              {hangout.ImageUrl === '' ? (
                <div className="upload-btn" onClick={this.onClickUploadFile}>
                  <i className="fas fa-plus-circle"></i>
                  上傳圖片
                </div>
              ) : (
                <div
                  id="image-bg"
                  className="image"
                  style={{
                    backgroundImage: `url(${hangout.ImageUrl})`,
                  }}
                  onClick={this.onClickUploadFile}
                />
              )}
              <div className="notice">檔案格式為png, jpeg, jpg, 770×437px</div>
              <input
                id={`uploadInput-setbg`}
                className="secret--input"
                type="file"
                onChange={this.handleSelectedImg}
              />
            </div>
            <div className="row">
              <div className="col-6 col-pad-12 col-phone-12 status-col">
                <label className="secret--input">上架狀態</label>
                <div className="status-box">
                  <input
                    id={`cb123`}
                    className="secret--input"
                    type="checkbox"
                    checked={hangout.Status === 1 ? true : false}
                    onChange={() => {
                      this.handleCheckboxOnChange();
                    }}
                  />
                  <label className="secret--input" htmlFor={`cb123`}></label>
                </div>
              </div>
            </div>
          </div>
          <div className="title">
            促銷活動
            <button className="btn save" onClick={this.handleAddActive}>
              <i className="fas fa-plus"></i>新增促銷活動
            </button>
          </div>
          <div className="row">
            <div
              className={
                Number(hangout.DiscountPrice) === -1 ||
                hangout.DiscountPrice === null
                  ? 'activity-box'
                  : 'col-12 activity-box active'
              }>
              <div className="form-groups row">
                <div className="col-6 col-pad-12 activity-time">
                  <label className="secret--input">活動期間</label>
                  <div className="date-interval">
                    <DatePicker
                      className="secret--input"
                      locale="zh-tw"
                      dateFormat="YYYY/MM/DD"
                      selected={moment(hangout.DiscountStartAt, [
                        'MM-DD-YYYY',
                        'YYYY-MM-DD',
                      ])}
                      onChange={(date) => {
                        this.handleDateChange('DiscountStartAt', date);
                      }}
                    />
                    <div className="line"></div>
                    <DatePicker
                      className="secret--input"
                      locale="zh-tw"
                      dateFormat="YYYY/MM/DD"
                      selected={moment(hangout.DiscountEndAt, [
                        'MM-DD-YYYY',
                        'YYYY-MM-DD',
                      ])}
                      onChange={(date) => {
                        this.handleDateChange('DiscountEndAt', date);
                      }}
                    />
                  </div>
                </div>
                <div className="col-6 col-pad-12 price-box">
                  <label className="secret--input">優惠價</label>
                  <input
                    name="DiscountPrice"
                    className="secret--input"
                    type="text"
                    value={
                      hangout['DiscountPrice'] === null
                        ? -1
                        : hangout['DiscountPrice']
                    }
                    onChange={(e) => {
                      this.handleDataChange('DiscountPrice', e);
                    }}
                  />
                </div>
              </div>
              <div className="btn-group">
                <button
                  onClick={this.handleDeleteActive}
                  className="btn delete">
                  刪除
                </button>
              </div>
            </div>
          </div>
          <div className="btn-group">
            <button
              className="btn save"
              onClick={() => {
                this.handleSendComplete.bind(this);
                this.setState({ renderStatus: true });
              }}
              disabled={this.props.loading}>
              儲存
            </button>
          </div>
        </div>
        <div
          className="block fadeIn"
          style={{
            display: this.state.step === 2 ? 'block' : 'none',
          }}>
          <div className="title">課程內容</div>
          <div className="hangout-content">
            <div className="form-groups row" style={{ margin: '0px' }}>
              <div className="col-12 col-phone-12 form-left">
                <div className="row">{chapterLayout}</div>
                <div className="row" style={{ margin: '0px' }}>
                  <button
                    className="btn save add-chapter"
                    style={{
                      display: isShowChapter ? 'block' : 'none',
                    }}
                    onClick={this.addChapter}>
                    新增章
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AlertBox
          isVisible={this.state.IsAlertVisible}
          content={this.state.alertString}
          closebox={this.handleAlertClose}
        />
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  questionSourceState: store.QuestionSourceState,
  hangoutTypeState: store.HangoutTypeState,
  SendAPIState: store.SendAPIStatusState,
  saleExamListState: store.SaleExamListState,
  loading: store.loading,
});

export default connect(mapStateToProps, {
  initQuestionSource,
  initSaleExamList,
  initHangoutTypeList,
  sendCompleteHangout,
  editHangoutStateAPI,
  InitAPIStatus,
  hangoutChapterEditName,
  DeleteHangoutChapter,
  hangoutUnitEditName,
  DeleteHangoutUnit,
  logoutSuccess,
  serverError,
  deleteHangoutUnitVideo,
  uploadUnitResource,
  deleteHangoutUnitResource,
  switchHangoutUnitFree,
})(EditSecret);
