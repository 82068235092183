import React from 'react';
import { QRCode } from 'react-qrcode-logo';

const QRCodeImg = (props) => {
  const { qrCodeValue } = props;

  return <QRCode value={qrCodeValue} size={100} padding={0} />;
};

export default QRCodeImg;
