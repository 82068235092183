import { useState, useEffect, useMemo } from 'react';
import { getSuitExam } from '../Util/fetchMethods';
import _ from 'lodash';

const useSuitExam = (suitExamId) => {
  const initSuitExam = useMemo(() => {
    return {
      QuestionBankSet: {
        QuestionBankSetId: -1,
        Status: 1,
        Price: '',
        AreaId: 1,
        Title: '',
        Description: '',
        CreateAt: '',
        Count: '',
        HasBuy: false,
      },
      QuestionBankList: [],
      HangoutList: [],
      HasBuy: false,
    };
  }, []);

  const [suitExam, setSuitExam] = useState({ ...initSuitExam });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (suitExamId) {
      setLoading(true);

      getSuitExam(suitExamId)
        .then((response) => {
          if (response.Status === 0) {
            throw new Error(_.join(response.Message, '<br>'));
          } else {
            setSuitExam(response.Result);
          }
        })
        .catch((err) => {
          setError(err.message);
        })
        .then(() => {
          setLoading(false);
        });
    }

    return () => {
      setSuitExam({ ...initSuitExam });
      setLoading(false);
      setError('');
    };
  }, [suitExamId]);

  return {
    suitExam,
    loading,
    error,
    initSuitExam,
  };
};

export default useSuitExam;
