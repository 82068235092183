import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import ImageFileUpload from '../../components/Forms/ImageFileUpload';
import DateInput from '../../components/Forms/DateInput';
import DeleteDialog from '../../components/Dialogs/DeleteDialog';
import {
  Container,
  TextField,
  Button,
  Box,
  Divider,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';
import TvOffOutlinedIcon from '@material-ui/icons/TvOffOutlined';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import {
  addAPIBanner,
  editAPIBanner,
  deleteAPIBanner,
  switchHomeBannerDisplay,
} from '../../store/action';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

function CarouselForm({ Banner, indexItem, nowShow }) {
  const [banner, setBanner] = useState(Banner);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [isSetDisplay, setIsSetDisplay] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const imageFileInputRef = useRef(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const snackbarConfig = {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    variant: 'success',
  };

  useEffect(() => {
    setBanner(Banner);
  }, [Banner]);

  const handleLink = (e) => {
    setBanner({ ...banner, Hyperlink: e.target.value });
  };

  const handleDeleteBanner = () => {
    let formData = new FormData();
    formData.append('BannerId', banner.Id);
    dispatch(deleteAPIBanner(formData));
    setIsConfirmVisible(false);
  };

  const handleSetDisplay = () => {
    setIsSetDisplay(true);

    let formData = new FormData();
    formData.append('BannerId', banner.Id);
    formData.append('IsDisplay', !banner.IsDisplay);

    dispatch(switchHomeBannerDisplay(formData)).then((response) => {
      if (response.Status == 1) {
        setBanner({ ...banner, IsDisplay: !banner.IsDisplay });
        enqueueSnackbar('設定成功', snackbarConfig);
      } else {
        enqueueSnackbar('設定失敗', {
          ...snackbarConfig,
          variant: 'error',
        });
      }

      setIsSetDisplay(false);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let start = new Date(banner.StartAt);
    let end = new Date(banner.EndAt);

    if (end < start) {
      Swal.fire('錯誤', '結束時間不可小於開始時間', 'error');
      return;
    }

    //處理圖片
    const imgFile = imageFileInputRef.current.files[0];

    let formData = new FormData();
    formData.append('Hyperlink', banner.Hyperlink);
    formData.append('StartAt', banner.StartAt);
    formData.append('EndAt', banner.EndAt);
    formData.append('ImageFile', imgFile);

    if (banner.Hyperlink.trim() === '') {
      Swal.fire('錯誤', '請輸入連結', 'error');

      return;
    }

    setIsSubmit(true);

    if (banner.newItem) {
      // 新增廣告
      if (!imgFile) {
        Swal.fire('錯誤', '請上傳圖片', 'error');
        return;
      }

      formData.append('SiteCode', 2);
      dispatch(addAPIBanner(formData)).then((response) => {
        setIsSubmit(false);
      });
    } else {
      // 編輯廣告
      formData.append('BannerId', banner.Id);
      dispatch(editAPIBanner(formData)).then((response) => {
        setIsSubmit(false);
      });
    }
  };

  const openDeleteDialog = () => {
    setIsConfirmVisible(true);
  };

  return (
    <Container maxWidth="lg">
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        style={{ display: indexItem === nowShow ? 'block' : 'none' }}>
        <Box display="inline-block">
          <Typography variant="h4" gutterBottom>
            {!banner.newItem ? (
              banner.IsDisplay ? (
                <>
                  <WbIncandescentOutlinedIcon
                    color="primary"
                    style={{ marginRight: '16px' }}
                  />
                  狀態：上線
                </>
              ) : (
                <>
                  <TvOffOutlinedIcon style={{ marginRight: '16px' }} />
                  狀態：下線
                </>
              )
            ) : (
              <>
                <AddPhotoAlternateOutlinedIcon
                  style={{ marginRight: '16px' }}
                />
                新增廣告
              </>
            )}
          </Typography>
        </Box>
        {!banner.newItem ? (
          <Box
            display="inline-block"
            ml={3}
            style={{ verticalAlign: 'text-bottom' }}>
            {banner.IsDisplay ? (
              <Button
                variant="contained"
                color="default"
                startIcon={<TvOffOutlinedIcon />}
                size="small"
                disabled={isSetDisplay}
                onClick={handleSetDisplay}>
                設定下線
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                startIcon={<WbIncandescentOutlinedIcon />}
                size="small"
                disabled={isSetDisplay}
                onClick={handleSetDisplay}>
                設定上線
              </Button>
            )}
            <Box display="inline-block" ml={2}>
              {isSetDisplay && <CircularProgress size={24} />}
            </Box>
          </Box>
        ) : null}
        <Divider />
        <ImageFileUpload
          helper="檔案格式為png, jpeg, jpg, 1920x500pix"
          imageUrl={banner.ImageUrl}
          imageFileInputRef={imageFileInputRef}
        />

        <TextField
          label="頁面鏈結"
          fullWidth
          margin="normal"
          value={banner.Hyperlink}
          onChange={handleLink}
        />

        <DateInput
          label="開始時間"
          date={banner.StartAt}
          setDate={(date) => {
            setBanner({ ...banner, StartAt: date });
          }}
        />

        <DateInput
          label="結束時間"
          date={banner.EndAt}
          setDate={(date) => {
            setBanner({ ...banner, EndAt: date });
          }}
        />

        <Box mt={3}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveOutlinedIcon />}
            disabled={isSubmit}
            type="submit">
            儲存
          </Button>

          {!banner.newItem ? (
            <Box ml={3} display="inline">
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteOutlinedIcon />}
                onClick={openDeleteDialog}
                disabled={isSubmit}>
                刪除
              </Button>
            </Box>
          ) : null}
        </Box>

        <DeleteDialog
          open={isConfirmVisible}
          setOpen={setIsConfirmVisible}
          action={handleDeleteBanner}
          title="確定刪除廣告嗎？">
          刪除後無法復原，請確認是否繼續？
        </DeleteDialog>
      </form>
    </Container>
  );
}

CarouselForm.propTypes = {
  Banner: PropTypes.object,
  nowShow: PropTypes.number,
  indexItem: PropTypes.number,
};

export default CarouselForm;
