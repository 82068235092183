import { loginAPI } from '../store/apiconfig';
import instance from './APIAuth';

const authApi = {
  refreshToken: (token) => {
    const params = new URLSearchParams();

    params.append('grant_type', 'refresh_token');
    params.append('refresh_token', token);

    return instance.post('', params);
  },
};

export default authApi;
