import { Skeleton } from '@material-ui/lab';
import React from 'react';
import ReferralCodeCard from './ReferralCodeCard';
import TableEmpty from '../TableEmpty';

const ReferralCodeList = (props) => {
  const {
    referralCodes,
    isLoading,
    setEditReferralCode,
    deleteReferralCode,
  } = props;

  if (isLoading) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  return (
    <div>
      {referralCodes.length > 0 &&
        referralCodes.map((referralCode, index) => {
          return (
            <ReferralCodeCard
              key={index}
              referralCode={referralCode}
              isLoading={isLoading}
              setEditReferralCode={setEditReferralCode}
              deleteReferralCode={deleteReferralCode}
            />
          );
        })}

      {referralCodes.length <= 0 && <TableEmpty />}
    </div>
  );
};

export default ReferralCodeList;
