import { useState } from 'react';
import {
  getBanners as apiGetBanners,
  setBannerDisplay,
  createBanner as apiCreateBanner,
  editBanner as apiEditBanner,
  deleteBanner as apiDeleteBanner,
} from '../Util/fetchMethods';

const useBanners = (siteCode) => {
  const [banners, setBanners] = useState();

  const getBanners = () => {
    return apiGetBanners(siteCode).then((res) => {
      setBanners(res.Result);
    });
  };

  const setDisplay = (banner) => {
    let formData = new FormData();

    formData.append('BannerId', banner.Id);
    formData.append('IsDisplay', !banner.IsDisplay);

    return setBannerDisplay(formData);
  };

  const deleteBanner = (banner) => {
    let formData = new FormData();

    formData.append('BannerId', banner.Id);

    return apiDeleteBanner(formData);
  };

  const createBanner = (formData) => {
    return apiCreateBanner(formData);
  };

  const editBanner = (formData) => {
    return apiEditBanner(formData);
  };

  return {
    getBanners,
    setDisplay,
    deleteBanner,
    createBanner,
    editBanner,
    banners,
  };
};

export default useBanners;
