import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Box, Chip } from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import clsx from 'clsx';
import MathJaxAndImgContent from '../Question/MathJaxAndImgContent';
import Knowledges from '../Question/Knowledges';
import AlertRepeatQuestions from './AlertRepeatQuestions';

const useStyles = makeStyles((theme) => ({
  examQuestionSet: {
    minHeight: '80px',
    width: '95%',
    border: `2px dashed ${theme.palette.grey[300]}`,
    borderRadius: '8px',
    margin: `0 auto ${theme.spacing(4)}px`,
    position: 'relative',
    '& .empty': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: 'auto',
      width: '200px',
      height: '45px',
      fontSize: '2rem',
      fontWeight: 600,
      color: theme.palette.grey[300],
    },
    '& .tag': {
      position: 'absolute',
      top: theme.spacing(1),
      left: theme.spacing(1),
    },
    '& .question': {
      margin: `${theme.spacing(5)}px ${theme.spacing(2)}px`,
      color: theme.palette.text.primary,
    },
  },
  alreadySet: {
    borderStyle: 'solid',
    backgroundColor: theme.palette.grey[50],
  },
  chipRoot: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.common.black,
  },
  chipColorPrimary: {
    color: theme.palette.common.white,
    backgroundColor: '#3f51b5',
  },
}));

/**
 * START component
 * !! droppableId 在同一頁面時，不能有重複的名稱
 * @param {*} props
 */
const ExamQuestionSetting = (props) => {
  const classes = useStyles();

  let BoxClassName =
    props.substance === ''
      ? classes.examQuestionSet
      : clsx(classes.examQuestionSet, classes.alreadySet);

  return (
    <Droppable droppableId={props.droppableId} ignoreContainerClipping>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          className={BoxClassName}
          style={snapshot.isDraggingOver ? { backgroundColor: '#eee' } : null}
          {...provided.droppableProps}>
          {_.trim(props.substance) === '' ? (
            <Box className="empty">
              <ArrowDropDownOutlinedIcon /> 請設定題目
            </Box>
          ) : (
            <Box className="question">
              {props.checkRepeatResult &&
              props.checkRepeatResult.isQuestionExistExam ? (
                <AlertRepeatQuestions
                  existPositions={props.checkRepeatResult.existPositions}
                />
              ) : null}
              <MathJaxAndImgContent
                substance={
                  props.subject === '13' && props.substance > 150
                    ? props.substance.substr(0, 150) + '...'
                    : props.substance
                }
              />
              {_.isObject(props.question) && (
                <Knowledges questions={props.question} />
              )}
            </Box>
          )}

          <Box className="tag">
            <Chip
              size="small"
              label={props.label}
              variant={props.substance === '' ? 'outlined' : 'default'}
              onDelete={props.substance === '' ? null : props.removeAction}
              color={props.substance === '' ? 'default' : 'primary'}
              classes={{
                root: classes.chipRoot,
                colorPrimary: classes.chipColorPrimary,
              }}
            />
          </Box>
          <Box display="none">{provided.placeholder}</Box>
        </Box>
      )}
    </Droppable>
  );
};

export default ExamQuestionSetting;
