import React, { useState, useRef, useEffect } from 'react';
import Swal from 'sweetalert2';
import ImageFileUpload from '../../components/Forms/ImageFileUpload';
import DateInput from '../../components/Forms/DateInput';
import DeleteDialog from '../../components/Dialogs/DeleteDialog';
import {
  Container,
  TextField,
  Button,
  Box,
  Divider,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';
import TvOffOutlinedIcon from '@material-ui/icons/TvOffOutlined';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import { useSnackbar } from 'notistack';

const defaultBanner = {
  Id: -1,
  ImageUrl: '',
  Title: '',
  SubTitle: '',
  Hyperlink: '',
  StartAt: new Date().toLocaleDateString(),
  EndAt: new Date().toLocaleDateString(),
  IsDisplay: false,
  FileUpload: '',
};

const snackbarConfig = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'success',
};

const Banner = (props) => {
  const {
    banner,
    setDisplay,
    refreshBanners,
    deleteBanner,
    siteCode,
    createBanner,
    editBanner,
  } = props;
  const [bannerForm, setBannerForm] = useState(defaultBanner);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const imageFileInputRef = useRef();
  const isCreate = bannerForm.Id === -1;

  useEffect(() => {
    setBannerForm(banner ? banner : { ...defaultBanner });
  }, [banner]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let start = new Date(bannerForm.StartAt);
    let end = new Date(bannerForm.EndAt);

    if (end < start) {
      Swal.fire('錯誤', '結束時間不可小於開始時間', 'error');
      return;
    }

    //處理圖片
    const imgFile = imageFileInputRef.current.files[0];

    let formData = new FormData();
    formData.append('Hyperlink', bannerForm.Hyperlink);
    formData.append('StartAt', bannerForm.StartAt);
    formData.append('EndAt', bannerForm.EndAt);
    formData.append('ImageFile', imgFile);

    if (bannerForm.Hyperlink.trim() === '') {
      Swal.fire('錯誤', '請輸入連結', 'error');

      return;
    }

    setIsLoading(true);

    if (isCreate) {
      // 新增廣告
      if (!imgFile) {
        Swal.fire('錯誤', '請上傳圖片', 'error');
        return;
      }

      formData.append('SiteCode', siteCode);

      createBanner(formData)
        .then(() => {
          refreshBanners();
        })
        .then(() => {
          setIsLoading(false);
        });
    } else {
      // 編輯廣告
      formData.append('BannerId', bannerForm.Id);

      editBanner(formData)
        .then(() => {
          refreshBanners();
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  };

  const handleSetDisplay = () => {
    setIsLoading(true);

    setDisplay(bannerForm)
      .then((res) => {
        if (res.Status == 1) {
          enqueueSnackbar('設定成功', snackbarConfig);
          refreshBanners();
        } else {
          enqueueSnackbar('設定失敗', {
            ...snackbarConfig,
            variant: 'error',
          });
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const handleLink = (e) => {
    setBannerForm({
      ...bannerForm,
      Hyperlink: e.target.value,
    });
  };

  const openDeleteDialog = () => {
    setIsOpenDeleteDialog(true);
  };

  const handleDeleteBanner = () => {
    deleteBanner(bannerForm).then(() => {
      setIsOpenDeleteDialog(false);
      refreshBanners();
    });
  };

  return (
    <Container maxWidth="lg">
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        encType="multipart/form-data">
        <Box display="inline-block">
          <Typography variant="h4" gutterBottom>
            {!isCreate ? (
              bannerForm.IsDisplay ? (
                <>
                  <WbIncandescentOutlinedIcon
                    color="primary"
                    style={{ marginRight: '16px' }}
                  />
                  狀態：上線
                </>
              ) : (
                <>
                  <TvOffOutlinedIcon style={{ marginRight: '16px' }} />
                  狀態：下線
                </>
              )
            ) : (
              <>
                <AddPhotoAlternateOutlinedIcon
                  style={{ marginRight: '16px' }}
                />
                新增廣告
              </>
            )}
          </Typography>
        </Box>
        {!isCreate ? (
          <Box
            display="inline-block"
            ml={3}
            style={{ verticalAlign: 'text-bottom' }}>
            {bannerForm.IsDisplay ? (
              <Button
                variant="contained"
                color="default"
                startIcon={<TvOffOutlinedIcon />}
                size="small"
                disabled={isLoading}
                onClick={handleSetDisplay}>
                設定下線
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                startIcon={<WbIncandescentOutlinedIcon />}
                size="small"
                disabled={isLoading}
                onClick={handleSetDisplay}>
                設定上線
              </Button>
            )}
            <Box display="inline-block" ml={2}>
              {isLoading && <CircularProgress size={24} />}
            </Box>
          </Box>
        ) : null}

        <Divider />

        <ImageFileUpload
          helper="檔案格式為png, jpeg, jpg, 1920x1000pix"
          imageUrl={bannerForm.ImageUrl}
          imageFileInputRef={imageFileInputRef}
        />

        <TextField
          label="頁面鏈結"
          fullWidth
          margin="normal"
          value={bannerForm.Hyperlink}
          onChange={handleLink}
        />

        <DateInput
          label="開始時間"
          date={bannerForm.StartAt}
          setDate={(date) => {
            setBannerForm({ ...bannerForm, StartAt: date });
          }}
        />

        <DateInput
          label="結束時間"
          date={bannerForm.EndAt}
          setDate={(date) => {
            setBannerForm({ ...bannerForm, EndAt: date });
          }}
        />

        <Box mt={3}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveOutlinedIcon />}
            disabled={isLoading}
            type="submit">
            儲存
          </Button>

          {!isCreate ? (
            <Box ml={3} display="inline">
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteOutlinedIcon />}
                onClick={openDeleteDialog}
                disabled={isLoading}>
                刪除
              </Button>
            </Box>
          ) : null}
        </Box>

        <DeleteDialog
          open={isOpenDeleteDialog}
          setOpen={setIsOpenDeleteDialog}
          action={handleDeleteBanner}
          title="確定刪除廣告嗎？">
          刪除後無法復原，請確認是否繼續？
        </DeleteDialog>
      </form>
    </Container>
  );
};

export default Banner;
