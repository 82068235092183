import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Divider,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import _ from 'lodash';

const SuitExamSetting = (props) => {
  return (
    <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
      <Typography variant="h5" color="textPrimary" gutterBottom>
        套卷編輯
      </Typography>
      <Divider light />
      <Box p={3}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="flex-start"
          spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="套裝名稱"
              fullWidth
              autoFocus
              disabled={!props.isReady}
              value={
                props.suitExamSetting.Title ? props.suitExamSetting.Title : ''
              }
              onChange={(e) => {
                props.handleSuitExamSettingChange('Title', e);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormControl fullWidth disabled={!props.isReady}>
              <InputLabel>地區</InputLabel>
              <Select
                value={
                  props.suitExamSetting.AreaId
                    ? props.suitExamSetting.AreaId
                    : ''
                }
                onChange={(e) => {
                  props.handleSuitExamSettingChange('AreaId', e);
                }}>
                {_.map(props.saleArea, (saleArea) => {
                  return (
                    <MenuItem key={saleArea.Id} value={saleArea.Id}>
                      {saleArea.Name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              label="價格"
              fullWidth
              disabled={!props.isReady}
              value={
                props.suitExamSetting.Price ? props.suitExamSetting.Price : ''
              }
              onChange={(e) => {
                props.handleSuitExamSettingChange('Price', e);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2} style={{ textAlign: 'center' }}>
            <FormControlLabel
              value="1"
              disabled={!props.isReady}
              control={<Switch color="primary" />}
              label="狀態"
              labelPlacement="top"
              checked={props.suitExamSetting.Status === 1}
              onChange={(e) => {
                props.handleSuitExamSettingChange('Status', e);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="簡介"
              multiline
              fullWidth
              rows={5}
              disabled={!props.isReady}
              variant="outlined"
              value={
                props.suitExamSetting.Description
                  ? props.suitExamSetting.Description
                  : ''
              }
              onChange={(e) => {
                props.handleSuitExamSettingChange('Description', e);
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SuitExamSetting;
