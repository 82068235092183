import React, { useState, useEffect, useRef } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
  Drawer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TableLoading from '../../components/TableLoading';
import bonusAPI from '../../middleware/bonusAPI';
import { alertError } from '../../Util/Util';
import TableEmpty from '../../components/TableEmpty';
import BonusesTable from '../../components/Bonus/BonusesTable';
import {
  BonusAuditStatus,
  BonusAuditStatusText,
  BonusStatus,
  BonusStatusText,
  LIST_PAGE_COUNT,
} from '../../data/common';
import Swal from 'sweetalert2';
import { trim, find, map, filter } from 'lodash';
import BonusView from '../../components/Bonus/BonusView';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import AuditResultModal from '../../components/Bonus/AuditResultModal';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';

const AuditList = (props) => {
  const { match } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [auditBonuses, setAuditBonuses] = useState();
  const [bonusDetail, setBonusDetail] = useState();
  const [pageNum, setPageNum] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [bonusStatus, setBonusStatus] = useState('');
  const [bonusAudit, setBonusAudit] = useState('');
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [auditResult, setAuditResult] = useState();
  const csvFileInputRef = useRef();
  const [isOpenAuditResult, setIsOpenAuditResult] = useState(false);
  const [touchRefresh, setTouchRefresh] = useState(false);
  const [csvType, setCsvType] = useState();

  useEffect(() => {
    getAuditBonuses();
  }, [pageNum, touchRefresh]);

  useEffect(() => {
    if (auditResult) {
      setIsOpenAuditResult(true);
    }
  }, [auditResult]);

  const closeAuditResult = () => {
    setIsOpenAuditResult(false);
  };

  const getAuditBonuses = () => {
    setIsLoading(true);

    const startAt =
      trim(startDate) !== '' ? moment(startDate).format('YYYY-MM-DD') : '';

    const endAt =
      trim(endDate) !== '' ? moment(endDate).format('YYYY-MM-DD') : '';

    const auditStatus = bonusAudit
      ? bonusAudit
      : [
          BonusAuditStatus.ERROR,
          BonusAuditStatus.NORMAL,
          BonusAuditStatus.SOLVED,
        ].join(',');

    bonusAPI
      .getBonus(
        keyword,
        pageNum,
        LIST_PAGE_COUNT,
        auditStatus,
        bonusStatus,
        undefined,
        startAt,
        endAt,
      )
      .then(
        (res) => {
          if (res.totalCount <= 0) {
            setAuditBonuses(undefined);
          } else {
            setAuditBonuses(res);
          }
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const handleKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const handleChangeStartDate = (e) => {
    setStartDate(e);
  };

  const handleChangeEndDate = (e) => {
    setEndDate(e);
  };

  const handleSearch = () => {
    setPageNum(1);

    if (pageNum === 1) {
      getAuditBonuses();
    }
  };

  const refreshAuditBounses = () => {
    setKeyword('');
    setBonusStatus('');
    setBonusAudit('');
    setPageNum(1);
    setTouchRefresh(!touchRefresh);
  };

  const handleCloseDetail = () => {
    setIsOpenDetail(false);
  };

  const handleChangeStatus = (e) => {
    setBonusStatus(e.target.value);
  };

  const handleChangeAuditStatus = (e) => {
    setBonusAudit(e.target.value);
  };

  const viewBonus = (bonusId) => {
    const findBonus = find(auditBonuses.historyList, (bonus) => {
      return bonus.id === bonusId;
    });
    setBonusDetail(findBonus);
    setIsOpenDetail(true);
  };

  const deleteBonus = (bonusId) => {
    Swal.fire({
      title: '刪除紅利點數',
      input: 'textarea',
      inputPlaceholder: '輸入刪除原因',
      inputAttributes: {
        'aria-label': '輸入刪除原因',
      },
      inputValidator: (value) => {
        if (trim(value) === '') {
          return '請輸入刪除原因!!';
        }

        if (value.length > 200) {
          return '長度不能超過200個字元';
        }
      },
      showCancelButton: true,
      confirmButtonText: '刪除',
      confirmButtonColor: 'red',
      cancelButtonText: '取消',
    }).then((res) => {
      if (res.isConfirmed) {
        setIsLoading(true);

        bonusAPI
          .deleteBonus(bonusId, res.value)
          .then(
            () => {
              Swal.fire({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                title: '刪除成功',
                icon: 'success',
              });
              getAuditBonuses();
            },
            (error) => {
              alertError(error);
            },
          )
          .then(() => {
            setIsLoading(false);
          });
      }
    });
  };

  const handleUploadRedeemCsv = () => {
    setCsvType('redeem');
    csvFileInputRef.current.click();
  };

  const handleUploadRewardCsv = () => {
    setCsvType('reward');
    csvFileInputRef.current.click();
  };

  const handleCsvFileChange = (e) => {
    const uploadFile = e.target.files[0];

    uploadCsvFile(uploadFile);
  };

  const uploadCsvFile = (file) => {
    setIsLoading(true);

    switch (csvType) {
      case 'reward':
        bonusAPI
          .uploadAuditRewardCsv(file)
          .then(
            (res) => {
              setAuditResult({
                ...res,
                fileName: file.name,
              });
              refreshAuditBounses();
            },
            (error) => {
              alertError(error);
              setIsLoading(false);
            },
          )
          .then(() => {
            csvFileInputRef.current.value = '';
          });
        break;

      case 'redeem':
        bonusAPI
          .uploadAuditRedeemCsv(file)
          .then(
            (res) => {
              setAuditResult({
                ...res,
                fileName: file.name,
              });
              refreshAuditBounses();
            },
            (error) => {
              alertError(error);
              setIsLoading(false);
            },
          )
          .then(() => {
            csvFileInputRef.current.value = '';
          });
        break;

      default:
        Swal.fire({
          title: '無法判斷 CSV 類型',
          icon: 'error',
        });
        break;
    }
  };

  const auditBonus = (bonusId) => {
    Swal.fire({
      title: '稽核紅利點數',
      input: 'textarea',
      inputPlaceholder: '輸入稽核處理記錄',
      inputAttributes: {
        'aria-label': '輸入稽核處理記錄',
      },
      inputValidator: (value) => {
        if (trim(value) === '') {
          return '輸入稽核處理記錄!!';
        }

        if (value.length > 200) {
          return '長度不能超過200個字元';
        }
      },
      showCancelButton: true,
      confirmButtonText: '稽核',
      confirmButtonColor: 'red',
      cancelButtonText: '取消',
    }).then((res) => {
      if (res.isConfirmed) {
        setIsLoading(true);

        bonusAPI
          .auditBonus(bonusId, res.value)
          .then(
            () => {
              Swal.fire({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                title: '稽核處理完成',
                icon: 'success',
              });
              getAuditBonuses();
            },
            (error) => {
              alertError(error);
            },
          )
          .then(() => {
            setIsLoading(false);
          });
      }
    });
  };

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={match.url}></BreakCrumbs>

      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center">
          <Grid item xs={4}>
            <Typography variant="h4" color="textPrimary" gutterBottom>
              稽核列表
            </Typography>
          </Grid>

          <Grid item xs>
            <Box textAlign="right">
              <input
                type="file"
                ref={csvFileInputRef}
                onChange={handleCsvFileChange}
                accept=".csv"
                style={{ display: 'none' }}
              />

              <div style={{ display: 'inline-block', margin: '3px 0' }}>
                上傳 CSV 檔案
                <DescriptionRoundedIcon
                  style={{
                    verticalAlign: 'middle',
                  }}
                />
              </div>

              <Button
                style={{ margin: '3px 0 3px 12px' }}
                variant="contained"
                color="secondary"
                disabled={isLoading}
                startIcon={<RemoveRoundedIcon />}
                onClick={handleUploadRedeemCsv}>
                折扣
              </Button>

              <Button
                style={{ margin: '3px 0 3px 12px', backgroundColor: 'green' }}
                variant="contained"
                color="secondary"
                disabled={isLoading}
                startIcon={<AddRoundedIcon />}
                onClick={handleUploadRewardCsv}>
                回饋
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Divider light />

        <Box textAlign="right" mt={2} mb={2}>
          <DatePicker
            autoOk
            label="開始時間"
            clearable
            disableFuture
            inputVariant="outlined"
            value={startDate || null}
            onChange={handleChangeStartDate}
            format="yyyy-MM-dd"
            style={{
              marginRight: '12px',
            }}
          />

          <DatePicker
            autoOk
            label="結束時間"
            clearable
            disableFuture
            inputVariant="outlined"
            value={endDate || null}
            onChange={handleChangeEndDate}
            format="yyyy-MM-dd"
            style={{
              marginRight: '12px',
            }}
          />

          <FormControl
            size="small"
            variant="outlined"
            disabled={isLoading}
            style={{ marginRight: '12px', minWidth: '150px' }}>
            <InputLabel>稽核狀態</InputLabel>
            <Select
              value={bonusAudit}
              onChange={handleChangeAuditStatus}
              label="狀態">
              <MenuItem value="">不設定</MenuItem>
              {map(
                filter(BonusAuditStatus, (auditStatus) => auditStatus > 1),
                (audit) => {
                  return (
                    <MenuItem value={audit} key={audit}>
                      {BonusAuditStatusText[audit]}
                    </MenuItem>
                  );
                },
              )}
            </Select>
          </FormControl>

          <FormControl
            size="small"
            variant="outlined"
            disabled={isLoading}
            style={{ marginRight: '12px', minWidth: '150px' }}>
            <InputLabel>紅利狀態</InputLabel>
            <Select
              value={bonusStatus}
              onChange={handleChangeStatus}
              label="狀態">
              <MenuItem value="">不設定</MenuItem>
              {map(BonusStatus, (status) => {
                return (
                  <MenuItem value={status} key={status}>
                    {BonusStatusText[status]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            label="手機/姓名 搜尋"
            type="search"
            variant="outlined"
            size="small"
            disabled={isLoading}
            value={keyword}
            onChange={handleKeyword}
            style={{ marginRight: '12px' }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />

          <Button
            variant="contained"
            color="primary"
            startIcon={<SearchIcon />}
            disabled={isLoading}
            onClick={handleSearch}>
            搜尋
          </Button>
        </Box>

        {isLoading && <TableLoading />}

        {!isLoading && !auditBonuses && <TableEmpty message="查無稽核記錄" />}

        {!isLoading && auditBonuses && (
          <BonusesTable
            bonuses={auditBonuses.historyList}
            bonusTotal={auditBonuses.totalCount}
            viewBonus={viewBonus}
            deleteBonus={deleteBonus}
            isLoading={isLoading}
            pageNum={pageNum}
            setPageNum={setPageNum}
            auditBonus={auditBonus}
          />
        )}
      </Box>

      <Drawer anchor="right" open={isOpenDetail} onClose={handleCloseDetail}>
        <BonusView bonus={bonusDetail} closeDrawer={handleCloseDetail} />
      </Drawer>

      {auditResult && (
        <AuditResultModal
          auditResult={auditResult}
          isOpen={isOpenAuditResult}
          close={closeAuditResult}
        />
      )}
    </div>
  );
};

export default AuditList;
