import React from 'react';
import PropTypes from 'prop-types';
import '../style/components/confirm-box.scss';

const ConfirmBox = ({ isVisible, title, content, confirmMethod, closebox }) => {
  let doComfirmMethod = () => {
    confirmMethod();
    closebox();
  };
  return (
    <div
      className="confirm-box animation bounceIn"
      style={{ display: isVisible ? 'block' : 'none', zIndex: '999' }}>
      <div className="top">
        {title ? (
          <div className="title">
            {title}
            <hr />
          </div>
        ) : (
          ''
        )}
        <div className="cross" onClick={closebox}></div>
      </div>
      <div
        className="contnet"
        dangerouslySetInnerHTML={{ __html: content }}></div>
      <div className="combtns">
        <div className="ok" onClick={doComfirmMethod}>
          確認
        </div>
        <div className="cancel" onClick={closebox}>
          取消
        </div>
      </div>
    </div>
  );
};

ConfirmBox.propTypes = {
  isVisible: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  confirmMethod: PropTypes.func,
  closebox: PropTypes.func,
};

export default ConfirmBox;
