import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Grid, Paper } from '@material-ui/core';

const RewardBonus = (props) => {
  const { rewardBonus } = props;

  return (
    <Paper className="bonus__reward">
      <Grid
        container
        direction="column"
        justifycontent="center"
        alignItems="center">
        <Grid item xs={12}>
          <div className="bonus__guest-title">獲得紅利點數</div>
        </Grid>

        <Grid item xs={12}>
          <div className="bonus__point">
            <AddIcon />
            {rewardBonus}
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default RewardBonus;
