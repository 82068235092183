import React, { useMemo } from 'react';
import _ from 'lodash';
import {
  TableCell,
  TableRow,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  Box,
} from '@material-ui/core';
import QuestionVideo from '../Question/QuestionVideo';
import VideoNodeTableRow from './VideoNodeTableRow';

const VideoNode = (props) => {
  const {
    video,
    nodeList,
    videoOpen,
    saveNode,
    deleteNode,
    videoQueue,
    isSelect,
    setSelect,
  } = props;

  const videoDuration = useMemo(() => {
    return parseInt(video.duration, 10) > 0
      ? parseInt(video.duration, 10)
      : parseInt(videoQueue.videoDuration, 10);
  }, [video, videoQueue]);

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="flex-start"
      spacing={1}>
      {videoQueue &&
      (videoQueue.status !== 'done' || videoQueue.status !== 'error') ? (
        <Grid item sm={12}>
          <Box
            textAlign="center"
            pt={5}
            pb={5}
            style={{
              backgroundColor: '#eee',
              fontSize: '1.5rem',
            }}>
            檔案上傳中，請稍候...
          </Box>
        </Grid>
      ) : (
        <>
          <Grid item sm={4}>
            {!videoQueue && (
              <QuestionVideo
                videoName={video.videoName}
                videoPath={video.videoPath}
                open={videoOpen}
              />
            )}
          </Grid>

          <Grid item sm={8}>
            <TableContainer style={{ maxHeight: '300px' }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">名稱</TableCell>
                    <TableCell align="right" style={{ width: '30%' }}>
                      知識點
                    </TableCell>
                    <TableCell align="right" style={{ width: '10%' }}>
                      開始秒數
                    </TableCell>
                    <TableCell align="right" style={{ width: '10%' }}>
                      結束秒數
                    </TableCell>
                    <TableCell align="right">功能</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {nodeList &&
                    _.map(nodeList, (node) => (
                      <VideoNodeTableRow
                        key={node.nodeId}
                        node={node}
                        saveNode={saveNode}
                        deleteNode={deleteNode}
                        handoutVideoId={video.handoutVideoId}
                        isSelect={isSelect}
                        setSelect={setSelect}
                        videoDuration={videoDuration}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Table size="small">
              <TableBody>
                <VideoNodeTableRow
                  saveNode={saveNode}
                  deleteNode={deleteNode}
                  handoutVideoId={video.handoutVideoId}
                  videoDuration={videoDuration}
                />
              </TableBody>
            </Table>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default VideoNode;
