import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import locale from 'date-fns/locale/zh-TW';
import { Save, Cancel } from '@material-ui/icons';
import {
  TextField,
  Select,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import styled from 'styled-components';
import dateFormat from '../../Util/dateFmt';
import mockZipCities from './mockZipCities';
import Button from '../../components/Button';
import { RadioSelector as GenderSelecter } from '../../components/RadioSelector';

const MemberText = styled(TextField)`
  margin: 5px;
  width: ${({ width }) => width};
`;

const SelectFormControl = styled(FormControl)`
  margin: 0px 5px;
  width: 100px;
`;

/**
 * 新增 MOD 會員
 *
 * @params {bool}     isOpen 是否開啟新增 MOD 會員畫面
 * @params {function} close  關閉新增 MOD 會員畫面
 * @params {function} create 新增 MOD 會員動作
 *
 */
function CreateModMember({ isOpen, close, create }) {
  const [cityValue, setCityValue] = useState(0);
  const [distValue, setDistValue] = useState(0);
  const [user, setUser] = useState({
    account: '',
    userName: '',
    gender: 2,
    birthday: new Date(),
    postCode: mockZipCities.citys[0].dist[0].c3,
    city: mockZipCities.citys[0].name,
    district: mockZipCities.citys[0].dist[0].name,
    road: '',
  });

  const memberTextList = [
    { id: 'account', label: 'MOD 帳號', isFullWidth: true },
    { id: 'userName', label: '姓名', isFullWidth: true },
    { id: 'gender', label: '性別', type: 'radio' },
    { id: 'birthday', label: '生日', type: 'date' },
    { id: 'city', label: '請選擇縣市' },
    { id: 'district', label: '鄉鎮市區' },
    { id: 'postCode', label: '郵遞區號' },
    { id: 'road', label: '地址' },
  ];
  const genderList = [
    { id: 'woman', label: '女', value: 2 },
    { id: 'man', label: '男', value: 1 },
  ];

  const [errorPrompt, setErrorPrompt] = useState({
    isError: false,
    text: `No error`,
  });

  const changeWithID = (e) => {
    setUser({ ...user, [e.target.id]: e.target.value });
  };

  const changeBirthday = (date) => {
    setUser({ ...user, birthday: date });
  };

  const changeGender = (e) => {
    setUser({ ...user, gender: Number(e.target.value) });
  };

  const changeCity = (e) => {
    const firstDist = mockZipCities.citys[e.target.value].dist[0];
    setCityValue(e.target.value);
    setUser({
      ...user,
      city: mockZipCities.citys[e.target.value].name,
      // 預設使用第一個地區與區域號碼
      district: firstDist.name,
      postCode: firstDist.c3,
    });
  };

  const changeDist = (e) => {
    const dist = mockZipCities.citys[cityValue].dist[e.target.value];
    setDistValue(e.target.value);
    setUser({
      ...user,
      district: dist.name,
      postCode: dist.c3,
    });
  };

  const createUser = () => {
    if (_.isEmpty(user.userName) || _.isEmpty(user.account)) {
      setErrorPrompt({ isError: true, text: `MOD 帳號或姓名不可空白` });
    } else {
      setErrorPrompt({ isError: false, text: `no error` });
      const newMember = {
        Username: user.account,
        Gender: user.gender,
        Birthday: dateFormat(user.birthday),
        Road: user.road,
        City: user.city,
        District: user.district,
        PostCode: user.postCode,
        RealName: user.userName,
      };
      create(newMember);
      close();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      maxWidth="xs"
      disableBackdropClick
      disableEscapeKeyDown>
      <DialogTitle align="center" id="createModTitle">
        新增 MOD 會員
      </DialogTitle>
      <DialogContent>
        {memberTextList.map((item, index) => {
          switch (item.id) {
            case 'account':
            case 'userName':
            case 'road':
              return (
                <form key={index} noValidate autoComplete="off">
                  <MemberText
                    fullWidth
                    error={errorPrompt.isError && item.id !== 'road'}
                    autoFocus={item.id === 'account'}
                    variant="outlined"
                    margin="dense"
                    id={item.id}
                    label={item.label}
                    onChange={changeWithID}
                  />
                </form>
              );
            case 'gender':
              return (
                <div key={item.id} style={{ margin: `5px` }}>
                  {genderList.map((item, index) => {
                    return (
                      <GenderSelecter
                        key={index}
                        color={item.id === 'woman' ? 'secondary' : 'primary'}
                        textColor={
                          item.id === 'woman' ? 'secondary' : 'primary'
                        }
                        value={item.value}
                        type={user.gender}
                        onChange={changeGender}
                        text={item.label}
                      />
                    );
                  })}
                </div>
              );
            case 'birthday':
              return (
                <MuiPickersUtilsProvider
                  key={item.id}
                  locale={locale}
                  utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    style={{ margin: `5px` }}
                    margin="dense"
                    variant="inline"
                    inputVariant="outlined"
                    format="yyyy/MM/dd"
                    disableFuture
                    openTo="year"
                    views={['year', 'month', 'date']}
                    autoOk
                    id={item.id}
                    label={item.label}
                    value={user.birthday}
                    onChange={changeBirthday}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              );
            case 'postCode':
              return (
                <MemberText
                  width={`100px`}
                  disabled
                  key={item.id}
                  value={mockZipCities.citys[cityValue].dist[distValue].c3}
                  variant="outlined"
                  margin="dense"
                  id={item.id}
                  label={item.label}
                />
              );
            case 'city':
            case 'district':
              return (
                <SelectFormControl key={item.id} size="small" variant="filled">
                  <InputLabel id={item.id}>{item.label}</InputLabel>
                  {item.id === 'city' ? (
                    <Select
                      labelId={`${item.id}-label`}
                      id={item.id}
                      value={cityValue}
                      onChange={changeCity}>
                      {mockZipCities.citys.map((city, index) => {
                        return (
                          <MenuItem key={index} value={index}>
                            {city.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  ) : (
                    <Select
                      labelId={`${item.id}-label`}
                      id={item.id}
                      value={distValue}
                      onChange={changeDist}>
                      {mockZipCities.citys[cityValue].dist.map(
                        (district, index) => {
                          return (
                            <MenuItem key={index} value={index}>
                              {district.name}
                            </MenuItem>
                          );
                        },
                      )}
                    </Select>
                  )}
                </SelectFormControl>
              );
            default:
              return <>{'default'}</>;
          }
        })}
      </DialogContent>

      <DialogActions>
        {errorPrompt.isError && (
          <Typography
            variant="subtitle1"
            align="justify"
            color="error"
            gutterBottom>
            {errorPrompt.text}
          </Typography>
        )}
        <Button
          variant="contained"
          startIcon={<Save />}
          width={'100px'}
          onClick={createUser}>
          儲存
        </Button>
        <Button
          variant="contained"
          startIcon={<Cancel />}
          width={'100px'}
          bgcolor="cancel"
          onClick={close}>
          取消
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CreateModMember.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  create: PropTypes.func,
};

export default CreateModMember;
