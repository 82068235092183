import * as types from '../actionType';

let initState = [
  {
    Id: 1,
    ImageUrl: '',
    Title: null,
    SubTitle: null,
    Hyperlink: '',
    StartAt: new Date().toLocaleDateString(),
    EndAt: new Date().toLocaleDateString(),
    IsDisplay: true,
    FileUpload: null,
  },
];

function BannerCarouselState(state = initState, action) {
  switch (action.type) {
    case types.BANNER_CAROUSEL_SUCCESS: {
      return action.result;
    }
    case types.ADD_BANNER: {
      let newState = [...state];
      newState.push(action.item);
      return newState;
    }
    default:
      return state;
  }
}

export default BannerCarouselState;
