import React, { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import OndemandVideoRoundedIcon from '@material-ui/icons/OndemandVideoRounded';
import QuestionVideoDialog from './QuestionVideoDialog';

const QuestionVideoButton = (props) => {
  const { videoName, videoPath } = props;
  const [openDialog, setOpenDialog] = useState(false);

  const handlePlayVideo = () => {
    setOpenDialog(true);
  };

  return (
    <>
      {videoName && (
        <Box mb={2} mt={2}>
          <Button
            variant="contained"
            startIcon={<OndemandVideoRoundedIcon />}
            onClick={handlePlayVideo}>
            播放影片
          </Button>

          <QuestionVideoDialog
            videoName={videoName}
            videoPath={videoPath}
            open={openDialog}
            setOpen={setOpenDialog}
          />
        </Box>
      )}
    </>
  );
};

export default QuestionVideoButton;
