import * as types from '../actionType';

let initState = [];

function PromoListState(state = initState, action) {
  switch (action.type) {
    case types.PROMO_LIST_SUCCESS: {
      return action.result;
    }
    case types.ADD_PROMO_SUCCESS: {
      return action.result;
    }
    case types.ADD_PROMO_FAILURE: {
      return action.result;
    }
    case types.DELETE_PROMO_SUCCESS: {
      return action.result;
    }
    case types.ADD_PROMO_QB_SUCCESS: {
      return action.result;
    }
    case types.DELETE_PROMO_QB_SUCCESS: {
      return action.result;
    }
    default:
      return state;
  }
}

export default PromoListState;
