import React from 'react';
import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';

const examQuestionTypes = {
  main: '正式題',
  similar: '類似題',
  easier: '補差題',
  harder: '培優題',
};

const AlertRepeatQuestions = (props) => {
  const { existPositions } = props;

  return (
    <>
      {existPositions
        ? _.map(existPositions, (existPosition) => (
            <Alert
              variant="filled"
              severity="error"
              key={existPosition.examQuestionIndex + '_' + existPosition.type}>
              題目已使用在 題目 {existPosition.examQuestionIndex + 1} 的{' '}
              {examQuestionTypes[existPosition.type]}
            </Alert>
          ))
        : null}
    </>
  );
};

export default AlertRepeatQuestions;
