import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import BonusProductList from './BonusProductList';
import UserBonusProductList from './UserBonusProductList';
import Page404 from '../../components/Page404';
import BonusProduct from './BonusProduct';
import RecommendProductList from './RecommendProductList';

const BonusProductRoute = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`/BonusProduct/Create`} component={BonusProduct} />
      <Route path={`/BonusProduct/:id`} component={BonusProduct} />
      <Route
        path={`${url}/RecommendProductList`}
        component={RecommendProductList}
      />
      <Route path={`${url}/BonusProductList`} component={BonusProductList} />
      <Route
        path={`${url}/UserBonusProductList`}
        component={UserBonusProductList}
      />
      <Route component={Page404} />
    </Switch>
  );
};

export default BonusProductRoute;
