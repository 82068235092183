import React, { useRef } from 'react';
import '../../style/components/BonusProduct/bonus-product.scss';
import { Grid } from '@material-ui/core';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import BonusProductImage from './BonusProductImage';
import BonusProductCover from './BonusProductCover';
import { map } from 'lodash';
import Compressor from 'compressorjs';

const CoverAndImages = (props) => {
  const { mainImage, subImages, setMainImage, setSubImages } = props;
  const fileRef = useRef();

  const handleFileUpload = () => {
    fileRef.current.click();
  };

  const compressorImage = (imageFile) => {
    return new Promise((resolve) => {
      const imageFileSize = (imageFile.size / 1024 / 1024).toFixed(0);

      if (imageFileSize <= 2) {
        resolve(imageFile);
      }

      new Compressor(imageFile, {
        quality: imageFileSize < 5 ? 0.8 : 0.6,
        success: (compressedResult) => {
          resolve(compressedResult);
        },
        error: () => {
          resolve(imageFile);
        },
      });
    });
  };

  const handleFileChange = async (e) => {
    let isChange = {
      main: false,
      sub: false,
    };
    let newMainImage = { ...mainImage };
    let newSubImages = [...subImages];
    const files = e.target.files;

    for (let index = 0; index < files.length; index++) {
      let file = files[index];
      let compressedImage = await compressorImage(file);

      if (!mainImage && index === 0) {
        newMainImage = {
          file: compressedImage,
          isUpload: true,
        };

        isChange.main = true;
      } else {
        newSubImages.push({
          file: compressedImage,
          isUpload: true,
        });

        isChange.sub = true;
      }
    }

    if (isChange.main) {
      setMainImage(newMainImage);
    }

    if (isChange.sub) {
      setSubImages(newSubImages);
    }

    fileRef.current.value = null;
  };

  const setCover = (subImageIndex) => {
    const originMainImage = { ...mainImage };
    const newMainImage = { ...subImages[subImageIndex] };
    let newSubImages = [...subImages];

    setMainImage(newMainImage);

    newSubImages.splice(subImageIndex, 1);

    if (originMainImage) {
      newSubImages = [originMainImage, ...newSubImages];
    }

    setSubImages(newSubImages);
  };

  const deleteImage = (subImageIndex) => {
    let newSubImages = [...subImages];

    newSubImages[subImageIndex].isDelete = true;

    setSubImages(newSubImages);
  };

  const revertDelete = (subImageIndex) => {
    let newSubImages = [...subImages];

    newSubImages[subImageIndex].isDelete = false;

    setSubImages(newSubImages);
  };

  return (
    <Grid
      container
      direction="row"
      justifycontent="flex-start"
      alignItems="flex-start">
      <Grid item xs={2}>
        <BonusProductCover bonusProductImage={mainImage} />
      </Grid>

      <Grid item xs={10}>
        <div className="bonus-product__images">
          {subImages.map((subImage, subImageIndex) => {
            return (
              <BonusProductImage
                index={subImageIndex}
                bonusProductImage={subImage}
                key={`uploadFile_${subImageIndex}`}
                setCover={setCover}
                deleteImage={deleteImage}
                revertDelete={revertDelete}
              />
            );
          })}

          <div>
            <div
              className="
                bonus-product__image__item
                bonus-product__image__upload__btn
              "
              onClick={handleFileUpload}>
              <div>
                <CloudUploadOutlinedIcon />
              </div>
              上傳圖片
            </div>

            <input
              type="file"
              multiple
              ref={fileRef}
              accept=".png,.jpg,.jpeg"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default CoverAndImages;
