import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { InitAPIStatus } from '../store/action';

class PrivateRoute extends Component {
  render() {
    const {
      component: Component,
      loginState,
      sendAPIStatusState,
      ...rest
    } = this.props;
    if (sendAPIStatusState === 500) {
      this.props.InitAPIStatus();
      return <Redirect to="/errorPage" />;
    }
    return (
      <Route
        {...rest}
        render={(props) =>
          loginState.isLogin === true ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/Login',
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }
}

const mapStateToProps = (store) => ({
  loginState: store.loginState,
  sendAPIStatusState: store.SendAPIStatusState,
});

export default connect(mapStateToProps, { InitAPIStatus })(PrivateRoute);
