import React, { useState, useEffect } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import CarouselForm from '../../components/Advertising/CarouselForm';
import { useSelector, useDispatch } from 'react-redux';
import {
  initBannerCarousel,
  addBanner,
  callPageLoading,
  cancelPageLoading,
} from '../../store/action';

const HomeCarousel = (props) => {
  const [nowAdIndex, setNowAdIndex] = useState(0);
  const bannerCarouselState = useSelector((state) => state.BannerCarouselState);
  const dispatch = useDispatch();

  const addAdvertisting = () => {
    let newId = bannerCarouselState.length;
    dispatch(
      addBanner({
        Id: newId + 1,
        ImageUrl: '',
        Title: '',
        SubTitle: '',
        Hyperlink: '',
        StartAt: new Date().toLocaleDateString(),
        EndAt: new Date().toLocaleDateString(),
        IsDisplay: false,
        FileUpload: '',
        newItem: true, //新增flag 用於辨識是否為新增或編輯廣告
      }),
    );
    setNowAdIndex(newId);
  };

  // init once
  useEffect(() => {
    dispatch(callPageLoading({ loadingContent: '讀取中...' }));
    dispatch(initBannerCarousel()).then((response) => {
      dispatch(cancelPageLoading());
    });
  }, []);

  return (
    <div className="adventisting home-carousel pagefadeIn">
      <BreakCrumbs path={props.match.url} />
      <div className="tag-group">
        {bannerCarouselState.length > 0 &&
          bannerCarouselState.map((ad, index) => (
            <div
              key={ad.Id}
              className={nowAdIndex === index ? 'tag active' : 'tag'}
              onClick={() => {
                setNowAdIndex(index);
              }}>
              {`廣告 ${index + 1}`}
            </div>
          ))}
        <div className="tag add-ad-btn" onClick={addAdvertisting}>
          <div className="add-circle">+</div>
        </div>
      </div>
      <div className="block">
        {bannerCarouselState.length > 0 &&
          bannerCarouselState.map((banner, index) => (
            <CarouselForm
              key={banner.Id}
              Banner={banner}
              indexItem={index}
              nowShow={nowAdIndex}
            />
          ))}
      </div>
    </div>
  );
};

export default HomeCarousel;
