import React, { useState, useEffect } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { useSelector, useDispatch } from 'react-redux';
import {
  initMemberOrderList,
  callPageLoading,
  cancelPageLoading,
} from '../../store/action';
import '../../style/components/MemberManagememt/member-management.scss';
import Swal from 'sweetalert2';
import commonData from '../../data/common.json';

const tableColumns = [
  {
    title: '訂單編號',
    field: 'Id',
    property: true,
  },
  {
    title: '購買日期',
    field: 'CreateAt',
    property: true,
    render: (data) => {
      return data.CreateAt.slice(0, 10);
    },
  },
  {
    title: '購買品項',
    field: 'ItemNameList',
    property: false,
    render: (data) => {
      return data.ItemNameList.map((y) => <div key={data.FlowId + y}>{y}</div>);
    },
  },
  {
    title: '金額',
    field: 'TotalAmount',
    property: false,
    render: (data) => {
      return Intl.NumberFormat('zh', {
        style: 'currency',
        currency: 'TWD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(data.TotalAmount);
    },
  },
  {
    title: '付款方式',
    field: 'PaymentId',
    property: false,
    render: (data) => {
      return commonData.PaymentType[data.PaymentId];
    },
  },
  {
    title: '狀態',
    field: 'Status',
    property: false,
    render: (data) => {
      return data.Status === 1 ? '成功' : '失敗';
    },
  },
];

const BougthRecord = (props) => {
  const [id] = useState(() => {
    return props.match.params.id;
  });
  const [userName, setUserName] = useState('');
  const [products, setProducts] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [countOfPage, setCountOfPage] = useState(10);
  const dispatch = useDispatch();

  const getPageStart = () => {
    return (currPage - 1) * countOfPage;
  };

  const getTotalPage = () => {
    return Math.ceil(products.length / countOfPage);
  };

  const handleChangePage = (page) => {
    setCurrPage(page);
  };

  const handleSelectChange = (e) => {
    setCountOfPage(e.target.value);
    setCurrPage(1);
  };

  useEffect(() => {
    dispatch(callPageLoading({ loadingContent: '讀取資料中...' }));

    dispatch(initMemberOrderList(id)).then((response) => {
      if (response.Status === 1) {
        setUserName(response.Result.UserName);
        setProducts(
          response.Result.Orders.map((order) => {
            return order.Order;
          }),
        );
      } else {
        Swal.fire('錯誤', '讀取資料失敗', 'error');
      }

      dispatch(cancelPageLoading());
    });
  }, []);

  return (
    <div className="member-managemenet bougth-record pagefadeIn">
      <BreakCrumbs path={props.match.url} />
      <div className="block">
        <div className="member">
          <div>{`會員編號：${id}`}</div>
          <div>{`姓名：${userName}`}</div>
        </div>
        <Table
          columns={tableColumns}
          datas={products.slice(getPageStart(), getPageStart() + countOfPage)}
        />
        <div className="pagination-box">
          <Pagination
            currPage={currPage}
            totalPage={getTotalPage()}
            onChangPage={handleChangePage}
          />
          <div className="view-single-page">
            每頁顯示
            <select value={countOfPage} onChange={handleSelectChange}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BougthRecord;
