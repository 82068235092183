import React, { useEffect, useState } from 'react';
import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded';
import {
  Divider,
  Button,
  TextField,
  LinearProgress,
  Grid,
} from '@material-ui/core';
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import LibraryBooksRoundedIcon from '@material-ui/icons/LibraryBooksRounded';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import SettingsBackupRestoreRoundedIcon from '@material-ui/icons/SettingsBackupRestoreRounded';
import FileButton from '../FileButton';
import ChapterResources from './ChapterResources';
import Swal from 'sweetalert2';
import _ from 'lodash';
import VideoNodeDialog from '../Video/VideoNodeDialog';
import OndemandVideoRoundedIcon from '@material-ui/icons/OndemandVideoRounded';
import QueueRoundedIcon from '@material-ui/icons/QueueRounded';
import SimpleVideoNodeDialog from '../Video/SimpleVideoNodeDialog';
import ResourceAssignment from './ResourceAssignment';

const initResource = {
  name: '',
  file: undefined,
  questionBankId: 0,
  videoNode: undefined,
  description: '',
  submissionType: null,
  submissionCount: null,
};

const initUploadProgress = {
  totalPart: 0,
  nowPart: 0,
};

const ResourceUnit = (props) => {
  const { children, setResourceType } = props;

  return (
    <div className="add--resource__type" onClick={setResourceType}>
      {children}
    </div>
  );
};

const ResourceVideo = (props) => {
  const { resource, setResource, openSelectVideoNode } = props;

  return (
    <div className="add--resource__inputs">
      <TextField
        label="影片名稱"
        fullWidth
        value={resource.name}
        onChange={(e) => {
          setResource({ ...resource, name: e.target.value });
        }}
      />

      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="stretch"
        spacing={2}>
        <Grid item xs>
          <Button
            variant="outlined"
            color="default"
            startIcon={<VideoCallIcon />}
            onClick={openSelectVideoNode}>
            選擇影片節點
          </Button>
        </Grid>

        <Grid item xs>
          {resource.videoNode && (
            <Alert
              variant="outlined"
              size="small"
              severity="success"
              icon={<OndemandVideoRoundedIcon />}>
              {resource.videoNode.name}
            </Alert>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const ResourcePdf = (props) => {
  const { resource, setResource } = props;

  return (
    <div className="add--resource__inputs">
      <TextField
        label="PDF名稱"
        fullWidth
        value={resource.name}
        onChange={(e) => {
          setResource({ ...resource, name: e.target.value });
        }}
      />

      <FileButton
        onChange={(file) => {
          const name = _.trim(resource.name) === '' ? file.name : resource.name;

          setResource({ ...resource, name: name, file: file });
        }}
        fileProps={{
          accept: '.pdf',
        }}
        buttonProps={{
          variant: 'outlined',
          startIcon: <PictureAsPdfRoundedIcon />,
        }}>
        選擇 PDF 檔案
      </FileButton>
    </div>
  );
};

const ResourceQuestionBank = (props) => {
  const saleExamList = useSelector((store) => store.SaleExamListState);
  const { resource, setResource } = props;

  return (
    <div className="add--resource__inputs">
      <TextField
        label="測驗名稱"
        fullWidth
        value={resource.name}
        onChange={(e) => {
          setResource({ ...resource, name: e.target.value });
        }}
      />

      <Autocomplete
        options={saleExamList ? saleExamList.QuestionBankList : []}
        autoHighlight
        fullWidth
        onChange={(event, value, reason) => {
          const name =
            _.trim(resource.name) === '' ? value.Name : resource.name;

          setResource({
            ...resource,
            name: name,
            questionBankId: value ? value.QuestionBankId : null,
          });
        }}
        className="secret--resource__exam"
        getOptionLabel={(option) => option.QuestionBankId + '-' + option.Name}
        renderOption={(option) => (
          <React.Fragment>
            {option.QuestionBankId} - {option.Name}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="選擇測驗"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
    </div>
  );
};

const ResourceProcessing = (props) => {
  const { resourceType, resource, uploadProgress } = props;

  return (
    <>
      {resourceType === 'video' && <div>設定影片節點中...</div>}
      {resourceType === 'pdf' && <div>目前上傳PDF中...</div>}
      {resourceType === 'questionBank' && <div>加入測驗中...</div>}
      <LinearProgress />
    </>
  );
};

const HangoutResources = (props) => {
  const {
    addResource,
    chapter,
    deleteResource,
    changeResource,
    changeResourceOrdering,
    refresh,
  } = props;
  const [resourceType, setResourceType] = useState(undefined);
  const [resource, setResource] = useState(_.cloneDeep(initResource));
  const [step, setStep] = useState('select');
  const [uploadProgress, setUploadProgress] = useState(
    _.cloneDeep(initUploadProgress),
  );
  const [error, setError] = useState(undefined);
  const [isOpenVideoNodeDialog, setIsOpenVideoNodeDialog] = useState(false);
  const [isOpenSimpleNodeDialog, setIsOpenSimpleNodeDialog] = useState(false);
  const [selectedNodes, setSelectedNodes] = useState([]);

  let resourceTypeName = '';
  resourceTypeName = resourceType === 'video' ? '影片節點' : resourceTypeName;
  resourceTypeName = resourceType === 'pdf' ? 'PDF' : resourceTypeName;
  resourceTypeName =
    resourceType === 'questionBank' ? '測驗' : resourceTypeName;

  const handleResourceTypeChange = (type) => {
    setResourceType(type);
    setResource(_.cloneDeep(initResource));
    setStep('resource');
  };

  const handleCancel = () => {
    setStep('select');
    setResourceType(undefined);
  };

  const handleSelectVideoNode = (node) => {
    const name = _.trim(resource.name) === '' ? node.name : resource.name;

    setResource({
      ...resource,
      name: name,
      videoNode: node,
    });

    setIsOpenVideoNodeDialog(false);
  };

  const handleUploadProgress = (status, part) => {
    if (status === 'start') {
      setUploadProgress({
        ..._.cloneDeep(initUploadProgress),
        totalPart: part,
      });
    } else if (status === 'progress') {
      setUploadProgress((state) => {
        return { ...state, nowPart: part };
      });
    }
  };

  const handleAddResource = () => {
    if (_.trim(resource.name) === '') {
      Swal.fire({
        title: '請輸入名稱',
        icon: 'info',
      });

      return false;
    }

    if (resourceType === 'video' && !resource.videoNode) {
      Swal.fire({
        title: '請選擇影片節點',
        icon: 'info',
      });

      return false;
    }

    if (resourceType === 'pdf' && !resource.file) {
      Swal.fire({
        title: '請選擇檔案',
        icon: 'info',
      });

      return false;
    }

    if (resourceType === 'questionBank' && resource.questionBankId <= 0) {
      Swal.fire({
        title: '請選擇測驗',
        icon: 'info',
      });

      return false;
    }

    if (resourceType === 'assignment') {
      if (
        resource.submissionCount !== null &&
        /^[0-9]*$/.test(resource.submissionCount) === false
      ) {
        Swal.fire({
          title: '上傳次數只能輸入數字',
          icon: 'info',
        });

        return false;
      }

      if (_.trim(resource.submissionType) === '') {
        Swal.fire({
          title: '請選擇繳交的檔案類型',
          icon: 'info',
        });

        return false;
      }
    }

    setStep('readyProcess');
  };

  const closeSimpleNodeDialog = () => {
    setIsOpenSimpleNodeDialog(false);
  };

  const addSelectedNodes = () => {
    if (selectedNodes.length <= 0) {
      Swal.fire({
        title: '請選擇影片節點',
        icon: 'info',
      });

      return false;
    }

    setResourceType('multipleVideo');
    setStep('readyProcess');
  };

  const processSelectedNodes = async () => {
    const processResult = [];

    for (let index = 0; index < selectedNodes.length; index++) {
      const selectedNode = selectedNodes[index];

      const addResult = await addResource(
        {
          name: selectedNode.name,
          videoNode: selectedNode,
        },
        'video',
        chapter,
        handleUploadProgress,
      ).catch((error) => {
        return {
          isError: true,
          node: {
            id: selectedNode.nodeId,
            name: selectedNode.name,
          },
          error: error,
        };
      });

      processResult.push(addResult);
    }

    if (_.filter(processResult, (rs) => rs.isError).length > 0) {
      setStep('error');
      setError({
        message: (
          <div>
            {_.map(processResult, (rs) => (
              <div key={rs.node.id}>
                <div>
                  {rs.node.id} - {rs.node.name}
                </div>
                <div>{rs.error}</div>
              </div>
            ))}
          </div>
        ),
      });
    } else {
      setStep('done');
    }

    refresh();
  };

  useEffect(() => {
    if (step === 'readyProcess') {
      setStep('processing');

      if (resourceType !== 'multipleVideo') {
        addResource(resource, resourceType, chapter, handleUploadProgress).then(
          (response) => {
            setStep('done');
            refresh();
          },
          (error) => {
            setStep('error');
            setError(error);
          },
        );
      } else {
        closeSimpleNodeDialog();
        processSelectedNodes();
      }
    }
  }, [step, resource, resourceType, chapter, handleUploadProgress]);

  return (
    <>
      <ChapterResources
        resources={chapter.ResourceList || []}
        deleteResource={deleteResource}
        changeResource={changeResource}
        changeResourceOrdering={changeResourceOrdering}
      />
      <div className="add--resource">
        <div className="add--resource__title">
          資源設定
          <div>{resourceTypeName}</div>
        </div>

        <div className="add-resource__content">
          {step === 'select' && (
            <div className="add--resource__units">
              <ResourceUnit
                setResourceType={() => {
                  setIsOpenSimpleNodeDialog(true);
                }}>
                <QueueRoundedIcon />
                多選影片
              </ResourceUnit>

              <Divider orientation="vertical" flexItem />

              <ResourceUnit
                setResourceType={() => {
                  handleResourceTypeChange('video');
                }}>
                <VideocamRoundedIcon />
                影片
              </ResourceUnit>

              <Divider orientation="vertical" flexItem />

              <ResourceUnit
                setResourceType={() => {
                  handleResourceTypeChange('pdf');
                }}>
                <PictureAsPdfRoundedIcon />
                PDF
              </ResourceUnit>

              <Divider orientation="vertical" flexItem />

              <ResourceUnit
                setResourceType={() => {
                  handleResourceTypeChange('questionBank');
                }}>
                <LibraryBooksRoundedIcon />
                測驗
              </ResourceUnit>

              <Divider orientation="vertical" flexItem />

              <ResourceUnit
                setResourceType={() => {
                  handleResourceTypeChange('assignment');
                }}>
                <AssignmentRoundedIcon />
                作業
              </ResourceUnit>
            </div>
          )}

          {step === 'resource' && (
            <div className="add--resource__form">
              {resourceType === 'video' && (
                <ResourceVideo
                  resource={resource}
                  setResource={setResource}
                  openSelectVideoNode={() => {
                    setIsOpenVideoNodeDialog(true);
                  }}
                />
              )}

              {resourceType === 'pdf' && (
                <ResourcePdf resource={resource} setResource={setResource} />
              )}

              {resourceType === 'questionBank' && (
                <ResourceQuestionBank
                  resource={resource}
                  setResource={setResource}
                />
              )}

              {resourceType === 'assignment' && (
                <ResourceAssignment
                  resource={resource}
                  setResource={setResource}
                />
              )}

              <Divider />

              <div className="add--resource__actives">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddRoundedIcon />}
                  onClick={handleAddResource}>
                  設定
                </Button>
                <Button
                  variant="contained"
                  startIcon={<ClearRoundedIcon />}
                  onClick={handleCancel}>
                  取消
                </Button>
              </div>
            </div>
          )}

          {step === 'processing' && (
            <ResourceProcessing
              resourceType={resourceType}
              resource={resource}
              uploadProgress={uploadProgress}
            />
          )}

          {(step === 'error' || step === 'done') && (
            <div className="add--resource__over">
              {step === 'error' && (
                <>
                  <Alert severity="error" variant="filled">
                    <AlertTitle>{resourceTypeName} 發生錯誤</AlertTitle>
                    {error && error.message}
                  </Alert>
                  <div className="add--resource__actives">
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<SettingsBackupRestoreRoundedIcon />}
                      onClick={handleCancel}>
                      重新設定
                    </Button>
                  </div>
                </>
              )}

              {step === 'done' && (
                <>
                  <Alert severity="success" variant="filled">
                    <AlertTitle>{resourceTypeName} 建立完成</AlertTitle>
                  </Alert>
                  <div className="add--resource__actives">
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<DoneRoundedIcon />}
                      onClick={handleCancel}>
                      確定
                    </Button>
                  </div>
                </>
              )}
            </div>
          )}
        </div>

        <VideoNodeDialog
          mode="selector"
          setSelect={handleSelectVideoNode}
          open={isOpenVideoNodeDialog}
          setOpen={setIsOpenVideoNodeDialog}
        />

        <SimpleVideoNodeDialog
          open={isOpenSimpleNodeDialog}
          close={closeSimpleNodeDialog}
          selectNode
          selectedNodes={selectedNodes}
          setSelectedNodes={setSelectedNodes}
          addSelectedNodes={addSelectedNodes}
        />
      </div>
    </>
  );
};

export default HangoutResources;
