import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Divider,
} from '@material-ui/core';
import BreakCrumbs from '../../components/BreakCrumbs';
import bonusProductAPI from '../../middleware/bonusProductAPI';
import RecommendProduct from '../../components/BonusProduct/RecommendProduct';
import { alertError } from '../../Util/Util';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import AddRecommendProductModal from '../../components/BonusProduct/AddRecommendProductModal';
import ChangeRecommendProductSortModal from '../../components/BonusProduct/ChangeRecommendProductSortModal';
import Swal from 'sweetalert2';
import TableEmpty from '../../components/TableEmpty';

const RecommendProductList = (props) => {
  const { match } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [recommendProducts, setRecommendProducts] = useState([]);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenChangeSortModal, setIsOpenChangeSortModal] = useState(false);
  const [changeSortProduct, setChangeSortProduct] = useState();
  const newSort = useMemo(() => {
    return recommendProducts.length + 1;
  }, [recommendProducts]);

  useEffect(() => {
    getRecommendList();
  }, []);

  const getRecommendList = () => {
    setIsLoading(true);

    bonusProductAPI
      .getRecommendList()
      .then(
        (res) => {
          setRecommendProducts(res);
        },
        (error) => alertError(error),
      )
      .then(() => setIsLoading(false));
  };

  const handleOpenAddModal = () => {
    setIsOpenAddModal(true);
  };

  const closeAddModal = () => {
    setIsOpenAddModal(false);
  };

  const handleOpenChangeSortModal = (product) => {
    setChangeSortProduct(product);
    setIsOpenChangeSortModal(true);
  };

  const closeChangeSortModal = () => {
    setIsOpenChangeSortModal(false);
  };

  const addRecommendProduct = (product) => {
    if (product.sort > 0) {
      Swal.fire({
        icon: 'error',
        title: '已是推薦商品',
      });

      return false;
    }

    setIsLoading(true);

    return bonusProductAPI
      .addRecommend(product.id, newSort)
      .then(
        (res) => {
          getRecommendList();
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const removeRecommendProduct = (product) => {
    Swal.fire({
      title: '確認移除推薦商品嗎？',
      showCancelButton: true,
      confirmButtonText: '移除',
      confirmButtonColor: 'red',
      cancelButtonText: '取消',
    }).then((res) => {
      if (res.isConfirmed) {
        setIsLoading(true);

        bonusProductAPI.removeRecommend(product.id).then(
          () => {
            getRecommendList();
          },
          (error) => {
            alertError(error);
            setIsLoading(false);
          },
        );
      }
    });
  };

  const changeSort = (product, sort) => {
    setIsLoading(true);

    return bonusProductAPI
      .setRecommendSort(product.id, sort)
      .then(
        (res) => {
          getRecommendList();
          closeChangeSortModal();
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={match.url}></BreakCrumbs>

      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center">
          <Grid item xs>
            <Typography variant="h4" color="textPrimary" gutterBottom>
              推薦商品
            </Typography>
          </Grid>

          <Grid item xs style={{ marignLeft: 'auto' }}>
            <div style={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                disabled={isLoading}
                onClick={handleOpenAddModal}>
                新增
              </Button>
            </div>
          </Grid>
        </Grid>

        <Divider light />

        <Box mt={2} mb={2}>
          {recommendProducts.length <= 0 && <TableEmpty />}

          <Grid
            container
            direction="row"
            justifycontent="flex-start"
            alignItems="flex-start"
            spacing={2}>
            {recommendProducts.map((product) => {
              return (
                <Grid item xs={3} key={product.id}>
                  <RecommendProduct
                    product={product}
                    removeRecommendProduct={removeRecommendProduct}
                    openChangeSort={handleOpenChangeSortModal}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>

      <AddRecommendProductModal
        isOpen={isOpenAddModal}
        close={closeAddModal}
        addRecommendProduct={addRecommendProduct}
      />

      <ChangeRecommendProductSortModal
        isOpen={isOpenChangeSortModal}
        close={closeChangeSortModal}
        changeSort={changeSort}
        product={changeSortProduct}
        maxSort={recommendProducts.length}
        isLoading={isLoading}
      />
    </div>
  );
};

export default RecommendProductList;
