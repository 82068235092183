import React from 'react';
import PropTypes from 'prop-types';
import searchImage from '../images/Search.png';

/**
 * Search
 *
 * @param {string} prompt      提示文字
 * @param {string} value       輸入值
 * @param {func} onChange      更新輸入
 * @param {func} searching     搜尋
 */
function Search({ prompt, value, onChange, searching }) {
  return (
    <div className="search-box" style={{ margin: 10, float: 'right' }}>
      <div className="search-input">
        <input
          type="text"
          value={value}
          onChange={onChange}
          placeholder={prompt}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              searching();
            }
          }}
        />
        <img src={searchImage} alt="search-icon" onClick={searching} />
      </div>
    </div>
  );
}

Search.propTypes = {
  prompt: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  searching: PropTypes.func,
};

export default Search;
