import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../style/components/MemberManagememt/member-management.scss';

/**
 * Table
 * @params {array} columns 表格第一欄
 * @params {array} data    表格內資料
 *
 * column: {
 *   title {string}    標題文字
 *   field {string}    資料欄的 Field name
 *   property {bool}   排列功能
 *   render {function} 表格內使用的函式
 * }
 *
 * EX:
 *    const columns = [
 *      { title: 'No.', field: 'ID', property: true },
 *      { title: '姓名', field: 'name', property: true },
 *      {
 *        title: '性別',
 *        field: 'gender',
 *        property: true,
 *        render: (props) => {
 *          if (props.gender === 1) {
 *            return '男';
 *          } else if (props.gender === 2) {
 *            return '女';
 *          } else {
 *            return '其他';
 *          }
 *        },
 *      },
 *    ];
 *    const datas = [
 *      { ID: 1, name: `Batman`, gender: 1 },
 *      { ID: 2, name: `Wonder Woman`, gender: 2 },
 *    ];
 *
 *    component 使用方式：
 *
 *    <Table columns={columns} datas={datas} />
 *
 */
function Table({ columns, datas }) {
  const [rowDatas, setRowDatas] = useState(datas || []);

  useEffect(() => {
    setRowDatas(datas);
  }, [datas]);

  const sortItems = (items, type) => {
    if (items.length === 0) {
      return [...rowDatas];
    } else if (items[0][type] < items[items.length - 1][type]) {
      return items.sort((a, b) => (a[type] < b[type] ? 1 : -1));
    } else {
      return items.sort((a, b) => (a[type] > b[type] ? 1 : -1));
    }
  };

  async function sortData(type) {
    const newDatas = await Promise.all(sortItems(rowDatas, type));
    setRowDatas(newDatas);
  }

  return (
    <div className="table-responsive">
      {rowDatas ? (
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              {columns.map((item, idx) => {
                return item.property ? (
                  <th
                    key={idx}
                    className="sort"
                    onClick={() => {
                      sortData(item.field);
                    }}>
                    {item.title} <i className="fas fa-angle-down" />
                  </th>
                ) : (
                  <th key={idx}>{item.title}</th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {rowDatas.map((data, idx) => {
              let newData = Object.assign({}, data);
              return (
                <tr key={idx}>
                  {columns.map((item, i) => {
                    // 若有指定欄位功能，就將該欄都使用 render function。
                    if (item.render) {
                      newData[item.field] = item.render(data);
                    }
                    return <td key={i}>{newData[item.field]}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <>Loading...</>
      )}
    </div>
  );
}

Table.propTypes = {
  columns: PropTypes.array,
  dates: PropTypes.array,
};

export default Table;
