import React from 'react';

const ChapterName = (props) => {
  const {
    isEdit,
    setIsEdit,
    chapterIndex,
    unitIndex,
    name,
    setName,
    isLoading,
    chapter,
    handleCancelReName,
    handleSaveName,
    handleDeleteChapter,
  } = props;

  return (
    <>
      {isEdit && (
        <div className="input-box fadeIn">
          <input
            name={`chapter-${chapterIndex}-${unitIndex}-name`}
            className="secret--input"
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            disabled={isLoading}
          />
          <div
            className="fas fa-times delete-icon"
            style={{
              display:
                isLoading || chapter.HangoutChapterId === -1 ? 'none' : '',
            }}
            onClick={handleCancelReName}
          />
          <button
            className="btn main-color"
            onClick={handleSaveName}
            disabled={isLoading}>
            儲存
          </button>
        </div>
      )}

      {!isEdit && (
        <div className="chapter-box fadeIn">
          <input
            name={`chapter-${chapterIndex}-${unitIndex}-name`}
            className="secret--input"
            type="text"
            value={chapter.Name}
            disabled
          />
          <div
            className="far fa-edit delete-icon"
            onClick={() => {
              setIsEdit(true);
            }}
          />
          <div
            className="far fa-trash-alt delete-icon"
            onClick={handleDeleteChapter}
          />
        </div>
      )}
    </>
  );
};

export default ChapterName;
