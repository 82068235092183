import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { cancelPageLoading } from '../store/action';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    textAlign: 'center',
  },
}));

const PageLoading = (props) => {
  const classes = useStyles();
  const pageLoadingState = useSelector((state) => state.pageLoadingState);
  const dispatch = useDispatch();

  const handleClose = () => {
    if (pageLoadingState.cancelable) {
      dispatch(cancelPageLoading());
    }
  };

  return (
    <div>
      <Backdrop
        classes={{ root: classes.root }}
        className={classes.backdrop}
        open={pageLoadingState.isLoading}
        onClick={handleClose}>
        <Box className={classes.content}>
          <CircularProgress color="inherit" size="5rem" thickness={6} />
          <Box mt={3}>
            <Typography variant="h3">
              {pageLoadingState.loadingContent}
            </Typography>
          </Box>
        </Box>
      </Backdrop>
    </div>
  );
};

export default PageLoading;
