import * as types from '../actionType';
import * as Api from '../apiconfig';
import Swal from 'sweetalert2';
import _ from 'lodash';
import httpCode from '../../Util/httpCode';
import {
  callInitBannerCarouselAPI,
  callAddBannerAPI,
  callEditBannerAPI,
  callDeleteBannerAPI,
  callSwitchBannerDisplayAPI,
  callQuestionBankBannerAPI,
  callEditQuestionBankBannerAPI,
  callSwitchMemberStatusAPI,
  fetchMemberOrders,
  getQuestionSources,
  getQuestions,
  getQuestion,
  callRejectQuestionAPI,
  getQuestionsByQuestionBankId,
  saveQuestionBank,
} from '../../Util/fetchMethods';
import { QuestionStatus } from '../../data/common.js';

const RESPONSE_SUCCESS = '1';

//formdata 無法這樣送資料 無解
const fetchFuc = (url, method, body) => {
  if (method === 'GET') {
    return fetch(url, {
      method: method,
      headers: {
        Authorization: 'bearer ' + localStorage.getItem('access_token'),
      },
    });
  } else if (method === 'POST') {
    return fetch(url, {
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('access_token'),
      },
      body: body,
    });
  } else if (method === 'DELETE') {
    return fetch(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem('access_token'),
      },
      body: body,
    });
  } else {
    return fetch(url);
  }
};

// 確認每個動作的token是否過期或有效 若失效則強制登出
export const checkResponseTokenEffective = (response, dispatch) => {
  if (
    response.status === httpCode.forbidden ||
    response.status === httpCode.unauthorized
  ) {
    dispatch(logoutSuccess());
  } else if (response.status === httpCode.internalServerError) {
    Swal.fire('伺服器錯誤', '請確認資料輸入是否正確', 'error');
    //dispatch(serverError())
  } else {
    return response.json();
  }
};

// API 狀態管理復原
export const InitAPIStatus = (item) => ({ type: types.INIT_SEND_API_STATUS });

//登出
export const logoutSuccess = () => ({ type: types.LOGOUT_SUCCESS });

//伺服器出錯
export const serverError = () => ({ type: types.SERVER_ERROR_500 });

//登入請求
export const adminLoginRequest = () => ({ type: types.ADMIN_LOGIN_REQUEST });
export const adminLoginSuccess = (result, username) => ({
  type: types.ADMIN_LOGIN_SUCCESS,
  result,
  username,
});
export const adminLoginFailure = (err) => ({
  type: types.ADMIN_LOGIN_FAILURE,
  err,
});

/**
 * 登入請求
 * @param {object} LoginObj 使用者帳號與密碼
 * @return dispatch The dispatch function
 */
export function adminLoginRequestFunc(LoginObj) {
  const params = {
    grant_type: 'password',
    sys: 'CDWB',
    username: LoginObj.userName,
    password: LoginObj.password,
  };

  const searchParams = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
    )
    .join('&');

  return (dispatch) => {
    dispatch(adminLoginRequest());
    fetch(Api.loginAPI.POST, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: searchParams,
      mode: 'cors',
      cache: 'default',
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.error === RESPONSE_SUCCESS) {
          return dispatch(adminLoginSuccess(json, LoginObj.userName));
        } else {
          return dispatch(adminLoginFailure());
        }
      })
      .catch((err) => {
        dispatch(adminLoginFailure(err));
      });
  };
}

/**
 * 記住我
 * @param {object} AdminObj
 */
export const switchRememberMe = (AdminObj) => ({
  type: types.SWITCH_REMEMBER_ME,
  AdminObj,
});

// 首頁數據Feature
export const homeFeatureStatisticRequest = () => ({
  type: types.HOME_FEATURE_STATISTIC_REQUEST,
});
export const homeFeatureStatisticSuccess = (result) => ({
  type: types.HOME_FEATURE_STATISTIC_SUCCESS,
  result,
});
export const homeFeatureStatisticFailure = (err) => ({
  type: types.HOME_FEATURE_STATISTIC_FAILURE,
  err,
});
export function initHomeFeatureStatistic() {
  return (dispatch) => {
    dispatch(homeFeatureStatisticRequest());
    fetchFuc(Api.homePageStatisticAPI.FEATURE, 'GET')
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .then((json) => dispatch(homeFeatureStatisticSuccess(json.Result)))
      .catch((err) => {
        dispatch(homeFeatureStatisticFailure(err));
      });
  };
}

// 首頁數據Income Order
export const homeOrderStatisticRequest = () => ({
  type: types.HOME_ORDER_STATISTIC_REQUEST,
});
export const homeOrderStatisticSuccess = (result) => ({
  type: types.HOME_ORDER_STATISTIC_SUCCESS,
  result,
});
export const homeOrderStatisticFailure = (err) => ({
  type: types.HOME_ORDER_STATISTIC_FAILURE,
  err,
});
export function initHomeOrderStatistic() {
  return (dispatch) => {
    dispatch(homeOrderStatisticRequest());
    fetchFuc(Api.homePageStatisticAPI.ORDER, 'GET')
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .then((json) => dispatch(homeOrderStatisticSuccess(json.Result)))
      .catch((err) => {
        dispatch(homeOrderStatisticFailure(err));
      });
  };
}

// 首頁數據 Members of Month
export const homeMemberStatisticRequest = () => ({
  type: types.HOME_MEMBER_STATISTIC_REQUEST,
});
export const homeMemberStatisticSuccess = (result) => ({
  type: types.HOME_MEMBER_STATISTIC_SUCCESS,
  result,
});
export const homeMemberStatisticFailure = (err) => ({
  type: types.HOME_MEMBER_STATISTIC_FAILURE,
  err,
});
export function initHomeMemberStatistic() {
  return (dispatch) => {
    dispatch(homeMemberStatisticRequest());
    fetchFuc(Api.homePageStatisticAPI.MEMBER, 'GET')
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .then((json) => dispatch(homeMemberStatisticSuccess(json.Result)))
      .catch((err) => {
        dispatch(homeMemberStatisticFailure(err));
      });
  };
}

// 編卷管理 選項來源
export const questionSourceRequest = () => ({
  type: types.QUESTION_SOURCE_REQUEST,
});
export const questionSourceSuccess = (result) => ({
  type: types.QUESTION_SOURCE_SUCCESS,
  result,
});
export const questionSourceFailure = (err) => ({
  type: types.QUESTION_SOURCE_FAILURE,
  err,
});
export function initQuestionSource() {
  return (dispatch) => {
    dispatch(questionSourceRequest());
    return getQuestionSources(dispatch)
      .then((json) => {
        dispatch(questionSourceSuccess(json.Result));

        return json;
      })
      .catch((err) => {
        dispatch(questionSourceFailure(err));
      });
  };
}

// 重新編卷 培優補差 考卷呼叫
export const reEditExamRequest = () => ({ type: types.REEDIT_EXAM_REQUEST });
export const reEditExamSuccess = (result) => ({
  type: types.REEDIT_EXAM_SUCCESS,
  result,
});
export const reEditExamFailure = (err) => ({
  type: types.REEDIT_EXAM_FAILURE,
  err,
});
export function reEditExamStateAPI(id, bool) {
  return (dispatch) => {
    dispatch(reEditExamRequest());
    fetchFuc(
      Api.questionSourceAPI.RE_EDIT_EXAM +
        `?questionBankId=${id}&getRelation=true`,
      'GET',
    )
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .then((json) => {
        dispatch(reEditExamSuccess(json.Result));
        if (bool)
          // bool=true: 重新編卷  bool=false: 培優補差設定
          dispatch(getQuestionByBankId(id));
      })
      .catch((err) => {
        dispatch(reEditExamFailure(err));
      });
  };
}

// 培優補差 設定
export const sendQuestionBankRelationRequest = () => ({
  type: types.SEND_QUESTIONBANK_RELATION_REQUEST,
});
export const sendQuestionBankRelationSuccess = (result) => ({
  type: types.SEND_QUESTIONBANK_RELATION_SUCCESS,
  result,
});
export const sendQuestionBankRelationFailure = (err) => ({
  type: types.SEND_QUESTIONBANK_RELATION_FAILURE,
  err,
});
export function sendQuestionBankRelation(obj) {
  return (dispatch) => {
    dispatch(sendQuestionBankRelationRequest());
    fetchFuc(
      Api.questionSourceAPI.SEND_QUESTIONBANK_RELATION,
      'POST',
      JSON.stringify(obj),
    )
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((err) => {
        dispatch(sendQuestionBankRelationFailure(err));
      })
      .then((response) => {
        if (response.Status === 1) {
          dispatch(sendQuestionBankRelationSuccess());
        } else {
          dispatch(sendQuestionBankRelationFailure());
        }
      });
  };
}

// 清空重新編卷的狀態
export const initReEditExamState = () => ({ type: types.INIT_REEDIT_EXAM });

// 新增培優題
export const addNewHardExam = (obj) => ({ type: types.ADD_HARD_EXAM, obj });
export const DeleteHardExamById = (id) => ({
  type: types.DELETE_HARD_EXAM,
  id,
});

// 新增補差題
export const addNewEasierExam = (obj) => ({ type: types.ADD_EASIER_EXAM, obj });
export const DeleteEasierExamById = (id) => ({
  type: types.DELETE_EASIER_EXAM,
  id,
});

// 依試卷ID獲取題目內容
export const getQuestionByBankIdRequest = () => ({
  type: types.GET_QUESTION_BY_BANKID_REQUEST,
});
export const getQuestionByBankIdSuccess = (result) => ({
  type: types.GET_QUESTION_BY_BANKID_SUCCESS,
  result,
});
export const getQuestionByBankIdFailure = (err) => ({
  type: types.GET_QUESTION_BY_BANKID_FAILURE,
  err,
});
export function getQuestionByBankId(questionBankId) {
  return (dispatch) => {
    dispatch(getQuestionByBankIdRequest());
    return getQuestionsByQuestionBankId(questionBankId, dispatch)
      .then((json) => {
        dispatch(getQuestionByBankIdSuccess(json.Result));

        return json;
      })
      .catch((err) => {
        dispatch(getQuestionByBankIdFailure(err));
      });
  };
}

// 編卷管理 選項搜尋結果
export const questionFilterResultRequest = () => ({
  type: types.QUESTION_FILTER_RESULT_REQUEST,
});
export const questionFilterResultSuccess = (result) => ({
  type: types.QUESTION_FILTER_RESULT_SUCCESS,
  result,
});
export const questionFilterResultFailure = (err) => ({
  type: types.QUESTION_FILTER_RESULT_FAILURE,
  err,
});
export function getQuestionFilterResult(urlParameter) {
  return (dispatch) => {
    dispatch(questionFilterResultRequest());
    return getQuestions(urlParameter, dispatch)
      .then((json) => {
        dispatch(questionFilterResultSuccess(json.Result));

        return json;
      })
      .catch((err) => {
        dispatch(questionFilterResultFailure(err));
      });
  };
}
export function getQuestionById(questionId) {
  return (dispatch) => {
    dispatch(questionFilterResultRequest());

    return getQuestion(questionId)
      .then((json) => {
        if (json.Status === 0) {
          let errorMessage = _.join(json.Message, '<br>');

          Swal.fire('題目讀取錯誤', errorMessage, 'error');
          dispatch(questionFilterResultFailure(errorMessage));
          return json;
        }

        let result = Object.assign({}, json.Result);

        if (result.Status !== QuestionStatus.ACCEPT) {
          Swal.fire('題目未過審', '請重新選擇其它題目', 'error');
          dispatch(questionFilterResultFailure('請重新選擇其它題目'));
          return json;
        }

        result.HasVideo = json.Result.VideoName !== null;
        result.CorrectAnswers = [];
        result.Answers.forEach((answer) => {
          if (answer.Correct) {
            result.CorrectAnswers.push(answer.Substance);
          }
        });

        dispatch(
          questionFilterResultSuccess({
            Questions: [result],
            TotalCount: 1,
          }),
        );

        return json;
      })
      .catch((err) => {
        dispatch(questionFilterResultFailure(err));
      });
  };
}

// 已選擇試卷的狀態操作
export const AddQuestionInExam = () => ({ type: types.ADD_QUESTION_IN_EXAM });
export const DeleteQuestionInExam = (OnlyId) => ({
  type: types.DELETE_QUESTION_IN_EXAM,
  OnlyId,
});
export const DeleteDepthQuestionInExam = (questionId) => ({
  type: types.DELETE_DEPTH_QUESTION_IN_EXAM,
  questionId,
});

export const AddMainInExam = (item, nowQuestion) => ({
  type: types.ADD_MAIN_QUESTION_EXAM,
  item,
  nowQuestion,
});
export const DeleteMainInExam = (OnlyId) => ({
  type: types.DLELTE_MAIN_QUESTION_EXAM,
  OnlyId,
});

export const AddRelationInExam = (item, OnlyId) => ({
  type: types.ADD_RELATION_IN_EXAM,
  item,
  OnlyId,
});
export const DeleteRelationInExam = (OnlyId) => ({
  type: types.DELETE_RELATION_IN_EXAM,
  OnlyId,
});

export const AddHardInExam = (item, OnlyId) => ({
  type: types.ADD_HARD_QUESTION_EXAM,
  item,
  OnlyId,
});
export const DeleteHardInExam = (OnlyId) => ({
  type: types.DELETE_HARD_QUESTION_EXAM,
  OnlyId,
});

export const AddEasierInExam = (item, OnlyId) => ({
  type: types.ADD_EASIER_QUESTION_EXAM,
  item,
  OnlyId,
});
export const DeleteEasierInExam = (OnlyId) => ({
  type: types.DELETE_EASIER_QUESTION_EXAM,
  OnlyId,
});

export const RemoveAllQuestionInExam = () => ({
  type: types.REMOVEALL_QUESTION_IN_EXAM,
});

// 修改配分
export const ChangeQuestionScore = (id, score) => ({
  type: types.CHANGE_QUESTION_SCORE,
  id,
  score,
});

// 已過濾後的試題的狀態操作
export const DeleteItemInFiltedArray = (id) => ({
  type: types.DELETE_ITEM_IN_FILTED_ARRAY,
  id,
});
export const RemoveAllItemInFiltedArray = () => ({
  type: types.REMOVEALL_ITEM_FILTED_ARRAY,
});

// 考題退回
export const CallRejectQuestionAPI = () => ({ type: types.REJECT_QUESTION });
export function rejectQuestion(questionId) {
  return (dispatch) => {
    dispatch(CallRejectQuestionAPI());

    return callRejectQuestionAPI(questionId, dispatch)
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        return response;
      });
  };
}

// 完成編卷 送出
export const CallSendCompleteExamAPI = () => ({
  type: types.SEND_COMPLETE_EXAM,
});
export const CallSendCompleteExamAPISuccess = () => ({
  type: types.SEND_COMPLETE_EXAM_SUCCESS,
});
export const CallSendCompleteExamAPIFailure = () => ({
  type: types.SEND_COMPLETE_EXAM_FAILURE,
});
export function sendCompleteExam(formData) {
  return (dispatch) => {
    dispatch(CallSendCompleteExamAPI());
    return saveQuestionBank(formData, dispatch)
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (response.Status === 1) {
          dispatch(CallSendCompleteExamAPISuccess());
        } else {
          dispatch(CallSendCompleteExamAPIFailure());
        }

        return response;
      });
  };
}

// 更新會員狀態
export const CallMemberStatusAPI = () => ({ type: types.MEMBER_STATUS_SWITCH });
export function memberStatusSwitch(obj) {
  return (dispatch) => {
    dispatch(CallMemberStatusAPI());
    return callSwitchMemberStatusAPI(obj, dispatch)
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        return response;
      });
  };
}

//重設會員密碼
export const CallResetPasswordAPI = () => ({
  type: types.MEMBER_RESET_PASSWORD,
});
export function memberResetPassword(obj) {
  return (dispatch) => {
    dispatch(CallMemberStatusAPI());
    fetchFuc(Api.memberManagementAPI.resetPassword, 'POST', JSON.stringify(obj))
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {});
  };
}

// 單一會員訂單列表
export const memberOrderListRequest = () => ({
  type: types.MEMBER_ORDER_LIST_REQUEST,
});
export const memberOrderListSuccess = (result) => ({
  type: types.MEMBER_ORDER_LIST_SUCCESS,
  result,
});
export const memberOrderListFailure = (err) => ({
  type: types.MEMBER_ORDER_LIST_FAILURE,
  err,
});
export function initMemberOrderList(memberId) {
  return (dispatch) => {
    dispatch(memberOrderListRequest());
    return fetchMemberOrders(memberId, dispatch)
      .then((json) => {
        dispatch(memberOrderListSuccess(json.Result));

        return json;
      })
      .catch((err) => {
        dispatch(memberOrderListFailure(err));
      });
  };
}

// 活動列表
export const promoListRequest = () => ({ type: types.PROMO_LIST_REQUEST });
export const promoListSuccess = (result) => ({
  type: types.PROMO_LIST_SUCCESS,
  result,
});
export const promoListFailure = (err) => ({
  type: types.PROMO_LIST_FAILURE,
  err,
});
export function initPromoList() {
  return (dispatch) => {
    dispatch(saleExamListRequest());
    let query = Api.promoListAPI.GETLIST;
    fetchFuc(query, 'GET')
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .then((json) => {
        dispatch(promoListSuccess(json.Result));
      })
      .catch((err) => dispatch(promoListFailure(err)));
  };
}

// 新增活動
export const callCreatePromoAPISuccess = (result) => ({
  type: types.ADD_PROMO_SUCCESS,
  result,
});
export const callCreatePromoAPIFail = (result) => ({
  type: types.ADD_PROMO_FAILURE,
  result,
});
export function callCreatePromoAPI(obj) {
  return (dispatch) => {
    let query = Api.promoListAPI.ADD;
    fetchFuc(query, 'POST', JSON.stringify(obj))
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error)) //TO DO 重複名稱錯誤處理
      .then((response) => {
        if (response.Result) {
          dispatch(callCreatePromoAPISuccess(response.Result));
        } else {
          dispatch(callCreatePromoAPIFail(response.Code));
        }
      });
  };
}

// 刪除活動
export const callDeletePromoAPISuccess = (result) => ({
  type: types.DELETE_PROMO_SUCCESS,
  result,
});
export const callDeletePromoAPIFail = (result) => ({
  type: types.DELETE_PROMO_FAILURE,
  result,
});
export function callDeletePromoAPI(promoId) {
  return (dispatch) => {
    let query = Api.promoListAPI.DELETE + '/' + promoId;
    fetchFuc(query, 'DELETE')
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error)) //TO DO 重複名稱錯誤處理
      .then((response) => {
        if (response.Result) {
          dispatch(callDeletePromoAPISuccess(response.Result));
        } else {
          dispatch(callDeletePromoAPIFail(response.Code));
        }
      });
  };
}
// 新增活動題庫
export const callCreatePromoQuestionBankAPISuccess = (result) => ({
  type: types.ADD_PROMO_QB_SUCCESS,
  result,
});
export const callCreatePromoQuestionBankAPIFail = (result) => ({
  type: types.ADD_PROMO_QB_FAILURE,
  result,
});
export function callCreatePromoQuestionBankAPI(promoId, questionBankId) {
  return (dispatch) => {
    let query =
      Api.promoListAPI.ADD + '/' + promoId + '?questionBank=' + questionBankId;
    fetchFuc(query, 'POST')
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error)) //TO DO 重複名稱錯誤處理
      .then((response) => {
        if (response.Result) {
          dispatch(callCreatePromoQuestionBankAPISuccess(response.Result));
        } else {
          dispatch(callCreatePromoQuestionBankAPIFail(response.Code));
        }
      });
  };
}

// 刪除活動題庫
export const callDeletePromoQuestionBankAPISuccess = (result) => ({
  type: types.DELETE_PROMO_QB_SUCCESS,
  result,
});
export const callDeletePromoQuestionBankAPIFail = (result) => ({
  type: types.DELETE_PROMO_QB_FAILURE,
  result,
});
export function callDeletePromoQuestionBankAPI(promoId, questionBankId) {
  return (dispatch) => {
    let query =
      Api.promoListAPI.DELETE +
      '/' +
      promoId +
      '/questionBank/' +
      questionBankId;
    fetchFuc(query, 'DELETE')
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error)) //TO DO 重複名稱錯誤處理
      .then((response) => {
        if (response.Result) {
          dispatch(callDeletePromoQuestionBankAPISuccess(response.Result));
        } else {
          dispatch(callDeletePromoQuestionBankAPIFail(response.Code));
        }
      });
  };
}

// 售卷列表
export const saleExamListRequest = () => ({
  type: types.SALE_EXAM_LIST_REQUEST,
});
export const saleExamListSuccess = (result) => ({
  type: types.SALE_EXAM_LIST_SUCCESS,
  result,
});
export const saleExamListFailure = (err) => ({
  type: types.SALE_EXAM_LIST_FAILURE,
  err,
});
export function initSaleExamList(keyword, page, countOfPage) {
  return (dispatch) => {
    dispatch(saleExamListRequest());

    let query =
      Api.saleExamListAPI.GETLIST + `?page=${page}&count=${countOfPage}`;
    if (keyword) {
      query =
        Api.saleExamListAPI.GETLIST +
        `?page=${page}&count=${countOfPage}&keyword=${keyword}`;
    }
    fetchFuc(query, 'GET')
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .then((json) => {
        dispatch(saleExamListSuccess(json.Result));
      });
    //.catch((err) => dispatch(saleExamListFailure(err)))
  };
}
// 複製題庫
export function callCopyApI(questionBankId) {
  return (dispatch) => {
    let query = Api.saleExamListAPI.COPY + '?questionBankId=' + questionBankId;
    fetchFuc(query, 'GET')
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        //window.location.reload()
      });
  };
}
// 刪除題庫活動
export function callDeleteEventAPI(questionBankId, eventName) {
  return (dispatch) => {
    let query = Api.saleExamListAPI.GETLIST;
    fetchFuc(
      query,
      'DELETE',
      JSON.stringify({ Id: questionBankId, Usage: eventName }),
    )
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        //window.location.reload()
      });
  };
}

//題庫上架/下架/刪除，status(1=上架, 2=下架, 3=刪除)
export const CallSaleExamStatusAPI = () => ({
  type: types.SALE_EXAM_STATUS_SWITCH,
});
export function saleExamStatusAPI(status, id) {
  return (dispatch) => {
    const APIquery =
      Api.saleExamListAPI.STATUS_AND_DELETE +
      '?status=' +
      status +
      '&questionBankId=' +
      id;
    dispatch(CallSaleExamStatusAPI());
    fetchFuc(APIquery, 'GET')
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {});
  };
}

// 套卷列表
export const suitExamRequest = () => ({ type: types.SUIT_EXAM_REQUEST });
export const suitExamSuccess = (result) => ({
  type: types.SUIT_EXAM_SUCCESS,
  result,
});
export const suitExamFailure = (err) => ({
  type: types.SUIT_EXAM_FAILURE,
  err,
});
export function initSuitExamList() {
  return (dispatch) => {
    dispatch(suitExamRequest());
    fetchFuc(Api.suitExamListAPI.GETLIST, 'GET')
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .then((json) => dispatch(suitExamSuccess(json.Result)))
      .catch((err) => {
        dispatch(suitExamFailure(err));
      });
  };
}

// 新增套裝考卷
export const addSuitExamRequest = () => ({ type: types.ADD_SUIT_EXAM_REQUEST });
export const addSuitExamSuccess = (result) => ({
  type: types.ADD_SUIT_EXAM_SUCCESS,
});
export const addSuitExamFailure = (err) => ({
  type: types.ADD_SUIT_EXAM_FAILURE,
});
export function suitExamAddProduct(obj) {
  return (dispatch) => {
    dispatch(addSuitExamRequest());
    fetchFuc(Api.suitExamListAPI.ADD, 'POST', JSON.stringify(obj))
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (response.Status === 1) {
          dispatch(addSuitExamSuccess());
          dispatch(initSuitExamList());
        } else {
          dispatch(addSuitExamFailure());
        }
      });
  };
}

// 套裝狀態修改 status(1=上架, 2=下架, 3=刪除)
export function switchSuitExamDisplay(Obj) {
  return (dispatch) => {
    fetchFuc(Api.suitExamListAPI.SWITCH, 'POST', JSON.stringify(Obj))
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        dispatch(initSuitExamList());
      });
  };
}

// 修改套裝內容 (標題 描述 價格 狀態 地區)
export const editSuitExamRequest = () => ({
  type: types.EDIT_SUIT_EXAM_REQUEST,
});
export const editSuitExamSuccess = (result) => ({
  type: types.EDIT_SUIT_EXAM_SUCCESS,
});
export const editSuitExamFailure = (err) => ({
  type: types.EDIT_SUIT_EXAM_FAILURE,
});
export function suitExamEdit(obj) {
  return (dispatch) => {
    dispatch(editSuitExamRequest());
    fetchFuc(Api.suitExamListAPI.EDIT, 'POST', JSON.stringify(obj))
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (response.Status === 1) {
          dispatch(editSuitExamSuccess());
          dispatch(initSuitExamList());
        } else {
          dispatch(editSuitExamFailure());
        }
      });
  };
}

// 修改套裝內容 試卷
export const editSuitExamProductRequest = () => ({
  type: types.EDIT_SUIT_EXAM_PRODUCT_REQUEST,
});
export const editSuitExamProductSuccess = (result) => ({
  type: types.EDIT_SUIT_EXAM_PRODUCT_SUCCESS,
});
export const editSuitExamProductFailure = (err) => ({
  type: types.EDIT_SUIT_EXAM_PRODUCT_FAILURE,
});
export function suitExamEditProduct(obj) {
  return (dispatch) => {
    dispatch(editSuitExamProductRequest());
    fetchFuc(Api.suitExamListAPI.EDITPRODUCT, 'POST', JSON.stringify(obj))
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (response.Status === 1) {
          dispatch(editSuitExamProductSuccess());
          dispatch(initSuitExamList());
        } else {
          dispatch(editSuitExamProductFailure());
        }
      });
  };
}

// 課程列表
export const hangoutListRequest = () => ({ type: types.HANGOUT_LIST_REQUEST });
export const hangoutListSuccess = (result) => ({
  type: types.HANGOUT_LIST_SUCCESS,
  result,
});
export const hangoutListFailure = (err) => ({
  type: types.HANGOUT_LIST_FAILURE,
  err,
});
export function initHangoutList() {
  return (dispatch) => {
    dispatch(hangoutListRequest());
    fetchFuc(Api.hangoutAPI.GETLIST, 'GET')
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .then((json) => dispatch(hangoutListSuccess(json.Result)))
      .catch((err) => {
        dispatch(hangoutListFailure(err));
      });
  };
}

// 課程狀態修改 status(1=上架, 2=下架, 3=刪除)
export function switchHangoutDisplay(Obj) {
  return (dispatch) => {
    fetchFuc(Api.hangoutAPI.SWITCH, 'POST', JSON.stringify(Obj))
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        dispatch(initHangoutList());
      });
  };
}

export function hangoutChapterEditName(formData, callback) {
  return (dispatch) => {
    fetch(Api.hangoutAPI.HANGOUT_CHAPTER, {
      method: 'POST',
      headers: {
        Authorization: 'bearer ' + localStorage.getItem('access_token'),
      },
      body: formData,
    })
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (response.Status === 1) {
          callback(response.Result.HangoutChapterId);
        } else {
          dispatch(serverError());
        }
      });
  };
}

export function DeleteHangoutChapter(chapterID, callback) {
  return (dispatch) => {
    fetchFuc(
      Api.hangoutAPI.HANGOUT_CHAPTER + `?hangoutChapterId=${chapterID}`,
      'DELETE',
    )
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (response.Status === 1) {
          if (callback) {
            callback();
          }
        } else {
          Swal.fire({
            title: '刪除失敗',
            html: response.Message.join('<br />'),
            icon: 'error',
          });
        }
      });
  };
}

export function hangoutUnitEditName(formData, callback) {
  return (dispatch) => {
    fetch(Api.hangoutAPI.HANGOUT_CHAPTER, {
      method: 'POST',
      headers: {
        Authorization: 'bearer ' + localStorage.getItem('access_token'),
      },
      body: formData,
    })
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (response.Status === 1) {
          callback(response.Result.HangoutChapterId);
        } else {
          dispatch(serverError());
        }
      });
  };
}

export function DeleteHangoutUnit(hangoutChapterId) {
  return (dispatch) => {
    fetchFuc(
      Api.hangoutAPI.HANGOUT_CHAPTER + `?hangoutChapterId=${hangoutChapterId}`,
      'DELETE',
    )
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (response.Status === 1) {
        } else {
          dispatch(serverError());
        }
      });
  };
}

// 刪除課程單元影片
export function deleteHangoutUnitVideo(UnitID) {
  return (dispatch) => {
    fetchFuc(
      Api.hangoutAPI.HANGOUT_UNIT_VIDEO + `?hangoutUnitId=${UnitID}`,
      'DELETE',
    )
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (response.Status === 1) {
        } else {
          dispatch(serverError());
        }
      });
  };
}

// 上傳單元講義檔案
//export function uploadUnitResource(formdata, callback) {
export function uploadUnitResource(formdata, callback) {
  return (dispatch) => {
    dispatch(CallSendCompleteHangoutAPI());
    fetch(Api.hangoutAPI.HANGOUT_UNIT_RESOURCE, {
      method: 'POST',
      headers: {
        Authorization: 'bearer ' + localStorage.getItem('access_token'),
      },
      body: formdata,
    })
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (response.Status === 1) {
          callback(response.Result.Resource);
        } else {
          dispatch(serverError());
        }
      });
  };
}

// 刪除課程單元講義
export function deleteHangoutUnitResource(UnitID) {
  return (dispatch) => {
    fetchFuc(
      Api.hangoutAPI.HANGOUT_UNIT_RESOURCE + `?hangoutUnitId=${UnitID}`,
      'DELETE',
    )
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (response.Status === 1) {
        } else {
          dispatch(serverError());
        }
      });
  };
}

// 課程影片開放
export function switchHangoutUnitFree(UnitID, isFree) {
  return (dispatch) => {
    fetchFuc(
      Api.hangoutAPI.HANGOUT_UNIT_ISFREE +
        `?hangoutUnitId=${UnitID}&isFree=${isFree}`,
      'POST',
    )
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (response.Status === 1) {
        } else {
          dispatch(serverError());
        }
      });
  };
}

// 課程類別列表
export const hangoutTypeListRequest = () => ({
  type: types.HANGOUT_TYPE_LIST_REQUEST,
});
export const hangoutTypeListSuccess = (result) => ({
  type: types.HANGOUT_TYPE_LIST_SUCCESS,
  result,
});
export const hangoutTypeListFailure = (err) => ({
  type: types.HANGOU_TYPET_LIST_FAILURE,
  err,
});
export function initHangoutTypeList() {
  return (dispatch) => {
    let query = Api.hangoutTypeAPI.GETLIST;
    dispatch(hangoutTypeListRequest());
    fetchFuc(query, 'GET')
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .then((json) => dispatch(hangoutTypeListSuccess(json.Result)))
      .catch((err) => {
        dispatch(hangoutTypeListFailure(err));
      });
  };
}

// 修改課程類別
export const CallHangoutTypeEditAPI = () => ({ type: types.SUBJECT_TYPE_EDIT });

export function hangoutTypeEditAPI(obj) {
  return (dispatch) => {
    dispatch(CallHangoutTypeEditAPI());
    let query = Api.hangoutTypeAPI.EDIT;
    fetchFuc(query, 'POST', JSON.stringify(obj))
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        dispatch(initHangoutTypeList());
      });
  };
}

// 新增課程類別
export const addHangoutType = () => ({ type: types.HANGOUT_TYPE_ADD });
export function hangoutTypeAddAPI(obj) {
  return (dispatch) => {
    dispatch(addHangoutType());
    fetchFuc(Api.hangoutTypeAPI.ADD, 'POST', JSON.stringify(obj))
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        dispatch(initHangoutTypeList());
      });
  };
}

// 刪除課程類別
export const deleteHangoutType = () => ({ type: types.HANGOUT_TYPE_DELETE });
export function hangoutTypeDeleteAPI(Id) {
  return (dispatch) => {
    dispatch(deleteHangoutType());
    let query = Api.hangoutTypeAPI.DELETE;
    fetchFuc(query, 'POST', JSON.stringify({ TypeId: Id }))
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        dispatch(initHangoutTypeList());
      });
  };
}

// 完成課程編卷第一步驟 送出
export const CallSendCompleteHangoutAPI = () => ({
  type: types.SEND_COMPLETE_HANGOUT,
});
export const CallSendCompleteHangoutAPISuccess = () => ({
  type: types.SEND_COMPLETE_HANGOUT_SUCCESS,
});
export const CallSendCompleteHangoutAPIFailure = () => ({
  type: types.SEND_COMPLETE_HANGOUT_FAILURE,
});
export function sendCompleteHangout(formData, callback) {
  return (dispatch) => {
    dispatch(CallSendCompleteHangoutAPI());
    fetch(Api.hangoutAPI.SEND_COMPLETE_HANGOUT, {
      method: 'POST',
      headers: {
        Authorization: 'bearer ' + localStorage.getItem('access_token'),
      },
      body: formData,
    })
      .then((response) => checkResponseTokenEffective(response, dispatch))

      .then((response) => {
        if (response.Status === 1) {
          dispatch(CallSendCompleteHangoutAPISuccess());
          callback(response.Result);
        } else {
          dispatch(CallSendCompleteHangoutAPIFailure());
        }
      })
      .catch((error) => console.error('Error:', error));
  };
}

// 重新編課程
export const editHangoutRequest = () => ({ type: types.EDIT_HANGOUT_REQUEST });
export const editHangoutSuccess = (result) => ({
  type: types.EDIT_HANGOUT_SUCCESS,
  result,
});
export const editHangoutFailure = (err) => ({
  type: types.EDIT_HANGOUT_FAILURE,
  err,
});
export function editHangoutStateAPI(id, callback) {
  return (dispatch) => {
    dispatch(editHangoutRequest());
    fetchFuc(Api.hangoutAPI.EDIT_HANGOUT + `?hangoutId=${id}`, 'GET')
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .then((json) => {
        let newObj = JSON.parse(JSON.stringify(json.Result));
        newObj.DiscountStartAt =
          newObj.DiscountStartAt === null
            ? new Date().toLocaleDateString()
            : newObj.DiscountStartAt;
        newObj.DiscountEndAt =
          newObj.DiscountEndAt === null
            ? new Date().toLocaleDateString()
            : newObj.DiscountEndAt;
        callback(newObj);
        dispatch(editHangoutSuccess(json.Result));
      })
      .catch((err) => {
        dispatch(editHangoutFailure(err));
      });
  };
}

// 考卷類別列表
// export const examTypeListRequest = () => ({
//   type: types.EXAM_TYPE_LIST_REQUEST,
// });
// export const examTypeListSuccess = (result) => ({
//   type: types.EXAM_TYPE_LIST_SUCCESS,
//   result,
// });
// export const examTypeListFailure = (err) => ({
//   type: types.EXAM_TYPE_LIST_FAILURE,
//   err,
// });
// export function initExamTypeList() {
//   return (dispatch) => {
//     dispatch(examTypeListRequest());
//     fetchFuc(Api.examTypeAPI.GETLIST, 'GET')
//       .then((response) => checkResponseTokenEffective(response, dispatch))
//       .then((json) => dispatch(examTypeListSuccess(json.Result)))
//       .catch((err) => {
//         dispatch(examTypeListFailure(err));
//       });
//   };
// }

export const examTypeListRequest = () => ({
  type: types.EXAM_TYPE_LIST_REQUEST,
});
export const examTypeListSuccess = (result) => ({
  type: types.EXAM_TYPE_LIST_SUCCESS,
  result,
});
export const examTypeListFailure = (err) => ({
  type: types.EXAM_TYPE_LIST_FAILURE,
  err,
});
export function initExamTypeList() {
  return (dispatch) => {
    dispatch(examTypeListRequest());
    let query = Api.examTypeAPI.GETLIST;
    fetchFuc(query, 'GET')
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .then((json) => dispatch(examTypeListSuccess(json.Result)))
      .catch((err) => {
        dispatch(examTypeListFailure(err));
      });
  };
}

// 修改考卷類別
export const CallExamTypeEditAPI = () => ({ type: types.EXAM_TYPE_EDIT });

// export function examTypeEditAPI(obj) {
//   return (dispatch) => {
//     dispatch(CallExamTypeEditAPI());
//     fetchFuc(Api.examTypeAPI.EDIT, 'POST', JSON.stringify(obj))
//       .then((response) => checkResponseTokenEffective(response, dispatch))
//       .catch((error) => console.error('Error:', error))
//       .then((response) => {
//         dispatch(initExamTypeList());
//       });
//   };
// }

export function examTypeEditAPI(obj) {
  return (dispatch) => {
    dispatch(CallExamTypeEditAPI());
    let query = Api.examTypeAPI.EDIT;
    fetchFuc(query, 'POST', JSON.stringify(obj))
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        dispatch(initExamTypeList());
      });
  };
}

// 新增考卷類別
export const addExamType = (obj) => ({ type: types.EXAM_TYPE_ADD, obj });

// export function examTypeAddAPI(obj) {
//   return (dispatch) => {
//     fetchFuc(Api.examTypeAPI.ADD, 'POST', JSON.stringify(obj))
//       .then((response) => checkResponseTokenEffective(response, dispatch))
//       .catch((error) => console.error('Error:', error))
//       .then((response) => {
//         dispatch(initExamTypeList());
//       });
//   };
// }

export function examTypeAddAPI(obj) {
  return (dispatch) => {
    let query = Api.examTypeAPI.ADD;
    fetchFuc(query, 'POST', JSON.stringify(obj))
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        dispatch(initExamTypeList());
      });
  };
}

// 刪除考卷類別
export const deleteExamType = (Id) => ({ type: types.EXAM_TYPE_DELETE, Id });

// export function examTypeDeleteAPI(Id) {
//   return (dispatch) => {
//     fetchFuc(Api.examTypeAPI.DELETE, 'POST', JSON.stringify({ TypeId: Id }))
//       .then((response) => checkResponseTokenEffective(response, dispatch))
//       .catch((error) => console.error('Error:', error))
//       .then((response) => {
//         dispatch(initExamTypeList());
//       });
//   };
// }

export function examTypeDeleteAPI(Id) {
  return (dispatch) => {
    let query = Api.examTypeAPI.DELETE;
    fetchFuc(query, 'POST', JSON.stringify({ TypeId: Id }))
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        dispatch(initExamTypeList());
      });
  };
}

// 考卷科目列表
export const subjectTypeListRequest = () => ({
  type: types.SUBJECT_TYPE_LIST_REQUEST,
});
export const subjectListSuccess = (result) => ({
  type: types.SUBJECT_TYPE_LIST_SUCCESS,
  result,
});
export const subjectTypeListFailure = (err) => ({
  type: types.SUBJECT_TYPE_LIST_FAILURE,
  err,
});
export function initSubjectTypeList() {
  return (dispatch) => {
    dispatch(subjectTypeListRequest());
    let query = Api.subjectTypeAPI.GETLIST;
    fetchFuc(query, 'GET')
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .then((json) => dispatch(subjectListSuccess(json.Result)))
      .catch((err) => {
        dispatch(subjectTypeListFailure(err));
      });
  };
}

// 修改考卷科目
export const CallSubjectTypeEditAPI = () => ({ type: types.SUBJECT_TYPE_EDIT });
export function subjectTypeEditAPI(obj) {
  return (dispatch) => {
    dispatch(CallSubjectTypeEditAPI());
    fetchFuc(Api.subjectTypeAPI.EDIT, 'POST', JSON.stringify(obj))
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        dispatch(initSubjectTypeList());
      });
  };
}

// 新增考卷科目
export const addSubjectType = (obj) => ({ type: types.SUBJECT_TYPE_ADD, obj });
export function subjectTypeAddAPI(obj) {
  return (dispatch) => {
    fetchFuc(Api.subjectTypeAPI.ADD, 'POST', JSON.stringify(obj))
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        dispatch(initSubjectTypeList());
      });
  };
}

// 刪除考卷科目
export const deleteSubjectType = (Id) => ({
  type: types.SUBJECT_TYPE_DELETE,
  Id,
});
export function subjectTypeDeleteAPI(Id) {
  return (dispatch) => {
    fetchFuc(
      Api.subjectTypeAPI.DELETE,
      'POST',
      JSON.stringify({ SubjectId: Id }),
    )
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        dispatch(initSubjectTypeList());
      });
  };
}

// 首頁輪播
export const bannerCarouselRequest = () => ({
  type: types.BANNER_CAROUSEL_REQUEST,
});
export const bannerCarouselSuccess = (result) => ({
  type: types.BANNER_CAROUSEL_SUCCESS,
  result,
});
export const bannerCarouselFailure = (err) => ({
  type: types.BANNER_CAROUSEL_FAILURE,
  err,
});
export function initBannerCarousel() {
  return (dispatch) => {
    dispatch(bannerCarouselRequest());
    return callInitBannerCarouselAPI(dispatch)
      .then((json) => {
        dispatch(bannerCarouselSuccess(json.Result));

        return json;
      })
      .catch((err) => {
        dispatch(bannerCarouselFailure(err));
      });
  };
}

export const CallAddBannerAPIRequest = () => ({
  type: types.ADD_API_BANNER_REQUEST,
});
export const CallAddBannerAPISuccess = () => ({
  type: types.ADD_API_BANNER_SUCCESS,
});
export const CallAddBannerAPIFailure = (err) => ({
  type: types.ADD_API_BANNER_FAILURE,
  err,
});
export function addAPIBanner(formData) {
  return (dispatch) => {
    dispatch(CallAddBannerAPIRequest());

    return callAddBannerAPI(formData, dispatch)
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (response.Status === 1) {
          dispatch(CallAddBannerAPISuccess());
          dispatch(initBannerCarousel());
        } else {
          dispatch(CallAddBannerAPIFailure());
        }

        return response;
      });
  };
}

// 首頁輪播狀態修改
export function switchHomeBannerDisplay(formData) {
  return (dispatch) => {
    return callSwitchBannerDisplayAPI(formData, dispatch)
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (response.Status === 1) {
          dispatch(initBannerCarousel());
        }

        return response;
      });
  };
}

export const CallEditBannerAPIRequest = () => ({
  type: types.EDIT_API_BANNER_REQUEST,
});
export const CallEditBannerAPISuccess = () => ({
  type: types.EDIT_API_BANNER_SUCCESS,
});
export const CallEditBannerAPIFailure = () => ({
  type: types.EDIT_API_BANNER_FAILURE,
});
export function editAPIBanner(formData) {
  return (dispatch) => {
    dispatch(CallEditBannerAPIRequest());

    return callEditBannerAPI(formData, dispatch)
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (response.Status === 1) {
          dispatch(CallEditBannerAPISuccess());
          dispatch(initBannerCarousel());
        } else {
          dispatch(CallEditBannerAPIFailure());
        }

        return response;
      });
  };
}

export const deleteBanner = () => ({ type: types.DELETE_API_BANNER });
export function deleteAPIBanner(obj) {
  return (dispatch) => {
    dispatch(deleteBanner());
    dispatch(
      callPageLoading({
        loadingContent: '刪除廣告中...',
      }),
    );

    callDeleteBannerAPI(obj, dispatch)
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (response.Status === 1) {
          window.location.reload();
        } else {
          dispatch(cancelPageLoading());
          Swal.fire('刪除失敗', '請重新操作', 'error');
        }
      });
  };
}

export const addBanner = (item) => ({ type: types.ADD_BANNER, item });

// 題庫商店廣告
export const questionStoreRequest = () => ({
  type: types.QUESTION_STORE_REQUEST,
});
export const questionStoreSuccess = (result) => ({
  type: types.QUESTION_STORE_SUCCESS,
  result,
});
export const questionStoreFailure = (err) => ({
  type: types.QUESTION_STORE_FAILURE,
  err,
});
export function initQuestionBank() {
  return (dispatch) => {
    dispatch(questionStoreRequest());
    return callQuestionBankBannerAPI(dispatch)
      .then((json) => {
        dispatch(questionStoreSuccess(json.Result));

        return json;
      })
      .catch((err) => {
        dispatch(questionStoreFailure(err));
      });
  };
}

export const CallEditQuestionStoreAPIRequest = () => ({
  type: types.EDIT_API_QUESTIONSTORE_REQUEST,
});
export const CallEditQuestionStoreAPISuccess = () => ({
  type: types.EDIT_API_QUESTIONSTORE_SUCCESS,
});
export const CallEditQuestionStoreAPIFailure = (err) => ({
  type: types.EDIT_API_QUESTIONSTORE_FAILURE,
  err,
});
export function editAPIQuestionStore(formData) {
  return (dispatch) => {
    dispatch(CallEditQuestionStoreAPIRequest());
    return callEditQuestionBankBannerAPI(formData)
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (response.Status === 1) {
          dispatch(CallEditQuestionStoreAPISuccess());
          dispatch(initQuestionBank());
        } else {
          dispatch(CallEditQuestionStoreAPIFailure());
        }

        return response;
      });
  };
}

// 熱門精選列表
export const popularFeatureRequest = () => ({
  type: types.POPULAR_FEATURE_STATISTIC_REQUEST,
});
export const popularFeatureSuccess = (result) => ({
  type: types.POPULAR_FEATURE_STATISTIC_SUCCESS,
  result,
});
export const popularFeatureFailure = (err) => ({
  type: types.POPULAR_FEATURE_STATISTIC_FAILURE,
  err,
});
export function initPopularFeatureList() {
  return (dispatch) => {
    dispatch(popularFeatureRequest());
    fetchFuc(Api.popularSelectionAPI.GET, 'GET')
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .then((json) => dispatch(popularFeatureSuccess(json.Result)))
      .catch((err) => {
        dispatch(popularFeatureFailure(err));
      });
  };
}

// 刪除熱門精選
export function popularFeatureDelete(obj) {
  return (dispatch) => {
    fetchFuc(Api.popularSelectionAPI.DELETE, 'POST', JSON.stringify(obj))
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        dispatch(initPopularFeatureList());
      });
  };
}

// 修改熱門精選 和套裝 內容 (標題 描述)
export const editPopularFeatureRequest = () => ({
  type: types.EDIT_POPULAR_FEATURE_REQUEST,
});
export const editPopularFeatureSuccess = (result) => ({
  type: types.EDIT_POPULAR_FEATURE_SUCCESS,
});
export const editPopularFeatureFailure = (err) => ({
  type: types.EDIT_POPULAR_FEATURE_FAILURE,
});
export function popularFeatureEdit(obj) {
  return (dispatch) => {
    dispatch(editPopularFeatureRequest());
    fetchFuc(Api.popularSelectionAPI.EDIT, 'POST', JSON.stringify(obj))
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (response.Status === 1) {
          dispatch(editPopularFeatureSuccess());
          dispatch(initPopularFeatureList());
        } else {
          dispatch(editPopularFeatureFailure());
        }
      });
  };
}

// 修改熱門精選內容 試卷
export const editPopularFeatureProductRequest = () => ({
  type: types.EDIT_POPULAR_FEATURE_PRODUCT_REQUEST,
});
export const editPopularFeatureProductSuccess = (result) => ({
  type: types.EDIT_POPULAR_FEATURE_PRODUCT_SUCCESS,
  initSaleExamList,
});
export const editPopularFeatureProductFailure = (err) => ({
  type: types.EDIT_POPULAR_FEATURE_PRODUCT_FAILURE,
});
export function popularFeatureEditProduct(obj) {
  return (dispatch) => {
    dispatch(editPopularFeatureProductRequest());
    fetchFuc(Api.popularSelectionAPI.EDITALL, 'POST', JSON.stringify(obj))
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (response.Status === 1) {
          dispatch(editPopularFeatureProductSuccess());
          dispatch(initPopularFeatureList());
        } else {
          dispatch(editPopularFeatureProductFailure());
        }
      });
  };
}

// 新增熱門精選內容
export const addPopularFeatureProductRequest = () => ({
  type: types.ADD_POPULAR_FEATURE_PRODUCT_REQUEST,
});
export const addPopularFeatureProductSuccess = (result) => ({
  type: types.ADD_POPULAR_FEATURE_PRODUCT_SUCCESS,
});
export const addPopularFeatureProductFailure = (err) => ({
  type: types.ADD_POPULAR_FEATURE_PRODUCT_FAILURE,
});
export function popularFeatureAddProduct(obj) {
  return (dispatch) => {
    dispatch(addPopularFeatureProductRequest());
    fetchFuc(Api.popularSelectionAPI.ADD, 'POST', JSON.stringify(obj))
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (response.Status === 1) {
          dispatch(addPopularFeatureProductSuccess());
          dispatch(initPopularFeatureList());
        } else {
          dispatch(addPopularFeatureProductFailure());
        }
      });
  };
}

// 新增套裝精選內容
export const addSuitFeatureProductRequest = () => ({
  type: types.ADD_POPULAR_SUIT_PRODUCT_REQUEST,
});
export const addSuitFeatureProductSuccess = (result) => ({
  type: types.ADD_POPULAR_SUIT_PRODUCT_SUCCESS,
});
export const addSuitFeatureProductFailure = (err) => ({
  type: types.ADD_POPULAR_SUIT_PRODUCT_FAILURE,
});
export function popularSuitAddProduct(obj) {
  return (dispatch) => {
    dispatch(addSuitFeatureProductRequest());
    fetchFuc(Api.popularSelectionAPI.ADDSUIT, 'POST', JSON.stringify(obj))
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (response.Status === 1) {
          dispatch(addSuitFeatureProductSuccess());
          dispatch(initPopularFeatureList());
        } else {
          dispatch(addSuitFeatureProductFailure());
        }
      });
  };
}

// 修改套裝精選內容 試卷
export const editSuitFeatureProductRequest = () => ({
  type: types.EDIT_SUIT_FEATURE_PRODUCT_REQUEST,
});
export const editSuitFeatureProductSuccess = (result) => ({
  type: types.EDIT_SUIT_FEATURE_PRODUCT_SUCCESS,
});
export const ediSuitFeatureProductFailure = (err) => ({
  type: types.EDIT_SUIT_FEATURE_PRODUCT_FAILURE,
});
export function suitFeatureEditProduct(obj) {
  return (dispatch) => {
    dispatch(editSuitFeatureProductRequest());
    fetchFuc(Api.popularSelectionAPI.EDITSETALL, 'POST', JSON.stringify(obj))
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (response.Status === 1) {
          dispatch(editSuitFeatureProductSuccess());
          dispatch(initPopularFeatureList());
        } else {
          dispatch(ediSuitFeatureProductFailure());
        }
      });
  };
}

// 熱門精選狀態修改
export function switchPopularFeatureDisplay(Obj) {
  return (dispatch) => {
    fetchFuc(Api.popularSelectionAPI.SWITCH, 'POST', JSON.stringify(Obj))
      .then((response) => checkResponseTokenEffective(response, dispatch))
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        dispatch(initPopularFeatureList());
      });
  };
}

export const callPageLoading = (setting) => ({
  type: types.CALL_PAGE_LOADING,
  setting: Object.assign(
    {},
    { isLoading: true, cancelable: false, loadingContent: '' },
    setting,
  ),
});

export const cancelPageLoading = () => ({
  type: types.CANCEL_PAGE_LOADING,
});

export const addFlashMessage = (message) => ({
  type: types.ADD_FLASH_MESSAGE,
  message: message,
});

export const clearShownMessages = () => ({
  type: types.DELETE_FLASH_MESSAGE,
});

export const reduxSetVideoQueue = (videoQueue) => ({
  type: types.SET_VIDEO_QUEUE,
  videoQueue: videoQueue,
});

export const reduxSetKnowledge = (knowledge) => ({
  type: types.SET_KNOWLEDGE,
  knowledge: knowledge,
});
