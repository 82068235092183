import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import questionAPI from '../../middleware/questionAPI';

const AudioPlayer = (props) => {
  const { audioName, audioPath } = props;
  const [audioUrl, setAudioUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (_.trim(audioName) !== '') {
      setIsLoading(true);

      questionAPI.signed(audioPath, audioName).then((response) => {
        setAudioUrl(response);
        setIsLoading(false);
      });
    }

    return () => {
      setAudioUrl(null);
      setIsLoading(false);
    };
  }, [audioName, audioPath]);

  return (
    <Box mt={2} mb={2}>
      {audioUrl && (
        <>
          <audio controls src={audioUrl} controlsList="nodownload">
            瀏覽器不支援音效播放
          </audio>
        </>
      )}

      {isLoading && (
        <Alert
          icon={<HourglassEmptyRoundedIcon />}
          variant="filled"
          severity="info">
          音訊檔案讀取中...
        </Alert>
      )}
    </Box>
  );
};

export default AudioPlayer;
