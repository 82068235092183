import React, { useState, useEffect } from 'react';
import { getQuestion } from '../../Util/fetchMethods';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
  LinearProgress,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import _ from 'lodash';
import QuestionDetail from '../Question/QuestionDetail';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import commonData from '../../data/common.json';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  titleDesc: {
    color: theme.palette.grey[500],
    fontSize: '0.9rem',
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(2),
    '& div': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const QuestionDetailDialog = (props) => {
  const [pageStatus, setPageStatus] = useState('init');
  const [question, setQuestion] = useState({});
  const [errorMsg, setErrorMsg] = useState('');
  const classes = useStyles();

  useEffect(() => {
    if (_.isInteger(_.parseInt(props.questionId)) === false) {
      setPageStatus('init');
    } else {
      setPageStatus('loading');
      getQuestion(props.questionId)
        .then((response) => {
          if (response.Status !== 0) {
            setQuestion({ ...response.Result });
            setPageStatus('question');
          } else {
            setPageStatus('error');
            setErrorMsg(_.join(response.Message, '<br>'));
          }
        })
        .catch((err) => {
          setPageStatus('error');
          setErrorMsg(err.msg);
        });
    }
  }, [props.questionId]);

  return (
    <Dialog
      onClose={props.close}
      aria-labelledby="question-detail-dialog-title"
      open={props.open}
      fullWidth
      keepMounted
      maxWidth="md"
      scroll="paper">
      <DialogTitle id="question-detail-dialog-title">
        {props.label ? props.label : '題目詳解'}
        <Box className={classes.titleDesc}>
          題目ID {props.questionId} | 題型{' '}
          {commonData.QuestionType[question.QuestionType]}
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box>
          {() => {
            switch (pageStatus) {
              case 'init':
                return (
                  <Alert severity="info">
                    <AlertTitle>題目詳細說明</AlertTitle>
                    尚無題目內容可顯示
                  </Alert>
                );
                break;

              case 'loading':
                return (
                  <Alert severity="info">
                    <AlertTitle>題目詳細說明</AlertTitle>
                    題目內容即將載入 <strong>請稍候</strong>
                    <LinearProgress />
                  </Alert>
                );
                break;

              case 'error':
                return (
                  <Alert severity="error">
                    <AlertTitle>題目內容載入失敗</AlertTitle>
                    {errorMsg}
                  </Alert>
                );
                break;

              case 'question':
              default:
                return <QuestionDetail question={question} open={props.open} />;
                break;
            }
          }}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={props.close}>
          關閉 <CloseOutlinedIcon size="small" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuestionDetailDialog;
