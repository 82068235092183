import * as types from '../actionType';

let initState = {
  feature: {},
  member: {},
  order: {},
};

function HomePageStatisticState(state = initState, action) {
  switch (action.type) {
    case types.HOME_FEATURE_STATISTIC_SUCCESS: {
      let newObj = Object.assign({}, state);
      newObj.feature = action.result;
      return newObj;
    }
    case types.HOME_ORDER_STATISTIC_SUCCESS: {
      let newObj = Object.assign({}, state);
      newObj.order = action.result;
      return newObj;
    }
    case types.HOME_MEMBER_STATISTIC_SUCCESS: {
      let newObj = Object.assign({}, state);
      newObj.member = action.result;
      return newObj;
    }
    default:
      return state;
  }
}

export default HomePageStatisticState;
