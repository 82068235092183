import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Divider,
  Grid,
  Button,
} from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import _ from 'lodash';
import { transformProductsData } from '../../Util/Util';
import ProductCard from './ProductCard';
import SearchProductDialog from '../Dialogs/SearchProductDialog';

const SuitExamProducts = (props) => {
  const [searchProductOpen, setSearchProductOpen] = useState(false);

  return (
    <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
      <Typography variant="h5" color="textPrimary" gutterBottom>
        套卷商品內容
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddOutlinedIcon />}
          onClick={() => {
            setSearchProductOpen(true);
          }}
          style={{ marginLeft: '8px' }}>
          增加商品
        </Button>
      </Typography>
      <Divider light />
      <Box p={3} style={{ backgroundColor: '#eee' }}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          spacing={2}>
          {props.questionBankList.map((questionBank) => (
            <Grid item xs={12} sm={3} key={questionBank.QuestionBankId}>
              <ProductCard
                product={questionBank}
                removeProduct={() => {
                  props.editProducts([
                    {
                      productType: 'questionBank',
                      action: 'del',
                      product: questionBank,
                    },
                  ]);
                }}
              />
            </Grid>
          ))}

          {props.hangoutList.map((hangout) => (
            <Grid item xs={12} sm={3} key={hangout.HangoutId}>
              <ProductCard
                product={hangout}
                removeProduct={() => {
                  props.editProducts([
                    {
                      productType: 'hangout',
                      action: 'del',
                      product: hangout,
                    },
                  ]);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <SearchProductDialog
        open={searchProductOpen}
        productType="questionBank"
        close={() => {
          setSearchProductOpen(false);
        }}
        questionBanks={transformProductsData(props.questionBankList)}
        hangouts={transformProductsData(props.hangoutList)}
        editProducts={props.editProducts}
      />
    </Box>
  );
};

export default SuitExamProducts;
