import React, { useState, useEffect } from 'react';
import {
  Paper,
  makeStyles,
  Typography,
  Chip,
  Divider,
  Button,
  Grid,
  Tooltip,
} from '@material-ui/core';
import RestoreIcon from '@material-ui/icons/Restore';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import _ from 'lodash';
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  paper: {
    padding: theme.spacing(1),
  },
  heading: {
    margin: theme.spacing(1, 0),
  },
  grid: {
    margin: theme.spacing(2, 0),
  },
  gridItem: {
    textAlign: 'center',
  },
  footer: {
    textAlign: 'right',
    margin: theme.spacing(2, 0),
  },
  helper: {
    padding: theme.spacing(1),
    height: '100%',
    float: 'left',
  },
  helperContent: {
    margin: 0,
    fontSize: '0.8rem',
    textAlign: 'left',
    color: 'red',
    letterSpacing: '1px',
  },
  imageDiv: {
    float: 'left',
    width: '100%',
    textAlign: 'center',
  },
  image: {
    margin: 'auto',
    maxHeight: '300px',
    maxWidth: '90%',
    cursor: 'pointer',
  },
  fileInput: {
    display: 'none',
  },
}));

const UploadImageFile = (props) => {
  const [imageUrl, setImageUrl] = useState(props.imageUrl);
  const [imageFileId] = useState(() => _.uniqueId('image-file-upload-'));
  const classes = useStyles();

  useEffect(() => {
    setImageUrl(props.imageUrl);
  }, [props.imageUrl]);

  const handleSelectedImg = (evt) => {
    const file = evt.target.files.item(0);
    let imageType = /^image\//;

    if (!file) return;

    if (!imageType.test(file.type)) {
      Swal.fire('錯誤', '請選擇圖片！', 'error');

      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', imageLoaded);
  };

  const imageLoaded = (evt) => {
    setImageUrl(evt.target.result);
  };

  const revertImage = () => {
    props.imageFileInputRef.current.value = '';
    setImageUrl(props.imageUrl);
  };

  return (
    <div className={classes.root}>
      <Paper elevation={3} className={classes.paper}>
        <Typography variant="subtitle1" className={classes.heading}>
          {props.label ? props.label : '圖片設定'}
        </Typography>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="center"
          alignItems="stretch"
          className={classes.grid}>
          <Grid
            item
            xs={12}
            sm={props.helper ? 8 : 12}
            className={classes.gridItem}>
            {imageUrl ? (
              <div className={classes.imageDiv}>
                <label htmlFor={imageFileId}>
                  <Tooltip title="點擊上傳新圖片" arrow>
                    <img
                      src={imageUrl}
                      className={classes.image}
                      alt={props.label ? props.label : '圖片設定'}
                    />
                  </Tooltip>
                </label>
              </div>
            ) : null}
            <input
              id={imageFileId}
              type="file"
              onChange={handleSelectedImg}
              className={classes.fileInput}
              ref={props.imageFileInputRef}
            />
            <label htmlFor={imageFileId} className={imageUrl ? 'hidden' : ''}>
              <Chip
                color="primary"
                icon={<CloudUploadOutlinedIcon />}
                label="上傳圖片"
                clickable
              />
            </label>
          </Grid>
        </Grid>
        <Divider />
        <div className={classes.footer}>
          <div className={classes.helper}>
            <Typography variant="body1" className={classes.helperContent}>
              {props.helper}
            </Typography>
          </div>
          <Button
            variant="contained"
            color="secondary"
            disabled={imageUrl === props.imageUrl}
            startIcon={<RestoreIcon />}
            onClick={revertImage}>
            還原設定
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default UploadImageFile;
