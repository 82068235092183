import {
  S3Client,
  CreateMultipartUploadCommand,
  UploadPartCommand,
  CompleteMultipartUploadCommand,
  AbortMultipartUploadCommand,
  DeleteObjectCommand,
  PutObjectCommand,
} from '@aws-sdk/client-s3';
import { AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY } from '../data/aws';

const s3Creds = {
  accessKeyId: AWS_ACCESS_KEY_ID,
  secretAccessKey: AWS_SECRET_ACCESS_KEY,
};

const s3Client = new S3Client({
  region: 'ap-northeast-1',
  credentials: s3Creds,
});

const multipartUploadS3File = async (file, options) => {
  const s3Params = {
    Bucket: options.bucket,
    ACL: options.acl ? options.acl : 'bucket-owner-full-control',
  };
  const uploadKey =
    (options.dir ? options.dir + '/' : '') +
    (options.fileName ? options.fileName : file.name);

  let multipartUploadId = null;

  try {
    const multipartUploadData = await s3Client.send(
      new CreateMultipartUploadCommand({ ...s3Params, Key: uploadKey }),
    );

    if (!multipartUploadData.UploadId) {
      throw new Error('無法建立上傳');
    }

    multipartUploadId = multipartUploadData.UploadId;
    const partSize = 1024 * 1024 * 100; // 100MB
    const fileSize = file.size;
    const numParts = Math.ceil(fileSize / partSize);
    const uploadedParts = [];
    let remainingBytes = fileSize;

    if (options.uploadStart) {
      options.uploadStart(numParts);
    }

    for (let i = 1; i <= numParts; i++) {
      let startOfPart = fileSize - remainingBytes;
      let endOfPart = Math.min(partSize, startOfPart + remainingBytes);

      if (i > 1) {
        endOfPart = startOfPart + Math.min(partSize, remainingBytes);
        startOfPart += 1;
      }

      const uploadParams = {
        Body: file.slice(startOfPart, endOfPart + 1),
        Bucket: s3Params.Bucket,
        Key: uploadKey,
        UploadId: multipartUploadData.UploadId,
        PartNumber: i,
      };

      const uploadPartResponse = await s3Client.send(
        new UploadPartCommand(uploadParams),
      );

      remainingBytes -= Math.min(partSize, remainingBytes);
      uploadedParts.push({ PartNumber: i, ETag: uploadPartResponse.ETag });

      if (options.uploadProgress) {
        options.uploadProgress(i);
      }
    }

    const completeParams = {
      Bucket: s3Params.Bucket,
      Key: uploadKey,
      UploadId: multipartUploadData.UploadId,
      MultipartUpload: {
        Parts: uploadedParts,
      },
    };

    const completeData = await s3Client.send(
      new CompleteMultipartUploadCommand(completeParams),
    );

    return (
      'https://s3-ap-northeast-1.amazonaws.com/' +
      s3Params.Bucket +
      '/' +
      uploadKey
    );
  } catch (error) {
    if (multipartUploadId !== null) {
      const abortParams = {
        Bucket: s3Params.Bucket,
        Key: uploadKey,
        UploadId: multipartUploadId,
      };

      const abortData = s3Client.send(
        new AbortMultipartUploadCommand(abortParams),
      );
    }
    console.log('upload s3 error', error);

    return false;
  }
};

const uploadS3File = async (file, options) => {
  const uploadKey =
    (options.dir ? options.dir + '/' : '') +
    (options.fileName ? options.fileName : file.name);

  const uploadParams = {
    Bucket: options.bucket,
    Key: uploadKey,
    Body: file,
    ACL: options.acl ? options.acl : 'bucket-owner-full-control',
  };

  try {
    await s3Client.send(new PutObjectCommand(uploadParams));
  } catch (error) {
    return false;
  }

  return (
    'https://s3-ap-northeast-1.amazonaws.com/' +
    options.bucket +
    '/' +
    uploadKey
  );
};

const deleteS3File = async (bucket, key) => {
  const bucketParams = {
    Bucket: bucket,
    Key: key,
  };

  try {
    const deleteData = await s3Client.send(
      new DeleteObjectCommand(bucketParams),
    );

    return deleteData;
  } catch (error) {
    console.log('delete s3 file error', error);
  }
};

export { multipartUploadS3File, uploadS3File, deleteS3File };
