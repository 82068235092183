import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import teacherAPI from '../../middleware/teacherAPI';
import BreakCrumbs from '../../components/BreakCrumbs';
import ImageFileUpload from '../../components/Forms/ImageFileUpload';
import {
  Box,
  Grid,
  Button,
  Paper,
  Typography,
  Divider,
  TextField,
  Switch,
} from '@material-ui/core';
import Editor from '../../components/Editor';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import KeyboardReturnRoundedIcon from '@material-ui/icons/KeyboardReturnRounded';
import { useHistory } from 'react-router-dom';
import { uploadS3File } from '../../Util/awsS3';
import { AWS_TEACHER_POSITION } from '../../data/aws';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { addFlashMessage } from '../../store/action/index';
import { useDispatch } from 'react-redux';
import { alertError } from '../../Util/Util';

const initTeacher = {
  teacherId: undefined,
  name: '',
  introduction: '',
  imageUrl: '',
  imageName: '',
  status: 0,
};

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  textAlignRight: {
    textAlign: 'right',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  buttonDivider: {
    margin: theme.spacing(2, 'auto'),
  },
}));

const Teacher = (props) => {
  const { teacherId } = useParams();
  const [teacher, setTeacher] = useState({ ...initTeacher });
  const [isProcessing, setIsProcessing] = useState(false);
  const imageFileRef = useRef(null);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (teacherId) {
      setIsProcessing(true);

      teacherAPI
        .get(teacherId)
        .then((response) => {
          setTeacher(response);
        })
        .then(() => {
          setIsProcessing(false);
        });
    }
  }, [teacherId]);

  const handleTeacherChange = (name, value) => {
    setTeacher({ ...teacher, [name]: value });
  };

  const handleSaveTeacher = () => {
    try {
      if (_.trim(teacher.name) === '') {
        throw new Error('請輸入名稱');
      }

      if (
        teacher.imageName === '' &&
        (!imageFileRef.current.files || imageFileRef.current.files.length <= 0)
      ) {
        throw new Error('請上傳照片');
      }

      if (_.trim(teacher.introduction) === '') {
        throw new Error('請編輯老師介紹');
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'info',
      });

      return false;
    }

    setIsProcessing(true);

    let avatarFileName = uuidv4();

    const api =
      _.parseInt(teacher.teacherId) > 0 ? teacherAPI.edit : teacherAPI.add;

    let params = new URLSearchParams();

    if (_.parseInt(teacher.teacherId) > 0) {
      params.append('TeacherId', teacher.teacherId);
    }

    params.append('Status', teacher.status);
    params.append('Name', teacher.name);
    params.append('Introduction', teacher.introduction);
    params.append('ImageName', teacher.imageName);

    if (imageFileRef.current.files && imageFileRef.current.files.length > 0) {
      params.set('ImageName', avatarFileName);

      uploadS3File(imageFileRef.current.files[0], {
        fileName: avatarFileName,
        dir: AWS_TEACHER_POSITION.avatar.dir,
        bucket: AWS_TEACHER_POSITION.avatar.bucket,
        acl: AWS_TEACHER_POSITION.avatar.acl,
      }).then((uploadResponse) => {
        if (uploadResponse === false) {
          Swal.fire({
            title: '儲存失敗',
            text: '檔案沒有成功上傳到S3',
            icon: 'warning',
          });

          setIsProcessing(false);

          return false;
        }

        saveTeacher(api, params);
      });
    } else {
      saveTeacher(api, params);
    }
  };

  const saveTeacher = (api, params) => {
    return api(params).then(
      () => {
        setIsProcessing(false);
        dispatch(addFlashMessage('儲存完成'));
        history.goBack();
      },
      (error) => {
        alertError(error);
        setIsProcessing(false);
      },
    );
  };

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={props.match.url}></BreakCrumbs>

      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Typography variant="h5" color="textPrimary" gutterBottom>
          {teacherId ? '編輯師資' : '建立師資'}
        </Typography>

        <Divider light />

        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          spacing={2}>
          <Grid item sm={12} md={7}>
            <TextField
              fullWidth
              className={classes.input}
              label="老師名稱"
              value={teacher ? teacher.name : ''}
              onChange={(e) => {
                handleTeacherChange('name', e.target.value);
              }}
              disabled={isProcessing}
            />

            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="baseline"
              className={classes.input}>
              <Grid item xs={3}>
                <Typography variant="subtitle1">上線狀態</Typography>
              </Grid>

              <Grid item xs={3} className={classes.textAlignRight}>
                <Switch
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  checked={teacher.status === 1}
                  onChange={(e) => {
                    handleTeacherChange('status', e.target.checked ? 1 : 0);
                  }}
                  disabled={isProcessing}
                />
              </Grid>
            </Grid>

            <Editor
              content={_.unescape(teacher.introduction)}
              setContent={(newContent) => {
                handleTeacherChange('introduction', _.escape(newContent));
              }}
              imageS3Position={{
                bucket: AWS_TEACHER_POSITION.introduction.bucket,
                dir: AWS_TEACHER_POSITION.introduction.dir,
                acl: AWS_TEACHER_POSITION.introduction.acl,
              }}
              editorProps={{
                height: '350',
                placeholder: '老師介紹',
              }}
              disabled={isProcessing}
            />
          </Grid>

          <Grid item sm={12} md={5}>
            <ImageFileUpload
              label="設定老師照片"
              imageUrl={teacher.imageUrl}
              imageFileInputRef={imageFileRef}
              helper="檔案格式為png, jpeg, jpg"
            />
          </Grid>
        </Grid>

        <Divider className={classes.buttonDivider} />

        <Box mt={3} mb={3} textAlign="right">
          <Button
            startIcon={<SaveRoundedIcon />}
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSaveTeacher}
            disabled={isProcessing}>
            儲存
          </Button>

          <Button
            startIcon={<KeyboardReturnRoundedIcon />}
            variant="contained"
            color="default"
            size="large"
            className={classes.button}
            onClick={() => {
              history.goBack();
            }}
            disabled={isProcessing}>
            回列表
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default Teacher;
