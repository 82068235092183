import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import _ from 'lodash';
import QuestionVideo from '../Question/QuestionVideo';

const VideoNodePlayerDialog = (props) => {
  const { open, setOpen, videoPath, videoName, startAt, endAt } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      size="sm"
      fullWidth={true}>
      <DialogTitle>影片節點播放</DialogTitle>

      <DialogContent>
        {videoName && (
          <QuestionVideo
            videoPath={videoPath}
            videoName={videoName}
            open={open}
            startAt={startAt}
            endAt={endAt}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VideoNodePlayerDialog;
