import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import emptySvg from '../images/emptyStreet.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    backgroundColor: theme.palette.grey[50],

    '& img': {
      margin: theme.spacing(5, 'auto'),
      width: '60vh',
      maxWidth: '98%',
    },
  },
  message: {
    fontSize: '1.5rem',
    fontWeight: '700',
    padding: theme.spacing(0, 0, 3),
  },
}));

const TableEmpty = (props) => {
  const classes = useStyles();
  const { message } = props;

  return (
    <Box textAlign="center" className={classes.root}>
      <img src={emptySvg} />
      <Box className={classes.message}>
        {message || '目前沒有資料，您可以新增一筆來建立相關資訊'}
      </Box>
    </Box>
  );
};

export default TableEmpty;
