import * as types from '../actionType';
import _ from 'lodash';

let initState = {
  queue: [],
};

function videoQueueState(state = initState, action) {
  switch (action.type) {
    case types.ADD_VIDEO_QUEUE: {
      let newState = Object.assign({}, state);

      newState.queue.push(action.video);

      return newState;
    }

    case types.SET_VIDEO_QUEUE: {
      let newState = Object.assign({}, state);

      newState.queue = action.videoQueue;

      return newState;
    }

    default:
      return state;
  }
}

export default videoQueueState;
