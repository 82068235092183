import React from 'react';

import backgroundImage from '../images/backgroundImage.png';
import '../style/components/page-404.scss';

const Page500 = () => {
  let title = '伺服器出現錯誤 請稍後在試';
  return (
    <div className="page-404">
      <div
        className="image-block"
        style={{ backgroundImage: `url('${backgroundImage}')` }}></div>
      <div className="title" dangerouslySetInnerHTML={{ __html: title }}></div>
    </div>
  );
};

export default Page500;
