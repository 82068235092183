import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import emptySvg from '../../images/empty.svg';
import _ from 'lodash';
import TablePaginationActions from '../TablePaginationActions';
import HandoutTableRow from './HandoutTableRow';

const useStyles = makeStyles((theme) => ({
  emptyImg: {
    textAlign: 'center',
    backgroundColor: theme.palette.grey[50],

    '& img': {
      margin: theme.spacing(5, 'auto'),
      maxHeight: '30vh',
    },
  },
  table: {
    marginTop: theme.spacing(3),
    '& thead > tr > th': {
      fontWeight: '600',
      color: '#fff',
    },
  },
}));

const HandoutTable = (props) => {
  const {
    handouts,
    defaultPrePageRows,
    perPageRows,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    totalCount,
    deleteHandout,
    editHandout,
    downloadHandoutAllQrcode,
  } = props;
  const classes = useStyles();

  return (
    <>
      {handouts.length > 0 && (
        <TableContainer component={Box}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>ID</TableCell>
                <TableCell align="right">名稱</TableCell>
                <TableCell align="right">開放時間</TableCell>
                <TableCell align="right">功能</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(handouts, (handout) => (
                <HandoutTableRow
                  key={handout.handoutId}
                  handout={handout}
                  deleteHandout={deleteHandout}
                  editHandout={editHandout}
                  downloadHandoutAllQrcode={downloadHandoutAllQrcode}
                />
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[defaultPrePageRows, 25, 50]}
                  colSpan={5}
                  count={totalCount}
                  rowsPerPage={perPageRows}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}

      {handouts.length <= 0 && (
        <Box textAlign="center" className={classes.emptyImg}>
          <img src={emptySvg} />
          <Box
            fontWeight="fontWeightBold"
            fontSize="h3.fontSize"
            lineHeight="3">
            目前沒有講義
          </Box>
        </Box>
      )}
    </>
  );
};

export default HandoutTable;
