import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import Logo from '../images/logo.png';
// import Search from '../images/Search.png';
import Ring from '../images/Ring.png';
import Account from '../images/Account.png';
import pathMap from '../data/RouteMap';
import Notification from './Notification';
import '../style/components/header.scss';
import { getEnvName, isOnlineEnv, signalR } from '../Util/Util';
import { connect } from 'react-redux';
import { logoutSuccess } from '../store/action';
import UploadQueueStatus from './UploadQueueStatus';
const version = require('../../package.json').version;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '首頁',
      adminName: localStorage.getItem('adminName') || '',
      isShowInformation: false,
      notificationArray: [],
    };
  }

  componentDidMount() {
    signalR(this.handleInputNotification);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      adminName: nextProps.loginState.adminName,
    });
  }

  handleInputNotification = (message) => {
    let newArr = [...this.state.notificationArray];
    newArr.push(message);
    this.setState({
      notificationArray: newArr,
    });
  };

  handleRemoveNotification = (index) => {
    let newArr = [...this.state.notificationArray];
    newArr.splice(index, 1);
    this.setState({
      notificationArray: newArr,
    });
  };

  toggleInformation = () => {
    this.setState({
      isShowInformation: !this.state.isShowInformation,
    });
  };

  render() {
    let titleArray = this.props.location.pathname.split('/');
    titleArray = titleArray.filter((item) => pathMap[item] !== undefined);
    let title = titleArray[titleArray.length - 1];
    title = pathMap[title];

    let { loginState } = this.props;
    let { adminName, isShowInformation, notificationArray } = this.state;

    const versionClassName = isOnlineEnv()
      ? 'version'
      : 'version version--highlight';

    return (
      <div
        className="header fadeIn"
        style={{ display: loginState.isLogin ? 'flex' : 'none' }}>
        <div className="logo">
          <NavLink exact to="/" activeClassName="active">
            <img src={Logo} width="80%" alt="" />
          </NavLink>
        </div>
        <div
          className="information"
          style={{ display: loginState.isLogin ? 'flex' : 'none' }}>
          <label htmlFor={'toggle-menu'} className="hambarger">
            <div className="bar"></div>
          </label>
          <div className="title">{title}</div>
          <div className="tools">
            {/* <div className="search">
              <img src={Search} alt='' />
            </div> */}
            <div className={versionClassName}>
              {getEnvName()}
              <br />
              {`v${version}`}
            </div>
            <div className="videoUpload">
              <UploadQueueStatus />
            </div>
            <div className="ring" onClick={this.toggleInformation}>
              <img src={Ring} alt="" />
              <div
                className="notification-dot fadeIn"
                style={{
                  display: notificationArray.length > 0 ? 'block' : 'none',
                }}>
                {notificationArray.length > 99 ? 99 : notificationArray.length}
              </div>
            </div>
            <div className="account" onClick={this.toggleInformation}>
              <img src={Account} alt="" />
              <Notification
                isShowInformation={isShowInformation}
                adminName={adminName}
                logoutSuccessFunc={this.props.logoutSuccess}
                notificationArray={notificationArray}
                removeNotification={this.handleRemoveNotification}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  loginState: store.loginState,
});

export default connect(
  mapStateToProps,
  {
    logoutSuccess,
  },
  null,
  { pure: false },
)(withRouter(Header));
