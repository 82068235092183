import * as types from '../actionType';

let initState = {};

function ReEditExamState(state = initState, action) {
  switch (action.type) {
    case types.REEDIT_EXAM_SUCCESS: {
      return action.result;
    }
    case types.ADD_HARD_EXAM: {
      let newState = JSON.parse(JSON.stringify(state));
      if (
        !newState.HarderQuestionBankList.some(
          (item) => item.QuestionBankId === action.obj.QuestionBankId,
        )
      )
        newState.HarderQuestionBankList.push(action.obj);
      return newState;
    }
    case types.DELETE_HARD_EXAM: {
      let newState = JSON.parse(JSON.stringify(state));
      newState.HarderQuestionBankList = newState.HarderQuestionBankList.filter(
        (item) => item.QuestionBankId !== action.id,
      );
      return newState;
    }
    case types.ADD_EASIER_EXAM: {
      let newState = JSON.parse(JSON.stringify(state));
      if (
        !newState.EasierQuestionBankList.some(
          (item) => item.QuestionBankId === action.obj.QuestionBankId,
        )
      )
        newState.EasierQuestionBankList.push(action.obj);
      return newState;
    }
    case types.DELETE_EASIER_EXAM: {
      let newState = JSON.parse(JSON.stringify(state));
      newState.EasierQuestionBankList = newState.EasierQuestionBankList.filter(
        (item) => item.QuestionBankId !== action.id,
      );
      return newState;
    }
    case types.INIT_REEDIT_EXAM: {
      return {};
    }
    default:
      return state;
  }
}

export default ReEditExamState;
