import axios from 'axios';
import qs from 'qs';
import { camelizeKeys } from 'humps';
import { SERVER_HOST, SERVER_API_TIMEOUT_MS } from '../store/apiconfig';

function parseBody(response) {
  if (response.status === 200) {
    if (response.data.status === 1) {
      return response.data.result;
    } else {
      return parseError(response.data.message);
    }
  }

  return parseError(response.data.messages);
}

/**
 * parse error response
 */
function parseError(messages) {
  if (messages) {
    if (messages instanceof Array) {
      return Promise.reject({ messages });
    }

    return Promise.reject({ messages: [messages.Message] });
  }

  return Promise.reject({ messages: ['錯誤'] });
}

/**
 * axios instance
 */
const instance = axios.create({
  baseURL: `${SERVER_HOST}/api/`,
  timeout: SERVER_API_TIMEOUT_MS,
  responseType: 'json',
  paramsSerializer(params) {
    return qs.stringify(params, { indices: false });
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    if (error.response) {
      return parseError(error.response.data);
    }

    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => parseBody(camelizeKeys(response)),
  (error) => {
    if (error.request.status === 401 || error.request.status === 403) {
      window.location.href = '/Logout';
      return false;
    }

    if (error.response) {
      return parseError(error.response.data);
    }

    return Promise.reject(error);
  },
);

export default instance;
