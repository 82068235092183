import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BreakCrumbs from '../../components/BreakCrumbs';
import { Stepper, Box, StepButton, Step, Zoom, Fab } from '@material-ui/core';
import SpellcheckRoundedIcon from '@material-ui/icons/SpellcheckRounded';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import ResultDrag from '../../components/SetExam/ResultDrag';
import SourceQuestion from '../../components/SetExam/SourceQuestion';
import ExamSetting from '../../components/SetExam/ExamSetting';
import {
  getQuestionFilterResult,
  getQuestionById,
  initQuestionSource,
  RemoveAllQuestionInExam,
  RemoveAllItemInFiltedArray,
  callPageLoading,
  cancelPageLoading,
  getQuestionByBankId,
} from '../../store/action';
import { getQuestionBankDetial } from '../../Util/fetchMethods';
import '../../style/components/SetExam/set-exam.scss';
import { makeStyles } from '@material-ui/core/styles';
import { QuestionStatus } from '../../data/common.js';
import _ from 'lodash';
import Swal from 'sweetalert2';

const steps = [
  { label: '編卷', icon: <SpellcheckRoundedIcon /> },
  { label: '設定背景', icon: <TuneOutlinedIcon /> },
];

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    right: theme.spacing(6),
    bottom: theme.spacing(3),
    zIndex: 200,
    '& span': {
      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
  },
}));

/**
 * SEtExam component
 */
const SetExam = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [numberOfPage] = useState(8);
  const [currPage, setCurrPage] = useState(1);
  const [subject, setSubject] = useState(12);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchQuestionsParams, setFetchQuestionsParams] = useState([]);
  const [isFetchRandom, setIsFetchRandom] = useState(false);
  const [questionIncompleteSetting, setQuestionIncompleteSetting] = useState(
    false,
  );
  const { QuestionBankId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [questionBank, setQuestionBank] = useState({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const questionSource = useSelector((state) => state.QuestionSourceState);

  const handleStep = (step) => {
    setActiveStep(step);
  };

  useEffect(() => {
    if (activeStep === 1 && questionIncompleteSetting === true) {
      Swal.fire('題目尚未設定完成', '請注意每題種類都需設定題目', 'warning');
    }
  }, [activeStep]);

  const getSelectedSource = (urlParams, isRandom) => {
    if (_.isArray(urlParams) === false || urlParams.length <= 0) {
      urlParams = ['categorys=12'];
    }

    setFetchQuestionsParams([...urlParams]);
    setIsFetchRandom(isRandom);
    searchQuestions(1, urlParams);
  };

  const searchQuestions = (page, urlParams) => {
    setIsLoading(true);

    urlParams =
      typeof urlParams === 'undefined' ? [...fetchQuestionsParams] : urlParams;
    urlParams.push(`page=${page}`);
    urlParams.push(`count=${numberOfPage}`);

    let urlParameter = _.join(urlParams, '&');

    return dispatch(getQuestionFilterResult(urlParameter)).then((response) => {
      setCurrPage(page);
      setIsLoading(false);

      enqueueSnackbar('題目取得完成！', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        variant: 'success',
      });
    });
  };

  const getSelectedIdSource = (questionId) => {
    setIsLoading(true);

    dispatch(getQuestionById(questionId)).then((response) => {
      setIsLoading(false);

      if (
        response.Status === 1 &&
        response.Result.Status === QuestionStatus.ACCEPT
      ) {
        setCurrPage(1);
        enqueueSnackbar('題目取得完成！', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          variant: 'success',
        });
      }
    });
  };

  const setExamSubject = (subject) => {
    dispatch(RemoveAllQuestionInExam());
    dispatch(RemoveAllItemInFiltedArray());
    setSubject(subject);
  };

  useEffect(() => {
    const initData = async () => {
      let questionBankDetail = null;

      await dispatch(initQuestionSource());

      if (
        _.isInteger(_.parseInt(QuestionBankId)) &&
        _.parseInt(QuestionBankId) > 0
      ) {
        questionBankDetail = await getQuestionBankDetial(QuestionBankId);
        await dispatch(getQuestionByBankId(QuestionBankId));
      }

      return questionBankDetail;
    };

    dispatch(callPageLoading({ loadingContent: '初始化資料...' }));

    initData()
      .then((questionBankDetail) => {
        /**
         * 編輯時才會有 questionBankDetial 的資料
         */
        if (questionBankDetail !== null) {
          setQuestionBank(questionBankDetail.Result);

          /**
           * 處理題庫在抓取對應的題目科目，如果題庫科目有設定對應，直接使用
           * 如果沒找到對應，則使用原本規則 題庫科目1=數學 題庫科目2=英文 其它就...
           */
          if (_.isInteger(questionBankDetail.Result.QuestionSubjectId)) {
            setSubject(questionBankDetail.Result.QuestionSubjectId);
          } else {
            switch (questionBankDetail.Result.SubjectId) {
              case 1:
                setSubject(12);
                break;

              case 2:
                setSubject(13);
                break;
            }
          }
        }

        dispatch(cancelPageLoading());
      })
      .catch((err) => {
        dispatch(cancelPageLoading());
      });

    return () => {
      dispatch(RemoveAllQuestionInExam());
      dispatch(RemoveAllItemInFiltedArray());
      setQuestionBank({});
      handleStep(0);
    };
  }, [dispatch, QuestionBankId]);

  return (
    <div className="set-exam pagefadeIn">
      <BreakCrumbs path={props.match.url} />
      <Box>
        <Stepper activeStep={activeStep} alternativeLabel nonLinear>
          {steps.map((step, index) => {
            return (
              <Step key={step.label}>
                <StepButton
                  onClick={() => {
                    handleStep(index);
                  }}>
                  {step.label}
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
      </Box>
      <Box display={activeStep === 0 ? '' : 'none'}>
        <Box mt={2}>
          <SourceQuestion
            questionSource={questionSource}
            handleGetSeleceted={getSelectedSource}
            handleGetIdSeleceted={getSelectedIdSource}
            examSubject={subject}
            onSetExamSubject={(subject) => {
              setExamSubject(subject);
            }}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            numberOfPage={numberOfPage}
            setIsFetchRandom={setIsFetchRandom}
          />
        </Box>
        <Box mt={2}>
          <ResultDrag
            currPage={currPage}
            onChangePage={(page) => {
              searchQuestions(page);
            }}
            subject={subject}
            numberOfPage={numberOfPage}
            isFetchRandom={isFetchRandom}
            isLoading={isLoading}
          />
        </Box>
      </Box>

      <Box display={activeStep === 1 ? '' : 'none'}>
        <Box mt={2}>
          <ExamSetting
            setQuestionIncompleteSetting={setQuestionIncompleteSetting}
            exam={questionBank}
          />
        </Box>
      </Box>

      {steps.map((step, index) => (
        <Zoom key={index} in={index !== activeStep} unmountOnExit>
          <Fab
            aria-label={step.label}
            variant="extended"
            size="small"
            className={classes.fab}
            onClick={() => {
              handleStep(activeStep === 0 ? 1 : 0);
            }}>
            {step.icon}
            {step.label}
          </Fab>
        </Zoom>
      ))}
    </div>
  );
};

export default SetExam;
