import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Box,
  Typography,
  Divider,
  Tooltip,
} from '@material-ui/core';
import _ from 'lodash';
import sourceUrlAPI from '../middleware/sourceUrlAPI';
import { alertError } from '../Util/Util';
import { makeStyles } from '@material-ui/core/styles';
import { QRCode } from 'react-qrcode-logo';
import qrcodeLogo from '../images/qrcode_logo.png';
import CropFreeRoundedIcon from '@material-ui/icons/CropFreeRounded';
import HttpRoundedIcon from '@material-ui/icons/HttpRounded';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
  loading: {
    margin: theme.spacing(10, 'auto'),
    textAlign: 'center',
    fontSize: '2rem',
    fontWeigh: '600',
    letterSpacing: '1px',
  },
  url: {
    fontSize: '0.9rem',
    fontWeight: '600',
    letterSpacing: '1px',
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.grey[100],
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
  },
  qrcode: {
    textAlign: 'center',
  },
  divider: {
    margin: theme.spacing(5, 'auto'),
  },
  copyBtn: {
    marginLeft: theme.spacing(1),
  },
}));

const SourceUrlDialog = (props) => {
  const { open, setOpen, title, sourceId, type } = props;
  const classes = useStyles();
  const [cacheData, setCacheData] = useState({});
  const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!sourceId) {
      return;
    }

    const cacheKey = sourceId + '-' + type;

    if (cacheData[cacheKey]) {
      setUrl(cacheData[cacheKey]);
    } else {
      setIsLoading(true);

      sourceUrlAPI
        .get(sourceId, type)
        .then(
          (response) => {
            setUrl(response);
            setCacheData({ ...cacheData, [cacheKey]: response });
          },
          (error) => {
            alertError(error);
          },
        )
        .then(() => {
          setIsLoading(false);
        });
    }

    return () => {
      setUrl('');
    };
  }, [sourceId, type]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      size="sm"
      fullWidth={true}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        {isLoading && (
          <Box className={classes.loading}>
            <CircularProgress />
            <Box>讀取中...</Box>
          </Box>
        )}

        {!isLoading && (
          <>
            <Typography variant="h3" gutterBottom>
              <HttpRoundedIcon /> 網址
              <CopyToClipboard
                text={url}
                onCopy={() => {
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: '網址已複製',
                    showConfirmButton: false,
                    timer: 1000,
                    backdrop: false,
                  });
                }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<FileCopyRoundedIcon />}
                  className={classes.copyBtn}>
                  複製
                </Button>
              </CopyToClipboard>
            </Typography>
            <Box className={classes.url}>{url}</Box>
            <Divider variant="middle" className={classes.divider} />
            <Typography variant="h3" gutterBottom>
              <CropFreeRoundedIcon /> QR CODE
            </Typography>
            <Tooltip title="右鍵下載圖檔">
              <Box className={classes.qrcode}>
                <QRCode
                  value={url}
                  size={200}
                  padding={0}
                  logoImage={qrcodeLogo}
                />
              </Box>
            </Tooltip>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SourceUrlDialog;
