import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
} from '@material-ui/core';
import QuestionVideo from './QuestionVideo';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import MovieFilterIcon from '@material-ui/icons/MovieFilter';
import RemoveIcon from '@material-ui/icons/Remove';
import TimerRoundedIcon from '@material-ui/icons/TimerRounded';
import { seconds2time } from '../../Util/Util';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f5f5f5',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    margin: theme.spacing(1, 'auto'),
    color: theme.palette.text.primary,
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  action: {
    marginLeft: 'auto',
    display: 'flex',
  },
  statusReady: {
    backgroundColor: '#454545',
  },
  statusSuccess: {
    backgroundColor: 'green',
  },
  statusError: {
    backgroundColor: '#ff4646',
  },
  content: {
    fontSize: '1rem',
    fontWeight: '700',
    color: '#5b675d',
  },
  message: {
    color: theme.palette.text.primary,
    fontWeight: '600',
    margin: theme.spacing(1, 0),
    fontSize: '1rem',
  },
  errorMessage: {
    color: 'red',
  },
  time: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    marginLeft: 'auto',
    color: '#5f5f5f',
    fontSize: '0.8rem',
  },
  timeIcon: {
    margin: theme.spacing(1, 'auto'),
    fontSize: '2rem',
  },
}));

const QuestionRow = (props) => {
  const classes = useStyles();
  const {
    question,
    status,
    questionId,
    message,
    isLoading,
    removeQuestion,
    createOrUpdateVideo,
    updateQuestion,
  } = props;
  const [videoDuration, setVideoDuration] = useState(0);
  const [isProcess, setIsProcess] = useState(false);

  useEffect(() => {
    if (status === 'process' && isProcess === false) {
      setIsProcess(true);
    }
  }, [status, isProcess]);

  useEffect(() => {
    if (isProcess === true && videoDuration > 0) {
      createOrUpdateVideo(question, videoDuration)
        .then(
          (rs) => {
            if (rs.status === true) {
              updateQuestion({
                status: 'success',
              });
            } else {
              updateQuestion({
                status: 'error',
                message: rs.message,
              });
            }
          },
          () => {
            updateQuestion({
              status: 'error',
              message: '發生錯誤',
            });
          },
        )
        .then(() => {
          setIsProcess(false);
        });
    }
  }, [isProcess, videoDuration]);

  const onVideoDuration = (duration) => {
    setVideoDuration(Math.floor(duration));
  };

  const avatarClass = `${classes.avatar} ${
    status === 'success'
      ? classes.statusSuccess
      : status === 'error'
      ? classes.statusError
      : status === 'ready'
      ? classes.statusReady
      : ''
  }`;

  return (
    <div className={classes.root}>
      <Avatar className={avatarClass}>
        {status === 'success' && <CheckCircleOutlineIcon />}
        {status === 'error' && <ErrorOutlineIcon />}
        {status === 'process' && <PresentToAllIcon />}
        {status === 'ready' && <MovieFilterIcon />}
      </Avatar>

      <div className={classes.content}>
        {questionId}
        <div
          className={`${classes.message} ${
            status === 'error' ? classes.errorMessage : ''
          }`}>
          {status === 'ready' && '影片可以使用'}
          {status === 'error' && message}
          {status === 'success' && '完成'}
          {status === 'process' && '處理中'}
        </div>
      </div>

      <div className={classes.action}>
        {(status === 'process' || status === 'success') && (
          <div className={classes.time}>
            <TimerRoundedIcon className={classes.timeIcon} />
            <div>
              {videoDuration <= 0
                ? '取得影片長度中'
                : seconds2time(videoDuration)}
            </div>
          </div>
        )}

        <IconButton
          color="secondary"
          component="span"
          onClick={removeQuestion}
          disabled={isLoading}>
          <RemoveIcon />
        </IconButton>
      </div>

      {status === 'process' && (
        <div style={{ display: 'none' }}>
          <QuestionVideo
            videoName={question.videoName}
            open={true}
            onVideoDuration={onVideoDuration}
          />
        </div>
      )}
    </div>
  );
};

export default QuestionRow;
