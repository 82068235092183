import React, { useState, useEffect, useRef } from 'react';
import { getVideoPolicy } from '../../Util/fetchMethods';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import videojs from 'video.js';
import Cookies from 'universal-cookie';
import 'video.js/dist/video-js.min.css';
import '../../style/components/fixVideoJs.scss';

const QuestionVideo = (props) => {
  const [videoUrl, setVideoUrl] = useState('');
  const [originVideo, setOriginVideo] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [player, setPlayer] = useState(null);
  const [videoDuration, setVideoDuration] = useState(0);
  const refVideo = useRef(null);
  const cookies = new Cookies();

  useEffect(() => {
    const videoCheckStr = props.videoPath + '/' + props.videoName;

    if (videoCheckStr !== originVideo) {
      setVideoUrl('');
      setOriginVideo(videoCheckStr);
      setErrorMsg('');
      setPlayer(null);
      setVideoDuration(0);
    }
  }, [props]);

  useEffect(() => {
    if (props.open === true && videoUrl === '' && errorMsg === '') {
      const videoPath = props.videoPath ? props.videoPath : 'QuestionVideo';

      getVideoPolicy(videoPath, props.videoName)
        .then((response) => {
          if (response.Status === 0) {
            setErrorMsg(_.join(response.Message, '<br>'));
          } else {
            let cookieExpiresDate = new Date(Date.now() + 490 * 60 * 1000);
            let cookieOptions = {
              domain: 'liangshishu.com',
              expires: cookieExpiresDate,
              path: '/',
            };

            setVideoUrl(response.Result.SignedVideoUrl);

            cookies.set(
              'CloudFront-Policy',
              response.Result.Policy,
              cookieOptions,
            );
            cookies.set(
              'CloudFront-Key-Pair-Id',
              response.Result.Key,
              cookieOptions,
            );
            cookies.set(
              'CloudFront-Signature',
              response.Result.Sign,
              cookieOptions,
            );
          }
        })
        .catch((err) => {
          setErrorMsg(err.msg);
        });
    }
  }, [props, videoUrl]);

  useEffect(() => {
    if (props.onVideoDuration && parseInt(videoDuration, 10) > 0) {
      props.onVideoDuration(videoDuration);
    }
  }, [videoDuration, props.onVideoDuration]);

  useEffect(() => {
    let videoPlayer = null;

    if (videoUrl !== '') {
      const videoJsOptions = {
        autoplay: false,
        controls: true,
        sources: [
          {
            src: videoUrl,
            type: 'application/x-mpegURL',
            withCredentials: true,
          },
        ],
      };

      videoPlayer = videojs(
        refVideo.current,
        videoJsOptions,
        function onPlayerReady() {
          this.on('loadedmetadata', function() {
            setVideoDuration(this.duration());
          });

          if (props.startAt || props.startAt === 0) {
            this.on('timeupdate', () => {
              if (
                this.currentTime() < props.startAt ||
                this.currentTime() >= props.endAt
              ) {
                this.currentTime(props.startAt);
              }
            });
          }
        },
      );

      setPlayer(videoPlayer);
    }

    return () => {
      if (_.isObject(videoPlayer)) {
        videoPlayer.dispose();
        videoPlayer.off('loadedmetadata');

        let cookieOptions = {
          domain: 'liangshishu.com',
          path: '/',
        };

        cookies.remove('CloudFront-Policy', cookieOptions);
        cookies.remove('CloudFront-Key-Pair-Id', cookieOptions);
        cookies.remove('CloudFront-Signature', cookieOptions);
      }
    };
  }, [videoUrl]);

  useEffect(() => {
    if (props.open === false) {
      if (_.isObject(player)) {
        player.pause();
      }
    }
  }, [props.open]);

  return (
    <Box mb={2}>
      <Box display={videoUrl !== '' ? '' : 'none'}>
        <video
          ref={refVideo}
          className="video-js vjs-styles-defaults vjs-fluid"></video>
      </Box>

      {_.trim(videoUrl) === '' && _.trim(errorMsg) !== '' && (
        <Alert severity="error">影片載入失敗: {errorMsg}</Alert>
      )}

      {_.trim(videoUrl) === '' && _.trim(errorMsg) === '' && (
        <Box
          textAlign="center"
          pt={5}
          pb={5}
          style={{
            backgroundColor: '#eee',
            fontSize: '1.5rem',
          }}>
          影片來源讀取中...
        </Box>
      )}
    </Box>
  );
};

export default QuestionVideo;
