import React, { useState, useEffect } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import BannerTags from '../../components/Advertising/BannerTags';
import Banner from '../../components/Advertising/Banner';
import useBanners from '../../hooks/useBanners';
import { useRouteMatch } from 'react-router';
import { trim } from 'lodash';

const BonusProductBanners = () => {
  const SITE_CODE = 5;
  const {
    getBanners,
    banners,
    setDisplay,
    deleteBanner,
    createBanner,
    editBanner,
  } = useBanners(SITE_CODE);
  const [bannerIndex, setBannerIndex] = useState();
  const { url } = useRouteMatch();

  useEffect(() => {
    getBanners().then(() => {
      setBannerIndex(0);
    });
  }, []);

  return (
    <div className="adventisting home-carousel pagefadeIn">
      <BreakCrumbs path={url} />

      <BannerTags
        banners={banners}
        selectIndex={bannerIndex}
        setSelectIndex={setBannerIndex}
      />

      <div className="block">
        {banners && trim(bannerIndex) !== '' && (
          <Banner
            banner={banners[bannerIndex]}
            setDisplay={setDisplay}
            refreshBanners={getBanners}
            deleteBanner={deleteBanner}
            siteCode={SITE_CODE}
            createBanner={createBanner}
            editBanner={editBanner}
          />
        )}
      </div>
    </div>
  );
};

export default BonusProductBanners;
