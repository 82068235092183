import * as types from '../actionType';

let initState = [
  {
    Id: 1,
    Name: '',
    Status: 0,
  },
];

function SubjectTypeState(state = initState, action) {
  switch (action.type) {
    case types.SUBJECT_TYPE_LIST_SUCCESS: {
      return action.result;
    }
    default:
      return state;
  }
}

export default SubjectTypeState;
