import React, { useEffect, useState } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import {
  Box,
  Grid,
  Button,
  Paper,
  Typography,
  Divider,
  TextField,
} from '@material-ui/core';
import { LIST_PAGE_COUNT } from '../../data/common';
import Swal from 'sweetalert2';
import HandoutTable from '../../components/Handout/HandoutTable';
import TableLoading from '../../components/TableLoading';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { isArray, join, trim } from 'lodash';
import handoutAPI from '../../middleware/handoutAPI';
import { useHistory } from 'react-router-dom';
import { alertError } from '../../Util/Util';
import qrcodeLogo from '../../images/qrcode_logo.png';
import { saveAs } from 'file-saver';
import QrCodeWithLogo from 'qrcode-with-logos';
import SearchIcon from '@material-ui/icons/Search';

const HandoutList = (props) => {
  const history = useHistory();
  const [pageParams, setPageParams] = useState({
    currentPage: 0,
    count: LIST_PAGE_COUNT,
  });
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [handouts, setHandouts] = useState([]);
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    searchHandout();

    return () => {
      setHandouts([]);
    };
  }, [pageParams]);

  const handleChangePage = (event, page) => {
    setPageParams({ ...pageParams, currentPage: page });
  };

  const handleChangeRowsPerPage = (event) => {
    setPageParams({ ...pageParams, currentPage: 0, count: event.target.value });
  };

  const handleSearch = () => {
    setPageParams({ ...pageParams, currentPage: 0 });
  };

  const searchHandout = () => {
    setIsLoading(true);

    let getListParams = {
      page: pageParams.currentPage + 1,
      count: pageParams.count,
    };

    if (trim(keyword) !== '') {
      getListParams.keyword = keyword;
    }

    handoutAPI.getList(getListParams).then(
      (response) => {
        if (
          response.totalPages < pageParams.currentPage + 1 &&
          pageParams.currentPage > 0
        ) {
          setPageParams({
            ...pageParams,
            currentPage: response.totalPages - 1,
          });
        } else {
          setHandouts(response.handoutList);
          setTotalCount(response.totalCount);
          setIsLoading(false);
        }
      },
      (error) => {
        Swal.fire({
          title: '無法取得講義列表',
          html:
            error.messages && isArray(error.messages)
              ? join(error.messages, '<br />')
              : error,
          icon: 'error',
        });
        setIsLoading(false);
      },
    );
  };

  const deleteHandout = (handoutId) => {
    return handoutAPI.delete(handoutId).then(
      (response) => {
        if (response === true) {
          Swal.fire({
            title: '刪除成功',
            icon: 'success',
          });
          setPageParams({ ...pageParams });
        } else {
          Swal.fire({
            title: '刪除失敗',
            icon: 'error',
          });
        }
      },
      (error) => {
        alertError(error);
      },
    );
  };

  const editHandout = (handoutId) => {
    history.push('/Handout/Edit/' + handoutId);
  };

  const downloadHandoutAllQrcode = (handout) => {
    return handoutAPI.getAllUrl(handout.handoutId).then((response) => {
      const zip = require('jszip')();

      return getAllUrlQrcodeImg(response.nodeList).then((allQrCodeImg) => {
        for (let urlQrCode of allQrCodeImg) {
          zip.file(urlQrCode.name, urlQrCode.img.split('base64,')[1], {
            base64: true,
          });
        }

        return zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, handout.name + '-QRCODE.zip');
        });
      });
    });
  };

  const getAllUrlQrcodeImg = async (nodes) => {
    let qrcodes = [];

    for (let node of nodes) {
      const fileName = node.sort + '. ' + node.name + '.png';
      const qrcodeCanvas = document.createElement('canvas');
      const qrcode = new QrCodeWithLogo({
        canvas: qrcodeCanvas,
        content: node.qRCodeUrl,
        width: 200,
        logo: qrcodeLogo,
      });

      await qrcode.toCanvas().then(() => {
        qrcodes.push({
          name: fileName,
          img: qrcodeCanvas.toDataURL('image/png'),
        });
      });
    }

    return qrcodes;
  };

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={props.match.url}></BreakCrumbs>

      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center">
          <Grid item xs={8}>
            <Typography variant="h4" color="textPrimary" gutterBottom>
              講義列表
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Box textAlign="right">
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddRoundedIcon />}
                disabled={isLoading}
                onClick={() => {
                  history.push('/Handout/Create');
                }}>
                建立講義
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Divider light />
        <Box mt={2} mb={2}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="flex-end"
            spacing={2}>
            <Grid item>
              <TextField
                label="名稱 / 描述"
                type="search"
                variant="outlined"
                size="small"
                disabled={isLoading}
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SearchIcon />}
                disabled={isLoading}
                onClick={handleSearch}>
                搜尋
              </Button>
            </Grid>
          </Grid>
        </Box>
        {isLoading ? (
          <TableLoading rows={pageParams.count} />
        ) : (
          <HandoutTable
            handouts={handouts}
            defaultPrePageRows={LIST_PAGE_COUNT}
            perPageRows={pageParams.count}
            page={pageParams.currentPage}
            totalCount={totalCount}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            deleteHandout={deleteHandout}
            editHandout={editHandout}
            downloadHandoutAllQrcode={downloadHandoutAllQrcode}
          />
        )}
      </Box>
    </div>
  );
};

export default HandoutList;
