import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Button,
  Paper,
  Typography,
  Divider,
  TextField,
} from '@material-ui/core';
import { LIST_PAGE_COUNT } from '../../data/common';
import SearchIcon from '@material-ui/icons/Search';
import videoAPI from '../../middleware/videoAPI';
import {
  trim,
  parseInt,
  map,
  isArray,
  each,
  remove,
  uniqBy,
  filter,
  matches,
} from 'lodash';
import { alertError } from '../../Util/Util';
import TableLoading from '../../components/TableLoading';
import TableEmpty from '../../components/TableEmpty';
import { Pagination } from '@material-ui/lab';
import VideoNodeCard from '../../components/Video/VideoNodeCard';

const SimpleVideoList = (props) => {
  const { selectNode, selectedNodes, setSelectedNodes } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [searchs, setSearchs] = useState({
    keyword: '',
    videoId: '',
  });
  const [pageParams, setPageParams] = useState({
    current: 1,
    perPageCount: LIST_PAGE_COUNT,
  });
  const [videoTotal, setVideoTotal] = useState(0);
  const [videos, setVideos] = useState([]);

  const reset = () => {
    setIsLoading(false);
    setSearchs({
      keyword: '',
      videoId: '',
    });
    setPageParams({
      current: 0,
      perPageCount: LIST_PAGE_COUNT,
    });
    setVideoTotal(0);
    setVideos([]);
    setSelectedNodes([]);
  };

  const handleSearch = () => {
    setPageParams({
      ...pageParams,
      current: 1,
    });
  };

  const getVideos = async () => {
    setIsLoading(true);

    videoAPI
      .getList({
        page: pageParams.current,
        count: pageParams.perPageCount,
        keyword:
          trim(searchs.keyword) !== '' ? trim(searchs.keyword) : undefined,
        videoId:
          parseInt(searchs.videoId, 10) > 0
            ? parseInt(searchs.videoId, 10)
            : undefined,
        hasNode: true,
      })
      .then(
        (response) => {
          setVideos(response.handoutVideoList);
          setVideoTotal(response.totalPages);
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const handleChangePage = (event, value) => {
    setPageParams({
      ...pageParams,
      current: value,
    });
  };

  const selectNodeAction = (action, nodes) => {
    let newSelectedNodes = [...selectedNodes];

    if (action === 'add') {
      if (isArray(nodes)) {
        each(nodes, (addNode) => {
          newSelectedNodes.push(addNode);
        });
      } else {
        newSelectedNodes.push(nodes);
      }
    } else {
      if (isArray(nodes)) {
        each(nodes, (removeNode) => {
          if (
            filter(selectedNodes, matches({ nodeId: removeNode.nodeId }))
              .length > 0
          ) {
            remove(
              newSelectedNodes,
              (node) => node.nodeId === removeNode.nodeId,
            );
          }
        });
      } else {
        remove(newSelectedNodes, (node) => node.nodeId === nodes.nodeId);
      }
    }

    setSelectedNodes(uniqBy(newSelectedNodes, 'nodeId'));
  };

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    getVideos();
  }, [pageParams]);

  return (
    <div className="popular pagefadeIn">
      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Typography variant="h4" color="textPrimary" gutterBottom>
          影片列表
        </Typography>

        <Divider light />

        <Box pt={2}>
          <form noValidate autoComplete="off">
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              spacing={1}>
              <Grid item xs={4}>
                <TextField
                  label="關鍵字"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={searchs.keyword}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch();
                    }
                  }}
                  onChange={(e) => {
                    setSearchs({
                      ...searchs,
                      keyword: e.target.value,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  startIcon={<SearchIcon />}
                  onClick={handleSearch}>
                  搜尋影片
                </Button>
              </Grid>

              <Grid item xs={4}>
                <TextField
                  label="影片ID"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={searchs.videoId}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch();
                    }
                  }}
                  onChange={(e) => {
                    setSearchs({
                      ...searchs,
                      videoId: e.target.value,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  startIcon={<SearchIcon />}
                  onClick={handleSearch}>
                  搜尋影片ID
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>

        {isLoading && <TableLoading />}

        {!isLoading && videos.length <= 0 && (
          <TableEmpty message="沒有含有節點的影片" />
        )}

        {!isLoading && videos.length > 0 && (
          <>
            {map(videos, (video, index) => (
              <VideoNodeCard
                key={video.handoutVideoId}
                video={video}
                selectNode={!!selectNode}
                selectedNodes={selectedNodes}
                selectNodeAction={selectNodeAction}
              />
            ))}
            <Pagination
              count={videoTotal}
              color="primary"
              disabled={isLoading}
              shape="rounded"
              page={pageParams.current}
              onChange={handleChangePage}
            />
          </>
        )}
      </Box>
    </div>
  );
};

export default SimpleVideoList;
