import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reduxSetVideoQueue } from '../store/action/index';
import videoAPI from '../middleware/videoAPI';
import _ from 'lodash';
import { multipartUploadS3File } from '../Util/awsS3';
import { v4 as uuidv4 } from 'uuid';

const queueConfig = {
  create: {
    limit: 1,
  },
  upload: {
    limit: 1,
  },
};

const canActive = (step, stepQueue) => {
  return (
    stepQueue.queue.length > 0 &&
    stepQueue.nowActive.length < queueConfig[step].limit &&
    stepQueue.nowActive.length < stepQueue.queue.length
  );
};

const getCanActive = (stepQueue, videoQueueState) => {
  let canActiveHandoutVideoId = null;

  _.each(stepQueue.queue, (handoutVideoId) => {
    if (
      _.indexOf(stepQueue.nowActive, handoutVideoId) === -1 &&
      canActiveHandoutVideoId === null
    ) {
      canActiveHandoutVideoId = handoutVideoId;
    }
  });

  return _.find(videoQueueState.queue, (queue) => {
    return queue.handoutVideoId === canActiveHandoutVideoId;
  });
};

const setVideoQueueState = (
  dispatch,
  videoQueueState,
  handoutVideoId,
  mergeState,
) => {
  let newQueue = [...videoQueueState.queue];

  _.each(newQueue, (queue, index) => {
    if (queue.handoutVideoId === handoutVideoId) {
      newQueue[index] = { ...queue, ...mergeState };
    }
  });

  dispatch(reduxSetVideoQueue(newQueue));
};

const removeQueue = (setStepQueue, removeHandoutVideoId) => {
  setStepQueue((state) => {
    const newQueue = _.filter(state.queue, (handoutVideoId) => {
      return handoutVideoId !== removeHandoutVideoId;
    });

    const newActive = _.filter(
      state.nowActive,
      (handoutVideoId) => handoutVideoId !== removeHandoutVideoId,
    );

    return {
      queue: [...newQueue],
      nowActive: [...newActive],
    };
  });
};

const setActiveQueue = (setStepQueue, handoutVideoId) => {
  setStepQueue((state) => {
    let newActive = [...state.nowActive];

    newActive.push(handoutVideoId);

    return {
      queue: [...state.queue],
      nowActive: [...newActive],
    };
  });
};

const checkQueueAllDone = (videoQueueState) => {
  return (
    _.filter(videoQueueState.queue, (queueState) => {
      return queueState.status !== 'done' && queueState.status !== 'error';
    }).length <= 0
  );
};

const useVideoQueue = () => {
  const dispatch = useDispatch();
  const videoQueueRef = useRef(null);
  const videoQueueState = useSelector((state) => state.videoQueueState);
  const [uploadQueue, setUploadQueue] = useState({ queue: [], nowActive: [] });
  const [queueUploadStatus, setQueueUploadStatus] = useState('idle');
  const [uploadDoneNotify, setUploadDoneNotify] = useState(undefined);

  useEffect(() => {
    videoQueueRef.current = videoQueueState;
  }, [videoQueueState]);

  useEffect(() => {
    if (checkQueueAllDone(videoQueueState)) {
      if (queueUploadStatus !== 'idle') {
        setQueueUploadStatus('idle');
      }
    } else {
      if (queueUploadStatus !== 'working') {
        setQueueUploadStatus('working');
      }
    }
  }, [videoQueueState, queueUploadStatus]);

  useEffect(() => {
    let videoInput = null;

    if (canActive('upload', uploadQueue)) {
      let video = getCanActive(uploadQueue, videoQueueRef.current);

      setActiveQueue(setUploadQueue, video.handoutVideoId);

      const fileUrl = URL.createObjectURL(video.file);
      videoInput = document.createElement('video');
      videoInput.id = video.handoutVideoId;
      videoInput.src = fileUrl;

      setVideoQueueState(
        dispatch,
        videoQueueRef.current,
        video.handoutVideoId,
        {
          status: 'getVideoInfo',
        },
      );

      videoInput.ondurationchange = (e) => {
        const s3FileName = uuidv4();
        const videoDuration = _.parseInt(e.currentTarget.duration);

        multipartUploadS3File(video.file, {
          bucket: 'video-resource',
          dir: 'HandoutVideo',
          fileName: s3FileName,
          uploadStart: (totalPart) => {
            setVideoQueueState(
              dispatch,
              videoQueueRef.current,
              video.handoutVideoId,
              {
                status: 'upload',
                uploadPart: 0,
                uploadTotalPart: totalPart,
                videoDuration: videoDuration,
              },
            );
          },
          uploadProgress: (nowPart) => {
            setVideoQueueState(
              dispatch,
              videoQueueRef.current,
              video.handoutVideoId,
              {
                uploadPart: nowPart,
              },
            );
          },
        }).then((response) => {
          if (response !== false) {
            let editVideoFormData = new URLSearchParams();

            editVideoFormData.append('Name', video.name);
            editVideoFormData.append(
              'KnowledgeIds',
              video.knowledgeIds ? _.join(video.knowledgeIds, ',') : '',
            );
            editVideoFormData.append('HandoutVideoId', video.handoutVideoId);
            editVideoFormData.append('Duration', videoDuration);
            editVideoFormData.append('Guid', s3FileName);

            videoAPI
              .edit(editVideoFormData)
              .then(
                (response) => {
                  setVideoQueueState(
                    dispatch,
                    videoQueueRef.current,
                    video.handoutVideoId,
                    {
                      status: 'done',
                    },
                  );
                  setUploadDoneNotify(video.handoutVideoId);
                },
                (error) => {
                  setVideoQueueState(
                    dispatch,
                    videoQueueRef.current,
                    video.handoutVideoId,
                    {
                      status: 'error',
                      errorMessage:
                        '上傳檔案失敗<br />' + _.join(error.messages, '<br />'),
                    },
                  );
                },
              )
              .then(() => {
                removeQueue(setUploadQueue, video.handoutVideoId);
              });
          } else {
            setVideoQueueState(
              dispatch,
              videoQueueRef.current,
              video.handoutVideoId,
              {
                status: 'error',
                errorMessage: '上傳檔案失敗 (S3上傳失敗)',
              },
            );
          }
        });
      };
    }

    return () => {
      if (videoInput !== null) {
        videoInput = null;
      }
    };
  }, [uploadQueue, setUploadQueue, videoQueueRef]);

  const createVideoBagByFile = (file, handoutVideo) => {
    return {
      name: handoutVideo ? handoutVideo.name : file.name,
      file: file,
      handoutVideoId: handoutVideo ? handoutVideo.handoutVideoId : null,
      status: 'ready',
      knowledgeIds: handoutVideo ? handoutVideo.knowledgeIds : null,
    };
  };

  const addVideoQueue = (video, callback) => {
    const addVideos = _.isArray(video) ? video : [video];
    let newVideoQueue = [...videoQueueState.queue];
    let newUploadQueue = [...uploadQueue.queue];

    createVideos(addVideos).then(
      (response) => {
        _.each(response, (addedQueue) => {
          if (addedQueue.status === 'standbyUpload') {
            newUploadQueue.push(addedQueue.handoutVideoId);
          }

          if (addedQueue.handoutVideoId) {
            newVideoQueue = _.filter(newVideoQueue, (videoQueue) => {
              return videoQueue.handoutVideoId !== addedQueue.handoutVideoId;
            });
          }

          newVideoQueue.push({ ...addedQueue });
        });

        dispatch(reduxSetVideoQueue(newVideoQueue));
        setUploadQueue({ ...uploadQueue, queue: newUploadQueue });

        if (callback) {
          callback(response);
        }
      },
      (error) => {
        console.log('createVideos Fail', error);
      },
    );
  };

  const createVideos = async (addVideos) => {
    let addVideoQueue = [];

    for (let videoIndex in addVideos) {
      const video = addVideos[videoIndex];

      if (!/\.(mp4|mov)$/.test(video.file.name)) {
        addVideoQueue.push({
          ...video,
          status: 'error',
          errorMessage: '檔案格式只能接受 mp4或mov',
        });
      } else {
        // 更改檔案的時候
        if (video.handoutVideoId) {
          addVideoQueue.push({
            ...video,
            status: 'standbyUpload',
          });
        } else {
          await videoAPI.add(video.name).then(
            (response) => {
              addVideoQueue.push({
                ...video,
                status: 'standbyUpload',
                handoutVideoId: response.handoutVideoId,
              });
            },
            (error) => {
              addVideoQueue.push({
                ...video,
                status: 'error',
                errorMessage: _.join(error.messages, '<br />'),
              });
            },
          );
        }
      }
    }

    return addVideoQueue;
  };

  const getVideoQueue = (handoutVideoId) => {
    if (videoQueueState) {
      const videoQueueIndex = _.findIndex(
        videoQueueState.queue,
        (queue) => queue.handoutVideoId === handoutVideoId,
      );

      if (videoQueueIndex >= 0) {
        return videoQueueState.queue[videoQueueIndex];
      }
    }

    return null;
  };

  const clearDoneVideoQueue = (handoutVideoIds) => {
    const oldQueue = videoQueueRef.current.queue;
    const newQueue = _.filter(oldQueue, (queue) => {
      return !(
        queue.status === 'done' &&
        handoutVideoIds.indexOf(queue.handoutVideoId) !== -1
      );
    });

    if (oldQueue.length !== newQueue.length) {
      dispatch(reduxSetVideoQueue(newQueue));
    }
  };

  return {
    videoQueueState,
    queueUploadStatus,
    uploadDoneNotify,
    getVideoQueue,
    createVideoBagByFile,
    addVideoQueue,
    clearDoneVideoQueue,
  };
};

export default useVideoQueue;
