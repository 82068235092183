import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { renderMathJax } from '../../Util/Util.js';
import MathJaxAndImgContent from './MathJaxAndImgContent';
import QuestionVideo from './QuestionVideo';
import AnswerOption from './AnswerOption';
import Pagination from '@material-ui/lab/Pagination';
import AudioPlayer from './AudioPlayer';
import QuestionVideoButton from './QuestionVideoButton';

const useStyles = makeStyles((theme) => ({
  pageinationUl: {
    justifyContent: 'center',

    '& li:first-child': {
      '&::before': {
        left: '-52px',
        content: '"子題"',
        display: 'block',
        position: 'absolute',
        top: '1px',
        fontSize: '1rem',
        fontWeight: '600',
        backgroundColor: '#e49292',
        color: '#fff',
        padding: theme.spacing(0.5, 1),
        borderRadius: theme.spacing(0.5),
      },
    },
  },
  answerOptionRoot: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
  title: {
    backgroundColor: '#e49292',
    color: '#fff',
    fontWeight: '500',
    fontSize: '1rem',
    letterSpacing: '1px',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
  },
  pageinationRoot: {
    backgroundColor: theme.palette.grey[100],
  },
}));

const QuestionDetail = (props) => {
  const { question } = props;
  // 題組類型紀錄顯示小題，單題類型請保持1
  const [questionNumber, setQuestionNumber] = useState(1);
  const classes = useStyles();
  const mainDomId = 'questionDetailId_' + question.Id;
  // 區別題組和單題的顯示，這裡代表要顯示的問題是那個
  const currentQuestion =
    question.QuestionType === 1
      ? question
      : question.SubQuestions[_.parseInt(questionNumber) - 1];

  useEffect(() => {
    setQuestionNumber(1);
  }, [props.question]);

  useEffect(() => {
    renderMathJax(mainDomId);
  }, [questionNumber]);

  return (
    <Grid
      id={mainDomId}
      container
      direction="row"
      justify="space-around"
      alignItems="stretch"
      spacing={3}>
      {(question.QuestionType === 3 || question.QuestionType === 2) && (
        <>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.title}>
              主題
            </Typography>
            <MathJaxAndImgContent
              substance={question.Substance}
              imageUrl={question.ImageUrl}
            />
          </Grid>
          <Grid item xs={12}>
            <AudioPlayer
              audioName={question.AudioName}
              audioPath={question.AudioPath}
            />
          </Grid>
          <Grid item xs={12}>
            <Pagination
              classes={{
                ul: classes.pageinationUl,
              }}
              count={question.SubQuestions.length}
              shape="rounded"
              page={questionNumber}
              color="primary"
              variant="outlined"
              onChange={(event, page) => {
                setQuestionNumber(page);
              }}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12} sm={6}>
        <Typography variant="h6" className={classes.title}>
          題目
        </Typography>
        <MathJaxAndImgContent
          substance={currentQuestion.Substance}
          imageUrl={currentQuestion.ImageUrl}
        />
        <AudioPlayer
          audioName={currentQuestion.AudioName}
          audioPath={currentQuestion.AudioPath}
        />
        {_.isString(question.QuestionVideoName) && (
          <QuestionVideoButton
            videoName={question.QuestionVideoName}
            videoPath={question.QuestionVideoPath}
          />
        )}
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="h6" className={classes.title}>
          選項
        </Typography>
        {_.map(currentQuestion.Answers, (answer, idx) => {
          return (
            <AnswerOption
              key={'questionAnswerOption_' + answer.Id}
              number={idx + 1}
              answer={answer}
            />
          );
        })}
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6" className={classes.title}>
          詳解
        </Typography>
        <MathJaxAndImgContent
          substance={question.Explanation}
          imageUrl={question.ExpImageUrl}
        />
        <AudioPlayer
          audioName={question.ExpAudioName}
          audioPath={question.ExpAudioPath}
        />
        {_.isString(question.VideoName) && (
          <QuestionVideoButton
            videoName={question.VideoName}
            videoPath={question.VideoPath}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default QuestionDetail;
