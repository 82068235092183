import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import DirectionsRunOutlinedIcon from '@material-ui/icons/DirectionsRunOutlined';
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined';
import ImportContactsOutlinedIcon from '@material-ui/icons/ImportContactsOutlined';
import SearchProduct from '../../components/Product/SearchProduct';
import SelectedProducts from '../../components/Product/SelectedProducts';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import _ from 'lodash';

const SearchProductDialog = (props) => {
  const [productType, setProductType] = useState(props.productType);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const selectProduct = (product) => {
    if (
      _.findIndex(selectedProducts, [
        'id',
        product.id,
        'type',
        product.type,
      ]) === -1
    ) {
      setSelectedProducts([...selectedProducts, product]);
    } else {
      let newSelectedProducts = _.filter(
        selectedProducts,
        (selectedProduct) => {
          return !(
            _.parseInt(selectedProduct.id) === _.parseInt(product.id) &&
            _.trim(selectedProduct.type) === _.trim(product.type)
          );
        },
      );

      setSelectedProducts(newSelectedProducts);
    }
  };

  const addProducts = () => {
    if (selectedProducts.length > 0) {
      const editProducts = selectedProducts.map((selectedProduct) => {
        return {
          productType: selectedProduct.type,
          action: 'add',
          product: selectedProduct.origin,
        };
      });

      props.editProducts(editProducts);
      setSelectedProducts([]);
      props.close();
    }
  };

  return (
    <Dialog
      open={props.open}
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth="md">
      <DialogTitle>新增產品</DialogTitle>
      <DialogContent dividers>
        <Tabs
          variant="fullWidth"
          value={productType}
          onChange={(event, value) => {
            setProductType(value);
          }}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary">
          <Tab
            icon={<BallotOutlinedIcon />}
            label="題庫商品"
            value="questionBank"
          />
          <Tab
            icon={<ImportContactsOutlinedIcon />}
            label="課程商品"
            value="hangout"
          />
        </Tabs>
        <div role="tabpanel" hidden={productType !== 'questionBank'}>
          {productType === 'questionBank' && (
            <Box pt={2} pb={2}>
              <SearchProduct
                type={productType}
                alreadyInPackProducts={props.questionBanks}
                selectedProducts={selectedProducts}
                selectProduct={selectProduct}
              />
            </Box>
          )}
        </div>

        <div role="tabpanel" hidden={productType !== 'hangout'}>
          {productType === 'hangout' && (
            <Box pt={2} pb={2}>
              <SearchProduct
                type={productType}
                alreadyInPackProducts={props.hangouts}
                selectedProducts={selectedProducts}
                selectProduct={selectProduct}
              />
            </Box>
          )}
        </div>

        <Box mt={3}>
          <SelectedProducts
            selectedProducts={selectedProducts}
            onClick={selectProduct}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {selectedProducts.length > 0 ? (
          <>
            <Button
              color="primary"
              variant="contained"
              startIcon={<AddOutlinedIcon />}
              onClick={addProducts}>
              加入商品
            </Button>
            <Button
              color="default"
              variant="contained"
              startIcon={<CloseOutlinedIcon />}
              onClick={() => {
                setSelectedProducts([]);
                props.close();
              }}>
              取消
            </Button>
          </>
        ) : (
          <Button
            color="secondary"
            size="small"
            endIcon={<DirectionsRunOutlinedIcon />}
            onClick={props.close}>
            結束
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SearchProductDialog;
