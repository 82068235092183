// [FIXME]: 還不確定取得後端資料要放在什麼地方暫停先放在 Util 下
import * as API from '../store/apiconfig';
import _ from 'lodash';
import httpCode from './httpCode';
import { logoutSuccess } from '../store/action';
import Swal from 'sweetalert2';

/**
 * 建立 fetch 回傳 Promise
 *
 * @param {string} url 網址
 * @param {string} method 傳送方式 GET, POST
 * @param {formData|string} body 傳送內容，GET不能用
 * @param {object|null} customHeader 需要客製化的 header 如有設定預設值的內容會替換掉
 * @param {dispatch|null} dispatch 如果是 redux 呼叫的，可以代入方便在這裡呼叫相關 action
 */
const promiseFetch = (url, method, body, customHeader, dispatch) => {
  let errorMsg = { httpCode: httpCode.ok, msg: 'No error' };
  let defaultHeader = {
    Accept:
      method === 'POST' || method === 'PUT' || method === 'DELETE'
        ? 'application/json'
        : '',
    'Content-Type':
      method === 'POST' || method === 'PUT' || method === 'DELETE'
        ? 'application/json'
        : '',
    Authorization: 'bearer ' + localStorage.getItem('access_token'),
  };
  let header = Object.assign({}, defaultHeader, customHeader);

  /**
   * fix bug 檔案上傳時，Content-Type 由 Client 自行設定操作即可，不然會有問題
   * TODO(kang): 後續可以用 axios 不要折磨自已
   */
  if (header['Content-Type'] == 'multipart/form-data') {
    delete header['Content-Type'];
  }

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: method,
      headers: header,
      body: body,
    })
      .catch((error) => {
        reject({
          ...errorMsg,
          httpCode: httpCode.ok,
          msg: `promiseFetch Error: ${error}`,
        });

        Swal.fire('網路發生錯誤', error, 'error');
      })
      .then((response) => {
        if (!response.ok) {
          let errorMessage = '網路發生錯誤';

          if (
            response.status === httpCode.forbidden ||
            response.status === httpCode.unauthorized
          ) {
            errorMessage = '帳號已登出';

            if (dispatch) {
              dispatch(logoutSuccess());
            }
          }

          Swal.fire({
            title: errorMessage,
            icon: 'error',
          });

          reject({
            ...errorMsg,
            httpCode: response.status,
            msg: `HTTP response error.`,
          });
        }

        return response.json();
      })
      .then((json) => {
        resolve(json);
      });
  });
};

/**
 * Fetch Members
 *
 * @param {number} page            當前頁數
 * @param {number} rowsPrePagepage 當前頁面的會員總數
 * @param {number} type            顯示會員種類
 * @param {string} searchText      收尋的字串
 */
const fetchMembers = (
  page,
  rowsPrePage,
  type,
  searchText,
  searchSignUpAtDate,
) => {
  let query =
    API.memberManagementAPI.memberList +
    `?page=${page}&count=${rowsPrePage}&memberType=${type}`;

  if (!_.isEmpty(searchText)) {
    query = query + `&keyword=${searchText}`;
  }

  if (!_.isEmpty(searchSignUpAtDate)) {
    query = query + `&signupat=${searchSignUpAtDate}`;
  }

  return promiseFetch(query, 'GET');
};

/**
 * Create Member
 *
 * @param {object} member 新增的會員資料
 */
const createMember = (member) => {
  let query = API.memberManagementAPI.create;
  return promiseFetch(query, 'POST', JSON.stringify(member));
};

/**
 * fetchOrders
 *
 * @param {number} page            當前頁數
 * @param {number} rowsPrePagepage 當前頁面的會員總數
 * @param {string} searchText      搜尋的字串
 * @param {string} start           搜尋開始日期
 * @param {string} end             搜尋結束日期
 */
const fetchOrders = (page, rowsPrePage, searchText, start, end) => {
  let query =
    API.orderAPI.GET_ORDER +
    `?page=${page}&count=${rowsPrePage}&keyword=${searchText}` +
    `&startAt=${start}&endAt=${end}`;
  return promiseFetch(query, 'GET');
};

const fetchMemberOrders = (memberId, dispatch) => {
  let query = API.orderAPI.GET_ORDER + '?memberid=' + memberId;

  return promiseFetch(query, 'GET', null, null, dispatch);
};

/**
 * fetchPromos
 */
const fetchPromos = () => {
  let query = API.promoListAPI.GETLIST;
  return promiseFetch(query, 'GET');
};

/**
 * Delete QB in promo
 *
 * @param {object} member 新增的會員資料
 */
const deletePromoQuestionBank = (id, questionBankId) => {
  let query =
    API.promoListAPI.DELETE + '/' + id + '/questionBank/' + questionBankId;
  return promiseFetch(query, 'DELETE');
};

/**
 * Add QB to promo
 *
 * @param {object} member 新增的會員資料
 */
const createPromoQuestionBankAPI = (promoId, questionBankId) => {
  let query =
    API.promoListAPI.ADD + '/' + promoId + '?questionBank=' + questionBankId;
  return promiseFetch(query, 'POST');
};

/**
 * Add QB to promo
 *
 * @param {object} member 新增的會員資料
 */
const deletePromoAPI = (promoId) => {
  let query = API.promoListAPI.DELETE + '/' + promoId;
  return promiseFetch(query, 'DELETE');
};

const createPromoAPI = (newPromo) => {
  let query = API.promoListAPI.ADD;
  return promiseFetch(query, 'POST', JSON.stringify(newPromo));
};

/**
 * Fetch SaleExamList
 *
 * @param {string} keyword         欲搜尋的關鍵字
 * @param {number} page            當前頁面
 * @param {number} countOfPage     當前每頁顯示多少筆資料
 */
const initSaleExamList = (keyword, page, countOfPage) => {
  let query =
    API.saleExamListAPI.GETLIST + `?page=${page}&count=${countOfPage}`;
  if (keyword) {
    query =
      API.saleExamListAPI.GETLIST +
      `?page=${page}&count=${countOfPage}&keyword=${keyword}`;
  }
  return promiseFetch(query, 'GET');
};

/**
 * Update saleExamStatusAPI
 *
 * @param {string} status        欲修改的狀態
 * @param {number} id            目標 id
 */

const saleExamStatusAPI = (status, id) => {
  const query =
    API.saleExamListAPI.STATUS_AND_DELETE +
    '?status=' +
    status +
    '&questionBankId=' +
    id;

  return promiseFetch(query, 'GET');
};

/**
 * callCopyApI
 *
 * @param {string} questionBankId     欲複製的目標名稱
 */

const callCopyApI = (questionBankId) => {
  let query = API.saleExamListAPI.COPY + '?questionBankId=' + questionBankId;
  return promiseFetch(query, 'GET');
};

/**
 * callDeletePromoQuestionBankAPI
 *
 * @param {string} promoId          欲刪除的活動名稱
 * @param {string} questionBankId   欲刪除的目標名稱
 */

const callDeletePromoQuestionBankAPI = (promoId, questionBankId) => {
  let query =
    API.promoListAPI.DELETE + '/' + promoId + '/questionBank/' + questionBankId;
  return promiseFetch(query, 'DELETE');
};

/**
 * initExamTypeList
 */

const initExamTypeList = () => {
  let query = API.examTypeAPI.GETLIST;
  return promiseFetch(query, 'GET');
};

/**
 * 修改考卷類別 examTypeEditAPI
 *
 * @param {obj} obj    欲修改的考卷
 */

const examTypeEditAPI = (obj) => {
  let query = API.examTypeAPI.EDIT;
  return promiseFetch(query, 'POST', JSON.stringify(obj));
};

/**
 * 新增考卷類別 examTypeAddAPI
 *
 * @param {obj} obj    欲新增的考卷
 */

const examTypeAddAPI = (obj) => {
  let query = API.examTypeAPI.ADD;
  return promiseFetch(query, 'POST', JSON.stringify(obj));
};

/**
 * 刪除考卷類別 examTypeAddAPI
 *
 * @param {string} Id    欲刪除的考卷
 */

const examTypeDeleteAPI = (Id) => {
  let query = API.examTypeAPI.DELETE;
  return promiseFetch(query, 'POST', JSON.stringify({ TypeId: Id }));
};

/**
 * 課程類別列表
 */

const initHangoutTypeList = () => {
  let query = API.hangoutTypeAPI.GETLIST;
  return promiseFetch(query, 'GET');
};

/**
 * 修改課程類別
 *
 * @param {obj} obj    欲修改的課程類別
 */

const hangoutTypeEditAPI = (obj) => {
  let query = API.hangoutTypeAPI.EDIT;
  return promiseFetch(query, 'POST', JSON.stringify(obj));
};

/**
 * 新增課程類別
 *
 * @param {obj} obj    欲新增的課程類別
 */

const hangoutTypeAddAPI = (obj) => {
  let query = API.hangoutTypeAPI.ADD;
  return promiseFetch(query, 'POST', JSON.stringify(obj));
};

/**
 * 刪除課程類別
 *
 * @param {string} Id    欲刪除課程類別
 */

const hangoutTypeDeleteAPI = (Id) => {
  let query = API.hangoutTypeAPI.DELETE;
  return promiseFetch(query, 'POST', JSON.stringify({ TypeId: Id }));
};

const hangoutChapterMaterialAddAPI = (formData) => {
  let query = API.hangoutChapterMaterialAPI.ADD;

  return promiseFetch(query, 'POST', formData, {
    'Content-Type': 'multipart/form-data',
  });
};

const hangoutChapterMaterialEditAPI = (params) => {
  let query = API.hangoutChapterMaterialAPI.EDIT;

  return promiseFetch(query, 'PUT', JSON.stringify(params));
};

const hangoutChapterMaterialEditOrderingAPI = (params) => {
  let query =
    API.hangoutChapterMaterialAPI.EDIT_ORDERING +
    `?HangoutChapterMaterialId=${params.HangoutChapterMaterialId}&Order=${params.Order}`;

  return promiseFetch(query, 'PUT');
};

const hangoutChapterMaterialDeleteAPI = (hangoutChapterMaterialId) => {
  let query =
    API.hangoutChapterMaterialAPI.DELETE +
    '?HangoutChapterMaterialId=' +
    hangoutChapterMaterialId;

  return promiseFetch(query, 'DELETE');
};

/**
 * 考卷科目列表
 */

const initSubjectTypeList = () => {
  let query = API.subjectTypeAPI.GETLIST;
  return promiseFetch(query, 'GET');
};

/**
 * 新增考卷科目
 *
 * @param {obj} obj    新增考卷科目
 */

const subjectTypeAddAPI = (obj) => {
  let query = API.subjectTypeAPI.ADD;
  return promiseFetch(query, 'POST', JSON.stringify(obj));
};

/**
 * 修改考卷科目
 *
 * @param {obj} obj    欲修改的考卷科目
 */

const subjectTypeEditAPI = (obj) => {
  let query = API.subjectTypeAPI.EDIT;
  return promiseFetch(query, 'POST', JSON.stringify(obj));
};

/**
 * 刪除考卷科目
 *
 * @param {string} Id    欲刪除課程類別
 */

const subjectTypeDeleteAPI = (Id) => {
  let query = API.subjectTypeAPI.DELETE;
  return promiseFetch(query, 'POST', JSON.stringify({ SubjectId: Id }));
};

/**
 * 課程列表
 */

const initHangoutList = (obj) => {
  let query = API.hangoutAPI.GETLIST;

  if (obj) {
    query =
      query +
      '?keyword=' +
      obj.keyword +
      '&page=' +
      obj.page +
      '&count=' +
      obj.count;

    query += obj.status ? '&status=' + obj.status : '';
  }

  return promiseFetch(query, 'GET');
};

/**
 * 課程狀態修改 status(1=上架, 2=下架, 3=刪除)
 *
 * @param {obj} obj    欲修改的課程狀態修改
 */

const switchHangoutDisplay = (obj) => {
  let query = API.hangoutAPI.SWITCH;
  return promiseFetch(query, 'POST', JSON.stringify(obj));
};

/**
 * 套裝狀態修改 status(1=上架, 2=下架, 3=刪除)
 *
 * @param {obj} obj    欲修改的套裝狀態修改
 */

const switchSuitExamDisplay = (obj) => {
  let query = API.suitExamListAPI.SWITCH;
  return promiseFetch(query, 'POST', JSON.stringify(obj));
};

/**
 * 套卷列表
 */

const initSuitExamList = () => {
  let query = API.suitExamListAPI.GETLIST;
  return promiseFetch(query, 'GET');
};

/**
 * 取的套卷
 * @param {suitExamId} suitExamId
 */
const getSuitExam = (suitExamId) => {
  const query = API.suitExamListAPI.GET + '/' + suitExamId;

  return promiseFetch(query, 'GET');
};

/**
 * 取的出售區域
 */
const getSaleArea = () => {
  const query = API.suitExamListAPI.GET_SALE_AREA;

  return promiseFetch(query, 'GET');
};

/**
 * 建立套卷 (含產品內容)
 */
const addSuitExam = (obj) => {
  const query = API.suitExamListAPI.ADD;

  return promiseFetch(query, 'POST', JSON.stringify(obj));
};

/**
 * 儲存套卷設定(不含產品內容)
 */
const saveSuitExam = (obj) => {
  const query = API.suitExamListAPI.EDIT;

  return promiseFetch(query, 'POST', JSON.stringify(obj));
};

/**
 * 儲存套卷的產品內容
 */
const saveSuitExamProducts = (obj) => {
  const query = API.suitExamListAPI.EDITPRODUCT;

  return promiseFetch(query, 'POST', JSON.stringify(obj));
};

/**
 * 熱門精選列表
 */

const initPopularFeatureList = () => {
  let query = API.popularSelectionAPI.GET;
  return promiseFetch(query, 'GET');
};

/**
 * 刪除熱門精選
 *
 * @param {obj} obj    欲刪除熱門精選
 */

const popularFeatureDelete = (obj) => {
  let query = API.popularSelectionAPI.DELETE;
  return promiseFetch(query, 'POST', JSON.stringify(obj));
};

/**
 * 熱門精選狀態修改
 *
 * @param {obj} obj    熱門精選狀態修改
 */

const switchPopularFeatureDisplay = (obj) => {
  let query = API.popularSelectionAPI.SWITCH;
  return promiseFetch(query, 'POST', JSON.stringify(obj));
};

/**
 * 搜尋熱門精選
 *
 * @param {obj} obj
 */
const searchPopularFeature = (obj) => {
  const queryParams = new URLSearchParams({
    status: 1,
    page: obj.page,
    count: obj.count,
  });

  if (_.trim(obj.keyword) !== '') {
    queryParams.append('keyword', obj.keyword);
  }

  let query = API.popularSelectionAPI.SEARCH + `?${queryParams.toString()}`;

  return promiseFetch(query, 'GET');
};

const callInitBannerCarouselAPI = (dispatch) => {
  let query = API.homeBannerCarouselAPI.GET;

  return promiseFetch(query, 'GET', null, null, dispatch);
};

/**
 * 增加廣告
 *
 * 需要上傳檔案，直接丟 formData 過去即可
 */
const callAddBannerAPI = (formData, dispatch) => {
  let query = API.homeBannerCarouselAPI.ADD;

  return promiseFetch(
    query,
    'POST',
    formData,
    { 'Content-Type': 'multipart/form-data' },
    dispatch,
  );
};

/**
 * 編輯廣告
 * @param {formData} 使用 formData
 * @param {dispatch} 提供給需要呼叫 dispatch
 */
const callEditBannerAPI = (formData, dispatch) => {
  let query = API.homeBannerCarouselAPI.EDIT;

  return promiseFetch(
    query,
    'POST',
    formData,
    { 'Content-Type': 'multipart/form-data' },
    dispatch,
  );
};

/**
 * 刪除廣告
 * @param {formData} 使用 formData
 * @param {dispatch} 提供給需要呼叫 dispatch
 * header Content-Type 帶 multipart 是因為帶 application/json 會無法運作
 */
const callDeleteBannerAPI = (formData, dispatch) => {
  let query = API.homeBannerCarouselAPI.DELETE;

  return promiseFetch(
    query,
    'DELETE',
    formData,
    { 'Content-Type': 'multipart/form-data' },
    dispatch,
  );
};

/**
 * 切換廣告上下線
 * @param {formData} 使用 formData
 * @param {dispatch} 提供給需要呼叫 dispatch
 * header 帶 multipart 是因為帶 application/json 會無法運作
 */
const callSwitchBannerDisplayAPI = (formData, dispatch) => {
  let query = API.homeBannerCarouselAPI.DISPALY;

  return promiseFetch(
    query,
    'POST',
    formData,
    { 'Content-Type': 'multipart/form-data' },
    dispatch,
  );
};

/**
 * 取的題庫商店廣告
 * @param {dispatch} dispatch
 */
const callQuestionBankBannerAPI = (dispatch) => {
  let query = API.questionStoreAPI.GET;

  return promiseFetch(query, 'GET', null, null, dispatch);
};

const callEditQuestionBankBannerAPI = (formData, dispatch) => {
  let query = API.questionStoreAPI.EDIT;

  return promiseFetch(
    query,
    'POST',
    formData,
    { 'Content-Type': 'multipart/form-data' },
    dispatch,
  );
};

const callSwitchMemberStatusAPI = (obj, dispatch) => {
  let query = API.memberManagementAPI.memberStatus;

  return promiseFetch(query, 'POST', JSON.stringify(obj), null, dispatch);
};

const getQuestionSources = (dispatch) => {
  let query = API.questionSourceAPI.GET_SOURCE;

  return promiseFetch(query, 'GET', null, null, dispatch);
};

const getQuestions = (urlParameter, dispatch) => {
  let query = API.questionSourceAPI.GET_FILTER_RESULT + '?' + urlParameter;

  return promiseFetch(query, 'GET', null, null, dispatch);
};

const getQuestion = (questionId, dispatch) => {
  let query =
    API.questionSourceAPI.DETAIL_QUESTION + '?questionId=' + questionId;

  return promiseFetch(query, 'GET', null, null, dispatch);
};

const getQuestionVideoUrl = (questionId) => {
  let query = API.questionSourceAPI.GET_VIDEO_URL + '/' + questionId;

  return promiseFetch(query, 'GET');
};

const getQuestionVideoPolicy = (videoName) => {
  return getVideoPolicy('QuestionVideo', videoName);
};

const getVideoPolicy = (videoPath, videoName) => {
  let query =
    API.questionSourceAPI.GET_VIDEO_COOKIE_POLICY +
    '?filePath=' +
    videoPath +
    '&fileName=' +
    videoName;

  return promiseFetch(query, 'GET');
};

const callRejectQuestionAPI = (questionId, dispatch) => {
  let query =
    API.questionSourceAPI.REJECT_QUESTION + '?questionId=' + questionId;

  return promiseFetch(query, 'GET', null, null, dispatch);
};

const getQuestionBankDetial = (questionBankId) => {
  let query =
    API.questionSourceAPI.RE_EDIT_EXAM +
    `?questionBankId=${questionBankId}&getRelation=true`;

  return promiseFetch(query, 'GET');
};

const getQuestionsByQuestionBankId = (questionBankId, dispatch) => {
  let query =
    API.questionSourceAPI.GET_QUESTION_BY_BANKID +
    `?questionBankId=${questionBankId}`;

  return promiseFetch(query, 'GET', null, null, dispatch);
};

const saveQuestionBank = (formData, dispatch) => {
  const query = API.questionSourceAPI.SEND_COMPLETE_EXAM;

  return promiseFetch(
    query,
    'POST',
    formData,
    { 'Content-Type': 'multipart/form-data' },
    dispatch,
  );
};

const getQuestionSubjects = () => {
  const query = API.questionSourceAPI.GET_QUESTION_SUBJECTS;

  return promiseFetch(query, 'GET');
};

const getBanners = (siteCode) => {
  const query = API.bannerAPI.GET + siteCode;

  return promiseFetch(query, 'GET');
};

const setBannerDisplay = (formData) => {
  const query = API.bannerAPI.DISPALY;

  return promiseFetch(query, 'POST', formData, {
    'Content-Type': 'multipart/form-data',
  });
};

const deleteBanner = (formData) => {
  const query = API.bannerAPI.DELETE;

  return promiseFetch(query, 'DELETE', formData, {
    'Content-Type': 'multipart/form-data',
  });
};

const createBanner = (formData) => {
  let query = API.bannerAPI.ADD;

  return promiseFetch(query, 'POST', formData, {
    'Content-Type': 'multipart/form-data',
  });
};

const editBanner = (formData) => {
  let query = API.bannerAPI.EDIT;

  return promiseFetch(query, 'POST', formData, {
    'Content-Type': 'multipart/form-data',
  });
};

const punchCard = (qrCode) => {
  const query = API.punchAPI.POST + qrCode + '?device=qrcode';

  return promiseFetch(query, 'POST');
};

export {
  fetchMembers,
  createMember,
  fetchOrders,
  fetchMemberOrders,
  fetchPromos,
  initSaleExamList,
  deletePromoQuestionBank,
  createPromoQuestionBankAPI,
  deletePromoAPI,
  createPromoAPI,
  saleExamStatusAPI,
  callCopyApI,
  callDeletePromoQuestionBankAPI,
  initExamTypeList,
  examTypeEditAPI,
  examTypeAddAPI,
  examTypeDeleteAPI,
  initHangoutTypeList,
  hangoutTypeEditAPI,
  hangoutTypeAddAPI,
  hangoutTypeDeleteAPI,
  initSubjectTypeList,
  subjectTypeEditAPI,
  subjectTypeAddAPI,
  subjectTypeDeleteAPI,
  initHangoutList,
  switchSuitExamDisplay,
  switchHangoutDisplay,
  initSuitExamList,
  getSuitExam,
  getSaleArea,
  addSuitExam,
  saveSuitExam,
  saveSuitExamProducts,
  initPopularFeatureList,
  popularFeatureDelete,
  switchPopularFeatureDisplay,
  searchPopularFeature,
  callInitBannerCarouselAPI,
  callAddBannerAPI,
  callEditBannerAPI,
  callDeleteBannerAPI,
  callSwitchBannerDisplayAPI,
  callQuestionBankBannerAPI,
  callEditQuestionBankBannerAPI,
  callSwitchMemberStatusAPI,
  getQuestionSources,
  getQuestions,
  getQuestion,
  getQuestionVideoUrl,
  getQuestionVideoPolicy,
  getVideoPolicy,
  callRejectQuestionAPI,
  getQuestionBankDetial,
  getQuestionsByQuestionBankId,
  saveQuestionBank,
  getQuestionSubjects,
  hangoutChapterMaterialAddAPI,
  hangoutChapterMaterialEditAPI,
  hangoutChapterMaterialEditOrderingAPI,
  hangoutChapterMaterialDeleteAPI,
  getBanners,
  setBannerDisplay,
  deleteBanner,
  createBanner,
  editBanner,
  punchCard,
};
