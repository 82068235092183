import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../../style/components/SaleExam/check-exam-history.scss';
import { saleExamListAPI } from '../../store/apiconfig';

class CheckExamHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logList: [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.isVisible === true &&
      nextProps.examName.trim() !== '' &&
      nextProps.examId !== 0
    ) {
      fetch(saleExamListAPI.GETLOG + '?questionBankId=' + nextProps.examId, {
        method: 'get',
        headers: {
          Authorization: 'bearer ' + localStorage.getItem('access_token'),
        },
      })
        .then((response) => {
          if (response.status === 401) {
            // token過期 導回登入頁面
            this.props.logoutSuccess();
          } else {
            return response.json();
          }
        })
        .then((data) => {
          this.setState({
            logList: data.Result,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  render() {
    const { logList } = this.state;
    const { isVisible, closeFunc, examName } = this.props;
    return (
      <div
        className="check-exam-history fadeIn"
        onClick={closeFunc}
        style={{ display: isVisible ? 'flex' : 'none' }}>
        <div
          className="box bounceIn2"
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <div className="close" onClick={closeFunc}>
            <i className="fas fa-times"></i>
          </div>
          <div className="title">{examName}</div>
          <div className="table-responsive">
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>修改日期</th>
                  <th>原本狀態</th>
                  <th>修改狀態</th>
                  <th>修改人</th>
                </tr>
              </thead>
              <tbody>
                {logList.map((item, index) => (
                  <tr key={index}>
                    <td>{item.CreateAt.slice(0, 10)}</td>
                    <td>
                      {item.OldStatus === 1
                        ? '上架'
                        : item.OldStatus === 2
                        ? '下架'
                        : '刪除'}
                    </td>
                    <td>
                      {item.NewStatus === 1
                        ? '上架'
                        : item.NewStatus === 2
                        ? '下架'
                        : '刪除'}
                    </td>
                    <td>{item.Username}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

CheckExamHistory.propTypes = {
  isVisible: PropTypes.bool,
  examId: PropTypes.number,
  examName: PropTypes.string,
  closeFunc: PropTypes.func,
};

export default CheckExamHistory;
