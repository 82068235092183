import React, { useState } from 'react';
import _ from 'lodash';
import {
  Button,
  Box,
  TableCell,
  TableRow,
  Collapse,
  IconButton,
  Chip,
  TextField,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import VideoLibraryRoundedIcon from '@material-ui/icons/VideoLibraryRounded';
import FaceIcon from '@material-ui/icons/Face';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VideoFileStatus from './VideoFileStatus';
import KnowledgeSelect from '../KnowledgeSelect';
import Swal from 'sweetalert2';
import useVideoQueue from '../../hooks/useVideoQueue';
import VideoNode from './VideoNode';

const useStyles = makeStyles((theme) => ({
  editTr: {
    backgroundColor: theme.palette.grey[50],
  },
  collapseTr: {
    paddingTop: '0',
    paddingBottom: '0',
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(1),
  },
  titleTd: {
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    fontWeight: '600',
  },
  toolsBtn: {
    marginLeft: theme.spacing(1),
  },
  knowledge: {
    padding: theme.spacing(1, 0),
    backgroundColor: theme.palette.grey[100],
    borderRadius: '8px',
    textAlign: 'left',

    '& span': {
      margin: theme.spacing(1),
      padding: theme.spacing(0, 1),
      fontSize: '0.8rem',
      display: 'block',
      wordWrap: 'break-word',
      whiteSpace: 'normal',
      borderLeft: '2px solid #555c78',
    },
  },
}));

const VideoTableRow = (props) => {
  const { getVideoQueue } = useVideoQueue();
  const {
    video,
    saveVideo,
    deleteVideo,
    changeVideoFile,
    saveNode,
    deleteNode,
    isSelect,
    setSelect,
  } = props;
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [videoForm, setVideoForm] = useState(() => {
    return {
      name: video.name,
      knowledgeList: _.map(video.knowledgeList, (knowledge) => knowledge.id),
    };
  });
  const classes = useStyles();

  const videoQueue = getVideoQueue(video.handoutVideoId);
  const isNowQueue =
    videoQueue && videoQueue.status !== 'done' && videoQueue.status !== 'error';

  const handleSaveVideo = () => {
    if (videoForm.name === '') {
      Swal.fire({
        icon: 'error',
        title: '請填寫影片名稱',
      });

      return false;
    }

    if (videoForm.knowledgeList.length <= 0) {
      Swal.fire({
        icon: 'error',
        title: '請選擇知識點',
      });

      return false;
    }

    let editVideoFormData = new URLSearchParams();

    editVideoFormData.append('HandoutVideoId', video.handoutVideoId);
    editVideoFormData.append('Name', videoForm.name);
    editVideoFormData.append(
      'KnowledgeIds',
      videoForm.knowledgeList ? videoForm.knowledgeList : '',
    );

    saveVideo(editVideoFormData);
  };

  const handleDeleteVideo = () => {
    Swal.fire({
      icon: 'question',
      title: '確定要刪除影片嗎？',
      text: '刪除後將無法再使用影片，請小心使用!',
      showCancelButton: true,
      confirmButtonText: '刪除',
      cancelButtonText: '取消',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteVideo(video.handoutVideoId);
      }
    });
  };

  const handleChangeVideo = () => {
    changeVideoFile(video);
  };

  return (
    <>
      <TableRow className={isEdit ? classes.editTr : ''}>
        <TableCell component="th">
          節點
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="right">{video.handoutVideoId}</TableCell>
        <TableCell
          align="right"
          className={classes.titleTd}
          colSpan={isEdit ? 2 : 1}>
          {isEdit ? (
            <TextField
              label="影片名稱"
              variant="standard"
              value={videoForm.name}
              autoFocus={true}
              fullWidth={true}
              size="small"
              onChange={(e) => {
                setVideoForm({ ...videoForm, name: e.target.value });
              }}
            />
          ) : (
            video.name
          )}
        </TableCell>
        <TableCell align="right" colSpan={isEdit ? 2 : 1}>
          {isEdit ? (
            <KnowledgeSelect
              initKnowledge={videoForm.knowledgeList}
              setKnowledge={(knowledge) => {
                setVideoForm((state) => {
                  return { ...state, knowledgeList: knowledge };
                });
              }}
            />
          ) : video.knowledgeList.length > 0 ? (
            <Box className={classes.knowledge}>
              {_.map(video.knowledgeList, (knowledge) => (
                <span key={knowledge.id}>{knowledge.name}</span>
              ))}
            </Box>
          ) : null}
        </TableCell>
        {!isEdit && (
          <>
            <TableCell align="right">
              <VideoFileStatus
                video={video}
                videoQueue={videoQueue}
                size="small"
              />
            </TableCell>
            <TableCell align="right">
              <Chip icon={<FaceIcon />} label={video.modifiedUser.realName} />
            </TableCell>
          </>
        )}
        <TableCell align="right">
          {!isEdit ? (
            <>
              <Button
                color="primary"
                variant="contained"
                size="small"
                startIcon={<VideoLibraryRoundedIcon />}
                className={classes.toolsBtn}
                onClick={handleChangeVideo}
                disabled={isNowQueue}>
                更換影片
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<EditIcon />}
                className={classes.toolsBtn}
                onClick={() => {
                  setIsEdit(true);
                }}
                disabled={isNowQueue}>
                編輯
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<DeleteIcon />}
                className={classes.toolsBtn}
                onClick={handleDeleteVideo}
                disabled={isNowQueue}>
                刪除
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<DoneRoundedIcon />}
                className={classes.toolsBtn}
                onClick={handleSaveVideo}>
                儲存
              </Button>
              <Button
                variant="contained"
                color="default"
                size="small"
                startIcon={<ClearRoundedIcon />}
                className={classes.toolsBtn}
                onClick={() => {
                  setIsEdit(false);
                }}>
                取消
              </Button>
            </>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.collapseTr} colSpan={7}>
          <Collapse in={open} timeout="auto" mountOnEnter>
            <Box mt={2} mb={1}>
              {(video.videoName || videoQueue) && (
                <VideoNode
                  video={video}
                  nodeList={video.nodeList}
                  videoQueue={videoQueue}
                  videoOpen={open}
                  saveNode={saveNode}
                  deleteNode={deleteNode}
                  isSelect={isSelect}
                  setSelect={setSelect}
                />
              )}

              {!video.videoName && !videoQueue && (
                <Alert severity="info" variant="filled">
                  <AlertTitle>目前沒有影片，無法建立節點</AlertTitle>
                </Alert>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default VideoTableRow;
