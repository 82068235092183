import React, { useState, useEffect, useRef } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import Pagination from '../../components/Pagination';
import ConfirmBox from '../../components/ConfirmBox';
import CheckExamHistory from '../../components/SaleExam/CheckExamHistory';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import '../../style/components/SaleExam/sale-exam.scss';
import QrcordBlock from '../../components/QrcordBlock';
import Search from '../../components/Search';
import Table from '../../components/Table';
import {
  initSaleExamList,
  saleExamStatusAPI,
  callDeletePromoQuestionBankAPI,
  callCopyApI,
} from '../../Util/fetchMethods';
import { Checkbox } from '@material-ui/core';
import SourceUrlDialog from '../../components/SourceUrlDialog';

const SaleExamList = ({ match }) => {
  //HOOKS
  const [qrcodeBlock, setQrcodeBlock] = useState({
    isOpenQrcodeBlock: false,
    QrcordText: '',
  });
  const [urlDialogBag, setUrlDialogBag] = useState({
    open: false,
    sourceId: '',
    type: '',
  });
  const [examination, setExamination] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const [currPage, setCurrPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [countOfPage, setCountOfPage] = useState(10);
  const [historyBox, setHistoryBox] = useState({
    checkId: 0,
    checkName: '',
    isCheckHistoryVisible: false,
  });

  const [deleteBox, setDeleteBox] = useState({
    deletingID: '',
    isDeleteVisible: false,
  });

  const updateSaleExamList = () => {
    initSaleExamList(inputSearch, currPage, countOfPage)
      .then((res) => {
        setExamination(res.Result.QuestionBankList);
        setCurrPage(res.Result.CurrentPage);
        setTotalPage(res.Result.TotalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    updateSaleExamList(inputSearch, currPage, countOfPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch, currPage, countOfPage]);

  // 上一次每頁顯示
  const preCountOfPage = useRef(10);
  useEffect(() => {
    preCountOfPage.current = countOfPage;
  });

  //Methods
  const handleToggleOpenQrcordBlcok = () => {
    setQrcodeBlock({
      ...qrcodeBlock,
      isOpenQrcodeBlock: !qrcodeBlock.isOpenQrcodeBlock,
    });
  };

  const handleOpenQrcordBlcok = (text) => {
    setQrcodeBlock({
      isOpenQrcodeBlock: true,
      QrcordText: text,
    });
  };

  const searchKeyWord = () => {
    updateSaleExamList(inputSearch, 1, countOfPage);
  };

  const handleChangePage = (thisPage) => {
    setCurrPage(thisPage);
  };

  const handleCountOfpageShow = (e) => {
    setCountOfPage(e.target.value);
    preCountOfPage.current < e.target.value
      ? setCurrPage(Math.ceil((countOfPage * currPage) / e.target.value))
      : setCurrPage(currPage);
  };

  const handleDeleteEventbox = (id, name) => {
    //新增 alert 訊息, 成功後更新頁面
    Swal.fire({
      icon: 'info',
      title: '是否確定要將題庫從活動中移除？',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: '確定',
      heightAuto: false,
    }).then((result) => {
      if (result.value) {
        callDeletePromoQuestionBankAPI(id, name).then(() => {
          Swal.fire({
            icon: 'success',
            title: '刪除成功',
            showConfirmButton: false,
            showCloseButton: false,
            timer: 1500,
          }).then(() => {
            updateSaleExamList(inputSearch, currPage, countOfPage);
          });
        });
      }
    });
    return;
  };

  const handleCopyButton = (id) => {
    //新增 alert 訊息, 成功後更新頁面
    Swal.fire({
      icon: 'info',
      title: '是否確定要複製？',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: '確定',
      heightAuto: false,
    }).then((result) => {
      if (result.value) {
        callCopyApI(id).then(() => {
          Swal.fire({
            icon: 'success',
            title: '複製成功',
            showConfirmButton: false,
            showCloseButton: false,
            timer: 1500,
          }).then(() => {
            updateSaleExamList(inputSearch, currPage, countOfPage);
          });
        });
      }
    });
    return;
  };

  const handleOpenChekHistorybox = (id, name) => {
    setHistoryBox({
      checkId: id,
      checkName: name,
      isCheckHistoryVisible: true,
    });
  };

  const handleCheckboxOnChange = (id, status) => {
    let newArray = [...examination].map((exam) => {
      if (exam.QuestionBankId === id) {
        exam.Status = exam.Status === 1 ? 2 : 1;
      }
      return exam;
    });
    setExamination(newArray);
    saleExamStatusAPI(status === 1 ? 2 : 1, id);
  };

  const handleOpenDeleteBox = (e, id) => {
    e.preventDefault();
    setDeleteBox({
      deletingID: id,
      isDeleteVisible: true,
    });
  };

  const handleDeleteBoxComfrim = (id) => {
    let newArray = [...examination].filter(
      (exam) => exam.QuestionBankId !== id,
    );
    setExamination(newArray);
    saleExamStatusAPI(3, id);
    //新增 alert 訊息, 成功後更新頁面
    Swal.fire({
      icon: 'success',
      title: '刪除成功',
      showConfirmButton: false,
      showCloseButton: false,
      timer: 2100,
    }).then(() => {
      updateSaleExamList(inputSearch, currPage, countOfPage);
    });
  };

  const handleCloseChekHistorybox = () => {
    setHistoryBox({ ...historyBox, isCheckHistoryVisible: false });
  };

  const handleCloseDeleteBox = () => {
    setDeleteBox({ ...deleteBox, isDeleteVisible: false });
  };

  const handleOpenUrl = (questionBankId) => {
    setUrlDialogBag({
      ...urlDialogBag,
      open: true,
      sourceId: questionBankId,
      type: 4,
    });
  };

  //table data setting
  const columns = [
    {
      title: 'ID',
      field: 'QuestionBankId',
      property: true,
    },
    {
      title: '編卷日期',
      field: 'CreateAt',
      property: true,
      render: (props) => {
        return <>{props.CreateAt.slice(0, 10)}</>;
      },
    },
    { title: '卷名', field: 'Name', property: true },
    { title: '定價', field: 'Price', property: true },
    {
      title: '特價',
      field: 'DiscountPrice',
      property: true,
      render: (props) => {
        return <span className="specialOffer">{props.DiscountPrice}</span>;
      },
    },
    {
      title: '特價期間',
      field: 'DiscountStartAt',
      property: false,
      render: (props) => {
        let { DiscountStartAt, DiscountEndAt } = props;
        DiscountStartAt = DiscountStartAt ? DiscountStartAt.slice(0, 10) : '';
        DiscountEndAt = DiscountEndAt ? DiscountEndAt.slice(0, 10) : '';
        let countinueMark = DiscountStartAt === '' ? '' : '~';
        if (DiscountStartAt !== null) {
          return (
            <>
              {DiscountStartAt}
              <br />
              <span style={{ writingMode: 'vertical-lr' }}>
                {countinueMark}
              </span>
              <br />
              {DiscountEndAt}
            </>
          );
        }
      },
    },
    { title: '銷售類別', field: 'SaleType', property: false },
    { title: '銷售地區', field: 'AreaId', property: false },
    {
      title: '銷售活動',
      field: 'Events',
      property: false,
      render: (props) => {
        return props.Events.map((eventName, i) => {
          return (
            <span key={i}>
              <a
                className="primary"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  handleOpenQrcordBlcok(
                    'https://wisdombank.liangshishu.com/promotion?promocode=' +
                      eventName.Usage,
                  );
                }}>
                {eventName.Usage}
              </a>
              <span
                onClick={(e) => {
                  e.preventDefault();
                  handleDeleteEventbox(eventName.PromoId, props.QuestionBankId);
                }}>
                <i className="fa fa-trash-alt"></i>
                <br />
              </span>
            </span>
          );
        });
      },
    },
    {
      title: '培優補差',
      field: 'peiyou',
      property: false,
      render: (props) => {
        return (
          <NavLink
            className="primary"
            to={`/SaleExam/PeiyouSetting/${props.QuestionBankId}`}>
            設定
          </NavLink>
        );
      },
    },
    {
      title: '上架',
      filed: 'status',
      property: false,
      render: (props) => {
        return (
          <Checkbox
            color="primary"
            checked={props.Status === 1 ? true : false}
            onChange={() => {
              handleCheckboxOnChange(props.QuestionBankId, props.Status);
            }}
            disabled={props.Events.length > 0}
          />
        );
      },
    },
    {
      title: '操作',
      field: 'operation',
      property: false,
      render: (props) => {
        return (
          <>
            <NavLink to={`/SetExam/edit/${props.QuestionBankId}`}>編輯</NavLink>
            <a
              className="warning"
              href="/"
              onClick={(e) => {
                e.preventDefault();
                handleCopyButton(props.QuestionBankId);
              }}>
              複製
            </a>
            <br />
            <a
              className="primary"
              href="/"
              onClick={(e) => {
                e.preventDefault();
                handleOpenChekHistorybox(props.QuestionBankId, props.Name);
              }}>
              查看
            </a>
            <a
              className="danger"
              href="/"
              onClick={(e) => {
                handleOpenDeleteBox(e, props.QuestionBankId);
              }}>
              刪除
            </a>
            <br />
            <a
              className="full"
              href="/"
              onClick={(e) => {
                e.preventDefault();
                handleOpenUrl(props.QuestionBankId);
              }}>
              查看連結
            </a>
          </>
        );
      },
    },
  ];

  //render jsx
  return (
    <div className="sale-exam-management pagefadeIn">
      <QrcordBlock
        isVisible={qrcodeBlock.isOpenQrcodeBlock}
        QrcordText={qrcodeBlock.QrcordText}
        toggleOpen={handleToggleOpenQrcordBlcok}></QrcordBlock>
      <BreakCrumbs path={match.url}></BreakCrumbs>

      <div className="block">
        <h4 style={{ display: 'inline' }}>
          銷售類別：1.完整詳解, 2.無文字詳解, 3.無影片詳解, 4.無詳解
        </h4>

        <Search
          prompt="搜尋 關鍵字"
          value={inputSearch}
          onChange={(e) => {
            setInputSearch(e.target.value);
          }}
          searching={searchKeyWord}></Search>

        <Table columns={columns} datas={examination}></Table>

        <div className="pagination-box">
          <Pagination
            currPage={currPage}
            totalPage={totalPage}
            onChangPage={handleChangePage}></Pagination>

          <div className="view-single-page">
            每頁顯示
            <select
              value={countOfPage}
              /* 計算更新每頁顯示後的 currPage */
              onChange={handleCountOfpageShow}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
          </div>
        </div>
      </div>

      <ConfirmBox
        isVisible={deleteBox.isDeleteVisible}
        title={'刪除'}
        content={`刪除後無法復原<br>請確認是否繼續`}
        confirmMethod={() => {
          handleDeleteBoxComfrim(deleteBox.deletingID);
        }}
        closebox={handleCloseDeleteBox}></ConfirmBox>

      <CheckExamHistory
        isVisible={historyBox.isCheckHistoryVisible}
        examName={historyBox.checkName}
        examId={historyBox.checkId}
        closeFunc={handleCloseChekHistorybox}></CheckExamHistory>

      <SourceUrlDialog
        open={urlDialogBag.open}
        setOpen={(open) => {
          setUrlDialogBag({ ...urlDialogBag, open: open });
        }}
        sourceId={urlDialogBag.sourceId}
        type={urlDialogBag.type}
        title="售卷連結"
      />
    </div>
  );
};

export default SaleExamList;
