import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import VideoTableRow from './VideoTableRow';
import { makeStyles } from '@material-ui/core/styles';
import videoEmptySvg from '../../images/video_empty.svg';
import _ from 'lodash';
import TablePaginationActions from '../TablePaginationActions';

const useStyles = makeStyles((theme) => ({
  emptyImg: {
    textAlign: 'center',
    backgroundColor: theme.palette.grey[50],

    '& img': {
      margin: theme.spacing(5, 'auto'),
      maxHeight: '30vh',
    },
  },
  table: {
    marginTop: theme.spacing(3),
    '& thead > tr > th': {
      fontWeight: '600',
      color: '#fff',
    },
  },
}));

const VideoTable = (props) => {
  const {
    videos,
    defaultPrePageRows,
    perPageRows,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    totalCount,
    saveVideo,
    deleteVideo,
    changeVideoFile,
    saveNode,
    deleteNode,
    isSelect,
    setSelect,
  } = props;
  const classes = useStyles();

  return (
    <>
      {videos.length > 0 && (
        <TableContainer component={Box}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="right">Id</TableCell>
                <TableCell align="right">名稱</TableCell>
                <TableCell align="right">知識點</TableCell>
                <TableCell align="right">影片</TableCell>
                <TableCell align="right">最後修改者</TableCell>
                <TableCell align="right">功能</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(videos, (video) => (
                <VideoTableRow
                  key={video.handoutVideoId}
                  video={video}
                  saveVideo={saveVideo}
                  deleteVideo={deleteVideo}
                  changeVideoFile={changeVideoFile}
                  saveNode={saveNode}
                  deleteNode={deleteNode}
                  isSelect={isSelect}
                  setSelect={setSelect}
                />
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[defaultPrePageRows, 25, 50]}
                  colSpan={7}
                  count={totalCount}
                  rowsPerPage={perPageRows}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}

      {videos.length <= 0 && (
        <Box textAlign="center" className={classes.emptyImg}>
          <img src={videoEmptySvg} />
          <Box
            fontWeight="fontWeightBold"
            fontSize="h3.fontSize"
            lineHeight="3">
            無影片內容，請上傳影片
          </Box>
        </Box>
      )}
    </>
  );
};

export default VideoTable;
