import React, { useEffect, useMemo, useState } from 'react';
import {
  Grid,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  FormHelperText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ContactSupportRoundedIcon from '@material-ui/icons/ContactSupportRounded';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import InputRoundedIcon from '@material-ui/icons/InputRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import _ from 'lodash';
import QuestionRow from '../Question/QuestionRow';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
  fileInput: {
    display: 'none',
  },
  option: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2, 3),
    cursor: 'pointer',
    borderRadius: '6px',
    margin: theme.spacing(2, 'auto'),

    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  optionTitle: {
    fontWeight: '500',
    fontSize: '1rem',
    color: theme.palette.info.main,
    letterSpacing: '1px',
    marginRight: theme.spacing(1),

    '& svg': {
      verticalAlign: 'bottom',
      fontSize: '2.5rem',
      marginRight: theme.spacing(1),
    },
  },
  optionContent: {
    marginLeft: theme.spacing(2),
    fontSize: '0.8rem',
    fontWeight: '500',

    '& > div > span': {
      fontWeight: '600',
      color: theme.palette.secondary.main,
    },

    '& > button': {
      verticalAlign: 'bottom',
      marginLeft: theme.spacing(1),
    },
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
  useVideoBtn: {
    marginTop: theme.spacing(1),
  },
  videoDurationString: {
    marginLeft: theme.spacing(2),
    fontSize: '0.8rem',
    fontWeight: '600',
  },
}));

const VideoSourceDialog = (props) => {
  const {
    open,
    setOpen,
    mode,
    handoutVideo,
    fileUpload,
    searchQuestionById,
    createVideoByQuestion,
    updateVideoByQuestion,
    refreshList,
  } = props;
  const classes = useStyles();
  const [questionId, setQuestionId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState(null);
  const [questionSearchError, setQuestionSearchError] = useState('');
  const [isProcessQuestions, setIsProcessQuestions] = useState(false);

  useEffect(() => {
    if (isProcessQuestions) {
      const isHaveProcessQuestionNow =
        _.filter(questions, (question) => {
          return question.status === 'process';
        }).length > 0;

      if (isHaveProcessQuestionNow === false) {
        const processQuestionIndex = _.findIndex(questions, (question) => {
          return question.status === 'ready';
        });

        if (processQuestionIndex === -1) {
          Swal.fire({
            title: '處理完成',
            icon: 'success',
          });
          setIsProcessQuestions(false);
          setIsLoading(false);
          setQuestionId('');
          refreshList();
        } else {
          let newQuestions = [...questions];

          newQuestions[processQuestionIndex].status = 'process';

          setQuestions(newQuestions);
        }
      }
    }
  }, [questions, isProcessQuestions]);

  const claerState = () => {
    setQuestions(null);
    setIsLoading(false);
    setQuestionId('');
    setQuestionSearchError('');
  };

  const handleClose = () => {
    setOpen(false);
    claerState();
  };

  const handleFileUpload = (e) => {
    setIsLoading(true);

    return fileUpload(e, handoutVideo, () => {
      setIsLoading(false);
      handleClose();
    });
  };

  const handleSearchQuestionById = async () => {
    const consecutiveNumbersRegex = /\d+/g;
    const searchQuestionIds = questionId.match(consecutiveNumbersRegex);

    if (!Array.isArray(searchQuestionIds) || searchQuestionIds.length <= 0) {
      setQuestionSearchError('請輸入題目ID');
      return false;
    }

    if (mode === 'update' && searchQuestionIds.length > 1) {
      setQuestionSearchError('更換影片，無法使用多題目 ID 搜尋更換');
      return false;
    }

    setIsLoading(true);
    setQuestions(null);
    setQuestionSearchError('');

    let searchQuestions = [];

    for (let index = 0; index < searchQuestionIds.length; index++) {
      const searchQuestionRs = await searchQuestionById(
        searchQuestionIds[index],
      ).then(
        (response) => {
          if (!response.videoName) {
            return {
              status: 'error',
              message: '題目沒有解題影片',
              questionId: searchQuestionIds[index],
            };
          }

          return {
            status: 'ready',
            question: response,
            questionId: searchQuestionIds[index],
          };
        },
        (error) => {
          return {
            status: 'error',
            message:
              error && error.messages && _.isArray(error.messages)
                ? _.join(error.messages, '<br />')
                : error,
            questionId: searchQuestionIds[index],
          };
        },
      );

      searchQuestions.push(searchQuestionRs);
    }

    setIsLoading(false);
    setQuestions(searchQuestions);
  };

  const handleUseVideo = () => {
    setIsLoading(true);
    setIsProcessQuestions(true);
  };

  const createOrUpdateVideo = (question, videoDuration) => {
    if (mode === 'update') {
      return updateVideoByQuestion(
        handoutVideo,
        question.videoName,
        videoDuration,
      );
    } else if (mode === 'create') {
      return createVideoByQuestion(question, videoDuration);
    }
  };

  const fileInputId = useMemo(() => {
    return _.uniqueId('dialogFileUpload_');
  }, []);

  const searchQuestionInputId = useMemo(() => {
    return _.uniqueId('dialogQuestionSearch_');
  }, []);

  const removeQuestion = (questionIndex) => {
    let newQuestions = [...questions];
    newQuestions.splice(questionIndex, 1);
    setQuestions(newQuestions);
  };

  const updateQuestion = (questionIndex, updateContent) => {
    let newQuestions = [...questions];
    newQuestions[questionIndex] = {
      ...newQuestions[questionIndex],
      ...updateContent,
    };
    setQuestions(newQuestions);
  };

  const getCanUseQuestionNumber = () => {
    if (questions) {
      return _.filter(questions, (question) => {
        return question.status === 'ready';
      }).length;
    } else {
      return 0;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      fullWidth={true}
      maxWidth="sm">
      <DialogTitle>{mode === 'update' ? '更換影片' : '建立影片'}</DialogTitle>

      <DialogContent>
        <label htmlFor={fileInputId}>
          <Box className={classes.option}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center">
              <Grid item xs={4}>
                <div className={classes.optionTitle}>
                  <CloudUploadIcon /> 選擇檔案上傳
                </div>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item xs={7}>
                <Box className={classes.optionContent}>
                  <div>
                    檔案格式僅接收 <span>MP4, MOV</span>
                  </div>

                  {mode === 'update' && (
                    <div>
                      檔案更換完成後，請注意<span>節點設定是否正確</span>
                    </div>
                  )}

                  {mode === 'create' && (
                    <div>
                      可一次<span>選擇多筆檔案</span>建立上傳
                    </div>
                  )}
                </Box>
              </Grid>
            </Grid>

            <input
              accept=".mp4,.mov"
              className={classes.fileInput}
              id={fileInputId}
              multiple={mode === 'create'}
              type="file"
              onChange={handleFileUpload}
              disabled={isLoading}
            />
          </Box>
        </label>
        <Divider variant="middle" />
        <label htmlFor={searchQuestionInputId}>
          <Box className={classes.option}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center">
              <Grid item xs={4}>
                <div className={classes.optionTitle}>
                  <ContactSupportRoundedIcon /> 使用題目影片
                </div>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item xs={7}>
                <Box className={classes.optionContent}>
                  <TextField
                    label="題目ID"
                    id={searchQuestionInputId}
                    value={questionId}
                    multiline
                    onChange={(e) => {
                      setQuestionId(e.target.value);
                    }}
                    disabled={isLoading}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SearchRoundedIcon />}
                    onClick={handleSearchQuestionById}
                    disabled={isLoading}>
                    搜尋
                  </Button>
                  <FormHelperText>
                    {mode === 'update' &&
                      '更換影片只能輸入單一ID，不能多ID更換'}
                    {mode !== 'update' &&
                      '可輸入多題目ID，請使用 , 空白 或 斷行 分隔'}
                  </FormHelperText>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </label>
        {questionSearchError !== '' && (
          <Alert severity="error">
            {typeof questionSearchError === 'string' && (
              <AlertTitle>{questionSearchError}</AlertTitle>
            )}
            {typeof questionSearchError !== 'string' && (
              <>
                <AlertTitle>{questionSearchError.title}</AlertTitle>
                {questionSearchError.html}
              </>
            )}
          </Alert>
        )}

        {questions &&
          questions.map((question, questionIndex) => {
            return (
              <QuestionRow
                key={question.questionId}
                question={question?.question}
                status={question.status}
                questionId={question.questionId}
                message={question.message}
                isLoading={isLoading}
                removeQuestion={() => {
                  removeQuestion(questionIndex);
                }}
                updateQuestion={(updateContent) => {
                  updateQuestion(questionIndex, updateContent);
                }}
                createOrUpdateVideo={createOrUpdateVideo}
              />
            );
          })}
      </DialogContent>

      <DialogActions classes={{ root: classes.dialogActions }}>
        <div>
          {questions && (
            <div className={classes.useVideoBtn}>
              <Button
                color="primary"
                variant="contained"
                startIcon={
                  mode === 'update' ? <InputRoundedIcon /> : <AddRoundedIcon />
                }
                onClick={handleUseVideo}
                disabled={isLoading || getCanUseQuestionNumber() <= 0}>
                {mode === 'update' ? '使用此影片' : '建立影片'}
              </Button>

              <span className={classes.videoDurationString}>
                可使用影片數量 {getCanUseQuestionNumber()}
              </span>
            </div>
          )}
        </div>

        <Button onClick={handleClose} disabled={isLoading} color="primary">
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VideoSourceDialog;
