import React from 'react';
import {
  Box,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import red from '@material-ui/core/colors/red';
import deepOrange from '@material-ui/core/colors/deepOrange';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import clsx from 'clsx';

const questionScoreToText = {
  Formal: '正式題',
  Similar: '類似題',
  Easier: '補差題',
  Harder: '培優題',
};

const useStyles = makeStyles((theme) => ({
  overview: {
    textAlign: 'center',
  },
  number: {
    fontSize: '5rem',
    color: '#fff',
    fontWeight: theme.typography.fontWeightLight,
    textAlign: 'center',
    margin: 'auto',
    width: '150px',
    backgroundColor: deepOrange[200],
    borderRadius: '2px',
  },
  overviewText: {
    fontSize: '0.9rem',
    marginTop: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '1px',
    lineHeight: '30px',
    color: '#fff',
  },
  overviewList: {
    listStyle: 'none',
    padding: 0,
    '& > li': {
      textAlign: 'center',
      lineHeight: '30px',
      fontSize: '0.9rem',
      color: theme.palette.grey[700],
      '& > span': {
        fontSize: '1.2rem',
        color: '#000',
        verticalAlign: 'sub',
        marginLeft: theme.spacing(1),
      },
    },
  },
  scoreItem: {
    marginTop: theme.spacing(1),
    fontSize: '1.5rem',
    width: '100%',
  },
  disabledSetting: {
    color: theme.palette.grey[300],
  },
  inlineDesc: {
    display: 'inline-block',
    fontSize: '0.8rem',
    color: theme.palette.grey[500],
    margin: theme.spacing(0, 1),
  },
  questionNumberDesc: {
    display: 'inline-block',
    fontSize: '0.9rem',
    color: theme.palette.grey[500],
    lineHeight: '20px',
    marginLeft: theme.spacing(1),
  },
  scoreType: {
    fontSize: '1.4rem',
    textAlign: 'left',
    fontWeight: theme.typography.fontWeightLight,
    backgroundColor: theme.palette.grey[200],
    borderRadius: '2px',
  },
  scoreTotal: {
    textAlign: 'right',
    '& :first-child': {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '1.2rem',
    },
    '& :last-child': {
      fontSize: '3rem',
      borderBottom: '2px solid',
      color: '#fff',
      marginLeft: theme.spacing(1),
      backgroundColor: red[200],
      padding: theme.spacing(0, 1),
      borderRadius: '2px',
    },
  },
}));

const ExamQuestionScoreSetting = (props) => {
  const questionSource = useSelector((state) => state.QuestionSourceState);
  const classes = useStyles();

  const score = (questionType) => {
    let score = 0;

    switch (questionType) {
      case 'singleChoice':
        score =
          props.questionCounts.singleChoiceCount * props.singleChoiceScore;
        break;

      case 'multipleChoice':
        score =
          props.questionCounts.multipleChoiceCount * props.multipleChoiceScore;
        break;

      case 'setChoice':
        score = props.questionCounts.setChoiceSubCount * props.setChoiceScore;
        break;

      case 'clozeChoice':
        score =
          props.questionCounts.clozeChoiceSubCount * props.clozeChoiceScore;
        break;

      case 'total':
        score =
          props.questionCounts.singleChoiceCount * props.singleChoiceScore +
          props.questionCounts.multipleChoiceCount * props.multipleChoiceScore +
          props.questionCounts.setChoiceSubCount * props.setChoiceScore +
          props.questionCounts.clozeChoiceSubCount * props.clozeChoiceScore;
        break;
    }

    return score;
  };

  return (
    <Box
      role="tabpanel"
      p={3}
      hidden={props.nowQuestionScoreIndex !== props.index}
      id={props.id}>
      <Grow in={props.nowQuestionScoreIndex === props.index}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={3}>
          <Grid item xs={12} sm={3} className={classes.overview}>
            <div className={classes.number}>
              {props.questionCounts.count}
              <div className={classes.overviewText}>
                {questionScoreToText[props.questionScore]}題數
              </div>
            </div>
            <ul className={classes.overviewList}>
              <li>
                影片數<span>{props.questionCounts.videoCount}</span>
              </li>
              <li>
                單選題<span>{props.questionCounts.singleChoiceCount}</span>
              </li>
              <li>
                複選題<span>{props.questionCounts.multipleChoiceCount}</span>
              </li>
              <li>
                題組題<span>{props.questionCounts.setChoiceCount}</span>
              </li>
              <li>
                克漏字題<span>{props.questionCounts.clozeChoiceCount}</span>
              </li>
            </ul>
          </Grid>

          <Grid item xs={12} sm={9}>
            <Typography variant="h5" color="textPrimary" gutterBottom>
              分數分配
            </Typography>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="baseline"
              spacing={3}>
              <Grid item xs={12} className={classes.scoreItem}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="stretch"
                  spacing={3}>
                  <Grid item xs={12} sm={2} className={classes.scoreType}>
                    單選題
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={10}
                    className={
                      props.questionCounts.singleChoiceCount <= 0
                        ? classes.disabledSetting
                        : ''
                    }>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-end"
                      spacing={3}>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          label="每題分數"
                          size="small"
                          value={props.singleChoiceScore}
                          disabled={props.questionCounts.singleChoiceCount <= 0}
                          onChange={(e) => {
                            props.setQuestionScore(
                              props.questionScore,
                              'SingleChoiceScore',
                              e.target.value,
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <div className={classes.inlineDesc}>題數</div> X{' '}
                        {props.questionCounts.singleChoiceCount}
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <div className={classes.inlineDesc}>單選題分數　</div> ={' '}
                        {score('singleChoice')}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} className={classes.scoreItem}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="stretch"
                  spacing={3}>
                  <Grid item xs={12} sm={2} className={classes.scoreType}>
                    複選題
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={10}
                    className={
                      props.questionCounts.multipleChoiceCount <= 0
                        ? classes.disabledSetting
                        : ''
                    }>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-end"
                      spacing={3}>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          label="每題分數"
                          size="small"
                          value={props.multipleChoiceScore}
                          disabled={
                            props.questionCounts.multipleChoiceCount <= 0
                          }
                          onChange={(e) => {
                            props.setQuestionScore(
                              props.questionScore,
                              'MultipleChoiceScore',
                              e.target.value,
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <div className={classes.inlineDesc}>題數</div> X{' '}
                        {props.questionCounts.multipleChoiceCount}
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <div className={classes.inlineDesc}>複選題分數　</div> ={' '}
                        {score('multipleChoice')}
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel
                            id={`select-multi-selection-mode-${props.index}-${props.questionScore}-label`}>
                            計分方式
                          </InputLabel>
                          <Select
                            labelId={`select-multi-selection-mode-${props.index}-${props.questionScore}-label`}
                            id={`select-multi-selection-mode-${props.index}-${props.questionScore}`}
                            value={props.multiSelectionMode}
                            disabled={
                              props.questionCounts.multipleChoiceCount <= 0
                            }
                            onChange={(e) => {
                              props.setMultiSelectionMode(e.target.value);
                            }}>
                            {_.map(
                              questionSource.multiSelectionMode,
                              (mode) => {
                                return (
                                  <MenuItem
                                    value={mode.Id}
                                    key={props.questionScore + '_' + mode.Id}>
                                    {mode.Description}
                                  </MenuItem>
                                );
                              },
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} className={classes.scoreItem}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="stretch"
                  spacing={3}>
                  <Grid item xs={12} sm={2} className={classes.scoreType}>
                    <div>題組題</div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={10}
                    className={
                      props.questionCounts.setChoiceSubCount <= 0
                        ? classes.disabledSetting
                        : ''
                    }>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-end"
                      spacing={3}>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          label="每題分數"
                          size="small"
                          value={props.setChoiceScore}
                          disabled={props.questionCounts.setChoiceSubCount <= 0}
                          onChange={(e) => {
                            props.setQuestionScore(
                              props.questionScore,
                              'SetChoiceScore',
                              e.target.value,
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <div className={classes.inlineDesc}>子題</div> X{' '}
                        {props.questionCounts.setChoiceSubCount}
                        <div className={classes.questionNumberDesc}>
                          <InfoOutlinedIcon fontSize="small" />{' '}
                          此題型使用子題計算
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <div className={classes.inlineDesc}>題組題分數　</div> ={' '}
                        {score('setChoice')}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} className={classes.scoreItem}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="stretch"
                  spacing={3}>
                  <Grid item xs={12} sm={2} className={classes.scoreType}>
                    <div>克漏字題</div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={10}
                    className={
                      props.questionCounts.clozeChoiceSubCount <= 0
                        ? classes.disabledSetting
                        : ''
                    }>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-end"
                      spacing={3}>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          label="每題分數"
                          size="small"
                          value={props.clozeChoiceScore}
                          disabled={
                            props.questionCounts.clozeChoiceSubCount <= 0
                          }
                          onChange={(e) => {
                            props.setQuestionScore(
                              props.questionScore,
                              'ClozeChoiceScore',
                              e.target.value,
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <div className={classes.inlineDesc}>子題</div> X{' '}
                        {props.questionCounts.clozeChoiceSubCount}
                        <div className={classes.questionNumberDesc}>
                          <InfoOutlinedIcon fontSize="small" />{' '}
                          此題型使用子題計算
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <div className={classes.inlineDesc}>克漏字題分數</div> ={' '}
                        {score('clozeChoice')}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                className={clsx(classes.scoreItem, classes.scoreTotal)}>
                <span>總分</span>
                <span>{score('total')}</span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grow>
    </Box>
  );
};

export default ExamQuestionScoreSetting;
