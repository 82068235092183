import * as types from '../actionType';

export default function loading(state = false, action) {
  switch (action.type) {
    case types.QUESTION_SOURCE_REQUEST:
    case types.BANNER_CAROUSEL_REQUEST:
    case types.QUESTION_STORE_REQUEST:
    case types.ADMIN_LOGIN_REQUEST:
    case types.SEND_COMPLETE_HANGOUT:
      return true;
    case types.QUESTION_SOURCE_SUCCESS:
    case types.QUESTION_SOURCE_FAILURE:
    case types.BANNER_CAROUSEL_SUCCESS:
    case types.BANNER_CAROUSEL_FAILURE:
    case types.QUESTION_STORE_SUCCESS:
    case types.QUESTION_STORE_FAILURE:
    case types.ADMIN_LOGIN_SUCCESS:
    case types.ADMIN_LOGIN_FAILURE:
    case types.SEND_COMPLETE_HANGOUT_SUCCESS:
    case types.SEND_COMPLETE_HANGOUT_FAILURE:
      return false;
    default:
      return state;
  }
}
