import instance from './API';

const videoPath = '/Handout/Video';

const videoNodeAPI = {
  getList: (handoutVideoId) => {
    return instance.get(videoPath + '/' + handoutVideoId + '/Node');
  },
  add: (formData, handoutVideoId) => {
    return instance.post(videoPath + '/' + handoutVideoId + '/Node', formData);
  },
  edit: (formData, handoutVideoId, nodeId) => {
    return instance.put(
      videoPath + '/' + handoutVideoId + '/Node/' + nodeId,
      formData,
    );
  },
  delete: (handoutVideoId, nodeId) => {
    return instance.delete(
      videoPath + '/' + handoutVideoId + '/Node/' + nodeId,
    );
  },
};

export default videoNodeAPI;
