/**
 * http status code
 */
const httpCode = {
  ok: 200,
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  unsupportedMediaType: 415,
  internalServerError: 500,
};
export default httpCode;
