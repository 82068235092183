import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Radio, FormControlLabel } from '@material-ui/core';

/**
 * RadioSelector
 *
 * @params {number} value      顯示值
 * @params {number} type       設定值
 * @params {function} onChange 切換選擇的動作
 * @params {string} text       說明文字
 * @params {string} textColor  說明文字顏色，顏色種類參考 https://material-ui.com/api/typography/
 * @params {string} color      選擇器顏色，顏色種類參考 https://material-ui.com/api/radio/
 *
 *
 * EX:
 *   const [gender, setGender] = useState(0);
 *   const genderList = [
 *      { id: 'woman', label: '女', value: 0 },
 *      { id: 'man', label: '男', value: 1 },
 *    ];
 *   const changeGender = (e) => {
 *    setGender(Number(e.target.value) });
 *   };
 *
 *   genderList.map((item, index) => {
 *     return (
 *       <GenderSelecter
 *         key={index}
 *         color={item.id === 'woman' ? 'secondary' : 'primary'}
 *         textColor={
 *           item.id === 'woman' ? 'secondary' : 'primary'
 *         }
 *         value={item.value}
 *         type={gender}
 *         onChange={changeGender}
 *         text={item.label}
 *       />
 *     );
 *   })
 */
function RadioSelector({
  value,
  type,
  onChange,
  text,
  textColor = 'initial',
  color,
}) {
  return (
    <FormControlLabel
      value={value}
      control={<Radio color={color} />}
      checked={type === value}
      label={<Typography color={textColor}>{text}</Typography>}
      onChange={onChange}
    />
  );
}

RadioSelector.propTypes = {
  value: PropTypes.number,
  type: PropTypes.number,
  onChange: PropTypes.func,
  text: PropTypes.string,
  textColor: PropTypes.string,
  color: PropTypes.string,
};

export { RadioSelector };
