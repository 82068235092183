import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Box,
  TextField,
  LinearProgress,
  Grid,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import SpeakerNotesOutlinedIcon from '@material-ui/icons/SpeakerNotesOutlined';
import MiniProductCard from '../../components/SaleExam/MiniProductCard';
import _ from 'lodash';
import Swal from 'sweetalert2';
import useProducts from '../../hooks/useProducts';

const useStyles = makeStyles((theme) => ({
  searchBtn: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  progress: {
    height: '200px',
    paddingTop: '90px',
  },
  products: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(1),
  },
  emptyProducts: {
    lineHeight: '200px',
    color: theme.palette.grey[400],
    fontSize: '3rem',
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
  },
}));

const SearchProduct = (props) => {
  const classes = useStyles();
  const { ...productsHook } = useProducts(props.type);
  const [keyword, setKeyword] = useState('');
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [canSearch, setCanSearch] = useState(false);
  const productTypeName = props.type === 'questionBank' ? '題庫' : '課程';

  const search = useCallback(() => {
    try {
      setCanSearch(false);

      productsHook.search({
        keyword: keyword,
        page: 1,
        countOfPage: 8,
      });
    } catch (error) {
      Swal.fire('搜尋錯誤', error.message, 'error');
    }
  }, [productsHook, setCanSearch]);

  const searchPage = useCallback(
    (toPage) => {
      try {
        setCanSearch(false);

        productsHook.page(toPage);
      } catch (error) {
        Swal.fire('搜尋錯誤', error.message, 'error');
      }
    },
    [productsHook, setCanSearch],
  );

  useEffect(() => {
    setProducts(productsHook.products.Products);
    setPage(productsHook.products.CurrentPage);
    setTotalPages(productsHook.products.TotalPages);
  }, [productsHook.products]);

  return (
    <Box mt={1}>
      <TextField
        label={`搜尋關鍵字 - 可搜尋${productTypeName}名稱和介紹內容`}
        variant="outlined"
        fullWidth
        autoFocus
        disabled={productsHook.isLoading}
        value={keyword}
        onChange={(e) => {
          setCanSearch(_.trim(e.target.value) !== '');
          setKeyword(e.target.value);
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && keyword !== '' && canSearch) {
            setCanSearch(false);
            search();
          }
        }}
      />

      <Button
        variant="contained"
        fullWidth
        disabled={!canSearch}
        color="primary"
        startIcon={<SearchOutlinedIcon />}
        onClick={search}
        className={classes.searchBtn}>
        搜尋{productTypeName}商品
      </Button>

      <Box mt={2}>
        {productsHook.isLoading === true ? (
          <Box className={classes.progress}>
            <LinearProgress color="secondary" />
          </Box>
        ) : products.length <= 0 ? (
          <Box className={classes.emptyProducts}>
            <SpeakerNotesOutlinedIcon /> 無{productTypeName}商品
          </Box>
        ) : (
          <>
            <Box className={classes.products}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="stretch"
                spacing={3}>
                {products.map((product) => {
                  const alreadyInPack =
                    _.findIndex(props.alreadyInPackProducts, {
                      id: product.id,
                      type: product.type,
                    }) !== -1;
                  const isSelected =
                    _.findIndex(props.selectedProducts, [
                      'id',
                      product.id,
                      'type',
                      product.type,
                    ]) !== -1;

                  return (
                    <Grid item xs={12} sm={3} key={product.id}>
                      <MiniProductCard
                        product={product}
                        disabled={alreadyInPack}
                        disabledProductTypeTag
                        status={
                          alreadyInPack
                            ? 'alreadyInPack'
                            : isSelected
                            ? 'selected'
                            : 'optional'
                        }
                        onClick={() => {
                          props.selectProduct && props.selectProduct(product);
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>

            {totalPages > 1 && (
              <Box mt={3}>
                <Pagination
                  page={page}
                  count={totalPages}
                  onChange={(event, page) => {
                    searchPage(page);
                  }}
                  variant="outlined"
                  shape="rounded"
                  disabled={productsHook.isLoading}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default SearchProduct;
