import React, { useState, useEffect } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import ConfirmBox from '../../components/ConfirmBox';
import AddNewType from '../../components/SaleExam/AddNewType';
import '../../style/components/SaleExam/exam-type.scss';

import {
  initHangoutTypeList,
  hangoutTypeEditAPI,
  hangoutTypeAddAPI,
  hangoutTypeDeleteAPI,
} from '../../Util/fetchMethods';

const SubjectType = ({ match }) => {
  //Hooks
  const [hangoutTypes, setHangoutTypes] = useState([]);
  const [renderStatus, setRenderStatus] = useState(false);
  const [isAddVisible, setIsAddVisible] = useState(false);
  const [reNameID, setReNameID] = useState('');
  const [inputId, setInputId] = useState('');
  const [confirmBox, setConfirmBox] = useState({
    isConfirmVisible: false,
    deletingID: '',
  });

  useEffect(() => {
    updateInitHangoutTypeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderStatus]);

  useEffect(() => {
    inputFocus(inputId);
  });

  //Methods
  const doRender = () => {
    setRenderStatus(!renderStatus);
  };

  const updateInitHangoutTypeList = () => {
    initHangoutTypeList()
      .then((res) => {
        setHangoutTypes(res.Result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCheckboxOnChange = (targetType) => {
    //更改本地端資料
    // let newArray = [...hangoutTypes].map((type) => {
    //   if (type.Id === targetType.Id) {
    //     type.Status = type.Status === 1 ? 0 : 1;
    //   }
    //   return type;
    // });
    // setHangoutTypes(newArray);

    // 因為是傳址呼叫 已經上面重新設定過status狀態 這邊就不用再重新設定
    //更改後端資料
    hangoutTypeEditAPI({
      Id: targetType.Id,
      Name: targetType.Name,
      Status: targetType.Status === 1 ? 0 : 1,
    }).then(() => {
      doRender();
    });
  };

  const handleOpenAddBlockVisible = () => {
    setIsAddVisible(true);
  };

  const handleCloseAddBlockVisible = () => {
    setIsAddVisible(false);
  };

  const handleOpenConfrimBox = (id) => {
    setConfirmBox({
      deletingID: id,
      isConfirmVisible: true,
    });
  };

  const handleCloseConfrimBox = () => {
    setConfirmBox({
      ...confirmBox,
      isConfirmVisible: false,
    });
  };

  const handleExamTypeDeleteApi = () => {
    hangoutTypeDeleteAPI(confirmBox.deletingID).then(() => {
      doRender();
    });
  };

  const handleReNameInput = (Id, e) => {
    // let newArray = [...hangoutTypes];
    let newArray = hangoutTypes.map((exam) => {
      if (exam.Id === Id) {
        exam.Name = e.target.value;
      }
      return exam;
    });
    setHangoutTypes(newArray);
  };

  const inputFocus = (inputId) => {
    setTimeout(() => {
      if (reNameID !== '') {
        document.getElementById(inputId).focus();
      }
    }, 50);
  };

  //render
  return (
    <div className="sale-exam-management exam-type pagefadeIn">
      <BreakCrumbs path={match.url}></BreakCrumbs>
      <div className="block">
        <div className="top-block">
          <div className="text">課程類別管理</div>
          <button className="btn save" onClick={handleOpenAddBlockVisible}>
            + 新增
          </button>
        </div>
        <hr />
        <div className="type-box">
          <ul className="type-list">
            {hangoutTypes.map((type) => {
              return (
                <li key={type.Id}>
                  <div className="left">
                    <div
                      className={
                        reNameID === type.Id ? 'type-name' : 'type-name show'
                      }>
                      {type.Name}
                    </div>
                    <input
                      type="text"
                      value={type.Name}
                      id={`rename-input-${type.Id}`}
                      onBlur={() => {
                        hangoutTypeEditAPI({
                          Id: type.Id,
                          Name: type.Name,
                          Status: type.Status,
                        });
                        setReNameID('');
                      }}
                      onChange={(e) => {
                        handleReNameInput(type.Id, e);
                      }}
                      className={
                        reNameID === type.Id
                          ? 'rename-input show'
                          : 'rename-input'
                      }
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' && reNameID !== '') {
                          hangoutTypeEditAPI({
                            Id: type.Id,
                            Name: type.Name,
                            Status: type.Status,
                          });
                          setReNameID('');
                        }
                      }}
                    />
                    <button
                      className="btn rename-btn"
                      data-itd={`rename-input-${type.Id}`}
                      onClick={(e) => {
                        setReNameID(type.Id);
                        setInputId(e.target.dataset.itd);
                        inputFocus(inputId);
                      }}>
                      重新命名
                    </button>
                  </div>
                  <div className="right">
                    <div className="notice">點選顯示，讓買家看得到此分類</div>
                    <div className="status-box">
                      <input
                        id={`cb${type.Id}`}
                        type="checkbox"
                        checked={type.Status === 1 ? true : false}
                        onChange={() => {
                          handleCheckboxOnChange(type);
                        }}
                      />
                      <label htmlFor={`cb${type.Id}`}></label>
                    </div>
                    <button
                      className="btn delete"
                      onClick={() => {
                        handleOpenConfrimBox(type.Id);
                      }}
                      disabled={confirmBox.isConfirmVisible}>
                      刪除
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <AddNewType
        title="新增課程類別"
        isVisible={isAddVisible}
        addNewExamTypeFunc={hangoutTypeAddAPI}
        closeAddBlock={handleCloseAddBlockVisible}
        setRenderStatus={doRender}></AddNewType>

      <ConfirmBox
        isVisible={confirmBox.isConfirmVisible}
        title={'刪除'}
        content={'刪除後無法復原<br>請確認是否繼續'}
        confirmMethod={handleExamTypeDeleteApi}
        closebox={handleCloseConfrimBox}></ConfirmBox>
    </div>
  );
};

export default SubjectType;
