import { useState, useEffect } from 'react';
import {
  initSubjectTypeList,
  subjectTypeEditAPI,
  subjectTypeAddAPI,
  subjectTypeDeleteAPI,
} from '../Util/fetchMethods';
import _ from 'lodash';

const useQuestionBankSubjects = () => {
  const [questionBankSubjects, setQuestionBankSubjects] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    initSubjectTypeList()
      .then((response) => {
        if (response.Status === 0) {
          setError(_.join(response.Message, '<br>'));
        } else {
          setQuestionBankSubjects(response.Result);
        }
      })
      .catch((err) => {
        setError(err.message);
      })
      .then(() => {
        setLoading(false);
      });

    return () => {
      setError('');
      setLoading(false);
      setQuestionBankSubjects([]);
    };
  }, []);

  const add = (addSubject) => {
    return subjectTypeAddAPI(addSubject).then((response) => {
      if (response.Status === 0) {
        throw new Error(_.join(response.Message, '<br>'));
      } else {
        let newQuestionBankSubjects = [...questionBankSubjects];

        newQuestionBankSubjects.push(response.Result);
        setQuestionBankSubjects(newQuestionBankSubjects);

        return response;
      }
    });
  };

  const edit = (editSubject) => {
    return subjectTypeEditAPI(editSubject).then((response) => {
      if (response.Status === 0) {
        throw new Error(_.join(response.Message, '<br>'));
      } else {
        let afterEditQuestionBankSubjects = [...questionBankSubjects];
        const editIndex = _.findIndex(afterEditQuestionBankSubjects, [
          'Id',
          editSubject.Id,
        ]);

        afterEditQuestionBankSubjects[editIndex] = editSubject;
        setQuestionBankSubjects(afterEditQuestionBankSubjects);

        return response;
      }
    });
  };

  const del = (questionBankSubjectId) => {
    return subjectTypeDeleteAPI(questionBankSubjectId).then((response) => {
      if (response.Status === 0) {
        throw new Error(_.join(response.Message, '<br>'));
      } else {
        let afterDeleteQuestionBankSubjects = [...questionBankSubjects];

        _.remove(afterDeleteQuestionBankSubjects, (qbSubject) => {
          return qbSubject.Id === questionBankSubjectId;
        });

        setQuestionBankSubjects(afterDeleteQuestionBankSubjects);
      }
    });
  };

  return {
    questionBankSubjects,
    loading,
    error,
    features: {
      add: add,
      edit: edit,
      del: del,
    },
  };
};

export default useQuestionBankSubjects;
