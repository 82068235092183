import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../../style/components/Advertisting/add-popular.scss';
import searchImg from '../../images/Search.png';
import noProductImg from '../../images/NoProduct.png';
import { popularSelectionAPI } from '../../store/apiconfig';

import '../../style/components/Advertisting/popular.scss';

import { connect } from 'react-redux';
import { logoutSuccess } from '../../store/action';
//套卷精選使用
class AddSuitPopular extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      searchProducts: [],
      choiceNumber: 0,
      choiceProduct: [],
    };
  }

  toggleCheckbox = (item, e) => {
    let newArray = [...this.state.choiceProduct];
    if (
      newArray.some(
        (product) => product.QuestionBankSetId === item.QuestionBankSetId,
      )
    ) {
      let filtNewArray = newArray.filter(
        (product) => product.QuestionBankSetId !== item.QuestionBankSetId,
      );
      this.setState({
        choiceProduct: filtNewArray,
      });
    } else {
      newArray.push(item);
      this.setState({
        choiceProduct: newArray,
      });
    }
  };

  handleOnChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  handleClearInput = () => {
    this.setState({
      searchValue: '',
    });
  };

  handleKeyPress = (e) => {
    let keyword = this.state.searchValue.trim();
    let { choiceProduct } = this.state;
    if (e.key === 'Enter' && keyword) {
      fetch(popularSelectionAPI.SEARCHSUIT + '?keyword=' + keyword, {
        method: 'get',
        headers: {
          Authorization: 'bearer ' + localStorage.getItem('access_token'),
        },
      })
        .then((response) => {
          if (response.status === 401) {
            // token過期 導回登入頁面
            this.props.logoutSuccess();
          } else {
            return response.json();
          }
        })
        .then((data) => {
          console.log(data.Result);
          let product = data.Result.filter(
            (item) =>
              !choiceProduct.some(
                (choice) => choice.QuestionBankSetId === item.QuestionBankSetId,
              ),
          );
          this.setState({
            searchProducts: product,
          });
          this.handleClearInput();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  initState = () => {
    this.handleClearInput();
    this.setState({
      searchProducts: [],
      choiceProduct: [],
    });
    this.props.onSwitch();
  };

  render() {
    const { isVisiable, onAddProducts } = this.props;
    let products =
      this.state.searchProducts.length > 0 ? (
        <div className="product-list-box">
          <div className="row">
            {this.state.searchProducts.map((item, index) => (
              <div
                key={item.QuestionBankSetId}
                className="col-6 col-phone-12 prodct-box">
                <input
                  type="checkbox"
                  id={item.QuestionBankSetId}
                  onChange={(e) => {
                    this.toggleCheckbox(item, e);
                  }}
                />
                <div className="product-name">{item.Title}</div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="product-list-box">
          <img src={noProductImg} alt=""></img>
          <div className="notice">沒發現商品</div>
        </div>
      );

    return (
      <div
        className={
          isVisiable
            ? 'AddPopular-shadow onVisible fadeIn'
            : 'AddPopular-shadow'
        }>
        <div className="add-block">
          <button className="btn-close" onClick={this.initState}>
            X
          </button>
          <div className="title">新增套卷。</div>
          <p>請使用套卷名稱與介紹的關鍵字做搜尋</p>
          <div className="search-box">
            <label>
              <img src={searchImg} alt="" />
              <input
                type="text"
                placeholder="關鍵字搜尋"
                value={this.state.searchValue}
                onChange={this.handleOnChange}
                onKeyPress={this.handleKeyPress}
              />
              <div
                className={
                  this.state.searchValue.length > 0 ? 'close open' : 'close'
                }
                onClick={this.handleClearInput}>
                X
              </div>
            </label>
          </div>
          <hr />
          <div style={{ height: '300px', overflow: 'scroll' }}>{products}</div>
          <hr />
          <div className="notice">
            你已選擇了{this.state.choiceProduct.length}
            個商品，最多可選８個商品
          </div>
          <div className="btn-group">
            <button
              className="btn save"
              onClick={() => {
                if (this.state.choiceProduct.length === 0) return;
                onAddProducts(this.state.choiceProduct);
                this.initState();
              }}>
              確認
            </button>
            <button className="btn cancel" onClick={this.initState}>
              取消
            </button>
          </div>
        </div>
      </div>
    );
  }
}

AddSuitPopular.propTypes = {
  isVisiable: PropTypes.bool,
  onSwitch: PropTypes.func,
  onAddProducts: PropTypes.func,
};

export default connect(null, {
  logoutSuccess,
})(AddSuitPopular);
