import React from 'react';
import { Box } from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  mathJax: {
    padding: theme.spacing(2),
    fontSize: '1.1rem',
    fontWeight: '600',
    letterSpacing: '1px',
    textAlign: 'left',
    lineHeight: '23px',
  },
  imgBox: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '& img': {
      maxWidth: '100%',
    },
  },
}));

const MathJaxAndImgContent = (props) => {
  const classes = useStyles();

  return (
    <>
      <Box
        className={
          _.isObject(props.classes) && props.classes.mathJax
            ? clsx(classes.mathJax, props.classes.mathJax)
            : classes.mathJax
        }
        dangerouslySetInnerHTML={{
          __html: props.substance,
        }}
      />
      {props.imageUrl ? (
        <Box
          className={
            _.isObject(props.imgBox) && props.classes.imgBox
              ? clsx(classes.imgBox, props.classes.imgBox)
              : classes.imgBox
          }>
          <img src={props.imageUrl}></img>
        </Box>
      ) : null}
    </>
  );
};

export default MathJaxAndImgContent;
