import React, { useMemo } from 'react';
import { TableCell, TableRow, Avatar, Chip } from '@material-ui/core';
import {
  BonusProductTypeText,
  UserBonusProductStatus,
  UserBonusProductStatusText,
  BonusProductType,
} from '../../data/common';
import moment from 'moment';
import { trim } from 'lodash';
import CardGiftcardRoundedIcon from '@material-ui/icons/CardGiftcardRounded';

const UserBonusProductTableRow = (props) => {
  const { userBonusProduct } = props;

  const expiry = useMemo(() => {
    const nowDate = moment();
    const expiryDate = moment(userBonusProduct.expiryDate);

    return {
      isExpiry:
        expiryDate.isSameOrBefore(nowDate) &&
        userBonusProduct.status === UserBonusProductStatus.UN_USE,
      expiryDate: expiryDate.format('YYYY-MM-DD'),
    };
  }, [userBonusProduct]);

  return (
    <TableRow>
      <TableCell>
        {userBonusProduct.userInfo.realName}
        <div style={{ fontSize: '10px', color: '#999' }}>
          ID {userBonusProduct.userInfo.id}
        </div>
      </TableCell>

      <TableCell>
        <div className="user__bonus-product__product__name">
          <Avatar
            style={{ marginRight: '12px' }}
            variant="rounded"
            alt="封面"
            src={userBonusProduct.productInfo.mainImage.imageName}
          />
          <div>{userBonusProduct.productInfo.name}</div>
        </div>
      </TableCell>

      <TableCell>
        <Chip
          color={
            userBonusProduct.productInfo.type === BonusProductType.DISCOUNT
              ? 'primary'
              : userBonusProduct.productInfo.type === BonusProductType.EXCHANGE
              ? 'secondary'
              : 'default'
          }
          size="small"
          label={BonusProductTypeText[userBonusProduct.productInfo.type]}
          icon={
            userBonusProduct.isPromo ? <CardGiftcardRoundedIcon /> : undefined
          }
        />
      </TableCell>

      <TableCell>
        <div
          style={{
            color: expiry.isExpiry ? 'red' : '',
          }}>
          {expiry.expiryDate}
        </div>
      </TableCell>

      <TableCell>
        {trim(userBonusProduct.useTime) !== '' &&
          moment(userBonusProduct.useTime).format('YYYY-MM-DD HH:mm:ss')}
      </TableCell>

      <TableCell>
        {userBonusProduct.staffInfo &&
          userBonusProduct.staffInfo !== null &&
          `${userBonusProduct.staffInfo.realName}`}
      </TableCell>

      <TableCell>
        <Chip
          color={
            expiry.isExpiry
              ? 'default'
              : userBonusProduct.status === UserBonusProductStatus.UN_USE
              ? 'primary'
              : 'secondary'
          }
          size="small"
          label={
            expiry.isExpiry
              ? '過期'
              : UserBonusProductStatusText[userBonusProduct.status]
          }
        />
      </TableCell>

      <TableCell>
        {userBonusProduct.giverInfo && userBonusProduct.giverInfo.realName}
      </TableCell>

      <TableCell>{userBonusProduct.referralCode}</TableCell>
    </TableRow>
  );
};

export default UserBonusProductTableRow;
