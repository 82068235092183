import React, { useState } from 'react';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import Grid from '@material-ui/core/Grid';

const CafeShiftLogin = (props) => {
  const { cafeShiftLogin, isLoading } = props;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    cafeShiftLogin(username, password);
  };

  return (
    <div>
      <div className="bonus__title">
        <LocalCafeIcon />
        良師塾人文食飲管理系統
      </div>

      <Grid
        container
        direction="column"
        justifycontent="flex-start"
        alignItems="center"
        spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="帳號"
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            disabled={isLoading}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="密碼"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={isLoading}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<VpnKeyOutlinedIcon />}
            onClick={handleLogin}
            disabled={isLoading}>
            登入
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default CafeShiftLogin;
