/*
 * 日期格式化
 *
 * @param {Date} date 欲轉換的日期，格式必須是由 new Date() 產生的。
 * @return {string} yyyy/MM/dd
 */
const dateFormat = (date) => {
  // getMonth 範圍是 0 ~ 11 所以加 1
  let newDate = `${date.getFullYear()}/${date.getMonth() +
    1}/${date.getDate()}`;
  return newDate;
};

export default dateFormat;
