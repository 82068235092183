import React, { useState } from 'react';
import _ from 'lodash';
import {
  Button,
  Box,
  TableCell,
  TableRow,
  Collapse,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import VideoLibraryRoundedIcon from '@material-ui/icons/VideoLibraryRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Swal from 'sweetalert2';
import { parseISO, format, isValid } from 'date-fns';
import HandoutNodes from '../Handout/HandoutNodes';

const useStyles = makeStyles((theme) => ({
  toolsBtn: {
    marginLeft: theme.spacing(1),
  },
  date: {
    display: 'inline-block',
    fontSize: '1rem',
    padding: theme.spacing(1, 2),

    '&.active': {
      fontWeight: '600',
    },
  },
  collapseTr: {
    paddingTop: '0',
    paddingBottom: '0',
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(1),
  },
}));

const HandoutTableRow = (props) => {
  const {
    handout,
    deleteHandout,
    editHandout,
    downloadHandoutAllQrcode,
  } = props;
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const handleDelete = () => {
    Swal.fire({
      icon: 'question',
      title: '確定要刪除嗎？',
      text: '刪除後，所有節點將失效，請小心!',
      showCancelButton: true,
      confirmButtonText: '刪除',
      cancelButtonText: '取消',
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);

        deleteHandout(handout.handoutId).then(() => {
          setIsLoading(false);
        });
      }
    });
  };

  const handleEdit = () => {
    editHandout(handout.handoutId);
  };

  const handleDownloadAllQrcode = () => {
    setIsLoading(true);

    downloadHandoutAllQrcode(handout).then(() => {
      setIsLoading(false);
    });
  };

  const parseStartAt = parseISO(handout.startAt);
  const parseExpireAt = parseISO(handout.expireAt);

  return (
    <>
      <TableRow>
        <TableCell component="th">
          節點
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell>{handout.handoutId}</TableCell>

        <TableCell align="right">{handout.name}</TableCell>

        <TableCell align="right">
          <div
            className={classes.date + (isValid(parseStartAt) ? ' active' : '')}>
            {isValid(parseStartAt)
              ? format(parseStartAt, 'yyyy/MM/dd')
              : '--/--/--'}
          </div>
          <div className={classes.date}> ~ </div>
          <div
            className={
              classes.date + (isValid(parseExpireAt) ? ' active' : '')
            }>
            {isValid(parseExpireAt)
              ? format(parseExpireAt, 'yyyy/MM/dd')
              : '--/--/--'}
          </div>
        </TableCell>

        <TableCell align="right">
          {handout.nodeList.length > 0 && (
            <Button
              color="primary"
              variant="contained"
              size="small"
              startIcon={<VideoLibraryRoundedIcon />}
              className={classes.toolsBtn}
              onClick={handleDownloadAllQrcode}
              disabled={isLoading}>
              下載全部QRCODE
            </Button>
          )}

          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<EditIcon />}
            className={classes.toolsBtn}
            onClick={handleEdit}
            disabled={isLoading}>
            編輯
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<DeleteIcon />}
            className={classes.toolsBtn}
            onClick={handleDelete}
            disabled={isLoading}>
            刪除
          </Button>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell className={classes.collapseTr} colSpan={5}>
          <Collapse in={open} timeout="auto" mountOnEnter>
            <Box mt={2} mb={1}>
              <HandoutNodes
                nodes={handout.nodeList}
                handoutId={handout.handoutId}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default HandoutTableRow;
