import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Typography,
  Divider,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import FindReplaceOutlinedIcon from '@material-ui/icons/FindReplaceOutlined';
import LooksOneOutlinedIcon from '@material-ui/icons/LooksOneOutlined';
import AlertDialog from '../Dialogs/AlertDialog';
import Swal from 'sweetalert2';
import commonData from '../../data/common.json';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';

const useStyles = makeStyles((theme) => ({
  questionSources: {
    marginTop: theme.spacing(3),
  },
  questionSourceArea: {
    fontSize: theme.typography.h3.fontSize,
    textAlign: 'right',
    color: theme.palette.grey[300],
    fontWeight: 600,
  },
  withButton: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  revertSearch: {
    position: 'absolute',
    top: `-${theme.spacing(2)}px`,
    right: theme.spacing(2),
  },
}));

/**
 * 提供渲染select使用，如有特殊需求可以在 渲染程式加上對應欄位內容
 */
const questionSourceSelects = [
  {
    group: 'question',
    label: '題目',
    selects: [
      { label: '科目', id: 'subject' },
      { label: '來源', id: 'examType' },
      { label: '用途', id: 'useType' },
    ],
  },
  {
    group: 'school',
    label: '學校',
    selects: [
      { label: '學校', id: 'schoolName' },
      { label: '教育程度', id: 'educationDegree' },
      { label: '學年度', id: 'schoolYear' },
      { label: '組別', id: 'schoolGroup' },
      { label: '年級', id: 'schoolGrade' },
    ],
  },
  {
    group: 'range',
    label: '範圍',
    selects: [
      { label: '段考次數', id: 'examTime' },
      { label: '課本版本', id: 'bookVersion' },
      { label: '書局', id: 'publisher' },
    ],
  },
];

/**
 * 將 commonData.QuestionType 轉成 array 供 autoComplete 使用
 */
const questionTypeOptions = [];

_.forOwn(commonData.QuestionType, (name, id) => {
  questionTypeOptions.push({
    Id: _.parseInt(id),
    Name: name,
  });
});

const initSelectedData = {
  area: {},
  subject: { Id: 12, Name: '數學' },
  examType: {},
  educationDegree: {},
  schoolYear: {},
  schoolName: {},
  schoolGroup: {},
  schoolGrade: {},
  examTime: {},
  bookVersion: {},
  publisher: {},
  questionType: { Id: 1, Name: '一般題' },
  useType: {},
};

/**
 * Component Start
 */

const SourceQuestion = (props) => {
  const [selectedData, setSelectedData] = useState({ ...initSelectedData });
  const [keyword, setKeyword] = useState('');
  const [knowledges, setKnowledges] = useState([]);
  const [questionId, setQuestionId] = useState('');
  const [askChangeSubject, setAskChangeSubject] = useState({
    open: false,
    subject: null,
  });
  const [isChangeSearch, setIsChangeSearch] = useState(false);

  const selectedExamQuestionState = useSelector(
    (state) => state.SelectedExamQuestionState,
  );

  const classes = useStyles();

  useEffect(() => {
    if (props.examSubject !== selectedData['subject'].Id) {
      const findSubject = _.find(props.questionSource.subject, [
        'Id',
        props.examSubject,
      ]);

      if (typeof findSubject !== 'undefined') {
        setSelectedData({ ...selectedData, subject: findSubject });
      }
    }
  }, [props.examSubject, props.questionSource, setSelectedData, selectedData]);

  const handleSourceChange = (sourceValue, source) => {
    setSelectedData({ ...selectedData, [source]: sourceValue });

    if (source === 'subject') {
      props.onSetExamSubject(sourceValue.Id);
    } else {
      setIsChangeSearch(true);
    }
  };

  const handleSubjectChange = (subject) => {
    if (selectedExamQuestionState.length <= 0) {
      handleSourceChange(subject, 'subject');
    } else {
      setAskChangeSubject({ open: true, subject: subject });
    }
  };

  const renderKnowledgeOptions = (option, state) => {
    return (
      <div>
        <Box ml={option.depth * 2}>{option.Name}</Box>
      </div>
    );
  };

  const knowledgesForAutocomplete = useMemo(() => {
    let newKnows = [];

    const flatten = (array, depth, result) => {
      depth || (depth = 0);
      result || (result = []);

      array.forEach((item, key) => {
        result.push({
          Id: item.Id,
          ParentId: item.ParentId,
          Name: item.Name,
          depth: depth,
        });

        if (item.KnowledgeDtoChildren.length > 0) {
          flatten(item.KnowledgeDtoChildren, depth + 1, result);
        }
      });
    };

    flatten(props.questionSource.knowledge, 0, newKnows);

    return newKnows;
  }, [props.questionSource.knowledge]);

  const getQuestions = (page = 1, isRandom, randomNumberOfQuestions) => {
    let querys = [];

    if (_.isArray(knowledges)) {
      knowledges.forEach((knowledge) => {
        querys.push(`knowledges=${knowledge.Id}`);
      });
    }

    for (const [key, value] of Object.entries(selectedData)) {
      if (_.isEmpty(value) === false) {
        let queryKey = key !== 'questionType' ? 'categorys' : 'questionType';

        querys.push(`${queryKey}=${value.Id}`);
      }
    }

    if (_.trim(keyword) !== '') {
      querys.push(`keyword=${keyword}`);
    }

    if (isRandom === true) {
      querys.push(`random=true`);

      if (_.isInteger(_.parseInt(randomNumberOfQuestions)) === false) {
        Swal.fire('隨機取出錯誤', '請設定隨機取出筆數', 'error');
        return;
      }

      querys.push(`count=${randomNumberOfQuestions}`);
    }

    props.handleGetSeleceted(querys, isRandom === true);
  };

  const getQuestion = () => {
    props.handleGetIdSeleceted(questionId);
  };

  return (
    <Box component={Paper} square elevation={1} p={1}>
      <Typography variant="h5" color="textPrimary" gutterBottom>
        題目來源
      </Typography>
      <Divider light />
      <Box mt={2}>
        {_.map(questionSourceSelects, (sourceGroup) => {
          return (
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="stretch"
              spacing={5}
              className={classes.questionSources}
              key={sourceGroup.group}>
              <Grid item xs={12} sm={2} className={classes.questionSourceArea}>
                {sourceGroup.label}
              </Grid>
              <Grid item xs={12} sm={10}>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start">
                  {_.map(sourceGroup.selects, (sourceSelect) => {
                    return (
                      <Grid item xs={12} md={4} key={sourceSelect.id}>
                        <Autocomplete
                          fullWidth
                          disableClearable={sourceSelect.id === 'subject'}
                          autoComplete
                          options={props.questionSource[sourceSelect.id]}
                          size="small"
                          onChange={(e, value) => {
                            if (sourceSelect.id !== 'subject') {
                              handleSourceChange(value, sourceSelect.id);
                            } else {
                              if (value.Id !== selectedData['subject'].Id) {
                                handleSubjectChange(value);
                              }
                            }
                          }}
                          value={
                            _.isEmpty(selectedData[sourceSelect.id])
                              ? null
                              : selectedData[sourceSelect.id]
                          }
                          getOptionLabel={(option) => {
                            return option.Name ? option.Name : '';
                          }}
                          getOptionSelected={(option, value) => {
                            if (_.isEmpty(value)) {
                              return false;
                            }

                            return option.Id === value.Id;
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label={sourceSelect.label} />
                          )}
                        />
                      </Grid>
                    );
                  })}

                  {sourceGroup.group === 'question' && (
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        fullWidth
                        autoComplete
                        options={questionTypeOptions}
                        size="small"
                        onChange={(e, value) => {
                          handleSourceChange(value, 'questionType');
                        }}
                        value={
                          _.isEmpty(selectedData['questionType'])
                            ? null
                            : selectedData['questionType']
                        }
                        getOptionLabel={(option) => {
                          return option.Name ? option.Name : '';
                        }}
                        getOptionSelected={(option, value) => {
                          if (_.isEmpty(value)) {
                            return false;
                          }

                          return option.Id === value.Id;
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="題目類型" />
                        )}
                      />
                    </Grid>
                  )}

                  {sourceGroup.group === 'question' && (
                    <Grid item xs={12} sm={8}>
                      <TextField
                        variant="outlined"
                        label="關鍵字搜尋"
                        type="search"
                        fullWidth
                        value={keyword}
                        onChange={(e) => {
                          setKeyword(e.target.value);
                          setIsChangeSearch(true);
                        }}
                      />
                    </Grid>
                  )}

                  {sourceGroup.group === 'question' && (
                    <Grid item xs={12} sm={12}>
                      <Autocomplete
                        multiple
                        fullWidth
                        autoComplete
                        options={knowledgesForAutocomplete}
                        getOptionLabel={(option) => option.Name}
                        renderOption={(option, state) => {
                          return renderKnowledgeOptions(option, state);
                        }}
                        onChange={(event, value, reason) => {
                          setKnowledges(value);
                          setIsChangeSearch(true);
                        }}
                        value={knowledges}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="知識點"
                            placeholder="請選擇要篩選的知識點"
                          />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        })}
        <Button
          variant="contained"
          startIcon={<YoutubeSearchedForIcon />}
          color="secondary"
          style={isChangeSearch === false ? { display: 'none' } : {}}
          onClick={() => {
            setSelectedData({
              ...initSelectedData,
              subject: selectedData.subject,
            });
            setKnowledges([]);
            setKeyword('');
            setIsChangeSearch(false);
          }}
          className={classes.revertSearch}>
          重新設定搜尋
        </Button>
      </Box>
      <Box mt={5} mb={4} textAlign="center">
        <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-end"
          alignItems="center">
          <Grid item sm md={3}>
            <TextField
              className={classes.withButton}
              label="題目ID"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={questionId}
              size="small"
              onChange={(e) => {
                setQuestionId(e.target.value);
              }}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<LooksOneOutlinedIcon />}
              disabled={
                !(_.isInteger(_.parseInt(questionId)) && questionId > 0) ||
                props.isLoading
              }
              onClick={getQuestion}>
              取出此題
            </Button>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item sm md={3}>
            <Button
              variant="contained"
              color="primary"
              disabled={props.isLoading}
              startIcon={<FindReplaceOutlinedIcon />}
              onClick={() => {
                getQuestions(1, true, props.numberOfPage);
              }}>
              隨機取出 {props.numberOfPage} 題
            </Button>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item sm md={3}>
            <Box m="auto" textAlign="center">
              <Button
                variant="contained"
                color="primary"
                startIcon={<SearchOutlinedIcon />}
                onClick={() => {
                  getQuestions(1);
                }}
                disabled={props.isLoading}>
                取得題目
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <AlertDialog
        open={askChangeSubject.open}
        setOpen={(open) => {
          setAskChangeSubject({ ...askChangeSubject, open: open });
        }}
        action={() => {
          handleSourceChange(askChangeSubject.subject, 'subject');
          setAskChangeSubject({ ...askChangeSubject, open: false });
        }}
        title="科目變更確認">
        科目變更，測試卷將重編。是否繼續此動作？
      </AlertDialog>
    </Box>
  );
};

SourceQuestion.propTypes = {
  questionSource: PropTypes.object,
  handleGetSeleceted: PropTypes.func,
  onSetExamSubject: PropTypes.func,
};

export default SourceQuestion;
