import React from 'react';
import { BonusProductStatus } from '../../data/common';

const RecommendProductSubHeader = (props) => {
  const { product, multiple } = props;
  const nowTimeSec = new Date().getTime();
  const stockQuantity =
    parseInt(product.quantity, 10) - parseInt(product.redeemQuantity, 10);
  const isOnSale =
    (product.onSaleDate === null ||
      new Date(product.onSaleDate).getTime() <= nowTimeSec) &&
    (product.offSaleDate === null ||
      new Date(product.offSaleDate).getTime() >= nowTimeSec);

  return (
    <div>
      剩餘數量
      <span className="recommend-product__status">{stockQuantity}</span>
      {multiple && <br />}
      目前狀態
      <span className="recommend-product__status">
        {product.status === BonusProductStatus.ON_SALE && isOnSale
          ? '上架中'
          : '未上架'}
      </span>
    </div>
  );
};

export default RecommendProductSubHeader;
