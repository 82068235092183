import * as types from '../actionType';

let initState = {
  CurrentPage: 1,
  TotalPages: 1,
  init: true,
  QuestionBankList: [
    {
      Name: '',
      Introduction: '',
      SaleType: 1,
      TypeId: 1,
      Status: 1,
      Price: '',
      AreaId: 1,
      Events: [],
      DiscountPrice: null,
      DiscountStartAt: null,
      DiscountEndAt: null,
      ImageFile: null,
      QuestionIds: null,
      QuestionBankId: 1,
      CreateAt: '',
      ImageUrl: '',
      HarderQuestionBankList: null,
      EasierQuestionBankList: null,
    },
  ],
};

function SaleExamListState(state = initState, action) {
  switch (action.type) {
    case types.SALE_EXAM_LIST_SUCCESS: {
      return action.result;
    }
    case types.SALE_EXAM_LIST_EVENT_SUCCESS: {
      return action.result;
    }
    default:
      return state;
  }
}

export default SaleExamListState;
