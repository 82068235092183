import React from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from '@material-ui/core';
import RecommendProductSubHeader from './RecommendProductSubHeader';

const AddRecommendProductCard = (props) => {
  const { product, addRecommendProduct, isLoading } = props;

  const handleClickAdd = () => {
    addRecommendProduct(product);
  };

  return (
    <Card>
      <CardActionArea onClick={handleClickAdd} disabled={isLoading}>
        <CardMedia
          style={{ height: '100px' }}
          image={product.mainImage.imageName}
          title="封面"
        />
        <CardContent style={{ height: '140px' }}>
          <div className="add__recomend-product__title">{product.name}</div>
          <RecommendProductSubHeader product={product} multiple />
          <div>
            推薦狀態
            <span className="recommend-product__status">
              {product.sort <= 0 ? '未推薦' : `已推薦 ${product.sort}`}
            </span>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default AddRecommendProductCard;
