import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from '@material-ui/core';
import QuestionVideo from './QuestionVideo';

const QuestionVideoDialog = (props) => {
  const { open, setOpen, videoName, videoPath } = props;
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="sm"
      keepMounted>
      <DialogContent>
        <QuestionVideo
          videoName={videoName}
          videoPath={videoPath}
          open={open}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuestionVideoDialog;
