// API 狀態管理
export const INIT_SEND_API_STATUS = 'INIT_SEND_API_STATUS';

// 500api出錯回應
export const SERVER_ERROR_500 = 'SERVER_ERROR_500';

// 登出請求
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

// 登入請求
export const ADMIN_LOGIN_SOURCE = 'ADMIN_LOGIN_SOURCE';
export const ADMIN_LOGIN_REQUEST = 'ADMIN_LOGIN_REQUEST';
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_LOGIN_FAILURE = 'ADMIN_LOGIN_FAILURE';

export const SWITCH_REMEMBER_ME = 'SWITCH_REMEMBER_ME';

// 首頁統計圖表數據
export const INIT_HOME_FEATURE_STATISTIC = 'INIT_HOME_FEATURE_STATISTIC';
export const HOME_FEATURE_STATISTIC_REQUEST = 'HOME_FEATURE_STATISTIC_REQUEST';
export const HOME_FEATURE_STATISTIC_SUCCESS = 'HOME_FEATURE_STATISTIC_SUCCESS';
export const HOME_FEATURE_STATISTIC_FAILURE = 'HOME_FEATURE_STATISTIC_FAILURE';

// 首頁統計圖表每月登入人數數據
export const INIT_HOME_MEMBER_STATISTIC = 'INIT_HOME_MEMBER_STATISTIC';
export const HOME_MEMBER_STATISTIC_REQUEST = 'HOME_MEMBER_STATISTIC_REQUEST';
export const HOME_MEMBER_STATISTIC_SUCCESS = 'HOME_MEMBER_STATISTIC_SUCCESS';
export const HOME_MEMBER_STATISTIC_FAILURE = 'HOME_MEMBER_STATISTIC_FAILURE';

// 首頁統計定單紀錄統計
export const INIT_HOME_ORDER_STATISTIC = 'INIT_HOME_ORDER_STATISTIC';
export const HOME_ORDER_STATISTIC_REQUEST = 'HOME_ORDER_STATISTIC_REQUEST';
export const HOME_ORDER_STATISTIC_SUCCESS = 'HOME_ORDER_STATISTIC_SUCCESS';
export const HOME_ORDER_STATISTIC_FAILURE = 'HOME_ORDER_STATISTIC_FAILURE';

// 編卷選項來源
export const INIT_QUESTION_SOURCE = 'INIT_QUESTION_SOURCE';
export const QUESTION_SOURCE_REQUEST = 'QUESTION_SOURCE_REQUEST';
export const QUESTION_SOURCE_SUCCESS = 'QUESTION_SOURCE_SUCCESS';
export const QUESTION_SOURCE_FAILURE = 'QUESTION_SOURCE_FAILURE';

// 編卷選項搜尋後狀態
export const INIT_QUESTION_FILTER_RESULT = 'INIT_QUESTION_FILTER_RESULT';
export const QUESTION_FILTER_RESULT_REQUEST = 'QUESTION_FILTER_RESULT_REQUEST';
export const QUESTION_FILTER_RESULT_SUCCESS = 'QUESTION_FILTER_RESULT_SUCCESS';
export const QUESTION_FILTER_RESULT_FAILURE = 'QUESTION_FILTER_RESULT_FAILURE';
export const DELETE_ITEM_IN_FILTED_ARRAY = 'DELETE_ITEM_IN_FILTED_ARRAY';
export const REMOVEALL_ITEM_FILTED_ARRAY = 'REMOVEALL_ITEM_FILTED_ARRAY';

export const SEND_COMPLETE_EXAM = 'SEND_COMPLETE_EXAM'; //編卷完畢後 按下完成儲存
export const SEND_COMPLETE_EXAM_SUCCESS = 'SEND_COMPLETE_EXAM_SUCCESS'; // 送出成功
export const SEND_COMPLETE_EXAM_FAILURE = 'SEND_COMPLETE_EXAM_FAILURE'; // 送出失敗

//依試卷ID獲取題目內容
export const GET_QUESTION_BY_BANKID = 'GET_QUESTION_BY_BANKID';
export const GET_QUESTION_BY_BANKID_REQUEST = 'GET_QUESTION_BY_BANKID_REQUEST';
export const GET_QUESTION_BY_BANKID_SUCCESS = 'GET_QUESTION_BY_BANKID_SUCCESS';
export const GET_QUESTION_BY_BANKID_FAILURE = 'GET_QUESTION_BY_BANKID_FAILURE';

//重新編卷、培優補差的狀態設定
export const REEDIT_EXAM_REQUEST = 'REEDIT_EXAM_REQUEST';
export const REEDIT_EXAM_SUCCESS = 'REEDIT_EXAM_SUCCESS';
export const REEDIT_EXAM_FAILURE = 'REEDIT_EXAM_FAILURE';
//重新編卷、培優補差的狀態清空
export const INIT_REEDIT_EXAM = 'INIT_REEDIT_EXAM';

//新增/刪除 培優卷
export const ADD_HARD_EXAM = 'ADD_HARD_EXAM';
export const DELETE_HARD_EXAM = 'DELETE_HARD_EXAM';

//新增/刪除 補差卷
export const ADD_EASIER_EXAM = 'ADD_EASIER_EXAM';
export const DELETE_EASIER_EXAM = 'DELETE_EASIER_EXAM';

//設定培優補差 api
export const SEND_QUESTIONBANK_RELATION_REQUEST =
  'SEND_QUESTIONBANK_RELATION_REQUEST';
export const SEND_QUESTIONBANK_RELATION_SUCCESS =
  'SEND_QUESTIONBANK_RELATION_SUCCESS';
export const SEND_QUESTIONBANK_RELATION_FAILURE =
  'SEND_QUESTIONBANK_RELATION_FAILURE';

// 已拉取 題目管理
export const ADD_QUESTION_IN_EXAM = 'ADD_QUESTION_IN_EXAM';
export const DELETE_QUESTION_IN_EXAM = 'DELETE_QUESTION_IN_EXAM';
export const DELETE_DEPTH_QUESTION_IN_EXAM = 'DELETE_DEPTH_QUESTION_IN_EXAM';

export const ADD_MAIN_QUESTION_EXAM = 'ADD_MAIN_QUESTION_EXAM';
export const DLELTE_MAIN_QUESTION_EXAM = 'DLELTE_MAIN_QUESTION_EXAM';

export const ADD_HARD_QUESTION_EXAM = 'ADD_HARD_QUESTION_EXAM';
export const DELETE_HARD_QUESTION_EXAM = 'DELETE_HARD_QUESTION_EXAM';

export const ADD_EASIER_QUESTION_EXAM = 'ADD_EASIER_QUESTION_EXAM';
export const DELETE_EASIER_QUESTION_EXAM = 'DELETE_EASIER_QUESTION_EXAM';

export const ADD_RELATION_IN_EXAM = 'ADD_RELATION_IN_EXAM';
export const DELETE_RELATION_IN_EXAM = 'DELETE_RELATION_IN_EXAM';

export const REMOVEALL_QUESTION_IN_EXAM = 'REMOVEALL_QUESTION_IN_EXAM';
export const REJECT_QUESTION = 'REJECT_QUESTION';
export const CHANGE_QUESTION_SCORE = 'CHANGE_QUESTION_SCORE';

// 首頁輪播廣告
export const INIT_BANNER_CAROUSEL = 'INIT_BANNER_CAROUSEL';
export const BANNER_CAROUSEL_REQUEST = 'BANNER_CAROUSEL_REQUEST';
export const BANNER_CAROUSEL_SUCCESS = 'BANNER_CAROUSEL_SUCCESS';
export const BANNER_CAROUSEL_FAILURE = 'BANNER_CAROUSEL_FAILURE';
export const ADD_BANNER = 'ADD_BANNER'; //新增廣告編輯欄位
export const ADD_API_BANNER_REQUEST = 'ADD_API_BANNER_REQUEST'; //新增廣告
export const ADD_API_BANNER_SUCCESS = 'ADD_API_BANNER_SUCCESS'; //新增廣告
export const ADD_API_BANNER_FAILURE = 'ADD_API_BANNER_FAILURE'; //新增廣告
export const EDIT_API_BANNER_REQUEST = 'EDIT_API_BANNER_REQUEST'; //編輯廣告
export const EDIT_API_BANNER_SUCCESS = 'EDIT_API_BANNER_SUCCESS'; //編輯廣告
export const EDIT_API_BANNER_FAILURE = 'EDIT_API_BANNER_FAILURE'; //編輯廣告
export const DELETE_API_BANNER = 'DELETE_API_BANNER'; //刪除廣告

// 會員列表
export const INIT_MEMBER_LIST = 'INIT_MEMBER_LIST';
export const MEMBER_STATUS_SWITCH = 'MEMBER_STATUS_SWITCH';
export const MEMBER_RESET_PASSWORD = 'MEMBER_RESET_PASSWORD';
export const ADD_MEMBER_SUCCESS = 'ADD_MEMBER_SUCCESS';
export const ADD_MEMBER_FAILURE = 'ADD_MEMBER_FAILURE';
export const INIT_ORDER_LIST = 'INIT_ORDER_LIST';
export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST';
export const ORDER_LIST_FAILURE = 'ORDER_LIST_FAILURE';

// 單一會員訂單列表
export const INIT_MEMBER_ORDER_LIST = 'INIT_MEMBER_ORDER_LIST';
export const MEMBER_ORDER_LIST_REQUEST = 'MEMBER_ORDER_LIST_REQUEST';
export const MEMBER_ORDER_LIST_SUCCESS = 'MEMBER_ORDER_LIST_SUCCESS';
export const MEMBER_ORDER_LIST_FAILURE = 'MEMBER_ORDER_LIST_FAILURE';

// 售卷列表
export const INIT_SALE_EXAM_LIST = 'INIT_SALE_EXAM_LIST';
export const SALE_EXAM_LIST_REQUEST = 'SALE_EXAM_LIST_REQUEST';
export const SALE_EXAM_LIST_SUCCESS = 'SALE_EXAM_LIST_SUCCESS';
export const SALE_EXAM_LIST_EVENT_SUCCESS = 'SALE_EXAM_LIST_EVENT_SUCCESS';
export const SALE_EXAM_LIST_EVENT_FAIL = 'SALE_EXAM_LIST_EVENT_FAIL';
export const SALE_EXAM_LIST_FAILURE = 'SALE_EXAM_LIST_FAILURE';
export const SALE_EXAM_STATUS_SWITCH = 'SALE_EXAM_STATUS_SWITCH'; //題庫上架/下架/刪除，status(1=上架, 2=下架, 3=刪除)

// 活動管理
export const INIT_PROMO_LIST = 'INIT_PROMO_LIST';
export const PROMO_LIST_REQUEST = 'PROMO_LIST_REQUEST';
export const PROMO_LIST_SUCCESS = 'PROMO_LIST_SUCCESS';
export const PROMO_LIST_FAILURE = 'PROMO_LIST_FAILURE';
export const ADD_PROMO_SUCCESS = 'ADD_PROMO_SUCCESS';
export const ADD_PROMO_FAILURE = 'ADD_PROMO_FAILURE';
export const DELETE_PROMO_SUCCESS = 'DELETE_PROMO_SUCCESS';
export const DELETE_PROMO_FAILURE = 'DELETE_PROMO_FAILURE';
export const ADD_PROMO_QB_SUCCESS = 'ADD_PROMO_QB_SUCCESS';
export const ADD_PROMO_QB_FAILURE = 'ADD_PROMO_QB_FAILURE';
export const DELETE_PROMO_QB_SUCCESS = 'DELETE_PROMO_QB_SUCCESS';
export const DELETE_PROMO_QB_FAILURE = 'DELETE_PROMO_QB_FAILURE';

// 完成課程編卷第一步驟 送出
export const SEND_COMPLETE_HANGOUT = 'SEND_COMPLETE_HANGOUT'; //編卷完畢後 按下完成儲存
export const SEND_COMPLETE_HANGOUT_SUCCESS = 'SEND_COMPLETE_HANGOUT_SUCCESS'; // 送出成功
export const SEND_COMPLETE_HANGOUT_FAILURE = 'SEND_COMPLETE_HANGOUT_FAILURE'; // 送出失敗

//重新課程
export const EDIT_HANGOUT_REQUEST = 'EDIT_HANGOUT_REQUEST';
export const EDIT_HANGOUT_SUCCESS = 'EDIT_HANGOUT_SUCCESS';
export const EDIT_HANGOUT_FAILURE = 'EDIT_HANGOUT_FAILURE';

// 課程類別列表
export const INIT_HANGOUT_TYPE_LIST = 'INIT_HANGOUT_TYPE_LIST';
export const HANGOUT_TYPE_LIST_REQUEST = 'HANGOUT_TYPE_LIST_REQUEST';
export const HANGOUT_TYPE_LIST_SUCCESS = 'HANGOUT_TYPE_LIST_SUCCESS';
export const HANGOU_TYPET_LIST_FAILURE = 'HANGOU_TYPET_LIST_FAILURE';
export const HANGOUT_TYPE_STATUS_SWITCH = 'HANGOUT_TYPE_STATUS_SWITCH'; //課程上架/下架/刪除，status(1=上架, 2=下架, 3=刪除)
export const HANGOUT_TYPE_EDIT = 'HANGOUT_TYPE_EDIT'; //課程類別編輯
export const HANGOUT_TYPE_ADD = 'HANGOUT_TYPE_ADD'; // 課程類別新增
export const HANGOUT_TYPE_DELETE = 'HANGOUT_TYPE_DELETE'; // 課程類別新增

// 課程列表
export const INIT_HANGOUT_LIST = 'INIT_HANGOUT_LIST';
export const HANGOUT_LIST_REQUEST = 'HANGOUT_LIST_REQUEST';
export const HANGOUT_LIST_SUCCESS = 'HANGOUT_LIST_SUCCESS';
export const HANGOUT_LIST_FAILURE = 'HANGOUT_LIST_FAILURE';
export const HANGOUT_STATUS_SWITCH = 'HANGOUT_STATUS_SWITCH'; //課程上架/下架/刪除，status(1=上架, 2=下架, 3=刪除)

// 取的所有套卷
export const INIT_SUIT_EXAM = 'INIT_SUIT_EXAM';
export const SUIT_EXAM_REQUEST = 'SUIT_EXAM_REQUEST';
export const SUIT_EXAM_SUCCESS = 'SUIT_EXAM_SUCCESS';
export const SUIT_EXAM_FAILURE = 'SUIT_EXAM_FAILURE';

export const ADD_SUIT_EXAM_REQUEST = 'ADD_SUIT_EXAM_REQUEST';
export const ADD_SUIT_EXAM_SUCCESS = 'ADD_SUIT_EXAM_SUCCESS';
export const ADD_SUIT_EXAM_FAILURE = 'ADD_SUIT_EXAM_FAILURE';

export const EDIT_SUIT_EXAM_REQUEST = 'EDIT_SUIT_EXAM_REQUEST';
export const EDIT_SUIT_EXAM_SUCCESS = 'EDIT_SUIT_EXAM_SUCCESS';
export const EDIT_SUIT_EXAM_FAILURE = 'EDIT_SUIT_EXAM_FAILURE';

export const EDIT_SUIT_EXAM_PRODUCT_REQUEST = 'EDIT_SUIT_EXAM_PRODUCT_REQUEST';
export const EDIT_SUIT_EXAM_PRODUCT_SUCCESS = 'EDIT_SUIT_EXAM_PRODUCT_SUCCESS';
export const EDIT_SUIT_EXAM_PRODUCT_FAILURE = 'EDIT_SUIT_EXAM_PRODUCT_FAILURE';

// 考卷類別列表
export const INIT_EXAM_TYPE_LIST = 'INIT_EXAM_TYPE_LIST';
export const EXAM_TYPE_LIST_REQUEST = 'EXAM_TYPE_LIST_REQUEST';
export const EXAM_TYPE_LIST_SUCCESS = 'EXAM_TYPE_LIST_SUCCESS';
export const EXAM_TYPE_LIST_FAILURE = 'EXAM_TYPE_LIST_FAILURE';
export const EXAM_TYPE_EDIT = 'EXAM_TYPE_EDIT'; //考卷類別編輯
export const EXAM_TYPE_ADD = 'EXAM_TYPE_ADD'; // 考卷類別新增
export const EXAM_TYPE_DELETE = 'EXAM_TYPE_DELETE'; // 考卷類別新增

// 考卷類別列表
export const INIT_SUBJECT_TYPE_LIST = 'INIT_SUBJECT_TYPE_LIST';
export const SUBJECT_TYPE_LIST_REQUEST = 'SUBJECT_TYPE_LIST_REQUEST';
export const SUBJECT_TYPE_LIST_SUCCESS = 'SUBJECT_TYPE_LIST_SUCCESS';
export const SUBJECT_TYPE_LIST_FAILURE = 'SUBJECT_TYPE_LIST_FAILURE';
export const SUBJECT_TYPE_EDIT = 'SUBJECT_TYPE_EDIT'; //考卷科目編輯
export const SUBJECT_TYPE_ADD = 'SUBJECT_TYPE_ADD'; // 考卷科目新增
export const SUBJECT_TYPE_DELETE = 'SUBJECT_TYPE_DELETE'; // 考卷科目新增

// 題庫商店廣告
export const INIT_QUESTION_STORE = 'INIT_QUESTION_STORE';
export const QUESTION_STORE_REQUEST = 'QUESTION_STORE_REQUEST';
export const QUESTION_STORE_SUCCESS = 'QUESTION_STORE_SUCCESS';
export const QUESTION_STORE_FAILURE = 'QUESTION_STORE_FAILURE';
export const EDIT_API_QUESTIONSTORE_REQUEST = 'EDIT_API_QUESTIONSTORE_REQUEST'; //編輯題庫商店廣告
export const EDIT_API_QUESTIONSTORE_SUCCESS = 'EDIT_API_QUESTIONSTORE_SUCCESS'; //編輯題庫商店廣告
export const EDIT_API_QUESTIONSTORE_FAILURE = 'EDIT_API_QUESTIONSTORE_FAILURE'; //編輯題庫商店廣告

// 取的所有精選
export const INIT_POPULAR_FEATURE_STATISTIC = 'INIT_POPULAR_FEATURE_STATISTIC';
export const POPULAR_FEATURE_STATISTIC_REQUEST =
  'POPULAR_FEATURE_STATISTIC_REQUEST';
export const POPULAR_FEATURE_STATISTIC_SUCCESS =
  'POPULAR_FEATURE_STATISTIC_SUCCESS';
export const POPULAR_FEATURE_STATISTIC_FAILURE =
  'POPULAR_FEATURE_STATISTIC_FAILURE';

export const EDIT_POPULAR_FEATURE_REQUEST = 'EDIT_POPULAR_FEATURE_REQUEST';
export const EDIT_POPULAR_FEATURE_SUCCESS = 'EDIT_POPULAR_FEATURE_SUCCESS';
export const EDIT_POPULAR_FEATURE_FAILURE = 'EDIT_POPULAR_FEATURE_FAILURE';

export const EDIT_POPULAR_FEATURE_PRODUCT_REQUEST =
  'EDIT_POPULAR_FEATURE_PRODUCT_REQUEST';
export const EDIT_POPULAR_FEATURE_PRODUCT_SUCCESS =
  'EDIT_POPULAR_FEATURE_PRODUCT_SUCCESS';
export const EDIT_POPULAR_FEATURE_PRODUCT_FAILURE =
  'EDIT_POPULAR_FEATURE_PRODUCT_FAILURE';

export const ADD_POPULAR_FEATURE_PRODUCT_REQUEST =
  'ADD_POPULAR_FEATURE_PRODUCT_REQUEST';
export const ADD_POPULAR_FEATURE_PRODUCT_SUCCESS =
  'ADD_POPULAR_FEATURE_PRODUCT_SUCCESS';
export const ADD_POPULAR_FEATURE_PRODUCT_FAILURE =
  'ADD_POPULAR_FEATURE_PRODUCT_FAILURE';

export const ADD_POPULAR_SUIT_PRODUCT_REQUEST =
  'ADD_POPULAR_SUIT_PRODUCT_REQUEST';
export const ADD_POPULAR_SUIT_PRODUCT_SUCCESS =
  'ADD_POPULAR_SUIT_PRODUCT_SUCCESS';
export const ADD_POPULAR_SUIT_PRODUCT_FAILURE =
  'ADD_POPULAR_SUIT_PRODUCT_FAILURE';

export const EDIT_SUIT_FEATURE_PRODUCT_REQUEST =
  'EDIT_SUIT_FEATURE_PRODUCT_REQUEST';
export const EDIT_SUIT_FEATURE_PRODUCT_SUCCESS =
  'EDIT_SUIT_FEATURE_PRODUCT_SUCCESS';
export const EDIT_SUIT_FEATURE_PRODUCT_FAILURE =
  'EDIT_SUIT_FEATURE_PRODUCT_FAILURE';

// 頁面 loading
export const CANCEL_PAGE_LOADING = 'CANCEL_PAGE_LOADING';
export const CALL_PAGE_LOADING = 'CALL_PAGE_LOADING';

// FLASH MESSAGE
export const ADD_FLASH_MESSAGE = 'ADD_FLASH_MESSAGE';
export const DELETE_FLASH_MESSAGE = 'DELETE_FLASH_MESSAGE';

// VIDEO 隊列
export const ADD_VIDEO_QUEUE = 'ADD_VIDEO_QUEUE';
export const SET_VIDEO_QUEUE = 'SET_VIDEO_QUEUE';
export const DELETE_VIDEO_QUEUE = 'DELETE_VIDEO_QUEUE';

// knowledge 知識點
export const SET_KNOWLEDGE = 'SET_KNOWLEDGE';
