import Swal from 'sweetalert2';
import httpCode from './httpCode';

/**
 * http error handle
 *
 * @param {number} code      httpErrorCode
 * @param {string} errorMsg  Error message
 * @param {func} authErrorCB 身份認證錯誤的 call back function
 *
 */
const httpErrorHandle = (code, errorMsg, authErrorCB) => {
  switch (code) {
    case httpCode.forbidden:
    case httpCode.unauthorized:
      Swal.fire(
        `身份認證錯誤`,
        `請確認身份資料再登入, 錯誤代碼: ${code}`,
        `error`,
      ).then(() => {
        // 目前身份認證錯誤在 memberList 與 orderList 中有強制登出，
        // 因不確定是否還有其他頁面，有相同需求，或是有其他的錯誤處理，
        // 所以在這做 call back function。
        authErrorCB();
      });
      break;
    case httpCode.badRequest:
    case httpCode.internalServerError:
      Swal.fire(`伺服器錯誤`, `請確通知資訊人員, 錯誤代碼: ${code}`, `error`);
      break;
    default:
      Swal.fire(
        `伺服器錯誤`,
        `請確通知資訊人員, 錯誤信息: ${errorMsg}`,
        `error`,
      );
      break;
  }
};

export default httpErrorHandle;
