import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import Table from '../../components/Table';
import BreakCrumbs from '../../components/BreakCrumbs';
import Pagination from '../../components/Pagination';
import ConfirmBox from '../../components/ConfirmBox';
import { Checkbox } from '@material-ui/core';
import '../../style/components/Advertisting/popular.scss';
import {
  initSuitExamList,
  switchSuitExamDisplay,
} from '../../Util/fetchMethods';

const SuitExamList = ({ match }) => {
  const [suitExams, setSuitExams] = useState([]);
  const [renderStatus, setRenderStatus] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [countOfPage, setCountOfPage] = useState(10);
  const [startPage, setStartPage] = useState(1);
  const [confirmBox, setConfirmBox] = useState({
    isConfirmVisible: false,
    deletingID: '',
  });

  const updateInitSuitExamList = () => {
    initSuitExamList()
      .then((res) => {
        setSuitExams(res.Result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    updateInitSuitExamList(renderStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderStatus]);

  const doRender = () => {
    setRenderStatus(!renderStatus);
  };

  // 上一次每頁顯示
  const preCountOfPage = useRef(10);

  useEffect(() => {
    preCountOfPage.current = countOfPage;
    handlePageStart(currPage, countOfPage);
    handleTotalPage(suitExams, countOfPage);
  }, [countOfPage, currPage, suitExams]);

  const handleTotalPage = (suitExams, countOfPage) => {
    setTotalPage(Math.ceil(suitExams.length / countOfPage));
  };

  const handlePageStart = (currPage, countOfPage) => {
    setStartPage((currPage - 1) * countOfPage);
  };

  const handleChangePage = (page) => {
    setCurrPage(page);
  };

  // 計算 '每頁顯示' 改變時 currPage 的頁數
  const handleCountOfpageShow = (e) => {
    setCountOfPage(e.target.value);
    preCountOfPage.current < e.target.value
      ? setCurrPage(Math.ceil((countOfPage * currPage) / e.target.value))
      : setCurrPage(currPage);
  };

  const handleSelectChange = (e) => {
    setCountOfPage(e.target.value);
  };

  const handleCheckboxOnChange = (id, status) => {
    //更改後端資料
    switchSuitExamDisplay({
      QuestionBankSetId: id,
      Status: status === 2 ? 1 : 2,
    }).then(() => {
      //畫面更新, 好處是可以確認在上面的 API 執行完成後再更新畫面
      doRender();
    });
  };

  const handleOpenConfrimBox = (id) => {
    setConfirmBox({
      deletingID: id,
      isConfirmVisible: true,
    });
  };

  const handleCloseConfrimBox = () => {
    setConfirmBox({ ...confirmBox, isConfirmVisible: false });
  };

  //table data setting
  const columns = [
    {
      title: 'No',
      filed: 'QuestionBankSetId',
      property: false,
      render: (props) => {
        return <>{props.QuestionBankSet.QuestionBankSetId}</>;
      },
    },
    {
      title: '精選名稱',
      filed: 'Title',
      property: false,
      render: (props) => {
        return <>{props.QuestionBankSet.Title}</>;
      },
    },
    {
      title: '套裝售價',
      filed: 'Price',
      property: false,
      render: (props) => {
        return <>{props.QuestionBankSet.Price}</>;
      },
    },
    {
      title: '建立日期',
      filed: 'CreateAt',
      property: false,
      render: (props) => {
        return <>{props.QuestionBankSet.CreateAt.slice(0, 10)}</>;
      },
    },
    {
      title: '題庫數量',
      filed: 'Count',
      property: false,
      render: (props) => {
        return <>{props.QuestionBankSet.Count}</>;
      },
    },
    {
      title: '編輯',
      filed: 'Edit',
      property: false,
      render: (props) => {
        return (
          <>
            <NavLink
              to={`/SaleExam/SuitExam/${props.QuestionBankSet.QuestionBankSetId}`}>
              編輯
            </NavLink>
          </>
        );
      },
    },
    {
      title: '操作',
      filed: 'Operation',
      property: false,
      render: (props) => {
        return (
          <>
            <i
              style={{ cursor: 'pointer' }}
              className="fa fa-trash-alt"
              onClick={() => {
                handleOpenConfrimBox(props.QuestionBankSet.QuestionBankSetId);
              }}></i>
          </>
        );
      },
    },
    {
      title: '狀態',
      filed: 'Status',
      property: false,
      render: (props) => {
        return (
          <Checkbox
            color="primary"
            checked={props.QuestionBankSet.Status === 1 ? true : false}
            onChange={() => {
              handleCheckboxOnChange(
                props.QuestionBankSet.QuestionBankSetId,
                props.QuestionBankSet.Status,
              );
            }}
          />
        );
      },
    },
  ];

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={match.url}></BreakCrumbs>
      <div className="block">
        <div className="top-block">
          <div className="text">套卷列表輪播</div>
          <NavLink to={`/SaleExam/SuitExam`}>+ 新增套卷</NavLink>
        </div>

        <Table
          columns={columns}
          // 取出每頁要顯示的資料
          datas={suitExams.slice(startPage, startPage + countOfPage)}></Table>

        <div className="pagination-box">
          <Pagination
            currPage={currPage}
            totalPage={totalPage}
            onChangPage={handleChangePage}
          />
          <div className="view-single-page">
            每頁顯示
            <select
              value={countOfPage}
              onChange={(handleSelectChange, handleCountOfpageShow)}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
          </div>
        </div>
      </div>
      <ConfirmBox
        isVisible={confirmBox.isConfirmVisible}
        title={'刪除'}
        content={'刪除後無法復原<br>請確認是否繼續'}
        confirmMethod={() => {
          switchSuitExamDisplay({
            QuestionBankSetId: confirmBox.deletingID,
            Status: 3,
          }).then(() => {
            doRender();
          });
        }}
        closebox={handleCloseConfrimBox}
      />
    </div>
  );
};

export default SuitExamList;
