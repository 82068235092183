import React, { useState } from 'react';
import {
  ListItemText,
  IconButton,
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
} from '@material-ui/core';
import Map from '../../data/Map';
import DoneOutlineRoundedIcon from '@material-ui/icons/DoneOutlineRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { trim } from 'lodash';

const resourceSecondary = (resource) => {
  const materialType = Map.MaterialTypeNumbers[resource.MaterialType];
  let secondary = materialType.name;

  switch (materialType.type) {
    case 'video':
      // 顯示影片節點引用的影片 ID (僅影片節點顯示)
      if (parseInt(resource.VideoId, 10) > 0) {
        secondary += ' | 影片ID: ' + resource.VideoId;
      }

      secondary +=
        ` | 節點名稱: ${resource.VideoNode ? resource.VideoNode.Name : ''} ` +
        ' | 影片長度: ' +
        (resource.Duration / 60).toFixed(2) +
        ' 分';
      break;

    case 'questionBank':
      secondary += ` | 編卷ID: ${resource.QuestionBankId}`;
      break;

    case 'pdf':
      secondary += ` | 檔名: ${resource.FileName}`;
      break;

    case 'assignment':
      const submissionTypeText = Map.SubmissionTypes[resource.SubmissionType]
        ? Map.SubmissionTypes[resource.SubmissionType].name
        : resource.SubmissionType;
      const submissionCountText =
        trim(resource.SubmissionCount) !== ''
          ? resource.SubmissionCount + ' 次'
          : '無限';

      secondary +=
        ` | 繳交類型: ${submissionTypeText}` +
        ` | 繳交次數: ${submissionCountText}`;
      break;
  }

  return secondary;
};

const ResourceName = (props) => {
  const { resource, changeResource } = props;
  const [isChangeName, setIsChangeName] = useState(false);
  const [changeName, setChangeName] = useState('');

  const triggerChangeName = () => {
    setIsChangeName(true);
    setChangeName(resource.Name);
  };

  const handleCancelChangeName = () => {
    setIsChangeName(false);
  };

  const handleChangeName = () => {
    changeResource({ ...resource, Name: changeName }).then(() => {
      setIsChangeName(false);
    });
  };

  return (
    <>
      {!isChangeName && (
        <ListItemText
          primary={
            <span
              onClick={triggerChangeName}
              style={{
                backgroundColor: '#eee',
                borderRadius: '6px',
                cursor: 'pointer',
                padding: '3px 6px',
              }}>
              {resource.Name}
            </span>
          }
          secondary={resourceSecondary(resource)}
        />
      )}

      {isChangeName && (
        <FormControl>
          <InputLabel
            htmlFor={`resource-name-${resource.HangoutChapterMaterialId}`}>
            顯示名稱
          </InputLabel>
          <Input
            id={`resource-name-${resource.HangoutChapterMaterialId}`}
            type="text"
            value={changeName}
            onChange={(e) => {
              setChangeName(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleChangeName();
              } else if (e.key === 'Escape') {
                handleCancelChangeName();
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={handleCancelChangeName}>
                  <ClearRoundedIcon />
                </IconButton>

                <IconButton onClick={handleChangeName}>
                  <DoneOutlineRoundedIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      )}
    </>
  );
};

export default ResourceName;
