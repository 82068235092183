import instance from './API';
import { questionSourceAPI } from '../store/apiconfig';

const questionPath = '/Question';

const questionAPI = {
  getOneById: (questionId) => {
    return instance.get(questionPath + '/Detail?questionId=' + questionId);
  },
  signed: (path, name) => {
    return instance.get('?filePath=' + path + '&fileName=' + name, {
      baseURL: questionSourceAPI.GET_AUDIO_URL,
    });
  },
};

export default questionAPI;
