const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const AWS_SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const AWS_HANGOUT_POSITION = {
  pdf: {
    bucket: 'hangout-resource',
    dir: 'QBHangout',
    acl: 'public-read',
  },
  video: {
    bucket: 'video-resource',
    dir: 'QBHangoutVideo',
  },
};
const AWS_TEACHER_POSITION = {
  avatar: {
    bucket: 'questionbank-resource',
    dir: 'TeacherPic',
    acl: 'public-read',
  },
  introduction: {
    bucket: 'questionbank-resource',
    dir: 'TeacherPic/Introduction',
    acl: 'public-read',
  },
};
const AWS_BONUS_PRODUCT_POSITION = {
  description: {
    bucket: 'questionbank-resource',
    dir: 'BonusProduct/Description',
    acl: 'public-read',
  },
};

export {
  AWS_ACCESS_KEY_ID,
  AWS_SECRET_ACCESS_KEY,
  AWS_HANGOUT_POSITION,
  AWS_TEACHER_POSITION,
  AWS_BONUS_PRODUCT_POSITION,
};
