import React, { useState, useEffect, useRef } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import ImageFileUpload from '../../components/Forms/ImageFileUpload';
import DateInput from '../../components/Forms/DateInput';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import Swal from 'sweetalert2';
import _ from 'lodash';
import {
  Container,
  Paper,
  Box,
  Typography,
  Divider,
  TextField,
  Button,
} from '@material-ui/core';
import {
  initQuestionBank,
  editAPIQuestionStore,
  callPageLoading,
  cancelPageLoading,
} from '../../store/action';
import { useDispatch, useSelector } from 'react-redux';

const QuestionBank = (props) => {
  const [questionBankBanner, setQuestionBankBanner] = useState({});
  const dbQuestionBankBanner = useSelector((state) => {
    return state.QuestionStoreState;
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const imageFileInputRef = useRef(null);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    let start = new Date(questionBankBanner.StartAt);
    let end = new Date(questionBankBanner.EndAt);

    if (end < start) {
      Swal.fire('設定錯誤', '結束時間不可小於開始時間', 'error');
      return;
    }

    if (_.trim(questionBankBanner.Hyperlink) == '') {
      Swal.fire('設定錯誤', '請輸入連結', 'error');
      return;
    }

    setIsSubmit(true);

    let imgFile = imageFileInputRef.current.files[0];
    let formData = new FormData();
    formData.append('Hyperlink', questionBankBanner.Hyperlink);
    formData.append('StartAt', questionBankBanner.StartAt);
    formData.append('EndAt', questionBankBanner.EndAt);
    formData.append('ImageFile', imgFile);
    formData.append('BannerId', questionBankBanner.Id);
    dispatch(editAPIQuestionStore(formData)).then((response) => {
      if (response.Status !== 1) {
        Swal.fire('發生錯誤', '儲存失敗，請重新操作', 'error');
      } else {
        Swal.fire('儲存成功', '', 'success');
      }

      setIsSubmit(false);
    });
  };

  const handleLink = (e) => {
    setQuestionBankBanner({
      ...questionBankBanner,
      Hyperlink: e.target.value,
    });
  };

  useEffect(() => {
    setQuestionBankBanner(dbQuestionBankBanner[0]);
  }, [dbQuestionBankBanner]);

  // init component for once
  useEffect(() => {
    dispatch(callPageLoading({ loadingContent: '讀取中...' }));
    dispatch(initQuestionBank()).then((response) => {
      dispatch(cancelPageLoading());
    });
  }, []);

  return (
    <div className="adventisting home-carousel pagefadeIn">
      <BreakCrumbs path={props.match.url} />
      {Object.keys(questionBankBanner).length > 0 && (
        <Container maxWidth="md">
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <Paper>
              <Box p={2}>
                <Typography variant="h4" gutterBottom>
                  設定廣告
                </Typography>

                <Divider />

                <ImageFileUpload
                  helper="檔案格式為 png, jpeg, jpg, 1440x544 pix"
                  imageUrl={questionBankBanner.ImageUrl}
                  imageFileInputRef={imageFileInputRef}
                />

                <TextField
                  label="頁面鏈結"
                  fullWidth
                  margin="normal"
                  value={questionBankBanner.Hyperlink}
                  onChange={handleLink}
                />

                <DateInput
                  label="開始時間"
                  date={questionBankBanner.StartAt}
                  setDate={(date) => {
                    setQuestionBankBanner({
                      ...questionBankBanner,
                      StartAt: date,
                    });
                  }}
                />

                <DateInput
                  label="結束時間"
                  date={questionBankBanner.EndAt}
                  setDate={(date) => {
                    setQuestionBankBanner({
                      ...questionBankBanner,
                      EndAt: date,
                    });
                  }}
                />

                <Box mt={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SaveOutlinedIcon />}
                    disabled={isSubmit}
                    type="submit">
                    儲存
                  </Button>
                </Box>
              </Box>
            </Paper>
          </form>
        </Container>
      )}
    </div>
  );
};

export default QuestionBank;
