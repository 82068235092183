import * as types from '../actionType';

let initState = [];

function suitExam(state = initState, action) {
  switch (action.type) {
    case types.SUIT_EXAM_SUCCESS: {
      return action.result.sort((a, b) => {
        return b.QuestionBankSet.Id - a.QuestionBankSet.Id;
      });
    }
    default:
      return state;
  }
}

export default suitExam;
