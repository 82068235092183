import React from 'react';
import BonusLevelPng_1 from '../../images/bonus/bonus_level_1.png';
import BonusLevelPng_2 from '../../images/bonus/bonus_level_2.png';
import BonusLevelPng_3 from '../../images/bonus/bonus_level_3.png';
import BonusLevelPng_4 from '../../images/bonus/bonus_level_4.png';
import BonusLevelPng_5 from '../../images/bonus/bonus_level_5.png';
import {
  Grid,
  Paper,
  Button,
  Divider,
  Card,
  CardContent,
  CardMedia,
} from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { formatNumberComma } from '../../Util/Util';

const levelToImg = {
  1: BonusLevelPng_1,
  2: BonusLevelPng_2,
  3: BonusLevelPng_3,
  4: BonusLevelPng_4,
  5: BonusLevelPng_5,
};

const AskUseBonus = (props) => {
  const {
    guestMember,
    calculateBonus,
    amount,
    bonusUse,
    bonusNoUse,
    isLoading,
  } = props;

  const handleUseBonus = () => {
    bonusUse();
  };

  const handleNoUseBonus = () => {
    bonusNoUse();
  };

  return (
    <Grid
      container
      direction="column"
      justifycontent="flex-start"
      alignItems="stretch"
      spacing={3}>
      <Grid item xs={12}>
        <Paper className="bonus__use">
          <Grid
            container
            direction="row"
            justifycontent="space-around"
            alignItems="baseline">
            <Grid item xs={4}>
              <div className="bonus__use-title">本次消費金額</div>
            </Grid>
            <Grid item xs={8}>
              <div className="bonus__use-detail">
                {formatNumberComma(amount)}
              </div>
            </Grid>

            <Grid item xs={4}>
              <div className="bonus__use-title">可折扣紅利</div>
            </Grid>
            <Grid item xs={8}>
              <div className="bonus__use-detail bonus__use-bonus">
                <RemoveIcon />
                {formatNumberComma(calculateBonus.bonusCalculate)}
                <div className="bonus__use-detail--outside">
                  <span className="bonus__member-point-unit">P</span>
                </div>
              </div>
            </Grid>
          </Grid>

          <Divider variant="middle" />

          <Grid
            container
            direction="row"
            justifycontent="space-around"
            alignItems="baseline">
            <Grid item xs={4}>
              <div className="bonus__use-title">折扣後金額</div>
            </Grid>
            <Grid item xs={8}>
              <div className="bonus__use-detail">
                {formatNumberComma(amount - calculateBonus.bonusCalculate)}
              </div>
            </Grid>
          </Grid>

          <div className="bonus__member-level-buttons">
            <Button
              variant="contained"
              color="default"
              size="large"
              startIcon={<CancelIcon />}
              onClick={handleNoUseBonus}
              disabled={isLoading}>
              不使用
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<CheckCircleIcon />}
              onClick={handleUseBonus}
              disabled={isLoading}>
              使用紅利折扣
            </Button>
          </div>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Card className="bonus__member-level-card">
          <CardMedia
            className="bonus__member-level-image"
            image={levelToImg[calculateBonus.level]}
            title="member Level"
          />
          <CardContent>
            <div className="bonus__member-contact">{guestMember.contact}</div>
            <div className="bonus__member-levle-info">
              <div className="bonus__member-levle-info__item">
                <div className="bonus__member-point">
                  {calculateBonus.userRemainBonus}
                  <span className="bonus__member-point-unit">P</span>
                </div>
                目前紅利
              </div>
              <Divider orientation="vertical" flexItem />
              <div className="bonus__member-levle-info__item">
                <div className="bonus__member-point">
                  {calculateBonus.bonusPercentage}
                  <span className="bonus__member-point-unit">%</span>
                </div>
                可享折扣優惠
              </div>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AskUseBonus;
