import { Button as OrgBtn } from '@material-ui/core';
import styled from 'styled-components';

/**
 * Button
 *
 * 使用了 material-ui 的 Button 為基底，此按扭只針對滿框的按鈕做設計，
 * 一定要使用 variant="contained" (EX: <Button variant="contained"> FULL-BTN </Button>)
 * size 預設改為 large，其他的 props 與原本的無衝突。
 * 可參考 https://material-ui.com/api/button/。
 *
 *
 * 以下是新增的 props
 * @params {string} bgcolor 按鈕的底色，有四種底色 default, primary, success, warn, error
 * @params {string} width 按鈕的寬度
 *
 * EX:
 *
 *  一般樣式:
 *
 *    <Button onClick={doSomeing...} variant="contained"> Default </Button>
 *
 *    <Button onClick={doSomeing...}
 *      variant="contained"
 *      bgcolor="error">
 *      ERROR
 *    </Button>
 *
 *    <Button
 *      onClick={doSomeing...}
 *      variant="contained"
 *      bgcolor="warn"
 *      width="100px">
 *      WARN
 *    </Button>
 *
 *  使用 DeleteIcon, size=small:
 *    <Button
 *      startIcon={<DeleteIcon />}
 *      onClick={close}
 *      variant="contained"
 *      bgcolor="error"
 *      size="small">
 *      Error
 *    </Button>
 */
const Button = styled(OrgBtn)`
  background-color: ${({ bgcolor }) => {
    switch (bgcolor) {
      case 'primary':
        return '#4e546c';
      case 'success':
        return '#5cb85c';
      case 'warn':
        return '#f5a623';
      case 'error':
        return '#ff4444';
      case 'cancel':
        return '#d8d8d8';
      default:
        return '#2a6fbd';
    }
  }};
  width: ${({ width }) => width};
  font-size: ${({ size }) => (size ? size : `large`)};
  color: ${({ bgcolor }) => (bgcolor === 'cancel' ? '#4a4a4a' : 'white')};
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 7px 14px;
  margin: 5px;
  &:hover {
    background-color: ${({ bgcolor }) => {
      switch (bgcolor) {
        case 'primary':
          return '#404559';
        case 'success':
          return '#2f6a2f';
        case 'warn':
          return '#925d07';
        case 'error':
          return '#990000';
        case 'cancel':
          return '#4d4d4d';
        default:
          return '#1c497d';
      }
    }};
    color: white;
  }
`;

export default Button;
