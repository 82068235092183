import * as types from '../actionType';

const initState = {
  area: [
    {
      Id: 19,
      Name: '台灣',
    },
  ],
  educationDegree: [
    {
      Id: 77,
      Name: '國小',
    },
    {
      Id: 78,
      Name: '國中',
    },
    {
      Id: 79,
      Name: '高中',
    },
    {
      Id: 80,
      Name: '大學',
    },
    {
      Id: 81,
      Name: '社會',
    },
  ],
  schoolYear: [
    {
      Id: 6,
      Name: '106',
    },
    {
      Id: 7,
      Name: '107',
    },
  ],
  schoolName: [
    {
      Id: 10,
      Name: '建國中學',
    },
    {
      Id: 11,
      Name: '台南一中',
    },
  ],
  schoolGroup: [
    {
      Id: 8,
      Name: '自然組',
    },
    {
      Id: 9,
      Name: '社會組',
    },
  ],
  schoolGrade: [
    {
      Id: 55,
      Name: '一年級',
    },
    {
      Id: 56,
      Name: '二年級',
    },
    {
      Id: 57,
      Name: '三年級',
    },
  ],
  examTime: [
    {
      Id: 58,
      Name: '第一次',
    },
    {
      Id: 59,
      Name: '第二次',
    },
    {
      Id: 60,
      Name: '第三次',
    },
  ],
  bookVersion: [
    {
      Id: 52,
      Name: '翰林',
    },
    {
      Id: 53,
      Name: '南一',
    },
  ],
  publisher: [
    {
      Id: 50,
      Name: '翰林',
    },
    {
      Id: 51,
      Name: '南一',
    },
  ],
  useType: [
    {
      Id: 83,
      Name: '範例',
    },
    {
      Id: 84,
      Name: '作業',
    },
    {
      Id: 85,
      Name: '類題',
    },
  ],
  subject: [
    {
      Id: 12,
      Name: '數學',
    },
    {
      Id: 13,
      Name: '英文',
    },
    {
      Id: 61,
      Name: '國文',
    },
    {
      Id: 62,
      Name: '物理',
    },
    {
      Id: 63,
      Name: '化學',
    },
    {
      Id: 64,
      Name: '社會',
    },
  ],
  examType: [
    {
      Id: 22,
      Name: '學測',
    },
    {
      Id: 23,
      Name: '學資',
    },
    {
      Id: 24,
      Name: '指考(自然組)',
    },
    {
      Id: 25,
      Name: '指考(社會組)',
    },
    {
      Id: 26,
      Name: '北模題',
    },
    {
      Id: 27,
      Name: '中模題',
    },
    {
      Id: 28,
      Name: '南模題',
    },
    {
      Id: 29,
      Name: 'AMC競賽',
    },
    {
      Id: 41,
      Name: '段考',
    },
    {
      Id: 86,
      Name: '講義',
    },
  ],
  questionBankType: [
    {
      Id: 1,
      Name: '專家特選',
    },
    {
      Id: 2,
      Name: '模擬考',
    },
  ],
  questionBankCurrency: [
    {
      Id: 1,
      Name: '台灣',
    },
    {
      Id: 2,
      Name: '大陸',
    },
  ],
  questionBankSubject: [
    {
      Id: 1,
      Name: '數學',
    },
    {
      Id: 2,
      Name: '英文',
    },
  ],
  multiSelectionMode: [],
  knowledge: [],
};

function QuestionSourceState(state = initState, action) {
  switch (action.type) {
    case types.QUESTION_SOURCE_SUCCESS: {
      return action.result;
    }
    default:
      return state;
  }
}

export default QuestionSourceState;
