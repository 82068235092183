import React, { useState, useCallback } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import Skeleton from '@material-ui/lab/Skeleton';
import useQuestionBankSubjects from '../../hooks/useQuestionBankSubjects';
import useQuestionSubjects from '../../hooks/useQuestionSubjects';
import QuestionBankSubjectFormDialog from '../../components/Dialogs/QuestionBankSubjectFormDialog';
import DeleteDialog from '../../components/Dialogs/DeleteDialog';
import {
  Box,
  Typography,
  Paper,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(3),
  },
  thead: {
    backgroundColor: theme.palette.grey[50],
    '& th': {
      color: theme.palette.grey[800],
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '1.4rem',
    },
  },
  subject: {
    fontSize: '1rem',
  },
}));

const SubjectType = (props) => {
  const { match } = props;
  const {
    questionBankSubjects,
    loading,
    error,
    features,
  } = useQuestionBankSubjects();
  const { ...questionSubjectHook } = useQuestionSubjects();
  const [isProcessing, setIsProcessing] = useState(false);
  const [formState, setFormState] = useState({
    isOpen: false,
    subject: {
      Id: -1,
    },
  });
  const [delState, setDelState] = useState({
    open: false,
    id: -1,
    name: '',
  });
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const createOrUpdateQuestionBankSubject = useCallback(
    (subject) => {
      let promise = null;

      if (subject.Id === -1) {
        promise = features.add(subject);
      } else {
        promise = features.edit(subject);
      }

      promise.then((response) => {
        if (response.Status === 0) {
          Swal.fire('儲存失敗', _.join(response.Message, '<br>'), 'error');
        } else {
          setFormState({ ...formState, isOpen: false });
          enqueueSnackbar(subject.id === -1 ? '新增科目成功' : '編輯科目成功', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            variant: 'success',
          });
        }
      });

      return promise;
    },
    [features, formState, enqueueSnackbar],
  );

  const delQuestionBankSubject = useCallback(() => {
    features
      .del(delState.id)
      .then((response) => {
        enqueueSnackbar('刪除科目 [' + delState.name + '] 成功', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          variant: 'success',
        });

        setDelState({ ...delState, open: false, id: -1, name: '' });
      })
      .catch((err) => {
        Swal.fire('刪除失敗', err.message, 'error');
        setDelState({ ...delState, open: false, id: -1, name: '' });
      });
  }, [features, delState, enqueueSnackbar]);

  return (
    <div className="sale-exam-management exam-type pagefadeIn">
      <BreakCrumbs path={match.url}></BreakCrumbs>
      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Typography variant="h5" color="textPrimary" gutterBottom>
          商品科目管理
        </Typography>
        <Divider light />
        <TableContainer className={classes.table}>
          <Table aria-label="question bank subjects table">
            <TableHead className={classes.thead}>
              <TableRow>
                <TableCell>科目名稱</TableCell>
                <TableCell>對應的題目科目</TableCell>
                <TableCell>上下線</TableCell>
                <TableCell align="right">功能</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={4} align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddOutlinedIcon />}
                    disabled={isProcessing}
                    onClick={() => {
                      setFormState({
                        ...formState,
                        subject: { Id: -1 },
                        isOpen: true,
                      });
                    }}>
                    新增科目
                  </Button>
                </TableCell>
              </TableRow>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                  </TableCell>
                </TableRow>
              ) : error === '' ? (
                questionBankSubjects.map((subject) => (
                  <TableRow key={subject.Id} hover>
                    <TableCell className={classes.subject}>
                      {subject.Name}
                    </TableCell>
                    <TableCell>
                      {_.isInteger(subject.QuestionSubjectId) === false ||
                      subject.QuestionSubjectId <= 0 ? (
                        <Alert severity="error">請設定對應的題目科目!</Alert>
                      ) : _.isEmpty(
                          _.find(questionSubjectHook.questionSubjects, [
                            'Id',
                            subject.QuestionSubjectId,
                          ]),
                        ) ? (
                        subject.QuestionSubjectId
                      ) : (
                        _.find(questionSubjectHook.questionSubjects, [
                          'Id',
                          subject.QuestionSubjectId,
                        ]).Name
                      )}
                    </TableCell>
                    <TableCell>
                      <FormControlLabel
                        value="start"
                        control={<Switch color="primary" size="small" />}
                        label={subject.Status === 1 ? '上線中' : '已下線'}
                        labelPlacement="start"
                        checked={subject.Status === 1}
                        disabled={isProcessing}
                        onChange={(e) => {
                          setIsProcessing(true);
                          features
                            .edit({
                              ...subject,
                              Status: e.target.checked ? 1 : 0,
                            })
                            .catch((err) => {
                              Swal.fire('設定失敗', err.message, 'error');
                            })
                            .then(() => {
                              setIsProcessing(false);
                            });
                        }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        color="primary"
                        startIcon={<BuildOutlinedIcon />}
                        variant="outlined"
                        disabled={isProcessing}
                        onClick={() => {
                          setFormState({
                            isOpen: true,
                            subject: subject,
                          });
                        }}>
                        編輯
                      </Button>
                      <Box ml={1} display="inline-block" />
                      <Button
                        color="secondary"
                        startIcon={<DeleteForeverOutlinedIcon />}
                        variant="outlined"
                        disabled={isProcessing}
                        onClick={() => {
                          setDelState({
                            open: true,
                            id: subject.Id,
                            name: subject.Name,
                          });
                        }}>
                        刪除
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Alert severity="error">
                      <AlertTitle>發生錯誤</AlertTitle>
                      {error}
                    </Alert>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <QuestionBankSubjectFormDialog
        label={
          formState.subject.Id === -1
            ? '新增商品科目'
            : '編輯商品科目 [' + formState.subject.Name + ']'
        }
        open={formState.isOpen}
        close={() => {
          setFormState({ ...formState, isOpen: false });
        }}
        action={createOrUpdateQuestionBankSubject}
        subject={formState.subject}
        questionSubjects={questionSubjectHook.questionSubjects}
      />

      <DeleteDialog
        open={delState.open}
        setOpen={(open) => {
          setDelState({ ...delState, open: open });
        }}
        action={delQuestionBankSubject}
        title="刪除商品科目確認">
        確認要刪除 {delState.name} 嗎？
      </DeleteDialog>
    </div>
  );
};

export default SubjectType;
