import { useState, useEffect } from 'react';
import { getQuestionSubjects } from '../Util/fetchMethods';
import _ from 'lodash';

const useQuestionSubjects = () => {
  const [questionSubjects, setQuestionSubjects] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    getQuestionSubjects()
      .then((response) => {
        if (response.Status === 0) {
          setError(_.join(response.Message, '<br>'));
        } else {
          setQuestionSubjects(response.Result);
        }
      })
      .catch((err) => {
        setError(err.message);
      })
      .then(() => {
        setLoading(false);
      });
    return () => {
      setError('');
      setLoading(false);
      setQuestionSubjects([]);
    };
  }, []);

  return {
    questionSubjects,
    loading,
    error,
  };
};

export default useQuestionSubjects;
