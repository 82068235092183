import React, { useEffect, useRef, useState } from 'react';
import useKnowledge from '../hooks/useKnowledge';
import { Box, Chip, Popover, Tabs, Tab, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import SubjectRoundedIcon from '@material-ui/icons/SubjectRounded';
import _ from 'lodash';
import SubjectKnowledgeTabPanel from './SubjectKnowledgeTabPanel';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',

    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  popoverPaper: {
    textAlign: 'left',
    width: '620px',
    maxHeight: '430px',
    marginTop: theme.spacing(1),
    backgroundColor: '#ecf6ff',
    border: '2px solid #4E546C',
    color: theme.palette.grey[900],
    padding: theme.spacing(1),
    boxShadow:
      '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20)',
  },
  knowledgeHeader: {
    fontSize: '0.9rem',
    fontWeight: '600',
    marginBottom: theme.spacing(1),

    '& > svg': {
      verticalAlign: 'bottom',
      marginRight: theme.spacing(1),
    },
  },
  tabs: {
    backgroundColor: '#7D84A1',
    color: '#fff',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1, 0),
  },
  tabsIndicator: {
    backgroundColor: '#fff',
    marginRight: theme.spacing(1),
  },
  tabRoot: {
    fontSize: '1.4rem',
    fontWeight: '600',
    letterSpacing: '1px',
  },
  tabSelected: {
    color: '#0FC29E',
  },
  tabDisabled: {
    opacity: '1 !important',
  },
}));

const KnowledgeSelect = (props) => {
  const classes = useStyles();
  const { initKnowledge, setKnowledge } = props;
  const { flatKnowledge, knowledges, loadKnowledge } = useKnowledge();
  const [subjectTabIndex, setSubjectTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState([]);
  const anchorRef = useRef(null);
  const tabsActions = React.useRef(null);
  const selectedSize = 'small';
  const popperOpen = Boolean(anchorEl);

  useEffect(() => {
    if (flatKnowledge.length <= 0) {
      setIsLoading(true);

      loadKnowledge().then(() => {
        setIsLoading(false);
      });
    }

    return () => {
      setIsLoading(false);
    };
  }, [flatKnowledge]);

  useEffect(() => {
    if (flatKnowledge.length > 0) {
      let initSelected = [];

      if (initKnowledge.length > 0) {
        _.forEach(initKnowledge, (knowledgeId) => {
          const initIndex = _.findIndex(
            flatKnowledge,
            (knowledge) => knowledge.id === knowledgeId,
          );

          initSelected.push(flatKnowledge[initIndex]);
        });
      }

      setSelected(initSelected);

      if (initSelected.length > 0) {
        setSubjectTabIndex(initSelected[0].subject.id);
      }
    }
  }, [flatKnowledge, initKnowledge]);

  const handleOpenSelecter = (e) => {
    setAnchorEl(anchorRef.current);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
    setSubjectTabIndex(0);
  };

  const handleOpenKnowledges = () => {
    setSubjectTabIndex(selected.length > 0 ? selected[0].subject.id : 0);
    tabsActions.current.updateIndicator();
  };

  const isSelected = (knowledgeId) => {
    return selected.filter((select) => select.id === knowledgeId).length > 0;
  };

  const changeSelected = (knowledge, checked) => {
    let newSelected = [...selected];

    if (checked === true) {
      const addKnowledge = _.find(
        flatKnowledge,
        (flatKnow) => flatKnow.id === knowledge.id,
      );
      newSelected.push(addKnowledge);
    } else if (checked === false) {
      newSelected = newSelected.filter(
        (selected) => selected.id !== knowledge.id,
      );
    }

    setSelected(newSelected);
    setKnowledge(_.map(newSelected, (v) => v.id));
  };

  return (
    <>
      <div className={classes.root} ref={anchorRef}>
        {selected &&
          selected.length > 0 &&
          _.map(selected, (selectedOption) => (
            <Chip
              key={selectedOption.id}
              size={selectedSize || 'middle'}
              onDelete={() => {
                changeSelected(selectedOption, false);
              }}
              label={selectedOption.name}
            />
          ))}
        <Chip
          color="secondary"
          size={selectedSize || 'middle'}
          icon={<AddCircleRoundedIcon />}
          disabled={isLoading}
          label="選擇知識點"
          onClick={handleOpenSelecter}
        />
      </div>
      <Popover
        open={popperOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        onEntered={handleOpenKnowledges}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: classes.popoverPaper,
        }}>
        <div className={classes.knowledgeHeader}>
          <SubjectRoundedIcon />
          知識點清單
        </div>
        <Grid
          container
          direction="row"
          justifycontent="center"
          alignItems="flex-start"
          spacing={2}>
          <Grid item xs={12} md={4}>
            <Tabs
              action={tabsActions}
              orientation="vertical"
              variant="scrollable"
              indicatorColor="primary"
              value={subjectTabIndex}
              onChange={(e, newSubjectTabIndex) => {
                setSubjectTabIndex(newSubjectTabIndex);
              }}
              className={classes.tabs}
              classes={{
                indicator: classes.tabsIndicator,
              }}>
              <Tab
                label="科目"
                value={0}
                disabled
                classes={{
                  root: classes.tabRoot,
                  disabled: classes.tabDisabled,
                }}
              />

              {_.map(knowledges, (knowledge) => (
                <Tab
                  key={'subject_tab_' + knowledge.id}
                  label={knowledge.name}
                  value={knowledge.id}
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                />
              ))}
            </Tabs>
          </Grid>

          <Grid item xs={12} md={8}>
            <Box hidden={subjectTabIndex !== 0}>
              <div></div>
            </Box>

            {_.map(knowledges, (knowledge, index) => (
              <SubjectKnowledgeTabPanel
                key={knowledge.id}
                value={subjectTabIndex}
                index={knowledge.id}
                subjectKnowledges={knowledge.knowledgeDtoChildren}
                isSelected={isSelected}
                changeSelected={changeSelected}
                selected={selected}
              />
            ))}
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default KnowledgeSelect;
