import React from 'react';
import { NavLink } from 'react-router-dom';
import '../style/components/Notification.scss';

const types = {
  1: {
    text: '有人發出新的訂單囉',
    link: '/MemberManagement/BougthRecord',
  },
  2: {
    text: '新的會員加入囉',
    link: '/MemberManagement',
  },
  3: {
    text: '新題庫被建立了',
    link: '/SaleExam/ExamList',
  },
  4: {
    text: '新課程被建立了',
    link: '/Secret/SecretList',
  },
};

const Notification = ({
  isShowInformation,
  adminName,
  logoutSuccessFunc,
  removeNotification,
  notificationArray,
}) => {
  return (
    <div
      className={
        isShowInformation ? 'account-information active' : 'account-information'
      }>
      <div className="admin-name">{adminName} 您好!</div>
      <div className="notification-box">
        {notificationArray.map((item, index) => {
          if (types[item.Type] === undefined)
            return (
              <NavLink
                key={index}
                className="fadeIn"
                to="/"
                onClick={() => {
                  removeNotification(index);
                }}>
                不明訊息
              </NavLink>
            );

          return (
            <NavLink
              key={index}
              className="fadeIn"
              to={
                item.MemberId
                  ? `${types[item.Type].link}/${item.MemberId}`
                  : `${types[item.Type].link}`
              }
              onClick={() => {
                removeNotification(index);
              }}>
              {item.Message}
            </NavLink>
          );
        })}
      </div>
      <div className="btn-group">
        <button className="btn cancel" onClick={logoutSuccessFunc}>
          登出
        </button>
      </div>
    </div>
  );
};

export default Notification;
