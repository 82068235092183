import React, { useEffect, useState } from 'react';
import RestaurantLogo from '../../images/restaurant_logo.svg';
import BackBtn from '../../components/Bonus/BackBtn';
import CafeShiftLogin from '../../components/Bonus/CafeShiftLogin';
import MakeupBonus from '../../components/Bonus/MakeupBonus';
import bonusAPI from '../../middleware/bonusAPI';
import Swal from 'sweetalert2';
import { intersection, isArray } from 'lodash';
import '../../style/bonus.scss';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';

const allowRoles = [1, 2, 5];

const Markup = () => {
  const [pageStep, setPageStep] = useState('login');
  const [cafeShiftAuth, setCafeShiftAuth] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [makeupBonusResult, setMakeupBonusResult] = useState(undefined);

  useEffect(() => {
    if (cafeShiftAuth) {
      const roles = cafeShiftAuth.roles
        .split(',')
        .map((val) => parseInt(val, 10));

      if (intersection(roles, allowRoles).length <= 0) {
        Swal.fire({
          icon: 'error',
          title: '帳號權限不足',
        });
        setCafeShiftAuth(undefined);
      } else {
        setPageStep('makeup');
      }
    }
  }, [cafeShiftAuth]);

  const cafeShiftLogin = (username, password) => {
    setIsLoading(true);

    bonusAPI
      .cafeShiftLogin(username, password)
      .then(
        (res) => {
          setCafeShiftAuth({
            ...res,
            cellPhone: username,
          });
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: '登入失敗',
          });
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const callMakeupBonus = (contact, amount, cafeShiftPhone, makeupDate) => {
    setIsLoading(true);

    bonusAPI
      .makeupPoints(contact, amount, cafeShiftPhone, makeupDate)
      .then(
        (res) => {
          setMakeupBonusResult({
            bonus: res,
            contact: contact,
            amount: amount,
            makeupDate: makeupDate,
          });
          setPageStep('result');
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: '補登紅利發生錯誤',
            html: isArray(error.messages)
              ? error.messages.join('<br />')
              : error.message,
          });
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="bonus">
      <div className="bonus__logo">
        <img src={RestaurantLogo} />
        <div className="bonus__logo-btn">
          <BackBtn />
        </div>
      </div>

      {pageStep === 'login' && (
        <CafeShiftLogin cafeShiftLogin={cafeShiftLogin} isLoading={isLoading} />
      )}

      {pageStep === 'makeup' && (
        <MakeupBonus
          cafeShiftAuth={cafeShiftAuth}
          callMakeupBonus={callMakeupBonus}
          isLoading={isLoading}
        />
      )}

      {pageStep === 'result' && (
        <Grid
          container
          direction="row"
          justifycontent="space-evenly"
          alignItems="center">
          <Grid item xs={12}>
            <div className="bonus__guest-title">獲得紅利點數</div>
          </Grid>

          <Grid item xs={12}>
            <div className="bonus__point">
              <AddIcon />
              {makeupBonusResult.bonus}
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="bonus__info">
              會員帳號
              <span>{makeupBonusResult.contact}</span>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="bonus__info">
              發票金額
              <span>{makeupBonusResult.amount}</span>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="bonus__info">
              補登日期
              <span>{makeupBonusResult.makeupDate}</span>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Markup;
