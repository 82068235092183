import instance from './API';

const knowledgePath = '/Knowledge';

const knowledgeAPI = {
  get: () => {
    return instance.get(knowledgePath);
  },
};

export default knowledgeAPI;
