import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import searchImg from '../../images/Search.png';
import noProductImg from '../../images/NoProduct.png';
import Pagination from '../../components/Pagination';
import { connect } from 'react-redux';
import { logoutSuccess } from '../../store/action';
import { searchPopularFeature } from '../../Util/fetchMethods';
import '../../style/components/Advertisting/popular.scss';
import '../../style/components/Advertisting/add-popular.scss';

AddPopular.propTypes = {
  isVisiable: PropTypes.bool,
  onSwitch: PropTypes.func,
  onAddProducts: PropTypes.func,
};

function AddPopular({ isVisiable, onSwitch, onAddProducts }) {
  const [searchValue, setSearchValue] = useState('');
  const [searchProducts, setSearchProducts] = useState([]);
  const [choiceProduct, setChoiceProduct] = useState([]);
  const [countOfPage] = useState(10);
  const [currPage, setCurrPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (isVisiable) {
      handleAPI('', choiceProduct, 1);
    }
  }, [isVisiable]);

  const initState = () => {
    handleClearInput();

    setSearchProducts([]);
    setChoiceProduct([]);

    onSwitch();
  };

  const handleClearInput = () => {
    setSearchValue('');
  };

  const handleOnChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyPress = (e) => {
    let keyword = searchValue.trim();

    if (e.key === 'Enter') {
      handleAPI(keyword, choiceProduct, 1);
    }
  };

  const handleAPI = (keyword, choiceProduct, page) => {
    searchPopularFeature({
      keyword: keyword,
      status: 1,
      page: page,
      count: countOfPage,
    })
      .then((response) => {
        if (response.status === 401) {
          // token過期 導回登入頁面
          this.props.logoutSuccess();
        }

        setSearchValue(keyword);
        setSearchProducts(response.Result.QuestionBankList);
        setCurrPage(response.Result.CurrentPage);
        setTotalPages(response.Result.TotalPages);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const toggleCheckbox = (item, e) => {
    if (choiceProduct.length === 8 && e.target.checked === true) {
      e.target.checked = false;
      return;
    }

    let newArray = [...choiceProduct];

    if (
      newArray.some((product) => product.QuestionBankId === item.QuestionBankId)
    ) {
      let filtNewArray = newArray.filter(
        (product) => product.QuestionBankId !== item.QuestionBankId,
      );

      setChoiceProduct(filtNewArray);
    } else {
      newArray.push(item);
      setChoiceProduct(newArray);
    }
  };

  const handleChangePage = (page) => {
    let keyword = searchValue.trim();
    handleAPI(keyword, choiceProduct, page);
  };

  let products =
    searchProducts.length > 0 ? (
      <div className="product-list-box">
        <div className="row">
          {searchProducts.map((item, index) => (
            <div
              key={item.QuestionBankId}
              className="col-6 col-phone-12 prodct-box">
              <input
                type="checkbox"
                id={item.QuestionBankId}
                checked={
                  choiceProduct.find(
                    (x) => x.QuestionBankId === item.QuestionBankId,
                  ) || false
                }
                onChange={(e) => {
                  toggleCheckbox(item, e);
                }}
              />
              <img src={item.ImageUrl} width="50px" height="50px" alt="" />
              <div className="product-name">
                ({item.SaleType}){item.Name}
              </div>
            </div>
          ))}
        </div>
      </div>
    ) : (
      <div className="product-list-box">
        <img src={noProductImg} alt=""></img>
        <div className="notice">沒發現商品</div>
      </div>
    );

  return (
    <div
      className={
        isVisiable ? 'AddPopular-shadow onVisible fadeIn' : 'AddPopular-shadow'
      }>
      <div className="add-block">
        <button className="btn-close" onClick={initState}>
          X
        </button>
        <div className="title">
          新增商品 ※查詢結果以 (銷售類別)題庫名稱 顯示。
        </div>
        <p>銷售類別：1.完整詳解, 2.無文字詳解, 3.無影片詳解, 4.無詳解</p>
        <p>請使用題庫名稱與介紹的關鍵字做搜尋</p>
        <div className="search-box">
          <label>
            <img src={searchImg} alt="" />
            <input
              type="text"
              placeholder="關鍵字搜尋"
              value={searchValue}
              onChange={handleOnChange}
              onKeyPress={handleKeyPress}
            />
            <div
              className={searchValue.length > 0 ? 'close open' : 'close'}
              onClick={handleClearInput}>
              X
            </div>
          </label>
        </div>
        <hr />
        <div>{products}</div>
        <Pagination
          currPage={currPage}
          totalPage={totalPages}
          onChangPage={handleChangePage}
        />
        <hr />
        <div className="notice">
          你已選擇了{choiceProduct.length}個商品，最多可選８個商品
        </div>
        <div className="btn-group">
          <button
            className="btn save"
            onClick={() => {
              if (choiceProduct.length === 0) return;
              onAddProducts(choiceProduct);
              initState();
            }}>
            確認
          </button>
          <button className="btn cancel" onClick={initState}>
            取消
          </button>
        </div>
      </div>
    </div>
  );
}

export default connect(null, {
  logoutSuccess,
})(AddPopular);
