import React from 'react';
import { Box, Typography, Chip, LinearProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import { seconds2time } from '../../Util/Util';

const VideoFileStatus = (props) => {
  const { video, videoQueue } = props;

  return (
    <Box>
      {videoQueue && videoQueue.status === 'upload' && (
        <>
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress />
            </Box>
            <Box minWidth={50}>
              ({videoQueue.uploadPart} / {videoQueue.uploadTotalPart})
            </Box>
          </Box>
          <Box textAlign="left">
            <Typography variant="body2" color="textSecondary">
              影片分割上傳中...
            </Typography>
          </Box>
        </>
      )}

      {videoQueue && videoQueue.status === 'getVideoInfo' && (
        <Box>正在分析影片...</Box>
      )}

      {videoQueue && videoQueue.status === 'standbyUpload' && (
        <Box>等待上傳中...</Box>
      )}

      {videoQueue && videoQueue.status === 'error' && (
        <Alert severity="error">{videoQueue.errorMessage}</Alert>
      )}

      {videoQueue && videoQueue.status === 'done' && (
        <Alert severity="success">檔案上傳完成!</Alert>
      )}

      {!videoQueue && video && (
        <Chip
          variant="outlined"
          color={video.videoName ? 'primary' : 'default'}
          icon={
            video.videoName ? (
              <VideocamOutlinedIcon />
            ) : (
              <VideocamOffOutlinedIcon />
            )
          }
          label={
            video.videoName
              ? '影片長度 ' + seconds2time(video.duration) + ' 秒'
              : '無影片'
          }
        />
      )}
    </Box>
  );
};

export default VideoFileStatus;
